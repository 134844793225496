import React from 'react';
import './loader.scss';

const CircleLoader = () => {
  return (
    <div className='loader-container'>
      <i className='fa fa-spinner fa-3x fa-spin' aria-hidden='true' />
    </div>
  );
};
export default CircleLoader;
