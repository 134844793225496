/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  setAlignment,
  setBtnType,
  customPaddingtop,
  customPaddingleft,
  customPaddingbottom,
  customPaddingright,
  buttonFontColor,
  buttonBgColor,
  fontType,
  fontSize,
} from '../../../../redux/multipleChoice/mcq.action';
import FontHandlerComponent from '../../../customizeComponentLayout/fontHandler';
import { customStyles } from '../../../customizeComponentLayout/styles';
import ColorPickerComponent from '../../../customizeComponentLayout/colorPicker';
import AlignmentComponent from '../../../customizeComponentLayout/alignment';
import SpaceComponent from '../../../customizeComponentLayout/spaceComponent';
import { getButtonDisplayType } from '../../utils';

const mapState = ({ mcqform }: any) => ({
  alignment: mcqform.alignment,
  btnType: mcqform.btnType,
  customPaddingtop: mcqform.customPaddingtop,
  customPaddingleft: mcqform.customPaddingleft,
  customPaddingbottom: mcqform.customPaddingbottom,
  customPaddingright: mcqform.customPaddingright,
  buttonFontColor: mcqform.buttonFontColor,
  buttonBgColor: mcqform.buttonBgColor,
  fontFamily: mcqform.fontFamily,
  fontSize: mcqform.fontSize,
  fontWeight: mcqform.fontWeight,
});

const mapDispatch = (dispatch: Function) => ({
  Setalignment: (form: {}) => dispatch(setAlignment(form)),
  SetButtonType: (data: {}) => dispatch(setBtnType(data)),
  SetcustomPaddingtop: (form: {}) => dispatch(customPaddingtop(form)),
  SetcustomPaddingleft: (form: {}) => dispatch(customPaddingleft(form)),
  SetcustomPaddingbottom: (form: {}) => dispatch(customPaddingbottom(form)),
  SetcustomPaddingright: (form: {}) => dispatch(customPaddingright(form)),
  ButtonFontColor: (form: {}) => dispatch(buttonFontColor(form)),
  ButtonBgColor: (form: {}) => dispatch(buttonBgColor(form)),
  HandleFontFamily: (form: {}) => dispatch(fontType(form)),
  HandleFontSize: (form: {}) => dispatch(fontSize(form)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = { fontFamilyPropsList: any } & ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const ButtonCustomize = (props: Props) => {
  const { buttonFontColor, buttonBgColor } = props;

  return (
    <>
      <FontHandlerComponent
        componentType='Type'
        setFontProperty={props.SetButtonType}
        fontPropertyValue={getButtonDisplayType(props.btnType)}
        options={customStyles.buttonType}
      />
      <AlignmentComponent alignmentValue={props.alignment} SetAlignmentValue={props.Setalignment} />
      <SpaceComponent
        componentType='Padding'
        left={props.customPaddingleft}
        top={props.customPaddingtop}
        right={props.customPaddingright}
        bottom={props.customPaddingbottom}
        SetLeft={props.SetcustomPaddingleft}
        SetTop={props.SetcustomPaddingtop}
        SetRight={props.SetcustomPaddingright}
        SetBottom={props.SetcustomPaddingbottom}
      />
      <FontHandlerComponent
        componentType='Font Family'
        setFontProperty={props.HandleFontFamily}
        fontPropertyValue={props.fontFamily}
        options={customStyles.fontFamily}
        customFonts={props.fontFamilyPropsList}
      />
      <FontHandlerComponent
        componentType='Font Size'
        setFontProperty={props.HandleFontSize}
        fontPropertyValue={props.fontSize}
        options={customStyles.fontSize}
      />
      <ColorPickerComponent
        type='buttonFontColor'
        title='Font Color'
        SetColor={props.ButtonFontColor}
        color={buttonFontColor}
      />
      <ColorPickerComponent
        type='buttonBgColor'
        title='Background Color'
        SetColor={props.ButtonBgColor}
        color={buttonBgColor}
      />
    </>
  );
};

export default connector(ButtonCustomize);
