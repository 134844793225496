import { mergeAttributes, Node } from '@tiptap/core';
import { optimizeStyle } from './utilityFunctions';

export interface TableColumnOptions {
  HTMLAttributes: Record<string, never>;
}

export const CustomizedTableColumn = Node.create<TableColumnOptions>({
  name: 'tableColumn',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  content: '',
  isolating: true,
  tableRole: 'column',

  addAttributes() {
    return {
      span: {
        default: null,
        parseHTML: (element) => element.getAttribute('span'),
      },
      width: {
        default: null,
        parseHTML: (element) => element.getAttribute('width'),
      },
    };
  },

  parseHTML() {
    return [{ tag: 'col' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['col', optimizeStyle(mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)), 0];
  },
});
