import React, { useMemo } from 'react';
import { ReactComponent as SvgRemoveLinkIcon } from '../editor/editorFormattingIcons/removeLinkIcon.svg';
import { useLoreeTranslation } from '@crystaldelta/loree-util-i18n-react';

interface LinkInfoProps {
  url: string;
  onRemove: () => void;
}

export function LinkInfo(props: Readonly<LinkInfoProps>) {
  const { url, onRemove } = props;
  const { t: translate } = useLoreeTranslation();

  const hyperLink = useMemo(() => (url.startsWith('#', 0) ? '//' + url : url), [url]);

  return (
    <div className='p-1' data-testid='linkInfo'>
      <a
        href={hyperLink}
        target='_blank'
        style={url.startsWith('#', 0) ? { pointerEvents: 'none' } : {}}
        rel='noreferrer'
      >
        {url}
      </a>
      <span
        className='ml-2 float-right justify-content-end'
        data-testid='removeLinkIcon'
        style={{ marginTop: '-2px', cursor: 'pointer' }}
        title={translate('global.remove')}
      >
        <SvgRemoveLinkIcon onClick={onRemove} />
      </span>
    </div>
  );
}
