import React, { useState } from 'react';

interface ImagePreviewContainerInterface {
  selectedMediaDetails: {
    elementType: string;
    name: string;
  };
  handleConfirmState: Function;
  setDescription: Function;
}

const IconPreviewRightContainer = (props: ImagePreviewContainerInterface) => {
  const { selectedMediaDetails, handleConfirmState, setDescription } = props;
  const [iconDescription, setIconDescription] = useState('');
  return (
    <>
      <div className='d-flex flex-column mb-2'>
        <label id='lint-icon-name' className='pb-1 mb-0'>
          Icon name *
        </label>
        <input
          id='lint-icon-name-input'
          className='media-modal-file-name p-1'
          type='text'
          value={selectedMediaDetails.name}
          autoComplete='off'
          disabled
        />
      </div>
      <div className='d-flex flex-column'>
        <label className='pb-1 mb-0' id='lint-media-modal-label-for-alt-text'>
          Description *
        </label>
        <input
          id='lint-icon-description-input'
          type='text'
          className='p-1'
          value={iconDescription.replace(/ +/g, ' ')}
          maxLength={120}
          style={{ outline: 'none' }}
          onChange={(event) => {
            const value = event.target.value;
            const confirmButtonState = value.trim() === '';
            handleConfirmState(confirmButtonState);
            setIconDescription(value);
            setDescription(value);
          }}
        />
      </div>
    </>
  );
};

export default IconPreviewRightContainer;
