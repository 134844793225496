// This file defines a number of table-related commands.

import { EditorState, TextSelection, Transaction } from 'prosemirror-state';
import { Fragment, Node as ProseMirrorNode, NodeType, ResolvedPos } from 'prosemirror-model';

import { Rect, TableMap } from './tableMap';
import { CellSelection } from './cellSelection';
import {
  addColSpan,
  cellAround,
  cellWrapping,
  columnIsHeader,
  getRowsFromTable,
  getTableFromCellPosition,
  isInTable,
  moveCellForward,
  removeColSpan,
  selectionCell,
  setAttr,
  tableNodeTypes,
} from './util';
import { ensure } from '@crystaldelta/loree-util-general';

interface RectExtra {
  tableStart: number;
  map: TableMap;
  table: ProseMirrorNode;
}

export type ExtraRect = Rect & RectExtra;
// Helper to get the selected rectangle in a table, if any. Adds table
// map, table node, and table start offset to the object for
// convenience.
export function selectedRect(state: EditorState): ExtraRect {
  const sel = state.selection;
  const $pos = ensure(selectionCell(state));
  const [table, tableStart] = getTableFromCellPosition($pos);
  const map = TableMap.get(table);
  let rect: Rect & Partial<RectExtra>;
  if (sel instanceof CellSelection)
    rect = map.rectBetween(sel.$anchorCell.pos - tableStart, sel.$headCell.pos - tableStart);
  else rect = map.findCell($pos.pos - tableStart);
  rect.tableStart = tableStart;
  rect.map = map;
  rect.table = table;
  return rect as ExtraRect;
}

// Add a column at the given position in a table.
export function addColumn(
  tr: Transaction,
  { map, tableStart, table }: RectExtra,
  col: number,
  placeholder?: Fragment[],
) {
  let refColumn: number | null = col > 0 ? -1 : 0;
  if (columnIsHeader(map, table, col + refColumn))
    refColumn = col == 0 || col == map.width ? null : 0;

  for (let row = 0; row < map.height; row++) {
    const index = row * map.width + col;
    // If this position falls inside a col-spanning cell
    if (col > 0 && col < map.width && map.map[index - 1] == map.map[index]) {
      const pos = map.map[index];
      const cell = ensure(table.nodeAt(pos));
      tr.setNodeMarkup(
        tr.mapping.map(tableStart + pos),
        null,
        addColSpan(cell.attrs, col - map.getColumnIndexFromPos(pos)),
      );
      // Skip ahead if rowspan > 1
      row = row + cell.attrs['rowspan'] - 1;
    } else {
      const type =
        refColumn == null
          ? tableNodeTypes(table.type.schema)['cell']
          : ensure(table.nodeAt(map.map[index + refColumn])).type;
      const pos = map.positionAt(row, col, table);
      const createdNode = createNewTableCell(type, placeholder);
      tr.insert(tr.mapping.map(tableStart + pos), ensure(createdNode));
    }
  }
  return tr;
}

function createNewTableCell(type: NodeType, placeholder?: Fragment[]) {
  let createdNode;
  if (placeholder) {
    const content = type?.name === 'tableHeader' ? placeholder[1] : placeholder[0];
    createdNode = type.createAndFill(
      setAttr({ style: 'border: 1px solid #000000; padding: 5px;' }, '', null),
      content,
    );
  } else {
    createdNode = type.createAndFill();
  }
  return createdNode;
}

// :: (EditorState, dispatch: ?(tr: Transaction)) → bool
// Command to add a column before the column with the selection.
export function addColumnBefore(
  state: EditorState,
  placeholder?: Fragment[],
  dispatch?: (tr: Transaction) => void,
) {
  if (!isInTable(state)) return false;
  const rect = selectedRect(state);
  if (rect.left === 0) {
    return false;
  }
  if (dispatch) {
    dispatch(addColumn(state.tr, rect, rect.left, placeholder));
  }
  return true;
}

// :: (EditorState, dispatch: ?(tr: Transaction)) → bool
// Command to add a column after the column with the selection.
export function addColumnAfter(
  state: EditorState,
  placeholder?: Fragment[],
  dispatch?: (tr: Transaction) => void,
) {
  if (!isInTable(state)) return false;
  if (dispatch) {
    const rect = selectedRect(state);
    dispatch(addColumn(state.tr, rect, rect.right, placeholder));
  }
  return true;
}

export function removeColumn(tr: Transaction, { map, table, tableStart }: RectExtra, col: number) {
  const mapStart = tr.mapping.maps.length;
  for (let row = 0; row < map.height; ) {
    const index = row * map.width + col;
    const pos = map.map[index];
    const cell = table?.nodeAt(pos);
    if (!cell) {
      return;
    }
    // If this is part of a col-spanning cell
    if (
      (col > 0 && map.map[index - 1] == pos) ||
      (col < map.width - 1 && map.map[index + 1] == pos)
    ) {
      tr.setNodeMarkup(
        tr.mapping.slice(mapStart).map(tableStart + pos),
        null,
        removeColSpan(cell.attrs, col - map.getColumnIndexFromPos(pos)),
      );
    } else {
      const start = tr.mapping.slice(mapStart).map(tableStart + pos);
      tr.delete(start, start + cell.nodeSize);
    }
    row += cell.attrs['rowspan'];
  }
}

// :: (EditorState, dispatch: ?(tr: Transaction)) → bool
// Command function that removes the selected columns from a table.
export function deleteColumn(state: EditorState, dispatch?: (tr: Transaction) => void) {
  if (!isInTable(state)) return false;
  if (dispatch) {
    const rect = selectedRect(state);
    const tr = state.tr;
    if (rect.left == 0 && rect.right == rect.map.width) return false;
    for (let i = rect.right - 1; ; i--) {
      removeColumn(tr, rect, i);
      if (i == rect.left) break;
      rect.table = ensure(rect.tableStart ? tr.doc.nodeAt(rect.tableStart - 1) : tr.doc);
      rect.map = TableMap.get(rect.table);
    }
    dispatch(tr);
  }
  return true;
}

export function rowIsHeader(map: TableMap, table: ProseMirrorNode, row: number) {
  const headerCell = tableNodeTypes(table.type.schema)['header_cell'];
  for (let col = 0; col < map.width; col++)
    if (ensure(table.nodeAt(map.map[col + row * map.width])).type != headerCell) return false;
  return true;
}

/**
 * Add a row to the table at `row` position
 * @param tr
 * @param map
 * @param tableStart
 * @param table
 * @param row
 */
export function addRow(
  tr: Transaction,
  { map, tableStart, table }: RectExtra,
  row: number,
  placeholder?: Fragment[],
) {
  const rows = getRowsFromTable(table);
  const newRowPosition = tableStart + rows[row - 1][1] + rows[row - 1][0].nodeSize;

  const newCells: ProseMirrorNode[] = [];

  let refRow: number | null = row > 0 ? -1 : 0;
  if (rowIsHeader(map, table, row + refRow)) {
    refRow = row == 0 || row == map.height ? null : 0;
  }

  for (let col = 0; col < map.width; col++) {
    const index = map.width * row + col;

    // Covered by a rowspan cell
    if (row > 0 && row < map.height && map.map[index] == map.map[index - map.width]) {
      const pos = map.map[index];
      const attrs = ensure(table.nodeAt(pos)).attrs;
      tr.setNodeMarkup(tableStart + pos, null, setAttr(attrs, 'rowspan', attrs['rowspan'] + 1));
      col += attrs['colspan'] - 1;
    } else {
      const type =
        refRow == null
          ? tableNodeTypes(table.type.schema)['cell']
          : ensure(table.nodeAt(map.map[index + refRow * map.width])).type;
      const createdNode = createNewTableCell(type, placeholder);
      newCells.push(ensure(createdNode));
    }
  }

  tr.insert(newRowPosition, tableNodeTypes(table.type.schema)['row'].create(null, newCells));
  return tr;
}

// :: (EditorState, dispatch: ?(tr: Transaction)) → bool
// Add a table row before the selection.
export function addRowBefore(
  state: EditorState,
  placeholder?: Fragment[],
  dispatch?: (tr: Transaction) => void,
) {
  if (!isInTable(state)) return false;
  const rect = selectedRect(state);
  if (rect.top === 0) {
    return false;
  }

  if (dispatch) {
    dispatch(addRow(state.tr, rect, rect.top, placeholder));
  }
  return true;
}

// :: (EditorState, dispatch: ?(tr: Transaction)) → bool
// Add a table row after the selection.
export function addRowAfter(
  state: EditorState,
  placeholder?: Fragment[],
  dispatch?: (tr: Transaction) => void,
) {
  if (!isInTable(state)) return false;
  if (dispatch) {
    const rect = selectedRect(state);
    dispatch(addRow(state.tr, rect, rect.bottom, placeholder));
  }
  return true;
}

export function removeRow(tr: Transaction, { map, table, tableStart }: RectExtra, row: number) {
  const rows = getRowsFromTable(table);
  const rowPosition = tableStart + rows[row][1];
  const nextRowPosition = tableStart + rows[row][1] + rows[row][0].nodeSize;

  const mapFrom = tr.mapping.maps.length;
  tr.delete(rowPosition, nextRowPosition);

  for (let col = 0, index = row * map.width; col < map.width; col++, index++) {
    const pos = map.map[index];
    if (row > 0 && pos == map.map[index - map.width]) {
      // If this cell starts in the row above, simply reduce its rowspan
      const attrs = ensure(table.nodeAt(pos)).attrs;
      tr.setNodeMarkup(
        tr.mapping.slice(mapFrom).map(pos + tableStart),
        null,
        setAttr(attrs, 'rowspan', attrs['rowspan'] - 1),
      );
      col += attrs['colspan'] - 1;
    } else if (row < map.width && pos == map.map[index + map.width]) {
      // Else, if it continues in the row below, it has to be moved down
      const cell = table.nodeAt(pos);
      if (cell) {
        const copy = cell.type.create(
          setAttr(cell.attrs, 'rowspan', cell.attrs['rowspan'] - 1),
          cell.content,
        );
        const newPos = map.positionAt(row + 1, col, table);
        tr.insert(tr.mapping.slice(mapFrom).map(tableStart + newPos), copy);
        col += cell.attrs['colspan'] - 1;
      }
    }
  }
}

// :: (EditorState, dispatch: ?(tr: Transaction)) → bool
// Remove the selected rows from a table.
export function deleteRow(state: EditorState, dispatch?: (tr: Transaction) => void) {
  if (!isInTable(state)) return false;
  if (dispatch) {
    const rect = selectedRect(state);
    const tr = state.tr;
    if (rect.top == 0 && rect.bottom == rect.map.height) return false;
    for (let i = rect.bottom - 1; ; i--) {
      removeRow(tr, rect, i);
      if (i == rect.top) break;
      rect.table = ensure(rect.tableStart ? tr.doc.nodeAt(rect.tableStart - 1) : tr.doc);
      rect.map = TableMap.get(rect.table);
    }
    dispatch(tr);
  }
  return true;
}

function isEmpty(cell: ProseMirrorNode) {
  const c = cell.content;
  return (
    c.childCount == 1 && c.firstChild && c.firstChild.isTextblock && c.firstChild.childCount == 0
  );
}

function cellsOverlapRectangle({ width, height, map }: TableMap, rect: ExtraRect) {
  let indexTop = rect.top * width + rect.left;
  let indexLeft = indexTop;
  let indexBottom = (rect.bottom - 1) * width + rect.left;
  let indexRight = indexTop + (rect.right - rect.left - 1);
  for (let i = rect.top; i < rect.bottom; i++) {
    if (
      (rect.left > 0 && map[indexLeft] == map[indexLeft - 1]) ||
      (rect.right < width && map[indexRight] == map[indexRight + 1])
    )
      return true;
    indexLeft += width;
    indexRight += width;
  }
  for (let i = rect.left; i < rect.right; i++) {
    if (
      (rect.top > 0 && map[indexTop] == map[indexTop - width]) ||
      (rect.bottom < height && map[indexBottom] == map[indexBottom + width])
    )
      return true;
    indexTop++;
    indexBottom++;
  }
  return false;
}

// :: (EditorState, dispatch: ?(tr: Transaction)) → bool
// Merge the selected cells into a single cell. Only available when
// the selected cells' outline forms a rectangle.
export function mergeCells(state: EditorState, dispatch?: (tr: Transaction) => void) {
  const sel = state.selection;
  if (!(sel instanceof CellSelection) || sel.$anchorCell.pos == sel.$headCell.pos) return false;
  const rect = selectedRect(state);
  const { map } = rect;
  if (cellsOverlapRectangle(map, rect)) return false;
  if (dispatch) {
    const tr = state.tr;
    const seen: Record<number, boolean> = {};
    let content = Fragment.empty;
    let mergedPos: number | null = null;
    let mergedCell;
    for (let row = rect.top; row < rect.bottom; row++) {
      for (let col = rect.left; col < rect.right; col++) {
        const cellPos = map.map[row * map.width + col];
        const cell = rect.table.nodeAt(cellPos);
        if (seen[cellPos]) continue;
        seen[cellPos] = true;
        if (mergedPos === null) {
          mergedPos = cellPos;
          mergedCell = cell;
        } else {
          if (cell) {
            if (!isEmpty(cell)) content = content.append(cell.content);
            const mapped = tr.mapping.map(cellPos + rect.tableStart);
            tr.delete(mapped, mapped + cell.nodeSize);
          }
        }
      }
    }
    if (!mergedPos || !mergedCell) {
      return false;
    }
    tr.setNodeMarkup(
      mergedPos + rect.tableStart,
      null,
      setAttr(
        addColSpan(
          mergedCell.attrs,
          mergedCell.attrs['colspan'],
          rect.right - rect.left - mergedCell.attrs['colspan'],
        ),
        'rowspan',
        rect.bottom - rect.top,
      ),
    );
    if (content.size) {
      const end = mergedPos + 1 + mergedCell.content.size;
      const start = isEmpty(mergedCell) ? mergedPos + 1 : end;
      tr.replaceWith(start + rect.tableStart, end + rect.tableStart, content);
    }
    tr.setSelection(new CellSelection(tr.doc.resolve(mergedPos + rect.tableStart)));
    dispatch(tr);
  }
  return true;
}
// :: (EditorState, dispatch: ?(tr: Transaction)) → bool
// Split a selected cell, whose rowpan or colspan is greater than one,
// into smaller cells. Use the first cell type for the new cells.
export function splitCell(
  state: EditorState,
  placeholder?: Fragment[],
  dispatch?: (tr: Transaction) => void,
) {
  const nodeTypes = tableNodeTypes(state.schema);
  return splitCellWithType(({ node }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return nodeTypes[node.type.spec['tableRole']];
  }, placeholder)(state, dispatch);
}

// :: (getCellType: ({ row: number, col: number, node: Node}) → NodeType) → (EditorState, dispatch: ?(tr: Transaction)) → bool
// Split a selected cell, whose rowpan or colspan is greater than one,
// into smaller cells with the cell type (th, td) returned by getType function.
export function splitCellWithType(
  getCellType: (props: { row: number; col: number; node: ProseMirrorNode }) => NodeType,
  placeholder?: Fragment[],
) {
  // eslint-disable-next-line complexity
  return (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    const sel = state.selection;
    let cellNode;
    let cellPos;
    if (!(sel instanceof CellSelection)) {
      cellNode = cellWrapping(sel.$from);
      if (!cellNode) return false;
      cellPos = ensure(cellAround(sel.$from)).pos;
    } else {
      if (sel.$anchorCell.pos != sel.$headCell.pos) return false;
      cellNode = sel.$anchorCell.nodeAfter;
      cellPos = sel.$anchorCell.pos;
    }
    if (!cellNode) {
      return false;
    }
    if (cellNode.attrs['colspan'] == 1 && cellNode.attrs['rowspan'] == 1) {
      return false;
    }
    if (dispatch) {
      let baseAttrs = cellNode.attrs;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const attrs: Record<string, any>[] = [];
      const colwidth = baseAttrs['colwidth'];
      if (baseAttrs['rowspan'] > 1) baseAttrs = setAttr(baseAttrs, 'rowspan', 1);
      if (baseAttrs['colspan'] > 1) baseAttrs = setAttr(baseAttrs, 'colspan', 1);
      const rect = selectedRect(state);
      const tr = state.tr;
      for (let i = 0; i < rect.right - rect.left; i++)
        attrs.push(
          colwidth ? setAttr(baseAttrs, 'colwidth', colwidth?.[i] && [colwidth[i]]) : baseAttrs,
        );
      let lastCell: number | null = null;
      for (let row = rect.top; row < rect.bottom; row++) {
        let pos = rect.map.positionAt(row, rect.left, rect.table);
        if (row == rect.top) pos += cellNode.nodeSize;
        for (let col = rect.left, i = 0; col < rect.right; col++, i++) {
          if (col == rect.left && row == rect.top) continue;
          const cellType = getCellType({ node: cellNode, row, col });
          let cellContent = null;
          if (placeholder) {
            cellContent = cellType.name === 'tableHeader' ? placeholder[1] : placeholder[0];
          }
          tr.insert(
            (lastCell = tr.mapping.map(pos + rect.tableStart, 1)),
            ensure(cellType.createAndFill(attrs[i], cellContent)),
          );
        }
      }
      tr.setNodeMarkup(
        cellPos,
        getCellType({ node: cellNode, row: rect.top, col: rect.left }),
        attrs[0],
      );
      if (sel instanceof CellSelection)
        tr.setSelection(
          new CellSelection(
            tr.doc.resolve(sel.$anchorCell.pos),
            lastCell ? tr.doc.resolve(lastCell) : undefined,
          ),
        );
      dispatch(tr);
    }
    return true;
  };
}

// :: (string, any) → (EditorState, dispatch: ?(tr: Transaction)) → bool
// Returns a command that sets the given attribute to the given value,
// and is only available when the currently selected cell doesn't
// already have that attribute set to that value.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setCellAttr(name: string, value: any) {
  return function (state: EditorState, dispatch?: (tr: Transaction) => void) {
    if (!isInTable(state)) return false;
    const $cell = selectionCell(state);
    if (!$cell?.nodeAfter || $cell?.nodeAfter?.attrs[name] === value) return false;
    if (dispatch) {
      const tr = state.tr;
      if (state.selection instanceof CellSelection)
        state.selection.forEachCell((node, pos) => {
          if (node.attrs[name] !== value)
            tr.setNodeMarkup(pos, null, setAttr(node.attrs, name, value));
        });
      else tr.setNodeMarkup($cell.pos, null, setAttr($cell.nodeAfter.attrs, name, value));
      dispatch(tr);
    }
    return true;
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function deprecated_toggleHeader(type: string) {
  return function (state: EditorState, dispatch?: (tr: Transaction) => void) {
    if (!isInTable(state)) return false;
    if (dispatch) {
      const types = tableNodeTypes(state.schema);
      const rect = selectedRect(state);
      const tr = state.tr;
      const cells = rect.map.cellsInRect(
        // eslint-disable-next-line no-nested-ternary
        type == 'column'
          ? new Rect(rect.left, 0, rect.right, rect.map.height)
          : type == 'row'
          ? new Rect(0, rect.top, rect.map.width, rect.bottom)
          : rect,
      );
      const nodes = cells.map((pos) => ensure(rect.table.nodeAt(pos)));
      for (
        let i = 0;
        i < cells.length;
        i++ // Remove headers, if any
      )
        if (nodes[i].type == types['header_cell'])
          tr.setNodeMarkup(rect.tableStart + cells[i], types['cell'], nodes[i].attrs);
      if (tr.steps.length == 0)
        for (
          let i = 0;
          i < cells.length;
          i++ // No headers removed, add instead
        )
          tr.setNodeMarkup(rect.tableStart + cells[i], types['header_cell'], nodes[i].attrs);
      dispatch(tr);
    }
    return true;
  };
}

function isHeaderEnabledByType(type: string, rect: ExtraRect, types: Record<string, NodeType>) {
  // Get cell positions for first row or first column
  const cellPositions = rect.map.cellsInRect({
    left: 0,
    top: 0,
    right: type == 'row' ? rect.map.width : 1,
    bottom: type == 'column' ? rect.map.height : 1,
  });

  for (let i = 0; i < cellPositions.length; i++) {
    const cell = rect.table.nodeAt(cellPositions[i]);
    if (cell && cell.type !== types['header_cell']) {
      return false;
    }
  }

  return true;
}

// :: (string, ?{ useDeprecatedLogic: bool }) → (EditorState, dispatch: ?(tr: Transaction)) → bool
// Toggles between row/column header and normal cells (Only applies to first row/column).
// For deprecated behavior pass `useDeprecatedLogic` in options with true.
export function toggleHeader(type: string, options?: { useDeprecatedLogic: boolean }) {
  options = options || { useDeprecatedLogic: false };

  if (options.useDeprecatedLogic) return deprecated_toggleHeader(type);

  return function (state: EditorState, dispatch?: (tr: Transaction) => void) {
    if (!isInTable(state)) return false;
    if (dispatch) {
      const types = tableNodeTypes(state.schema);
      const rect = selectedRect(state);
      const tr = state.tr;

      const isHeaderRowEnabled = isHeaderEnabledByType('row', rect, types);
      const isHeaderColumnEnabled = isHeaderEnabledByType('column', rect, types);

      let isHeaderEnabled;
      if (type === 'column') {
        isHeaderEnabled = isHeaderRowEnabled;
      } else if (type === 'row') {
        isHeaderEnabled = isHeaderColumnEnabled;
      } else {
        isHeaderEnabled = false;
      }

      const selectionStartsAt = isHeaderEnabled ? 1 : 0;

      let cellsRect;
      if (type == 'column') {
        cellsRect = new Rect(0, selectionStartsAt, 1, rect.map.height);
      } else if (type == 'row') {
        cellsRect = new Rect(selectionStartsAt, 0, rect.map.width, 1);
      } else {
        cellsRect = rect;
      }

      const newType =
        // eslint-disable-next-line no-nested-ternary
        type == 'column'
          ? isHeaderColumnEnabled
            ? types['cell']
            : types['header_cell']
          : // eslint-disable-next-line no-nested-ternary
          type == 'row'
          ? isHeaderRowEnabled
            ? types['cell']
            : types['header_cell']
          : types['cell'];

      rect.map.cellsInRect(cellsRect).forEach((relativeCellPos) => {
        const cellPos = relativeCellPos + rect.tableStart;
        const cell = tr.doc.nodeAt(cellPos);

        if (cell) {
          tr.setNodeMarkup(cellPos, newType, cell.attrs);
        }
      });

      dispatch(tr);
    }
    return true;
  };
}

// :: (EditorState, dispatch: ?(tr: Transaction)) → bool
// Toggles whether the selected row contains header cells.
export const toggleHeaderRow = toggleHeader('row', { useDeprecatedLogic: true });

// :: (EditorState, dispatch: ?(tr: Transaction)) → bool
// Toggles whether the selected column contains header cells.
export const toggleHeaderColumn = toggleHeader('column', {
  useDeprecatedLogic: true,
});

// :: (EditorState, dispatch: ?(tr: Transaction)) → bool
// Toggles whether the selected cells are header cells.
export const toggleHeaderCell = toggleHeader('cell', {
  useDeprecatedLogic: true,
});

function findNextCell($cell: ResolvedPos, dir: number) {
  if (dir < 0) {
    const before = $cell.nodeBefore;
    if (before) return $cell.pos - before.nodeSize;
    for (let row = $cell.index(-1) - 1, rowEnd = $cell.before(); row >= 0; row--) {
      const rowNode = $cell.node(-1).child(row);
      if (rowNode.childCount) return rowEnd - 1 - ensure(rowNode.lastChild).nodeSize;
      rowEnd -= rowNode.nodeSize;
    }
  } else {
    if ($cell.index() < $cell.parent.childCount - 1)
      return $cell.pos + ensure($cell.nodeAfter).nodeSize;

    const table = $cell.node(-1);
    for (let row = $cell.indexAfter(-1), rowStart = $cell.after(); row < table.childCount; row++) {
      const rowNode = table.child(row);
      if (rowNode.childCount) return rowStart + 1;
      rowStart += rowNode.nodeSize;
    }
  }
  return undefined;
}

// :: (number) → (EditorState, dispatch: ?(tr: Transaction)) → bool
// Returns a command for selecting the next (direction=1) or previous
// (direction=-1) cell in a table.
export function goToNextCell(direction: number) {
  return function (state: EditorState, dispatch?: (tr: Transaction) => void) {
    if (!isInTable(state)) return false;
    const cell = findNextCell(ensure(selectionCell(state)), direction);
    if (cell == null) return false;
    if (dispatch) {
      const $cell = state.doc.resolve(cell);
      dispatch(
        state.tr
          .setSelection(TextSelection.between($cell, moveCellForward($cell)))
          .scrollIntoView(),
      );
    }
    return true;
  };
}

// :: (EditorState, ?(tr: Transaction)) → bool
// Deletes the table around the selection, if any.
export function deleteTable(state: EditorState, dispatch?: (tr: Transaction) => void) {
  const $pos = state.selection.$anchor;
  for (let d = $pos.depth; d > 0; d--) {
    const node = $pos.node(d);
    if (node.type.spec['tableRole'] == 'table') {
      if (dispatch) dispatch(state.tr.delete($pos.before(d), $pos.after(d)).scrollIntoView());
      return true;
    }
  }
  return false;
}
