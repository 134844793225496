/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import LoaderComponent from '../../utils/loader';
import { getSortedDataCollection } from '../../utils';
import '../../utils/loader.scss';
import { Col } from 'react-bootstrap';

interface ListElementInterface {
  elementCollection: Array<{ id: number; name: string; url: string }>;
  handleElementSelection: Function;
  isErrorInFetch: boolean;
  isFetching: boolean;
  searchText: string;
  sortingValue?: string;
}

const ListElementComponent = (props: ListElementInterface) => {
  const {
    elementCollection,
    handleElementSelection,
    isErrorInFetch,
    isFetching,
    searchText,
    sortingValue,
  } = props;
  const [filteredData, setFilterdData] = useState([...elementCollection]);

  useEffect(() => {
    let listOfElements = [...elementCollection];
    handleElementSelection();
    listOfElements = listOfElements?.filter((item: { name: string }) =>
      item.name.toLowerCase().includes(searchText.toLowerCase()),
    );
    setFilterdData(getSortedDataCollection(sortingValue as string, listOfElements));
  }, [sortingValue]);

  useEffect(() => {
    let list = [...filteredData];
    setFilterdData(
      list.filter((item: { name: string }) =>
        item.name.toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [searchText]);
  return (
    <div
      id='lint-element-list-container'
      className='d-flex flex-row flex-wrap custom-scrollbar lint-custom-element-list-container me-1'
    >
      {!isFetching && !isErrorInFetch && (elementCollection?.length as number) > 0 && (
        <>
          {filteredData.map((image, index: number) => {
            return (
              <Col
                sm={2}
                key={image.id}
                className='d-flex flex-column element-thumbnail'
                tabIndex={0}
                role='button'
                id={`${image.name}_${index}`}
                onClick={() => handleElementSelection(image, index)}
              >
                <img
                  src={image.url
                    .substring(1, image.url.length - 1)
                    .split(' ')
                    .join('%20')}
                  alt={image.name}
                  title={image.name}
                  className='lint-custom-element-list-thumbnail'
                  loading='lazy'
                />
                <div
                  className='lint-custom-element-list-title text-truncate text-center pt-1'
                  title={image.name}
                >
                  {image.name}
                </div>
              </Col>
            );
          })}
        </>
      )}
      {(isFetching ||
        isErrorInFetch ||
        elementCollection?.length === 0 ||
        filteredData.length === 0) && (
        <div className='col-sm-12 element-wrapper'>
          {isFetching && !isErrorInFetch && <LoaderComponent />}
          {isErrorInFetch && !isFetching && <>Something went wrong! Please try again later</>}
          {!isErrorInFetch && !isFetching && elementCollection?.length === 0 && (
            <>No Images found</>
          )}
          {!isErrorInFetch && !isFetching && filteredData?.length === 0 && <>No Results found</>}
        </div>
      )}
    </div>
  );
};

export default ListElementComponent;
