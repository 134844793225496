import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

const i18n = i18next
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    debug: process.env.REACT_APP_LOREE_ENV === 'develop',
    detection: {
      lookupLocalStorage: 'LintLng',
    },
    ns: ['loree', 'a11y'],
    defaultNS: 'loree',
    fallbackLng: 'en',
    preload: ['en'],
    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      allowMultiLoading: false,
    },
  });

export default i18n;
