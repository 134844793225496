import React from "react";

class NotFoundPage extends React.Component{
  render(){
    return <div data-testid='404' style={{textAlign:"center"}}>
      <h1>404 - Page not found</h1>
    </div>;
  }
}
export default NotFoundPage;
