import { mergeAttributes, Node } from '@tiptap/core';
import { optimizeStyle } from './utilityFunctions';

export interface TableColGroupOptions {
  HTMLAttributes: Record<string, never>;
}

export const CustomizedTableColGroup = Node.create<TableColGroupOptions>({
  name: 'tableColGroup',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  // group: 'tableBlock',
  content: 'tableColumn*',
  isolating: true,
  tableRole: 'colgroup',

  addAttributes() {
    return {
      span: {
        default: 1,
      },
    };
  },

  parseHTML() {
    return [{ tag: 'colgroup' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'colgroup',
      optimizeStyle(mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)),
      0,
    ];
  },
});
