import styled from 'styled-components';
import { Select, LineHeightOption } from '@crystaldelta/loree-ui-components';

export const CustomDropDown = styled(Select)`
  background-color: white;
  padding: 4px 10px;
  font-size: 14px;
  border: 1px solid #112299;
  gap: 3px;
  color: #000;
  font-family: SourceSansPro-Regular;
  outline: none !important;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  &:focus {
    outline: unset !importtant;
  }
`;

export const CustomDropDownOption = styled(LineHeightOption)`
  padding-left: 10px;
  padding-top: 4px;
  font-size: 14px;
  font-family: SourceSansPro-Regular;
  span {
    width: 100%;
  }
  &:hover {
    background: #112299;
    color: #ffffff;
  }
`;
