/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import {
  pbBgColor,
  pbFontColor,
  pbFontHeadings,
  pbFontType,
  pbFontSize,
  pbFontWeight,
} from '../../../../redux/multipleChoice/mcq.action';
import FontHandlerComponent from '../../../customizeComponentLayout/fontHandler';
import DropDownComponent from '../../../utils/dropDown';
import { customStyles } from '../../../customizeComponentLayout/styles';
import ColorPickerComponent from '../../../customizeComponentLayout/colorPicker';
import { isFontNameAvailable } from '../../utils';

const mapState = ({ mcqform }: any) => ({
  pbFontHeadings: mcqform.pbFontHeadings,
  pbFontFamily: mcqform.pbFontFamily,
  pbFontSize: mcqform.pbFontSize,
  pbFontWeight: mcqform.pbFontWeight,
  pbBgColor: mcqform.pbBgColor,
  pbFontColor: mcqform.pbFontColor,
});

const mapDispatch = (dispatch: Function) => ({
  HandlePbBgColor: (form: {}) => dispatch(pbBgColor(form)),
  HanldePbFontColor: (form: {}) => dispatch(pbFontColor(form)),
  HandlepbFontHeadings: (form: {}) => dispatch(pbFontHeadings(form)),
  HandlepbFontFamily: (form: {}) => dispatch(pbFontType(form)),
  HandlepbFontSize: (form: {}) => dispatch(pbFontSize(form)),
  HandlepbFontWeight: (form: {}) => dispatch(pbFontWeight(form)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = {
  fontFamilyPropsList: any;
  customHeaderStylesPropsList: { [key: string]: { size: string; font: string } }[];
  id?: string;
} & ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const ProgressBarCustomize = (props: Props) => {
  const {
    pbFontFamily,
    pbFontHeadings,
    pbFontSize,
    pbFontWeight,
    pbBgColor,
    pbFontColor,
    HandlepbFontSize,
    HandlepbFontHeadings,
    HandlepbFontWeight,
    HandlePbBgColor,
    HanldePbFontColor,
    HandlepbFontFamily,
    customHeaderStylesPropsList,
    fontFamilyPropsList,
  } = props;
  const [isRendered, setIsRendered] = useState(false);
  useEffect(() => {
    if (pbFontHeadings === 'h2' && !props.id && !isRendered) {
      HandlepbFontSize({
        pbFontSize: parseInt(customHeaderStylesPropsList[1]?.h2?.size, 10).toString(),
      });
      HandlepbFontFamily({
        pbFontFamily: isFontNameAvailable(
          customHeaderStylesPropsList[1]?.h2?.font,
          fontFamilyPropsList,
        ),
      });
    }
    setIsRendered(true);
  }, [
    HandlepbFontFamily,
    HandlepbFontSize,
    customHeaderStylesPropsList,
    fontFamilyPropsList,
    pbFontHeadings,
    props,
    isRendered,
  ]);
  const fontHeadingsHandler = (element: string | null) => {
    if (element !== null) {
      HandlepbFontHeadings({
        pbFontHeadings: element,
      });

      HandlepbFontWeight({
        pbFontWeight: 'Bold',
      });

      switch (element) {
        case 'h1':
          HandlepbFontSize({
            pbFontSize: parseInt(customHeaderStylesPropsList[0].h1.size, 10).toString(),
          });
          HandlepbFontFamily({
            pbFontFamily: isFontNameAvailable(
              customHeaderStylesPropsList[0].h1.font,
              fontFamilyPropsList,
            ),
          });
          break;
        case 'h2':
          HandlepbFontSize({
            pbFontSize: parseInt(props.customHeaderStylesPropsList[1].h2.size, 10).toString(),
          });
          HandlepbFontFamily({
            pbFontFamily: isFontNameAvailable(
              customHeaderStylesPropsList[1].h2.font,
              fontFamilyPropsList,
            ),
          });
          break;
        case 'h3':
          HandlepbFontSize({
            pbFontSize: parseInt(props.customHeaderStylesPropsList[2].h3.size, 10).toString(),
          });
          HandlepbFontFamily({
            pbFontFamily: isFontNameAvailable(
              customHeaderStylesPropsList[2].h3.font,
              fontFamilyPropsList,
            ),
          });
          break;
        case 'h4':
          HandlepbFontSize({
            pbFontSize: parseInt(props.customHeaderStylesPropsList[3].h4.size, 10).toString(),
          });
          HandlepbFontFamily({
            pbFontFamily: isFontNameAvailable(
              customHeaderStylesPropsList[3].h4.font,
              fontFamilyPropsList,
            ),
          });
          break;
        case 'h5':
          HandlepbFontSize({
            pbFontSize: parseInt(props.customHeaderStylesPropsList[4].h5.size, 10).toString(),
          });
          HandlepbFontFamily({
            pbFontFamily: isFontNameAvailable(
              customHeaderStylesPropsList[4].h5.font,
              fontFamilyPropsList,
            ),
          });
          break;
        case 'h6':
          HandlepbFontSize({
            pbFontSize: parseInt(props.customHeaderStylesPropsList[5].h6.size, 10).toString(),
          });
          HandlepbFontFamily({
            pbFontFamily: isFontNameAvailable(
              customHeaderStylesPropsList[5].h6.font,
              fontFamilyPropsList,
            ),
          });
          break;
        default:
          HandlepbFontWeight({
            pbFontWeight: 'Normal',
          });
          HandlepbFontSize({
            pbFontSize: parseInt(
              props.customHeaderStylesPropsList[6].paragraph.size,
              10,
            ).toString(),
          });
          HandlepbFontFamily({
            pbFontFamily: isFontNameAvailable(
              customHeaderStylesPropsList[6].paragraph.font,
              fontFamilyPropsList,
            ),
          });
          break;
      }
    }
  };

  const handleFontFamily = (value: { fontFamily: string }) => {
    HandlepbFontFamily({
      pbFontFamily: value.fontFamily,
    });
  };

  const handleFontSize = (value: { fontSize: string }) => {
    HandlepbFontSize({
      pbFontSize: value.fontSize,
    });
  };

  const handleFontWeight = (value: { fontWeight: string }) => {
    HandlepbFontWeight({
      pbFontWeight: value.fontWeight,
    });
  };

  return (
    <>
      <FontHandlerComponent
        componentType='Font Family'
        setFontProperty={handleFontFamily}
        fontPropertyValue={pbFontFamily}
        options={customStyles.fontFamily}
        customFonts={props.fontFamilyPropsList}
      />
      <Form.Group className='mb-3'>
        <Form.Label className='design-section-text'>Font Style</Form.Label>
        <DropDownComponent
          setSelectedOption={fontHeadingsHandler}
          selectedOption={pbFontHeadings ? pbFontHeadings : ''}
          className='lint-customize-dropdown'
          optionCollection={customStyles.fontHeadings}
        />
      </Form.Group>
      <FontHandlerComponent
        componentType='Font Size'
        setFontProperty={handleFontSize}
        fontPropertyValue={pbFontSize}
        options={customStyles.fontSize}
      />
      <FontHandlerComponent
        componentType='Font Weight'
        setFontProperty={handleFontWeight}
        fontPropertyValue={pbFontWeight}
        options={customStyles.fontWeight}
      />
      {/* <AlignmentComponent alignmentValue={alignment} SetAlignmentValue={props.Setalignment} /> */}
      <ColorPickerComponent
        type='pbFontColor'
        title='Font Color'
        SetColor={HanldePbFontColor}
        color={pbFontColor}
      />
      <ColorPickerComponent
        type='pbBgColor'
        title='Background Color'
        SetColor={HandlePbBgColor}
        color={pbBgColor}
      />
    </>
  );
};

export default connector(ProgressBarCustomize);
