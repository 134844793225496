import { mergeAttributes, Node } from '@tiptap/core';
import { filterSpans, optimizeStyle } from './utilityFunctions';

export interface TableHeadOptions {
  HTMLAttributes: Record<string, never>;
}

export const CustomizedTableHead = Node.create<TableHeadOptions>({
  name: 'tableHead',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  // group: 'tableBlock',
  content: 'tableRow+',
  isolating: true,
  tableRole: 'head',

  parseHTML() {
    return [{ tag: 'thead' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'thead',
      optimizeStyle(filterSpans(mergeAttributes(this.options.HTMLAttributes, HTMLAttributes))),
      0,
    ];
  },
});
