import React, { ReactNode, useCallback, useState } from 'react';
import './tooltip.scss';

interface TooltipInterface {
  children: ReactNode;
  info: string;
  arrow?: boolean;
  direction?: 'top' | 'right' | 'bottom' | 'left';
}

const Tooltip = (props: TooltipInterface) => {
  const [active, setActive] = useState(false);

  const showTip = useCallback(() => {
    setActive(true);
  }, [setActive]);

  const hideTip = useCallback(() => {
    setActive(false);
  }, [setActive]);

  return (
    <div className='styled-tool-tip' onMouseEnter={showTip} onMouseLeave={hideTip}>
      {props.children}
      {active && (
        <div
          className={`tool-tip-content tooltip-${props.direction ?? 'bottom'} ${
            props.arrow ? 'tooltip-arrow' : ''
          }`}
        >
          {props.info}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
