/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConnectedProps, connect } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import Store from '../../redux/store';
import * as formStore from '../../redux/form/form.actions';
import * as buttonStore from '../../redux/button/button.actions';
import * as flipcardStore from '../../redux/flipCard/form.action';
import * as mcqStore from '../../redux/multipleChoice/mcq.action';
import * as componentStore from '../../redux/components/reducer.action';
import ModalComponent from '../utils/modal';
import { getModalDetails } from '../../middleware/utils';
import '../layouts/header.scss';

interface NavHeaderInterface {
  config: any;
  data: {
    type: string;
    history: {
      push: Function;
    };
  };
}
const mapDispatchToProps = (dispatch: Function) => ({
  ResetFormStore: () => dispatch(formStore.setDefault()),
  ResetButtonStore: () => dispatch(buttonStore.setDefault()),
  ResetFlipStore: () => dispatch(flipcardStore.setDefault()),
  ResetMcqStore: () => dispatch(mcqStore.setDefault()),
  ResetComponentStore: () => dispatch(componentStore.setDefault()),
});

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & NavHeaderInterface;

export const NavHeader = (props: Props) => {
  const {
    myinteractive,
    globalinteractive,
    sharedinteractive,
    myinteractivecreate,
    globalinteractivecreate,
  } = props.config || {};
  const [prevState, setPrevState] = useState(Store.getState());
  const [showModal, setShowModal] = useState(false);
  const [confirmType, setConfirmType] = useState('');
  const getStore = useCallback(() => {
    const { type } = props.data;
    let prevStore;
    switch (type) {
      case 'Button':
        prevStore = prevState ? prevState.button : null;
        break;
      case 'multiplechoice':
        prevStore = prevState ? prevState.mcqform : null;
        break;
      case 'flipcard':
        prevStore = prevState ? prevState.flipform : null;
        break;
      default:
        prevStore = prevState ? prevState.form : null;
        break;
    }
    return prevStore;
  }, [prevState, props.data]);

  useEffect(() => {
    const { type } = props.data;
    const prevStore = getStore();
    if (type && type !== prevStore?.type) {
      setPrevState(Store.getState());
    }
  }, [props, setPrevState, getStore]);

  const homeOnclick = (value: string) => {
    const currentState = Store.getState();
    const { type } = props.data;
    let validatedParams;
    switch (true) {
      case type === 'button':
        validatedParams = prevState && currentState && prevState.button === currentState.button;
        break;
      case type === 'multiplechoice':
        validatedParams = prevState && currentState && prevState.mcqform === currentState.mcqform;
        break;
      case type === 'flipcard':
        validatedParams = prevState && currentState && prevState.flipform === currentState.flipform;
        break;
      case type !== undefined:
        validatedParams = prevState && currentState && prevState.form === currentState.form;
        break;
      default:
        validatedParams = true;
        break;
    }

    validateInputForm(validatedParams, value);
  };

  const validateInputForm = (validatedParams: boolean, value: string) => {
    if (validatedParams) {
      redirect(value);
    } else {
      setShowModal(true);
      setConfirmType(value);
    }
  };

  const redirect = (value: string) => {
    const { ResetButtonStore, ResetFlipStore, ResetFormStore, ResetMcqStore, ResetComponentStore } =
      props;
    props.data.history.push(value);
    ResetButtonStore();
    ResetFlipStore();
    ResetFormStore();
    ResetMcqStore();
    ResetComponentStore();
  };

  return (
    <>
      {(myinteractive || globalinteractive || sharedinteractive) && (
        <div className='navbar-tabs'>
          <Navbar className='p-0 fixed-top bg-white nav-navbar'>
            <Nav
              activeKey={window.location.pathname}
              className='navbtn nav-tabs'
              onSelect={(eventKey) => homeOnclick(eventKey as string)}
            >
              {(myinteractivecreate || globalinteractivecreate) && (
                <Nav.Link
                  as='button'
                  href={`/interactives/${sessionStorage.getItem('user')}/${sessionStorage.getItem(
                    'org_id',
                  )}/${sessionStorage.getItem('loreeVersion')}`}
                >
                  Home
                </Nav.Link>
              )}
              {myinteractive && (
                <Nav.Link
                  as='button'
                  href={`/Contents/${sessionStorage.getItem('user')}/${sessionStorage.getItem(
                    'user_id',
                  )}`}
                >
                  My Interactives
                </Nav.Link>
              )}
              {globalinteractive && (
                <Nav.Link as='button' href={`/global/${sessionStorage.getItem('org_id')}`}>
                  Global Interactives
                </Nav.Link>
              )}
              {sharedinteractive && (
                <Nav.Link as='button' href='/shared'>
                  Shared Interactives
                </Nav.Link>
              )}
            </Nav>
          </Navbar>
        </div>
      )}
      <ModalComponent
        show={showModal}
        handleCancel={() => setShowModal(false)}
        modalDetails={getModalDetails('Exit')}
        confirmButton={{
          label: 'Exit',
          disabled: false,
          handleConfirm: () => redirect(confirmType),
        }}
        cancelButton={{
          label: 'Cancel',
        }}
      />
    </>
  );
};

//connecting form with redux
export default connector(NavHeader);
