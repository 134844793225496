import React from 'react';
import { Form } from 'react-bootstrap';
import './customStyles.scss';

interface SpaceComponentInterface {
  componentType: string;
  left: string;
  top: string;
  right: string;
  bottom: string;
  SetLeft: Function;
  SetRight: Function;
  SetTop: Function;
  SetBottom: Function;
}

const SpaceComponent = (props: SpaceComponentInterface) => {
  const { componentType, left, top, right, bottom, SetLeft, SetRight, SetTop, SetBottom } = props;
  const isPadding = componentType === 'Padding';

  const handleCustomSpace = (event: React.ChangeEvent, type: string) => {
    const { value } = event.target as HTMLInputElement;
    const finalValue = Number(value);
    if (finalValue < 0 || finalValue > 40) return;
    switch (type) {
      case 'left':
        const leftValue = isPadding
          ? {
              customPaddingleft: value,
            }
          : {
              borderLeft: value,
            };
        SetLeft(leftValue);
        break;
      case 'top':
        const topValue = isPadding
          ? {
              customPaddingtop: value,
            }
          : {
              borderTop: value,
            };
        SetTop(topValue);
        break;
      case 'right':
        const rightValue = isPadding
          ? {
              customPaddingright: value,
            }
          : {
              borderRight: value,
            };
        SetRight(rightValue);
        break;
      case 'bottom':
        const bottomValue = isPadding
          ? {
              customPaddingbottom: value,
            }
          : {
              borderBottom: value,
            };

        SetBottom(bottomValue);
        break;
    }
  };
  return (
    <Form.Group className='mb-3' data-testid={`${componentType}-component`}>
      <Form.Label className='design-section-text'>{`${componentType} (px)`}</Form.Label>
      <div className='d-flex mt-2 me-5'>
        <Form.Control
          data-testid={`${componentType}-left`}
          className='space-left-input'
          type='number'
          min='0'
          max='40'
          name='fontSize'
          size='sm'
          value={left}
          style={{ borderLeft: `2px ${isPadding ? 'dashed' : 'solid'} #112299` }}
          onChange={(e) => handleCustomSpace(e, 'left')}
        />
        <Form.Control
          className='space-top-input'
          data-testid={`${componentType}-top`}
          type='number'
          min='0'
          max='40'
          name='fontSize'
          size='sm'
          value={top}
          style={{ borderTop: `2px ${isPadding ? 'dashed' : 'solid'} #112299` }}
          onChange={(e) => handleCustomSpace(e, 'top')}
        />
      </div>
      <div className='d-flex mt-2 me-5'>
        <Form.Control
          data-testid={`${componentType}-right`}
          className='space-right-input'
          type='number'
          min='0'
          max='40'
          name='fontSize'
          size='sm'
          value={right}
          style={{ borderRight: `2px ${isPadding ? 'dashed' : 'solid'} #112299` }}
          onChange={(e) => handleCustomSpace(e, 'right')}
        />
        <Form.Control
          data-testid={`${componentType}-bottom`}
          className='space-bottom-input'
          type='number'
          min='0'
          max='40'
          name='fontSize'
          size='sm'
          value={bottom}
          style={{ borderBottom: `2px ${isPadding ? 'dashed' : 'solid'} #112299` }}
          onChange={(e) => handleCustomSpace(e, 'bottom')}
        />
      </div>
    </Form.Group>
  );
};

export default SpaceComponent;
