/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { svgIconValue } from './svgIcon';

export const setIcon = (customisations: any) => {
  const icon = customisations.hotspotIcon;
  if (!icon) return null;
  const key = icon.iconType;
  const transform =
    key === 'Question'
      ? 'translate(13,-2.632)'
      : key === 'Plus'
      ? 'translate(-3874.431,-423.915)'
      : key === 'Minus'
      ? 'translate(-3874.431,-424.06)'
      : key === 'Information'
      ? 'translate(16.5,64.5)'
      : 'translate(3,58.214)';
  const svgElem = `<svg xmlns="http://www.w3.org/2000/svg" width=${
    customisations.fontSize
  }px height=${customisations.fontSize}px viewBox="0 0 30 30">
      <g transform=${key === 'Question' ? 'translate(-3,1)' : 'translate(-3,-58)'}>
        <g transform=${key === 'Question' ? 'translate(3,-1)' : 'translate(3,58)'} fill=${
    customisations.fontColor
  }>
          <path d="${svgIconValue.outerCircle}" />
          <path d="${svgIconValue.innerCircle}" fill=${customisations.bgcolor}/>
        </g>
        <path d=${icon.draw} transform=${transform} fill=${customisations.bgcolor} />
      </g>
    </svg>`;
  const element = document.createElement('div');
  element.innerHTML = svgElem;
  const svgIcon = getDataURL(element.innerHTML);
  return svgIcon;
};

const getDataURL = (svgElem: any) => {
  let svg64 = btoa(decodeURIComponent(encodeURIComponent(svgElem)));
  let b64Start = 'data:image/svg+xml;base64,';
  return b64Start + svg64;
};
