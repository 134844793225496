import { mergeAttributes, Node } from '@tiptap/core';
import { optimizeStyle } from './utilityFunctions';
import { Attrs } from 'prosemirror-model';

export interface ParagraphOptions {
  HTMLAttributes: Record<string, unknown>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customParagraph: {
      /**
       * Toggle a paragraph
       */
      setCustomParagraph: (attributes: {
        style?: string | null;
        attrs?: Attrs | null;
      }) => ReturnType;
    };
  }
}

export const CustomizedParagraph = Node.create<ParagraphOptions>({
  name: 'paragraph',

  priority: 1006,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: 'block',
  content: 'inline*',
  isolating: false,
  draggable: false,

  parseHTML() {
    return [{ tag: 'p' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['p', optimizeStyle(mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)), 0];
  },
  addCommands() {
    return {
      setCustomParagraph:
        (attributes) =>
        ({ commands }) => {
          const mergedAttrs = { ...attributes.attrs, style: attributes.style };
          return commands.setNode(this.name, mergedAttrs);
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Alt-0': () => this.editor.commands.setCustomParagraph({}),
    };
  },
});
