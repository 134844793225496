/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import AccordionComponent from '../Interactives/accordion/accordion';
import ButtonComponent from '../Interactives/button';
import TabComponent from '../Interactives/tabs';
import ClickAndRevealComponent from '../Interactives/clickAndReveal';
import FlipCard from '../Interactives/flipCard/flipCardPreview';
import ImageSlider from '../Interactives/imageSlider';
import HotspotViewComponent from '../Interactives/hotspot/hotspotView';
import DragAndDropComponent from '../Interactives/draganddrop';
import MCQComponent from '../Interactives/multipleChoice';
import ModalComponent from '../utils/modal';

const getPreviewComponent = (type: string, content: any, buttonParameter: {}) => {
  switch (type) {
    case 'Accordion':
      return (
        <AccordionComponent parameters={content.parameters} customisations={content} isLMSView />
      );
    case 'Tabs':
      return <TabComponent parameters={content.parameters} customisations={content} />;
    case 'Button':
      return <ButtonComponent parameters={buttonParameter} customisations={content} />;
    case 'multiplechoice':
      return (
        <MCQComponent
          parameters={content.parameters}
          customisations={{
            ...content,
            optionType: content.optiontype,
            retry: content.customtryagain,
          }}
        />
      );
    case 'ClickAndRevealInteractions':
      return <ClickAndRevealComponent parameters={content.parameters} customisations={content} />;
    case 'flipcard':
      return <FlipCard parameters={content.parameters} customisations={content} />;
    case 'imageslider':
      return <ImageSlider parameters={content.parameters} customisations={content} />;
    case 'Hotspot':
      return <HotspotViewComponent parameters={content.dndParameters} customisations={content} />;
    case 'DragandDrop':
      return (
        <DragAndDropComponent
          parameters={
            content.dndLayout === 'CustomOverlay' ? content.dndParameters : content.parameters
          }
          customisations={content}
        />
      );
    default:
      return <></>;
  }
};

const PreviewComponent = (props: any) => {
  const [buttonParameter, setButtonParamaeter] = useState({});

  const { content, type } = props;
  useEffect(() => {
    if (type !== 'Button') return;
    const { name, link, isChecked } = content;
    content.bgColor = content?.color;
    setButtonParamaeter({
      name,
      link,
      isChecked,
    });
  }, [props.show, content, type, props.parameters]);
  return (
    <ModalComponent
      size='xl'
      show={props.show}
      handleCancel={props.onHide}
      modalDetails={{ title: 'Preview', body: getPreviewComponent(type, content, buttonParameter) }}
      modalType='preview'
    />
  );
};

export default PreviewComponent;
