import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import DropDownComponent from '../utils/dropDown';
import ColorPickerComponent from './colorPicker';
import SpaceComponent from './spaceComponent';
import { customStyles } from './styles';

interface BorderRadiusComponentInterface {
  SetBorderColor: Function;
  borderColor: string;
  borderTop: string;
  borderLeft: string;
  borderRight: string;
  borderBottom: string;
  SetBorderLeft: Function;
  SetBorderRight: Function;
  SetBorderBottom: Function;
  SetBorderTop: Function;
  borderStyle: string;
  SetBorderStyle: Function;
  borderRadius: string;
  SetBorderRadius: Function;
}

const BorderCustomizeComponent = (props: BorderRadiusComponentInterface) => {
  const { SetBorderStyle, SetBorderRadius } = props;
  const borderStyleHandler = (event: string) => {
    SetBorderStyle({
      borderStyle: event,
    });
  };
  const borderRadiusHandler = (event: React.ChangeEvent) => {
    const { value } = event.target as HTMLInputElement;
    const finalValue = Number(value);
    if (finalValue < 0 || finalValue > 40) return;
    SetBorderRadius({
      borderRadius: value,
    });
  };
  return (
    <>
      <ColorPickerComponent
        type='borderColor'
        title='Border Color'
        SetColor={props.SetBorderColor}
        color={props.borderColor}
      />
      <SpaceComponent
        componentType='Border'
        left={props.borderLeft}
        top={props.borderTop}
        right={props.borderRight}
        bottom={props.borderBottom}
        SetLeft={props.SetBorderLeft}
        SetRight={props.SetBorderRight}
        SetBottom={props.SetBorderBottom}
        SetTop={props.SetBorderTop}
      />
      <DropDownComponent
        setSelectedOption={borderStyleHandler}
        selectedOption={props.borderStyle ? props.borderStyle : 'Select'}
        className='lint-customize-dropdown'
        optionCollection={customStyles.borderStyle}
      />
      <Form.Group className='my-4' data-testid='border-radius'>
        <Row>
          <Col sm={7}>
            <Form.Label className='design-section-text'>
              Border radius <span style={{ fontStyle: 'italic' }}>(px)</span>
            </Form.Label>
          </Col>
          <Col sm={5} className='ps-0'>
            <Form.Control
              data-testid='border-radius-input'
              className='border-radius-input'
              type='number'
              min='0'
              max='40'
              size='sm'
              value={props.borderRadius}
              onChange={borderRadiusHandler}
            />
          </Col>
        </Row>
      </Form.Group>
    </>
  );
};

export default BorderCustomizeComponent;
