import { Extension } from '@tiptap/core';

type ClassAttributeOptions = {
  types: string[];
  defaultClass: string | null;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    className: {
      setClass: (size: string) => ReturnType;
    };
  }
}

export const ClassAttribute = Extension.create<ClassAttributeOptions>({
  name: 'className',

  addOptions() {
    return {
      types: ['paragraph', 'heading'],
      defaultClass: null,
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          class: {
            default: null,
            parseHTML: (element) => {
              return element.className;
            },
            renderHTML: (attributes: Record<string, string>) => {
              if (!attributes['class']) {
                return {};
              }
              return {
                class: attributes['class'],
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setClass:
        (className) =>
        ({ commands }) => {
          return this.options.types.every((type) =>
            commands.updateAttributes(type, { class: className }),
          );
        },
    };
  },
});

type StyleOptions = {
  types: string[];
  defaultStyle: string | null;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    style: {
      setStyle: (style: string) => ReturnType;
    };
  }
}

export const StyleAttribute = Extension.create<StyleOptions>({
  name: 'style',

  addOptions() {
    return {
      types: ['paragraph', 'heading'],
      defaultStyle: null,
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          style: {
            default: null,
            parseHTML: (element) => element.getAttribute('style'),
            renderHTML: (attributes: Record<string, string>) => {
              return {
                style: attributes['style'],
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setStyle:
        (style) =>
        ({ commands }) => {
          return this.options.types.every((type) => commands.updateAttributes(type, { style }));
        },
    };
  },
});
