/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  borderColor,
  borderStyle,
  setBorderBottom,
  setBorderLeft,
  setBorderRadius,
  setBorderRight,
  setBorderTop,
} from '../../../../redux/multipleChoice/mcq.action';
import BorderCustomizeComponent from '../../../customizeComponentLayout/borderCustomize';

const mapState = ({ mcqform }: any) => ({
  borderStyle: mcqform.borderStyle,
  borderColor: mcqform.borderColor,
  borderTop: mcqform.borderTop,
  borderBottom: mcqform.borderBottom,
  borderLeft: mcqform.borderLeft,
  borderRight: mcqform.borderRight,
  borderRadius: mcqform.borderRadius,
});

const mapDispatch = (dispatch: Function) => ({
  SetBorderTop: (form: {}) => dispatch(setBorderTop(form)),
  SetBorderBottom: (form: {}) => dispatch(setBorderBottom(form)),
  SetBorderRight: (form: {}) => dispatch(setBorderRight(form)),
  SetBorderLeft: (form: {}) => dispatch(setBorderLeft(form)),
  SetBorderRadius: (form: {}) => dispatch(setBorderRadius(form)),
  SetBorderColor: (form: {}) => dispatch(borderColor(form)),
  SetBorderStyle: (form: {}) => dispatch(borderStyle(form)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const BorderCustomize = (props: Props) => {
  const {
    borderStyle,
    borderColor,
    borderTop,
    borderBottom,
    borderLeft,
    borderRight,
    borderRadius,
  } = props;

  return (
    <BorderCustomizeComponent
      borderLeft={borderLeft}
      borderTop={borderTop}
      borderRight={borderRight}
      borderBottom={borderBottom}
      borderStyle={borderStyle}
      borderRadius={borderRadius}
      borderColor={borderColor}
      SetBorderColor={props.SetBorderColor}
      SetBorderLeft={props.SetBorderLeft}
      SetBorderRight={props.SetBorderRight}
      SetBorderBottom={props.SetBorderBottom}
      SetBorderTop={props.SetBorderTop}
      SetBorderStyle={props.SetBorderStyle}
      SetBorderRadius={props.SetBorderRadius}
    />
  );
};

export default connector(BorderCustomize);
