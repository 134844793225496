/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const carouselUpdate = (customisations: any) => {
  let carouselPrevIcon = document.getElementsByClassName('carousel-control-prev');
  let carouselnextIcon = document.getElementsByClassName('carousel-control-next');
  let carouselprevControlIcon = document.getElementsByClassName('carousel-control-prev-icon');
  let carouselnextControlIcon = document.getElementsByClassName('carousel-control-next-icon');
  let carouselContent = document.getElementsByClassName('carousel-content');
  if (carouselContent) {
    Array.from(carouselContent).forEach((carousel) => {
      (carousel as HTMLElement).style.backgroundColor = customisations?.imageBgColor;
    });
  }

  if (carouselPrevIcon[0] && carouselnextIcon[0]) {
    carouselPrevIcon[0].setAttribute('style', `background-color: ${customisations?.iconColor};`);
    carouselnextIcon[0].setAttribute('style', `background-color: ${customisations?.iconColor};`);
    carouselprevControlIcon[0].setAttribute('style', `padding: ${customisations?.iconSize};`);
    carouselnextControlIcon[0].setAttribute('style', `padding: ${customisations?.iconSize};`);

    if (customisations?.iconType === '50%') {
      carouselPrevIcon[0]?.setAttribute(
        'style',
        carouselPrevIcon[0]?.getAttribute('style') + `border-radius: ${customisations?.iconType};`,
      );
      carouselnextIcon[0]?.setAttribute(
        'style',
        carouselnextIcon[0]?.getAttribute('style') + `border-radius: ${customisations?.iconType};`,
      );
    }

    if (customisations?.iconAlignment === 'Top') {
      carouselPrevIcon[0].className = 'carousel-control-prev-top';
      carouselnextIcon[0].className = 'carousel-control-next-top';
    }
    if (customisations?.iconAlignment === 'Bottom') {
      carouselPrevIcon[0].className = 'carousel-control-prev-bottom';
      carouselnextIcon[0].className = 'carousel-control-next-bottom';
    }
  }
};

export const wrapIframeInVideoWrapper = (parameters: any) => {
  // To wrap the iframe inside a div to make the video responsive
  return parameters.map((content: any) => {
    let object = JSON.parse(JSON.stringify(content));
    let origString = object.text;
    let stringToAdd = "<div class='videoWrapper'>";
    let indexPosition = object.text.indexOf('<iframe');
    if (indexPosition !== -1) {
      let newString =
        origString.slice(0, indexPosition) + stringToAdd + origString.slice(indexPosition);
      indexPosition = newString.indexOf('</iframe>');
      newString = newString.slice(0, indexPosition) + '</div>' + newString.slice(indexPosition);
      object.text = newString;
    }
    return object;
  });
};
