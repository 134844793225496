/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createContent, editContent, titleCheck } from '../../../middleware/api';
import { getValueFromSession } from '../../../middleware/utils';
import store from '../../../redux/store';
import { validateButtonForm } from '../validate';
import { ButtonFormInterface } from './form';

export const getButtonStyle = (customisations: any, type: string) => {
  return {
    background: type === 'unhover' ? 'white' : `${customisations.bgColor}`,
    fontFamily: `${customisations.fontFamily}`,
    fontSize: `${customisations.fontSize}px`,
    color: type === 'unhover' ? `${customisations.bgColor}` : `${customisations.fontColor}`,
    border: `2px solid ${customisations.bgColor}`,
    paddingTop:
      customisations.customPaddingtop === '' ? 10 : `${customisations.customPaddingtop}px`,
    paddingLeft:
      customisations.customPaddingleft === '' ? 10 : `${customisations.customPaddingleft}px`,
    paddingBottom:
      customisations.customPaddingbottom === '' ? 10 : `${customisations.customPaddingbottom}px`,
    paddingRight:
      customisations.customPaddingright === '' ? 10 : `${customisations.customPaddingright}px`,
  };
};
export const handleButtonSubmit = async (
  props: any,
  isGlobal: boolean,
  config: any,
  id?: string,
) => {
  const { SetButtonFormError } = props;
  const buttonStateCollection = store.getState().button;
  const error = validateButtonForm(buttonStateCollection, SetButtonFormError);
  if (error.title || error.name || error.link) {
    return;
  }
  const response = !id
    ? await createButtonInteractive(buttonStateCollection, config, isGlobal)
    : await updateButtonInteractive(buttonStateCollection, config, isGlobal, id);
  if (response?.error?.includes('already exist')) {
    SetButtonFormError({
      errors: { title: 'Title already exist, Please enter a new title' },
    });
    return;
  }
  return response;
};

const createButtonInteractive = async (
  props: ButtonFormInterface,
  config: any,
  isGlobal: boolean,
) => {
  let buttonData = getButtonDataStructure(props, isGlobal, config);
  const apiData = JSON.parse(JSON.stringify(buttonData));
  return await createContent(apiData);
};

const updateButtonInteractive = async (
  props: ButtonFormInterface,
  config: any,
  isGlobal: boolean,
  id: string,
) => {
  let buttonData = getButtonDataStructure(props, isGlobal, config);
  const apiData = JSON.parse(JSON.stringify(buttonData));
  return await editContent({
    id,
    body: apiData,
  });
};

export const checkInteractiveTitle = async (title: string) => {
  const data = {
    title: title,
    organization_id: getValueFromSession('org_id'),
  };
  const response = await titleCheck(data);
  if (response.error === null) {
    return true;
  }
  return false;
};

const getButtonDataStructure = (props: ButtonFormInterface, isGlobal: boolean, config: any) => {
  const { name, link, isChecked, title } = props;
  return {
    user_id: getValueFromSession('user_id'),
    library_id: 3,
    organization_id: getValueFromSession('org_id'),
    title: title,
    parameters: {
      name,
      link,
      isChecked,
    },
    customisations: {
      bgColor: props.color,
      btnType: props.btnType,
      fontFamily: props.fontFamily,
      fontSize: props.fontSize,
      fontColor: props.fontColor,
      alignment: props.alignment,
      customPaddingtop: props.customPaddingtop !== '' ? props.customPaddingtop : '0',
      customPaddingleft: props.customPaddingleft !== '' ? props.customPaddingleft : '0',
      customPaddingbottom: props.customPaddingbottom !== '' ? props.customPaddingbottom : '0',
      customPaddingright: props.customPaddingright !== '' ? props.customPaddingright : '0',
      fontsList: JSON.stringify(config.fontFamilyList),
    },
    active: true,
    private: !isGlobal,
    global: isGlobal,
    editable: isGlobal,
  };
};
