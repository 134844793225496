import { mergeAttributes, Node } from '@tiptap/core';
import { optimizeStyle } from './utilityFunctions';

export interface CaptionOptions {
  HTMLAttributes: Record<string, never>;
}

export const CustomizedCaption = Node.create<CaptionOptions>({
  name: 'caption',
  priority: 1004,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },
  group: 'caption',
  content: 'paragraph*',
  isolating: true,
  tableRole: 'caption',
  selectable: true,

  parseHTML() {
    return [{ tag: 'caption' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'caption',
      optimizeStyle(mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)),
      0,
    ];
  },
});
