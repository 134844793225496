/* eslint-disable @typescript-eslint/no-explicit-any */
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { ClassAttribute, StyleAttribute } from './attributeExtension';
import { LoreeInteractiveEditorDashboardContentType } from '../editorUtilityFunctions/lintEditorType';

export const CustomizedUnderLine = Underline.configure({
  HTMLAttributes: {
    class: 'lint-underline',
  },
});

export const CustomizedStyleAttribute = StyleAttribute.configure({
  types: [
    'starterKit',
    'bold',
    'CustomizedHeading',
    'heading',
    'paragraph',
    'table',
    'tableRow',
    'tableHeader',
    'TableCell',
    'CustomizedTableCell',
    'TableHeader',
    'CustomizedTableHeader',
    'caption',
    'image',
    'iconTag',
    'blockquote',
    'orderedList',
    'bulletList',
    'listItem',
  ],
});

export const CustomizedClassAttribute = ClassAttribute.configure({
  types: [
    'starterKit',
    'bold',
    'CustomizedHeading',
    'heading',
    'paragraph',
    'orderedList',
    'bulletList',
    'listItem',
    'caption',
    'image',
    'iconTag',
    'blockquote',
  ],
});

export const CustomizedTextAlign = TextAlign.configure({
  types: ['paragraph', 'heading', 'list_item', 'textStyle'],
  defaultAlignment: '',
  alignments: ['left', 'center', 'right', 'justify'],
});

export const CustomizedPlaceholder = (editorConfig: LoreeInteractiveEditorDashboardContentType) =>
  Placeholder.configure({
    emptyNodeClass: 'lint-editor-is-empty-class',
    placeholder: ({ node }) => {
      if (node.type.name === 'heading') {
        return 'What’s the title?';
      }
      return 'Write something..';
    },
  });
