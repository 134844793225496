/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@crystaldelta/loree-ui-components';
import { EditorMenuButton, EditorMenuDropdownButton, EditorMenuDropdown } from './styledContents';
import lintStyle from './loreeInteractiveEditor.module.scss';
import { LintEditorFormatPropsType, WordOptionTextTransformType } from './lintEditorType';
import { ReactComponent as SvgLowerCaseIcon } from '../editorFormattingIcons/lowerCaseIcon.svg';
import { ReactComponent as SvgStrikeThroughIcon } from '../editorFormattingIcons/strikeThroughIcon.svg';
import { ReactComponent as SvgSubscriptIcon } from '../editorFormattingIcons/subscription.svg';
import { ReactComponent as SvgSuperscriptIcon } from '../editorFormattingIcons/superscription.svg';
import { ReactComponent as SvgUpperCaseIcon } from '../editorFormattingIcons/upperCaseIcon.svg';
import { ReactComponent as SvgWordOptionsIcon } from '../editorFormattingIcons/wordOptionIcon.svg';
import { ReactComponent as TitleCaseButton } from '../editorFormattingIcons/titleCaseButton.svg';

const WordOptionIcon = styled.div`
  &.is-active svg {
    fill: #112299 !important;
    color: #112299 !important;
  }
  width: 17px;
  height: 16px;
  object-fit: contain;
`;

export function WordOptionsButton(props: Readonly<LintEditorFormatPropsType>) {
  const { editor } = props;
  if (!editor) {
    return null;
  }

  const toggleTextTransform = (value: WordOptionTextTransformType): void => {
    if (value === 'uppercase' || value === 'lowercase' || value === 'capitalize' || value === '') {
      if (isTextTransform(value)) {
        editor.chain().focus().setTextTransform('').run();
        return;
      }
    }
    if (value === 'uppercase' || value === 'lowercase' || value === 'capitalize') {
      editor.chain().focus().setTextTransform(value).run();
    } else if (value === 'strike') {
      editor.chain().focus().toggleStrike().run();
    } else if (value === 'superscript') {
      editor.chain().focus().unsetSubscript().toggleSuperscript().run();
    } else if (value === 'subscript') {
      editor.chain().focus().unsetSuperscript().toggleSubscript().run();
    }
  };

  const isTextTransform = (value: '' | 'uppercase' | 'lowercase' | 'capitalize'): boolean => {
    const ff = editor.getAttributes('textStyle');
    return (ff['textTransform'] ?? '') === value;
  };

  const isTextTransformApplied = (type: WordOptionTextTransformType) => {
    if (type === 'uppercase' || type === 'lowercase' || type === 'capitalize' || type === '') {
      return isTextTransform(type) ? 'is-active' : '';
    } else {
      return editor.isActive(type) ? 'is-active' : '';
    }
    return '';
  };

  return (
    <EditorMenuDropdown
      placement='bottom'
      element={
        <EditorMenuButton data-testid='wordOption-btn'>
          <Tooltip info='Word Options'>
            <TitleCaseButton className={lintStyle.formatButton} />
          </Tooltip>
        </EditorMenuButton>
      }
    >
      <Tooltip info='Uppercase'>
        <EditorMenuDropdownButton
          onClick={() => toggleTextTransform('uppercase')}
          className={isTextTransformApplied('uppercase')}
          data-testid='uppercase-btn'
        >
          <WordOptionIcon as={SvgUpperCaseIcon} />
        </EditorMenuDropdownButton>
      </Tooltip>
      <Tooltip info='Lowercase'>
        <EditorMenuDropdownButton
          onClick={() => toggleTextTransform('lowercase')}
          className={isTextTransformApplied('lowercase')}
          data-testid='lowercase-btn'
        >
          <WordOptionIcon as={SvgLowerCaseIcon} />
        </EditorMenuDropdownButton>
      </Tooltip>

      <Tooltip info='Capitalize'>
        <EditorMenuDropdownButton
          onClick={() => toggleTextTransform('capitalize')}
          className={isTextTransformApplied('capitalize')}
          data-testid='capitalize-btn'
        >
          <WordOptionIcon as={SvgWordOptionsIcon} />
        </EditorMenuDropdownButton>
      </Tooltip>

      <Tooltip info='Strikethrough'>
        <EditorMenuDropdownButton
          onClick={() => toggleTextTransform('strike')}
          className={isTextTransformApplied('strike')}
          data-testid='strikeThrough-btn'
        >
          <WordOptionIcon as={SvgStrikeThroughIcon} />
        </EditorMenuDropdownButton>
      </Tooltip>

      <Tooltip info='Superscript'>
        <EditorMenuDropdownButton
          onClick={() => toggleTextTransform('superscript')}
          className={isTextTransformApplied('superscript')}
          data-testid='superscript-btn'
        >
          <WordOptionIcon as={SvgSuperscriptIcon} />
        </EditorMenuDropdownButton>
      </Tooltip>

      <Tooltip info='Subscript'>
        <EditorMenuDropdownButton
          onClick={() => toggleTextTransform('subscript')}
          className={isTextTransformApplied('subscript')}
          data-testid='subscript-btn'
        >
          <WordOptionIcon as={SvgSubscriptIcon} />
        </EditorMenuDropdownButton>
      </Tooltip>
    </EditorMenuDropdown>
  );
}
