/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useState } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import CustomizeSection from './customizeSection';
import { ReactComponent as AccordionExpand } from '../../../assets/icons/accordionExpand.svg';
import { ReactComponent as AccordionHide } from '../../../assets/icons/accordionHide.svg';
import FontHandlerComponent from '../../customizeComponentLayout/fontHandler';
import { customStyles } from '../../customizeComponentLayout/styles';
import { cardType, flip, noOfCard } from '../../../redux/flipCard/form.action';
import { connect } from 'react-redux';
import { FlipFormInterface } from '../../../redux/interface';

const FlipCardAndClickAndRevealCustomize = (props: any) => {
  const [collapseID, setCollapseId] = useState(
    props.type === 'flipcard' ? 'cardType' : 'clickContent',
  );
  const toggleCollapse = (id: string) => {
    setCollapseId(id !== collapseID ? id : '');
  };

  const setCardTypeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { CardType } = props;
    CardType({
      cardType: event.target.value,
    });
  };
  const setEventTypeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { EventType } = props;
    EventType({
      flip: event.target.value,
    });
  };

  return (
    <Accordion
      className='mx-2'
      defaultActiveKey={props.type === 'flipcard' ? 'cardType' : 'clickContent'}
    >
      {props.type === 'flipcard' && (
        <Accordion.Item eventKey='cardType' className='border-0 my-3'>
          <Accordion.Header
            as='div'
            className={`custom-header-title m-0 ${collapseID === 'cardType' && 'selected-section'}`}
            onClick={() => toggleCollapse('cardType')}
          >
            {collapseID === 'cardType' ? <AccordionExpand /> : <AccordionHide />}
            <div className={`px-2 ${collapseID === 'cardType' && 'selected-section'}`}>
              Card Type
            </div>
          </Accordion.Header>
          <Accordion.Body className='pb-0 px-2 pt-3'>
            <div className='icon-items container-inner'>
              <span className='design-section-text'>Type</span>
              <div className='my-2'>
                <Form.Check
                  inline
                  label='Square'
                  id='square-button'
                  type='radio'
                  name='cardType'
                  value='square'
                  onChange={setCardTypeHandler}
                  checked={props.cardType === 'square'}
                  className='icon-label'
                />
                <Form.Check
                  inline
                  label='Portrait'
                  id='portrait-button'
                  type='radio'
                  name='cardType'
                  value='portrait'
                  onChange={setCardTypeHandler}
                  checked={props.cardType === 'portrait'}
                  className='icon-label'
                />
              </div>
            </div>
            <FontHandlerComponent
              componentType='No of cards in a row'
              setFontProperty={props.NoOfCards}
              fontPropertyValue={props.noOfCard}
              options={customStyles.cardsInRows}
            />
            <div className='icon-items container-inner'>
              <span className='design-section-text'>Event</span>
              <div>
                <Form.Check
                  inline
                  label='Click'
                  name='eventType'
                  type='radio'
                  value='0'
                  id='click-button'
                  onChange={setEventTypeHandler}
                  checked={props.flip === '0'}
                  className='icon-label'
                />
                <Form.Check
                  inline
                  label='Hover'
                  name='eventType'
                  type='radio'
                  value='1'
                  id='hover-button'
                  onChange={setEventTypeHandler}
                  checked={props.flip === '1'}
                  className='icon-label'
                />
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      )}
      <Accordion.Item eventKey='clickContent' className='border-0 my-3'>
        <Accordion.Header
          as='div'
          className={`custom-header-title m-0 ${
            collapseID === 'clickContent' && 'selected-section'
          }`}
          onClick={() => toggleCollapse('clickContent')}
        >
          {collapseID === 'clickContent' ? <AccordionExpand /> : <AccordionHide />}
          <div className={`px-2 ${collapseID === 'clickContent' && 'selected-section'}`}>
            {props.type === 'flipcard' ? 'Card Front' : 'Click Content'}
          </div>
        </Accordion.Header>
        <CustomizeSection customizeType={props.type === 'flipcard' ? 'frontcard' : 'click'} />
      </Accordion.Item>
      <Accordion.Item eventKey='revealContent' className='border-0 my-3'>
        <Accordion.Header
          as='div'
          className={`custom-header-title m-0 ${
            collapseID === 'revealContent' && 'selected-section'
          }`}
          onClick={() => toggleCollapse('revealContent')}
        >
          {collapseID === 'revealContent' ? <AccordionExpand /> : <AccordionHide />}
          <div className={`px-2 ${collapseID === 'revealContent' && 'selected-section'}`}>
            {props.type === 'flipcard' ? 'Card Back' : 'Reveal Content'}
          </div>
        </Accordion.Header>
        <CustomizeSection customizeType={props.type === 'flipcard' ? 'backcard' : 'reveal'} />
      </Accordion.Item>
    </Accordion>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
  NoOfCards: (flipform: FlipFormInterface) => dispatch(noOfCard(flipform)),
  CardType: (flipform: FlipFormInterface) => dispatch(cardType(flipform)),
  EventType: (flipform: FlipFormInterface) => dispatch(flip(flipform)),
});
const mapStateToProps = ({ flipform }: any) => ({
  noOfCard: flipform.noOfCard,
  cardType: flipform.cardType,
  flip: flipform.flip,
});
//connecting form with redux
export default connect(mapStateToProps, mapDispatchToProps)(FlipCardAndClickAndRevealCustomize);
