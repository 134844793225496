/* eslint-disable no-undefined */

//to set the default values in all the fields of customisation while editing the interactives which were created at earlier days

export const defaultValue = (props) => {

  const { FontColor, HandleFontSize, BgColor , BorderColor } = props;
  const type = props.content.type;
   
  if(props.fontSize === ""){
    HandleFontSize({
      fontSize: 16
    });
  }
  
  if (props.fontColor === ""){
    FontColor({
      fontColor: (type === "imageslider") ? "#ffffff" : "#000000"
    });
  }
  
  if (props.bgcolor === "" || props.bgcolor === "#0e000"){
    BgColor({
      bgcolor: "#F2F2F2"
    });
  }
  
  if(props.borderColor === ""|| props.borderColor === undefined ){
    BorderColor({
      borderColor: "#000000"
    });
  }
};
  
export const flipcardValue = (props) => {
  const {BgColor, BackColor, BorderColor} = props;
  
  
  if (props.bgcolor === ""){
    BgColor({
      BgColor: "#000000",
      bgcolor:"#ffffff"
    });
  }
  
  if(props.backColor === ""){
    BackColor({
      backColor: "#ffffff"
    });
  }
  
  if(props.borderColor === ""|| props.borderColor === undefined ){
    BorderColor({
      borderColor: "#000000"
  
    });
  }
  
};
  
export const filterPtag =  (content) => {
  return content.replace(/<p\b[^<>/]*>\s*(<img\b[^<>]*>)\s*<\/p>/g, "$1");
};
