import React, { useRef, useState } from 'react';
import { findParentNodeClosestToPos } from '@tiptap/core';
import { Tooltip } from '@crystaldelta/loree-ui-components';
import { CONSTANTS } from './constants';
import { LintEditorFormatPropsType, TableModalTitleType, tableCellTypes } from './lintEditorType';
import {
  TableMenuSection,
  TableMenuDropdown,
  TableMenuOptionButton,
  TableMenuButton,
} from './styledContents';
import { TableModal } from './tableContentModal';
import {
  checkDeleteColumnDisabled,
  checkDeleteRowDisabled,
  createPlaceholderText,
  getTableElementStylesFromWindow,
} from './utilityFunctions';
import { isInTable } from '../editorExtensions/tableElementContent/util';
import { ReactComponent as TableButtonDropdown } from '../editorFormattingIcons/tableButtonDropdown.svg';
import { ReactComponent as TableDropDownIcon } from '../editorFormattingIcons/dropDownMenu.svg';
import { ReactComponent as TableSubMenuOption } from '../editorFormattingIcons/subMenuIndication.svg';

export const TableFormatContent = (props: LintEditorFormatPropsType) => {
  const { editor, editorConfig } = props;
  const [showCreateNewTable, setShowCreateNewTable] = useState(false);
  const [showTableCellProperties, setShowTableCellProperties] = useState(false);
  const [showTableRowProperties, setShowTableRowProperties] = useState(false);
  const [showTableProperties, setShowTableProperties] = useState(false);
  const [modalHeaderTitle, setModalHeaderTitle] = useState('');

  const tableIconSection = useRef<HTMLDivElement | null>(null);

  const appliedTableStyles = {
    width: '100',
    alignment: 'left',
    border: '0',
    borderStyle: 'solid',
    borderColor: '#000000',
    borderCollapse: 'collapse',
    backgroundColor: '#ffffff',
  };
  const appliedTableRowStyles = {
    border: '0',
    borderStyle: 'solid',
    borderColor: '#000000',
    borderCollapse: 'collapse',
    backgroundColor: '#ffffff',
  };
  const appliedTableCellStyles = {
    padding: '5px 5px 5px 5px',
    cellScope: 'none',
    border: '0',
    borderStyle: 'solid',
    borderColor: '#000000',
    borderCollapse: 'collapse',
    backgroundColor: '#ffffff',
  };
  if (!editor) {
    return null;
  }
  const { selection } = editor.state;

  const tablePos = findParentNodeClosestToPos(selection.ranges[0].$from, (node) => {
    return node.type.name === 'table';
  });

  const tableRowPos = findParentNodeClosestToPos(selection.ranges[0].$from, (node) => {
    return node.type.name === 'tableRow';
  });

  const tableCellPos = findParentNodeClosestToPos(selection.ranges[0].$from, (node) => {
    return node.type.name === 'tableCell' || node.type.name === 'tableHeader';
  });

  if (tablePos?.pos !== undefined) {
    const tableElement = editor.view.domAtPos(tablePos?.pos !== undefined ? tablePos.pos + 1 : 0)
      .node as HTMLElement;

    if (tableElement.style.width) {
      appliedTableStyles.width = tableElement.style.width.split('%')[0];
    } else {
      const windowStyle = window.getComputedStyle(tableElement).getPropertyValue('width');
      appliedTableStyles.width = windowStyle.indexOf('%') > 0 ? windowStyle.split('%')[0] : '100';
    }

    if (tableElement.style.margin) {
      appliedTableStyles.alignment = tableElement.style.margin;
    } else {
      const windowTableAlignmentStyle = window
        .getComputedStyle(tableElement)
        .getPropertyValue('margin');
      if (windowTableAlignmentStyle) {
        appliedTableStyles.alignment = windowTableAlignmentStyle;
      }
    }

    const windowBorder = window.getComputedStyle(tableElement).getPropertyValue('border');
    if (tableElement.style.width) {
      appliedTableStyles.border = tableElement.style.borderWidth.split('px')[0];
    } else if (windowBorder) {
      appliedTableStyles.border = windowBorder.split(' ')[0].split('px')[0];
    }

    appliedTableStyles.borderStyle = getTableElementStylesFromWindow(tableElement, 'border-style');

    appliedTableStyles.borderColor = getTableElementStylesFromWindow(tableElement, 'border-color');

    appliedTableStyles.borderCollapse = getTableElementStylesFromWindow(
      tableElement,
      'border-collapse',
    );
    appliedTableStyles.backgroundColor = getTableElementStylesFromWindow(
      tableElement,
      'background-color',
    );
  }

  if (tableRowPos?.pos !== undefined) {
    const tableRowElement = editor.view.domAtPos(
      tableRowPos?.pos !== undefined ? tableRowPos.pos + 1 : 0,
    ).node as HTMLElement;

    const windowBorder = window.getComputedStyle(tableRowElement).getPropertyValue('border');
    if (tableRowElement.style.width) {
      appliedTableRowStyles.border = tableRowElement.style.borderWidth.split('px')[0];
    } else if (windowBorder) {
      appliedTableRowStyles.border = windowBorder.split(' ')[0].split('px')[0];
    }

    appliedTableRowStyles.borderStyle = getTableElementStylesFromWindow(
      tableRowElement,
      'border-style',
    );

    appliedTableRowStyles.borderColor = getTableElementStylesFromWindow(
      tableRowElement,
      'border-color',
    );

    appliedTableRowStyles.backgroundColor = getTableElementStylesFromWindow(
      tableRowElement,
      'background-color',
    );
  }

  if (tableCellPos?.pos !== undefined) {
    const tableCellElement = editor.view.domAtPos(
      tableCellPos?.pos !== undefined ? tableCellPos.pos + 1 : 0,
    ).node as HTMLElement;

    const windowPaddingStyle = getTableElementStylesFromWindow(tableCellElement, 'padding');

    const windowPaddingTopStyle = getTableElementStylesFromWindow(tableCellElement, 'padding-top');
    const windowPaddingRightStyle = getTableElementStylesFromWindow(
      tableCellElement,
      'padding-right',
    );
    const windowPaddingBottomStyle = getTableElementStylesFromWindow(
      tableCellElement,
      'padding-bottom',
    );
    const windowPaddingLeftStyle = getTableElementStylesFromWindow(
      tableCellElement,
      'padding-left',
    );

    appliedTableCellStyles.padding = windowPaddingStyle
      ? windowPaddingTopStyle +
        ' ' +
        windowPaddingRightStyle +
        ' ' +
        windowPaddingBottomStyle +
        ' ' +
        windowPaddingLeftStyle
      : '0px 0px 0px 0px';

    const tableCellScope = tableCellElement.hasAttribute('scope')
      ? tableCellElement.getAttribute('scope')
      : 'none';
    appliedTableCellStyles.cellScope = tableCellScope as string;

    const windowBorder = window.getComputedStyle(tableCellElement).getPropertyValue('border');
    if (tableCellElement.style.width) {
      appliedTableCellStyles.border = tableCellElement.style.borderWidth.split('px')[0];
    } else if (windowBorder) {
      appliedTableCellStyles.border = windowBorder.split(' ')[0].split('px')[0];
    }

    appliedTableCellStyles.borderStyle = getTableElementStylesFromWindow(
      tableCellElement,
      'border-style',
    );

    appliedTableCellStyles.borderColor = getTableElementStylesFromWindow(
      tableCellElement,
      'border-color',
    );

    appliedTableCellStyles.backgroundColor = getTableElementStylesFromWindow(
      tableCellElement,
      'background-color',
    );
  }

  const isSplitCells = () => {
    return !editor.can().splitCell();
  };
  const isMergeCells = () => {
    return !editor.can().mergeCells();
  };

  const closeParentOption = () => {
    if (tableIconSection.current) {
      const tableIcon = (tableIconSection.current as HTMLDivElement).querySelectorAll(
        '[data-testid="table-icon"]',
      )[0] as HTMLButtonElement;
      tableIcon.click();
    }
  };

  const openTableModal = (title: string) => {
    closeParentOption();
    setModalHeaderTitle(title);
    if (title === TableModalTitleType[1]) {
      setShowTableCellProperties(true);
    } else if (title === TableModalTitleType[2]) {
      setShowTableRowProperties(true);
    } else if (title === TableModalTitleType[3]) {
      setShowTableProperties(true);
    } else {
      setShowCreateNewTable(true);
    }
  };
  const insertTable = (
    row: number,
    column: number,
    isHeaderRow: boolean,
    isHeaderColumn: boolean,
    captionValue: string,
  ) => {
    const inserTableOption = {
      rows: row,
      cols: column,
      withHeaderRow: isHeaderRow,
      withHeaderColumn: isHeaderColumn,
      captionValue: captionValue,
      editorConfig: editorConfig,
    };
    editor.commands.insertTable(inserTableOption);
  };

  const addTableCellStyles = (
    padding: string,
    cellScope: string,
    border: string,
    backgroundColor: string,
  ) => {
    editor
      .chain()
      .updateAttributes(tableCellTypes[0], { scope: cellScope })
      .updateAttributes(tableCellTypes[1], { scope: cellScope })
      .updateNodeStylesInRange(
        tableCellTypes,
        {
          padding: padding,
          border: border,
          backgroundColor: backgroundColor,
        },
        'style',
      )
      .run();
  };

  const addTableRowStyles = (border: string, backgroundColor: string) => {
    editor
      .chain()
      .updateNodeStylesInRange(
        ['tableRow'],
        {
          border: border,
          backgroundColor: backgroundColor,
        },
        'style',
      )
      .run();
  };

  const addTableStyles = (
    width: string,
    tableAlign: string,
    border: string,
    borderCollapse: string,
    backgroundColor: string,
  ) => {
    editor
      .chain()
      .updateNodeStyles('table', {
        width: width,
        margin: tableAlign,
        border: border,
        borderCollapse: borderCollapse,
        backgroundColor: backgroundColor,
      })
      .run();
  };

  const canInsertRow = (direction: string) => {
    if (direction === 'before') {
      return editor.can().addRowBefore();
    } else {
      return editor.can().addRowAfter();
    }
  };
  const insertRow = (direction: string) => {
    closeParentOption();
    const placeholder = createPlaceholderText(editor, editorConfig);
    if (direction === 'before') {
      editor.commands.addRowBefore(placeholder);
    } else {
      editor.commands.addRowAfter(placeholder);
    }
  };

  const canInsertColumn = (direction: string) => {
    if (direction === 'before') {
      return editor.can().addColumnBefore();
    } else {
      return editor.can().addColumnAfter();
    }
  };
  const insertColumn = (direction: string) => {
    closeParentOption();
    const placeholder = createPlaceholderText(editor, editorConfig);
    if (direction === 'before') {
      editor.commands.addColumnBefore(placeholder);
    } else {
      editor.commands.addColumnAfter(placeholder);
    }
  };

  const canDeleteRow = (): boolean => {
    return checkDeleteRowDisabled(editor.state);
  };
  const deleteRow = () => {
    closeParentOption();
    editor.commands.deleteRow();
  };

  const canDeleteColumn = (): boolean => {
    return checkDeleteColumnDisabled(editor.state);
  };
  const deleteColumn = () => {
    closeParentOption();
    editor.commands.deleteColumn();
  };

  const canShowTableProperties = (): boolean => {
    return isInTable(editor.state);
  };

  const canDeleteTable = (): boolean => {
    return editor.can().deleteTable() as boolean;
  };

  const addActiveForSubMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    let subMenuElement = e.target as HTMLElement;
    if (subMenuElement.tagName !== 'A') {
      subMenuElement = subMenuElement.closest('.dropdown-item') as HTMLElement;
    }

    if (subMenuElement.classList.contains(CONSTANTS.LOREE_EDITOR_TABLE_ACTIVE_MENU)) {
      removeActiveForSubMenu();
    } else {
      removeActiveForSubMenu();
      subMenuElement.classList.add(CONSTANTS.LOREE_EDITOR_TABLE_ACTIVE_MENU);
    }
  };
  const removeActiveForSubMenu = () => {
    const activeTableSubMenu = document.getElementsByClassName(
      CONSTANTS.LOREE_EDITOR_TABLE_ACTIVE_MENU,
    );
    if (activeTableSubMenu.length > 0) {
      activeTableSubMenu[0].classList.remove(CONSTANTS.LOREE_EDITOR_TABLE_ACTIVE_MENU);
    }
  };

  return (
    <>
      {showCreateNewTable && (
        <TableModal
          modalHeaderTitle={modalHeaderTitle}
          show={showCreateNewTable}
          onHide={() => setShowCreateNewTable(false)}
          onSubmit={(
            row: number,
            column: number,
            isHeaderRow: boolean,
            isHeaderColumn: boolean,
            captionValue: string,
          ) => insertTable(row, column, isHeaderRow, isHeaderColumn, captionValue)}
        />
      )}
      {showTableCellProperties && (
        <TableModal
          modalHeaderTitle={modalHeaderTitle}
          show={showTableCellProperties}
          styles={appliedTableCellStyles}
          onHide={() => setShowTableCellProperties(false)}
          onSubmitTableCellProperties={(
            padding: string,
            cellScope: string,
            border: string,
            backgroundColor: string,
          ) => addTableCellStyles(padding, cellScope, border, backgroundColor)}
        />
      )}
      {showTableRowProperties && (
        <TableModal
          modalHeaderTitle={modalHeaderTitle}
          show={showTableRowProperties}
          styles={appliedTableRowStyles}
          onHide={() => setShowTableRowProperties(false)}
          onSubmitTableRowProperties={(border: string, backgroundColor: string) =>
            addTableRowStyles(border, backgroundColor)
          }
        />
      )}
      {showTableProperties && (
        <TableModal
          modalHeaderTitle={modalHeaderTitle}
          show={showTableProperties}
          styles={appliedTableStyles}
          onHide={() => setShowTableProperties(false)}
          onSubmitTableProperties={(
            width: string,
            tableAlign: string,
            border: string,
            borderCollapse: string,
            backgroundColor: string,
          ) => addTableStyles(width, tableAlign, border, borderCollapse, backgroundColor)}
        />
      )}
      <TableMenuSection ref={tableIconSection}>
        <TableMenuDropdown
          placement='right'
          element={
            <TableMenuButton data-testid='table-icon'>
              <Tooltip info='Table' data-testid='table-options'>
                <TableButtonDropdown /> <TableDropDownIcon />
              </Tooltip>
            </TableMenuButton>
          }
        >
          <TableMenuOptionButton
            onClick={() => openTableModal(TableModalTitleType[0])}
            data-testid='add-table'
            name='LintTables'
          >
            Create table
          </TableMenuOptionButton>
          {/* Cells */}
          <TableMenuDropdown
            placement='right'
            boundary={document.body}
            offset={{
              crossAxis: 168,
              mainAxis: -30,
            }}
            element={
              <TableMenuOptionButton onClick={(e) => addActiveForSubMenu(e)}>
                Cell <TableSubMenuOption />
              </TableMenuOptionButton>
            }
          >
            <TableMenuOptionButton
              onClick={() => openTableModal(TableModalTitleType[1])}
              data-testid='table-cellproperties'
              name='LintTables'
              className={canShowTableProperties() ? '' : 'disabled'}
            >
              Cell properties
            </TableMenuOptionButton>

            <TableMenuOptionButton
              onClick={() => editor.commands.mergeCells()}
              disabled={isMergeCells()}
              data-testid='table-mergecells'
            >
              Merge Cells
            </TableMenuOptionButton>
            <TableMenuOptionButton
              onClick={() => editor.commands.splitCell()}
              disabled={isSplitCells()}
              data-testid='table-splitcells'
            >
              Split Cells
            </TableMenuOptionButton>
          </TableMenuDropdown>
          {/* Rows */}
          <TableMenuDropdown
            placement='right'
            boundary={document.body}
            offset={{
              crossAxis: 168,
              mainAxis: -30,
            }}
            element={
              <TableMenuOptionButton onClick={(e) => addActiveForSubMenu(e)}>
                Row <TableSubMenuOption />
              </TableMenuOptionButton>
            }
          >
            <TableMenuOptionButton
              onClick={() => insertRow('before')}
              data-testid='table-column'
              className={canInsertRow('before') ? '' : 'disabled'}
            >
              Insert row before
            </TableMenuOptionButton>
            <TableMenuOptionButton
              onClick={() => insertRow('after')}
              data-testid='table-cell'
              className={canInsertRow('after') ? '' : 'disabled'}
            >
              Insert row after
            </TableMenuOptionButton>
            <TableMenuOptionButton
              onClick={() => deleteRow()}
              data-testid='table-deleterow'
              name='LintTables'
              className={canDeleteRow() ? '' : 'disabled'}
            >
              Delete row
            </TableMenuOptionButton>
            <TableMenuOptionButton
              onClick={() => openTableModal(TableModalTitleType[2])}
              data-testid='table-rowproperties'
              name='LintTables'
              className={canDeleteRow() ? '' : 'disabled'}
            >
              Row properties
            </TableMenuOptionButton>
          </TableMenuDropdown>
          {/* Column */}
          <TableMenuDropdown
            placement='right'
            boundary={document.body}
            offset={{
              crossAxis: 168,
              mainAxis: -30,
            }}
            element={
              <TableMenuOptionButton onClick={(e) => addActiveForSubMenu(e)}>
                Column <TableSubMenuOption />
              </TableMenuOptionButton>
            }
          >
            <TableMenuOptionButton
              onClick={() => insertColumn('before')}
              data-testid='table-column'
              className={canInsertColumn('before') ? '' : 'disabled'}
            >
              Insert column before
            </TableMenuOptionButton>
            <TableMenuOptionButton
              onClick={() => insertColumn('after')}
              data-testid='table-cell'
              className={canInsertColumn('after') ? '' : 'disabled'}
            >
              Insert column after
            </TableMenuOptionButton>
            <TableMenuOptionButton
              onClick={() => deleteColumn()}
              data-testid='table-deletecolumn'
              name='LintTables'
              className={canDeleteColumn() ? '' : 'disabled'}
            >
              Delete column
            </TableMenuOptionButton>
          </TableMenuDropdown>
          <TableMenuOptionButton
            onClick={() => openTableModal(TableModalTitleType[3])}
            data-testid='table-properties'
            name='LintTables'
            className={canDeleteTable() ? '' : 'disabled'}
          >
            Table properties
          </TableMenuOptionButton>
          <TableMenuOptionButton
            onClick={() => editor.commands.deleteTable()}
            data-testid='table-delete'
            name='LintTables'
            className={canDeleteTable() ? '' : 'disabled'}
          >
            Delete table
          </TableMenuOptionButton>
        </TableMenuDropdown>
      </TableMenuSection>
    </>
  );
};
