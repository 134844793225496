import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import Store from './redux/store';
import 'bootstrap/scss/bootstrap.scss';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(
  <Provider store={Store}>
    <App />
  </Provider>,
);

serviceWorker.unregister();
