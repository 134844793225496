/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg';

interface SearchInterface {
  initialvalue: string;
  onChange: Function;
  isSearchText: boolean;
  setIsSearchText: Function;
  loading?: boolean;
}

const Search = (props: SearchInterface) => {
  const { initialvalue, onChange, isSearchText, setIsSearchText } = props;
  const [value, setValue] = useState(initialvalue);
  const inputRef = useRef() as any;

  useEffect(() => {
    setValue(initialvalue);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [props.loading, initialvalue, inputRef]);

  return (
    <div className='select-form'>
      <div className='d-inline-flex align-content-center position-relative mt-1'>
        <input
          name='search'
          className={`search pe-4 ${!isSearchText ? 'd-none' : ''}`}
          type='text'
          ref={inputRef}
          autoComplete='off'
          placeholder='Search'
          data-testid='debounce-search'
          onChange={(e) => onChange(e.target.value.trimStart())}
          value={value}
          style={{ width: '215px' }}
        />
        <SearchIcon
          data-testid='search-icon'
          width={!isSearchText ? 24 : 15}
          height={!isSearchText ? 20 : 15}
          style={isSearchText ? { position: 'absolute', top: 3, right: 0 } : {}}
          fill={props.loading ? 'rgb(108, 117, 125)' : ''}
          className={!isSearchText ? 'mx-2 search-normal-icon' : 'mx-2 iconActive'}
          onClick={() => {
            setIsSearchText(!isSearchText);
          }}
        />
      </div>
    </div>
  );
};

export default Search;
