import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { ColorPickerButton } from '@crystaldelta/loree-ui-color-picker';
import { CreateNewTableProps, borderStyles, cellScopeOptions } from '../lintEditorType';
import { getCapitalizeWord, numberValidation } from '../utilityFunctions';
import {
  BorderTableMenuButton,
  BorderTableMenuDropdown,
  BorderTableMenuOptionButton,
  BorderTableMenuSection,
} from '../styledContents';
import { ReactComponent as TableDropDownIcon } from '../../editorFormattingIcons/dropDownMenu.svg';
import { CONSTANTS } from '../constants';

export const TableContentProperties = (props: CreateNewTableProps) => {
  const {
    onHide,
    styles,
    isCellProperties,
    onSubmitTableRowProperties,
    onSubmitTableCellProperties,
  } = props;
  const paddingStyleProps = styles?.padding
    ? styles.padding.split(' ')
    : ['0px', '0px', '0px', '0px'];
  const borderStyleProps = styles?.borderStyle ? getCapitalizeWord(styles?.borderStyle) : 'solid';

  let cellScopeStyles = 'None';
  if (styles?.cellScope) {
    cellScopeStyles = styles?.cellScope.toLowerCase();
    switch (styles?.cellScope) {
      case 'col':
        cellScopeStyles = cellScopeOptions[2];
        break;
      case 'rowgroup':
        cellScopeStyles = cellScopeOptions[3];
        break;
      case 'colgroup':
        cellScopeStyles = cellScopeOptions[4];
        break;
    }
  }

  const [paddingTop, setPaddingTop] = useState(paddingStyleProps[0].split('px')[0]);
  const [paddingRight, setPaddingRight] = useState(paddingStyleProps[1].split('px')[0]);
  const [paddingBottom, setPaddingBottom] = useState(paddingStyleProps[2].split('px')[0]);
  const [paddingLeft, setPaddingLeft] = useState(paddingStyleProps[3].split('px')[0]);
  const [borderWidth, setBorderwidth] = useState(styles?.border ?? '3');
  const [cellScope, setCellScope] = useState(cellScopeStyles ?? 'None');
  const [borderStyle, setBorderStyle] = useState(borderStyleProps);
  const [borderColor, setBorderColor] = useState(styles?.borderColor ?? CONSTANTS.BORDER_COLOR);
  const [backgroundColor, setBackgroundColor] = useState(
    styles?.backgroundColor ?? CONSTANTS.BG_COLOR,
  );

  const paddingSetup = (target: EventTarget, side: string) => {
    const value = (target as HTMLInputElement).value;
    switch (side) {
      case 'top':
        setPaddingTop(parseInt(value) < 0 ? '0' : value);
        break;
      case 'right':
        setPaddingRight(parseInt(value) < 0 ? '0' : value);
        break;
      case 'bottom':
        setPaddingBottom(parseInt(value) < 0 ? '0' : value);
        break;
      case 'left':
        setPaddingLeft(parseInt(value) < 0 ? '0' : value);
        break;
    }
  };

  const borderWidthSetup = (target: EventTarget) => {
    const value = (target as HTMLInputElement).value;
    if (parseInt(value) < 0) {
      setBorderwidth('0');
    } else {
      setBorderwidth(value);
    }
  };

  const applyStyles = () => {
    const borderFullStyle =
      (borderWidth ? borderWidth : '0') +
      'px ' +
      (borderStyle ? borderStyle : 'solid') +
      ' ' +
      (borderColor ? borderColor : CONSTANTS.BORDER_COLOR);

    const backgroundColorStyle = backgroundColor ? backgroundColor : 'transparent';
    if (isCellProperties) {
      const paddingStyle =
        (paddingTop ? paddingTop + 'px ' : '0px ') +
        (paddingRight ? paddingRight + 'px ' : '0px ') +
        (paddingBottom ? paddingBottom + 'px ' : '0px ') +
        (paddingLeft ? paddingLeft + 'px ' : '0px ');
      let scopeStyle = cellScope.replace(/\s/g, '');
      if (scopeStyle === 'column') {
        scopeStyle = 'col';
      } else if (scopeStyle === 'columngroup') {
        scopeStyle = 'colgroup';
      }
      onSubmitTableCellProperties &&
        onSubmitTableCellProperties(
          paddingStyle,
          scopeStyle,
          borderFullStyle,
          backgroundColorStyle,
        );
    } else {
      onSubmitTableRowProperties &&
        onSubmitTableRowProperties(borderFullStyle, backgroundColorStyle);
    }
    onHide();
  };

  return (
    <>
      <Modal.Body className='pb-0 pt-3'>
        <div className='row'>
          {isCellProperties && (
            <>
              <div className='col-lg-6 col-md-6 col-sm-6 mb-2'>
                <b>Padding</b> <i>(px)</i>
                <div className='d-flex mt-3'>
                  <div className='d-flex w-100 paddingTwoSection'>
                    <div className='paddingFlexContent'>
                      <div className='left-pad'>
                        <Form.Control
                          className='form-control-design paddingInputBox'
                          type='number'
                          inputMode='numeric'
                          name='left-padding-input'
                          min='0'
                          tabIndex={0}
                          defaultValue={paddingLeft}
                          onKeyDown={numberValidation}
                          onChange={(e) => paddingSetup(e.target, 'left')}
                        />
                      </div>
                    </div>
                    <div className='paddingFlexContent'>
                      <div className='top-pad'>
                        <Form.Control
                          className='form-control-design paddingInputBox'
                          type='number'
                          inputMode='numeric'
                          name='top-padding-input'
                          min='0'
                          tabIndex={0}
                          defaultValue={paddingTop}
                          onKeyDown={numberValidation}
                          onChange={(e) => paddingSetup(e.target, 'top')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex mt-1'>
                  <div className='d-flex w-100 paddingTwoSection'>
                    <div className='paddingFlexContent'>
                      <div className='right-pad'>
                        <Form.Control
                          className='form-control-design paddingInputBox'
                          type='number'
                          inputMode='numeric'
                          name='right-padding-input'
                          min='0'
                          tabIndex={0}
                          defaultValue={paddingRight}
                          onKeyDown={numberValidation}
                          onChange={(e) => paddingSetup(e.target, 'right')}
                        />
                      </div>
                    </div>
                    <div className='paddingFlexContent'>
                      <div className='bottom-pad'>
                        <Form.Control
                          className='form-control-design paddingInputBox'
                          type='number'
                          inputMode='numeric'
                          name='bottom-padding-input'
                          min='0'
                          tabIndex={0}
                          defaultValue={paddingBottom}
                          onKeyDown={numberValidation}
                          onChange={(e) => paddingSetup(e.target, 'bottom')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6'>
                <b>Cell Scope</b>
                <Form.Group className='d-flex w-100 position-relative mt-3'>
                  <BorderTableMenuSection className='w-100'>
                    <BorderTableMenuDropdown
                      width='138px;'
                      placement='bottom'
                      element={
                        <BorderTableMenuButton className='w-100'>
                          {getCapitalizeWord(cellScope)}
                        </BorderTableMenuButton>
                      }
                    >
                      {cellScopeOptions.map((optionValue) => {
                        return (
                          <BorderTableMenuOptionButton
                            key={optionValue}
                            data-testid='add-table'
                            name='LintTables'
                            className={optionValue === getCapitalizeWord(cellScope) ? 'active' : ''}
                            onClick={() => setCellScope(optionValue.toLowerCase())}
                          >
                            {optionValue}
                          </BorderTableMenuOptionButton>
                        );
                      })}
                    </BorderTableMenuDropdown>
                  </BorderTableMenuSection>
                  <TableDropDownIcon className='borderSearchIcon' />
                </Form.Group>
              </div>
            </>
          )}
          <b className='mt-0'>Border</b>
          <div className='d-flex'>
            <div className='col-lg-6 col-md-6 col-sm-6 col-6 d-flex mt-0 borderWidthSection'>
              <Form.Label className='tablePropertyLabelSection'>
                Width<i>(px)</i>
              </Form.Label>
              <Form.Control
                type='number'
                inputMode='numeric'
                className='loree-table-text-element lintTableTextBox borderWidthContent'
                min='0'
                tabIndex={0}
                value={borderWidth}
                onKeyDown={numberValidation}
                onChange={(e) => {
                  borderWidthSetup(e.target);
                }}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-6 col-6 d-flex padLeft12px mt-0'>
              <Form.Label className='tablePropertyLabelSection'>Border Color</Form.Label>
              <ColorPickerButton
                className='colorPicker'
                label='Background color'
                currentColor={borderColor}
                onChange={(color) => {
                  setBorderColor(color || CONSTANTS.BORDER_COLOR);
                }}
                globalColorList={[]}
                sharedColors={[]}
                colorPickerFeatures={CONSTANTS.LOREE_COLORPICKER_FEATURES}
                colorPickerType='Background'
                colorContrastRatio={() => {}}
              />
            </div>
          </div>
          <div className='col-lg-12 col-md-12 col-sm-12 d-flex mt-1'>
            <Form.Group className='d-flex w-100 position-relative'>
              <Form.Label className='tablePropertyLabelSection tableBorderStyleLabel w-auto'>
                Border Style
              </Form.Label>
              <BorderTableMenuSection className='w-100'>
                <BorderTableMenuDropdown
                  width='210px;'
                  placement='bottom'
                  element={
                    <BorderTableMenuButton className='w-100'>
                      {getCapitalizeWord(borderStyle)}
                    </BorderTableMenuButton>
                  }
                >
                  {borderStyles.map((optionValue) => {
                    return (
                      <BorderTableMenuOptionButton
                        key={optionValue}
                        data-testid='add-table'
                        name='LintTables'
                        className={optionValue === getCapitalizeWord(borderStyle) ? 'active' : ''}
                        onClick={() => setBorderStyle(optionValue.toLowerCase())}
                      >
                        {optionValue}
                      </BorderTableMenuOptionButton>
                    );
                  })}
                </BorderTableMenuDropdown>
              </BorderTableMenuSection>
              <TableDropDownIcon className='borderSearchIcon' />
            </Form.Group>
          </div>
          <b className='mt-2'>Background</b>
          <div className='col-lg-6 col-md-6 col-sm-6 col-6 d-flex mt-0'>
            <Form.Label className='tablePropertyLabelSection mb-0'>Background Color</Form.Label>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-6 col-6 mt-0'>
            <ColorPickerButton
              className='colorPicker mt-2'
              label='Background color'
              currentColor={backgroundColor}
              onChange={(color) => {
                setBackgroundColor(color || CONSTANTS.BG_COLOR);
              }}
              globalColorList={[]}
              sharedColors={[]}
              colorPickerFeatures={CONSTANTS.LOREE_COLORPICKER_FEATURES}
              colorPickerType='Background'
              colorContrastRatio={() => {}}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='mx-auto d-flex border-0'>
        <Button className='modalCancelButton' onClick={() => onHide()}>
          Cancel
        </Button>
        <Button className='modalOkButton' onClick={() => applyStyles()}>
          Apply
        </Button>
      </Modal.Footer>
    </>
  );
};
