export const getComponentLink = (
  list: { library_type: string; obscure_key: string },
  actionType: string,
) => {
  const orgId = sessionStorage.getItem('org_id');
  const version = sessionStorage.getItem('loreeVersion');
  const userId = sessionStorage.getItem('user_id');

  return `/component/${actionType}/${list.library_type}/${userId}/${list.obscure_key}/${orgId}/${version}`;
};

export const libraryTypes = {
  Type: '',
  Accordion: 'Accordion',
  Tabs: 'Tab',
  Button: 'Button',
  'Multiple Choice Question': 'multiplechoice',
  'Flip Card': 'flipcard',
  'Image/Video Slider ': 'imageslider',
  'Click & Reveal Interactive': 'ClickAndRevealInteractions',
  'Drag and Drop': 'DragandDrop',
  'Image Hotspot': 'Hotspot',
};

export const getFeaturesList = (features: Record<string, boolean>, type: string) => {
  let config = {};
  config = {
    globalinteractive: features?.globalinteractive,
    sharedinteractive: features?.sharedinteractive,
    myinteractive: features?.myinteractive,
    globalinteractivecreate: features?.globalinteractivecreate,
    myinteractivecreate: features?.myinteractivecreate,
    globalinteractiveedit: features?.globalinteractiveedit,
    sharedinteractiveedit: features?.sharedinteractiveedit,
    myinteractiveedit: features?.myinteractiveedit,
    sharedinteractiveduplicate: features?.sharedinteractiveduplicate,
    globalinteractiveduplicate: features?.globalinteractiveduplicate,
    myinteractiveduplicate: features?.myinteractiveduplicate,
    edit: getEditType(features, type),
    delete: getDeleteType(features, type),
    duplicate: getDuplicateType(features, type),
    type: type === 'user' ? 'userInteractives' : '',
  };
  return config;
};
const getEditType = (features: Record<string, boolean>, type: string) => {
  if (type === 'global') {
    return features.globalinteractiveedit;
  } else if (type === 'shared') {
    return features.sharedinteractiveedit;
  } else {
    return features?.myinteractiveedit;
  }
};

const getDeleteType = (features: Record<string, boolean>, type: string) => {
  if (type === 'global') {
    return features.globalinteractivedelete;
  } else if (type === 'shared') {
    return features.sharedinteractivedelete;
  } else {
    return features?.myinteractivedelete;
  }
};

const getDuplicateType = (features: Record<string, boolean>, type: string) => {
  if (type === 'global') {
    return features.globalinteractiveduplicate;
  } else if (type === 'shared') {
    return features.sharedinteractiveduplicate;
  } else {
    return features?.myinteractiveduplicate;
  }
};

export const getAdminDashboardFontFamilyList = (configList: Record<string, {}>) => {
  return configList?.customFonts ?? {};
};

export const getCustomHeaderFontDetails = (configList: Record<string, {}>) => {
  return configList?.customHeaderStyle ?? {};
};
