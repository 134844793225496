/* eslint-disable @typescript-eslint/no-explicit-any */
import { Node, mergeAttributes } from '@tiptap/core';
import { optimizeStyle } from './utilityFunctions';

export interface BlockquoteOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    blockQuotes: {
      /**
       * Toggle a blockquote
       */
      toggleBlockquoteContent: () => ReturnType;
    };
  }
}

export const CustomizedBlockQuote = Node.create<BlockquoteOptions>({
  name: 'blockQuote',
  group: 'block',
  content: 'block*',
  selectable: false,

  addOptions() {
    return {
      HTMLAttributes: {
        style:
          'display: flex; margin: 0px 0px 10px 0px; padding: 0px 0px 0px 20px; border-left: 5px solid #000000; border-right: 0px solid #000000; border-top: 0px solid #000000; border-bottom: 0px solid #000000',
      },
    };
  },

  parseHTML() {
    return [{ tag: 'blockquote' }];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'blockquote',
      optimizeStyle(mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {})),
      0,
    ];
  },

  addCommands() {
    return {
      toggleBlockquoteContent:
        () =>
        ({ commands, chain }) => {
          return commands.toggleBlockquote();
        },
    };
  },
});
