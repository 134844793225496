/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { ReactComponent as UploadIcon } from '../../../../assets/icons/uploadIcon.svg';
import { ToastManager } from '@crystaldelta/loree-ui-components';

interface LocalImageUploadInterface {
  uploadImageDisabled: boolean;
  enableOrDisableContainers: Function;
  setScreenType: Function;
  setLocalImageDetails: Function;
  handleSelectedElement: Function;
  handleConfrimState: Function;
  sectionTypeTitle: string;
}

// convert file to a base64 url
const readURL = (file: File) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => res(e?.target?.result);
    reader.onerror = (e) => rej(e);
    reader.readAsDataURL(file);
  });
};

export const LocalImageUploadComponent = (props: LocalImageUploadInterface) => {
  const {
    uploadImageDisabled,
    enableOrDisableContainers,
    setScreenType,
    setLocalImageDetails,
    handleSelectedElement,
    handleConfrimState,
    sectionTypeTitle,
  } = props;
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const handleImageUpload = () => {
    const fileInput = document.createElement('input');
    handleConfrimState(true);

    fileInput.setAttribute('type', 'file');
    fileInput.click();

    fileInput.onchange = async () => {
      const file = fileInput?.files ? fileInput.files[0] : null;

      // file type is only image.
      if (file && /^image\//.test(file.type)) {
        if (file.size <= 1000000) {
          handleSelectedElement({
            url: await readURL(file),
            fileName: file.name,
            elementType: 'localImage',
            mediaType: 'image',
          });
          setLocalImageDetails('localImage', file);
          setScreenType('preview');
        } else {
          setShowToast(true);
          setToastMessage('Image size is bigger than 1MB');
        }
      } else if (file) {
        setShowToast(true);
        setToastMessage('This will support only Image Format');
      }
    };
  };
  const uploadImageButton = () => {
    enableOrDisableContainers(true, false, true);
    handleImageUpload();
  };
  return (
    <>
      <div
        id='upload-local-image'
        className={`upload-local-image ${uploadImageDisabled ? 'disable-image-upload' : null}`}
        tabIndex={0}
        role='button'
        style={{
          cursor: sectionTypeTitle !== 'Images' ? 'not-allowed' : 'pointer',
        }}
        onClick={(event) => {
          sectionTypeTitle === 'Images' ? uploadImageButton() : event.preventDefault();
        }}
      >
        <div
          className='upload-image-icon-holder active'
          style={{
            cursor: sectionTypeTitle !== 'Images' ? 'not-allowed' : 'pointer',
          }}
          id='loree-image-local-upload-input'
        >
          <div className='d-flex justify-content-center'>
            <UploadIcon />
          </div>
          <p className='fw-bolder text-center px-0 py-4'>Click to upload a new Interactive image</p>
        </div>
        <div className='lint-image-local-upload-input-message text-muted'>
          <p className='text-center m-0'>Supported files - jpg, jpeg, png, gif, svg.</p>
          <p className='text-center m-0'>Maximum file size allowed should be 1MB</p>
        </div>
      </div>
      {showToast && (
        <ToastManager
          toastType='error'
          toastMessage={toastMessage}
          closeButton
          closeToast={() => setShowToast(false)}
        />
      )}
    </>
  );
};
