import { Node, mergeAttributes } from '@tiptap/core';
export interface IframeOptions {
  HTMLAttributes: Record<string, string>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    iframe: {
      /**
       * Add an iframe
       */
      setIframe: (options: { src: string }) => ReturnType;
    };
  }
}

export const CustomizedIframeElement = Node.create<IframeOptions>({
  name: 'iframe',
  group: 'block',
  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      ...this.parent?.(),

      src: {
        parseHTML: (element) => element.getAttribute('src'),
      },
      id: {
        parseHTML: (element) => element.getAttribute('id'),
      },
      scrolling: {
        parseHTML: (element) => element.getAttribute('scrolling'),
      },
      frameborder: {
        parseHTML: (element) => element.getAttribute('frameborder'),
      },
      width: {
        parseHTML: (element) => element.getAttribute('width'),
      },
      height: {
        parseHTML: (element) => element.getAttribute('height'),
      },
      allow: {
        parseHTML: (element) => element.getAttribute('allow'),
      },
      contenteditable: { default: false },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'iframe',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['iframe', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },
});
