/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, Slice, NodeType } from 'prosemirror-model';
import { EditorState, Transaction } from 'prosemirror-state';
import {
  isList,
  getNodeType,
  findParentNode,
  Editor,
  ChainedCommands,
  SingleCommands,
  CanCommands,
} from '@tiptap/core';
import { ReplaceAroundStep, canJoin } from 'prosemirror-transform';

const joinListBackwards = (tr: Transaction, listType: NodeType): boolean => {
  const list = findParentNode((node) => node.type === listType)(tr.selection);

  if (!list) {
    return false;
  }

  const before = tr.doc.resolve(Math.max(0, list.pos - 1)).before(list.depth);

  if (before === undefined) {
    return false;
  }

  const nodeBefore = tr.doc.nodeAt(before);
  const canJoinBackwards = list.node.type === nodeBefore?.type && canJoin(tr.doc, list.pos);

  if (!canJoinBackwards) {
    return false;
  }

  tr.join(list.pos);

  return true;
};

const joinListForwards = (tr: Transaction, listType: NodeType): boolean => {
  const list = findParentNode((node) => node.type === listType)(tr.selection);

  if (!list) {
    return false;
  }

  const after = tr.doc.resolve(list.start).after(list.depth);

  if (after === undefined) {
    return false;
  }

  const nodeAfter = tr.doc.nodeAt(after);
  const canJoinForwards = list.node.type === nodeAfter?.type && canJoin(tr.doc, after);

  if (!canJoinForwards) {
    return false;
  }

  tr.join(after);

  return true;
};

export function toggleList(listTypeOrName: string, itemTypeOrName: string, numberingType: string) {
  return (
    editor: Editor,
    tr: Transaction,
    state: EditorState,
    chain: () => ChainedCommands,
    commands: SingleCommands,
    can: () => CanCommands,
    dispatch?: (tr: Transaction) => void,
  ) => {
    const { extensions, splittableMarks } = editor.extensionManager;
    const listType = getNodeType(listTypeOrName, state.schema);
    const itemType = getNodeType(itemTypeOrName, state.schema);
    const { selection, storedMarks } = state;
    const { $from, $to } = selection;
    const range = $from.blockRange($to);

    const marks = storedMarks || (selection.$to.parentOffset && selection.$from.marks());

    if (!range) {
      return false;
    }

    const parentList = findParentNode((node) => isList(node.type.name, extensions))(selection);

    if (range.depth >= 1 && parentList && range.depth - parentList.depth <= 1) {
      // remove list
      if (parentList.node.type === listType && parentList.node.attrs['type'] === numberingType) {
        return commands.liftListItem(itemType);
      }

      // change list type
      if (
        isList(parentList.node.type.name, extensions) &&
        listType.validContent(parentList.node.content) &&
        dispatch
      ) {
        return chain()
          .command(() => {
            tr.setNodeMarkup(parentList.pos, listType, { type: numberingType });

            return true;
          })
          .command(() => joinListBackwards(tr, listType))
          .command(() => joinListForwards(tr, listType))
          .run();
      }
    }
    if (!marks || !dispatch) {
      return (
        chain()
          // try to convert node to default node if needed
          .command(() => {
            const canWrapInList = can().wrapInList(listType);

            if (canWrapInList) {
              return true;
            }

            return commands.clearNodes();
          })
          .wrapInList(listType, { type: numberingType })
          .command(() => joinListBackwards(tr, listType))
          .command(() => joinListForwards(tr, listType))
          .run()
      );
    }

    return (
      chain()
        // try to convert node to default node if needed
        .command(() => {
          const canWrapInList = can().wrapInList(listType);

          const filteredMarks = marks.filter((mark) => splittableMarks.includes(mark.type.name));

          tr.ensureMarks(filteredMarks);

          if (canWrapInList) {
            return true;
          }

          return commands.clearNodes();
        })
        .wrapInList(listType, { type: numberingType })
        .command(() => joinListBackwards(tr, listType))
        .command(() => joinListForwards(tr, listType))
        .run()
    );
  };
}

export function sinkListItem(typeOrName: NodeType | string) {
  return (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    const itemType = getNodeType(typeOrName, state.schema);

    const { $from, $to } = state.selection;
    const range = $from.blockRange(
      $to,
      (node) => node.childCount > 0 && node.firstChild?.type == itemType,
    );
    if (!range) return false;
    const startIndex = range.startIndex;
    if (startIndex == 0) return false;
    const parent = range.parent;
    const nodeBefore = parent.child(startIndex - 1);
    if (nodeBefore.type != itemType) return false;

    if (dispatch) {
      const nestedBefore = nodeBefore.lastChild && nodeBefore.lastChild.type == parent.type;
      const inner = Fragment.from(nestedBefore ? itemType.create() : null);
      const slice = new Slice(
        Fragment.from(
          itemType.create(
            null,
            Fragment.from(parent.type.create({ type: parent.attrs['type'] }, inner)),
          ),
        ),
        nestedBefore ? 3 : 1,
        0,
      );
      const before = range.start;
      const after = range.end;
      dispatch(
        state.tr
          .step(
            new ReplaceAroundStep(
              before - (nestedBefore ? 3 : 1),
              after,
              before,
              after,
              slice,
              1,
              true,
            ),
          )
          .scrollIntoView(),
      );
    }
    return true;
  };
}
export function updateNodeStyles(typeName: string, style: Record<string, string>) {
  return (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    const { from, to } = state.selection;
    const nodes = [];
    const tr = state.tr;
    state.doc.nodesBetween(from, to, (node, pos) => {
      if (nodes.length) {
        return false;
      }
      const contaninerTypeName = ['containerWrapper', 'container'];
      const containerSelection = contaninerTypeName.includes(typeName)
        ? pos >= from + 1 && pos <= to
        : node.type.name === typeName;
      if (containerSelection) {
        nodes.push(node);
        const dom = document.createElement('div');
        dom.style.cssText = node.attrs['style'];
        Object.assign(dom.style, style);
        tr.setNodeMarkup(pos, null, { ...node.attrs, ...{ style: dom.style.cssText } });
      }
      return true;
    });
    if (nodes.length < 1) {
      return false;
    }
    if (dispatch) {
      dispatch(tr);
    }
    return true;
  };
}

export function removeAllEmptySpan() {
  return (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    const { from, to } = state.selection;
    const tr = state.tr;
    state.doc.nodesBetween(from, to, (node, pos) => {
      if (node.type?.name === 'text') {
        const textStyleMark = node.marks.find((mark) => mark.type.name === 'textStyle');
        if (!textStyleMark?.attrs) return;
        const hasStyles = Object.entries(textStyleMark.attrs).some(([, value]) => !!value);
        if (!hasStyles) {
          tr.removeMark(pos, pos + node.nodeSize, state.schema.marks['textStyle']);
        }
      }
      return true;
    });

    if (dispatch) {
      dispatch(tr);
    }
    return true;
  };
}
