/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { CreateNewTableProps, optionCollection } from '../lintEditorType';
import { TableRowsAndColumnOption, TableRowsAndColumnSelect } from '../styledContents';

export const CreateNewTableContent = (props: CreateNewTableProps) => {
  const { onHide, onSubmit } = props;

  const [tableRowcount, setTableRowcount] = useState(3);
  const [tableColumncount, setTableColumncount] = useState(3);
  const [isRowHeader, setIsRowHeader] = useState(true);
  const [isColumnHeader, setIsColumnHeader] = useState(false);
  const [captionValue, setCaptionValue] = useState('');
  const [isAddTableButtonDisabled, setIsAddTableButtonDisabled] = useState(true);

  useEffect(() => {
    setIsAddTableButtonDisabled(captionValue.trim() === '' || (!isRowHeader && !isColumnHeader));
  }, [isRowHeader, isColumnHeader, captionValue]);

  const setHeaderValues = (type: string) => {
    if (type === 'row') {
      setIsRowHeader(!isRowHeader);
    } else {
      setIsColumnHeader(!isColumnHeader);
    }
  };

  const addCaptionValue = (value: string) => {
    setCaptionValue(value);
  };
  const properties = ['row', 'column'];

  const setCount = (value: number, type: string) => {
    if (type === 'row') {
      setTableRowcount(value);
    } else {
      setTableColumncount(value);
    }
  };

  const addTableContent = () => {
    onSubmit &&
      onSubmit(tableRowcount, tableColumncount, isRowHeader, isColumnHeader, captionValue);
    onHide();
  };
  return (
    <>
      <Modal.Body className='tableBodySection pb-0 pt-2'>
        {properties.map((type) => {
          return (
            <Form
              key={type}
              className='d-flex tableFormContent mb-2'
              onClick={(e) => e.preventDefault()}
            >
              <Form.Group className='modalBodyGroupContent d-flex justify-content-between'>
                <Form.Label className='m-0 pt-1'>{`Number of ${type}s`}</Form.Label>
                <TableRowsAndColumnSelect
                  value='3'
                  aria-label='rowsAndColumn'
                  data-testid='rows-and-cols'
                  onChange={(e) => {
                    setCount(parseInt(e), type);
                  }}
                  className={`px-1 loree-interactive-table-${type}-input ${
                    type === 'row' ? 'ml-5' : ''
                  }`}
                  render={(fontFamilyArraySize: number) => {
                    return <div>{optionCollection[fontFamilyArraySize]}</div>;
                  }}
                >
                  {optionCollection.map((optionValue: string, index) => (
                    <TableRowsAndColumnOption
                      key={`${optionValue + '_' + index}`}
                      data-testid='font-family-select-option'
                      value={optionValue}
                    >
                      <span
                        title={optionValue + ' ' + type}
                        data-toggle='tooltip'
                        data-placement='right'
                      >
                        {optionValue}
                      </span>
                    </TableRowsAndColumnOption>
                  ))}
                </TableRowsAndColumnSelect>
              </Form.Group>
            </Form>
          );
        })}
        {properties.map((type) => {
          return (
            <div className={type === 'row' ? 'd-flex mb-2 mt-3' : 'd-flex mb-1 mt-2'} key={type}>
              <label
                className='pb-1 mb-0 h5 cursor-pointer d-inline-flex align-items-center'
                onClick={() => setHeaderValues(type)}
              >
                <input
                  id={`${type}-header-checkbox`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setHeaderValues(type);
                  }}
                  name='lint-table-header-type-option-input'
                  className='p-1 me-2 cursor-pointer lint-checkbox'
                  type='checkbox'
                  checked={type === 'row' ? isRowHeader : isColumnHeader}
                />
                <span style={{ fontSize: '15px' }}>Include {type} header</span>
              </label>
            </div>
          );
        })}
        <Form.Text className='lintTableInfoMessage size text-muted m-0'>
          Note: Must select at least one header
        </Form.Text>
        <hr className='mt-2 mb-2' />
        <Form.Group className='m-0 tableFormContent'>
          <Form.Label className='tableCaptionLabel mb-1'>
            Table caption<span className='text-danger'>*</span>
          </Form.Label>
          <Form.Control
            type='text'
            className='loree-table-text-element'
            id='tableCaption'
            placeholder='Enter caption'
            value={captionValue}
            onChange={(e) => addCaptionValue(e.target.value.replace(/  +/g, ' '))}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className='mx-auto d-flex border-0'>
        <Button className='modalCancelButton' onClick={() => onHide()}>
          Cancel
        </Button>
        <Button
          className='modalOkButton'
          onClick={() => addTableContent()}
          disabled={isAddTableButtonDisabled}
        >
          Add table
        </Button>
      </Modal.Footer>
    </>
  );
};
