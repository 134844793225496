import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { ColorPickerButton } from '@crystaldelta/loree-ui-color-picker';
import { CreateNewTableProps, borderCollapseOption, borderStyles } from '../lintEditorType';
import { getCapitalizeWord, numberValidation } from '../utilityFunctions';
import {
  BorderTableMenuButton,
  BorderTableMenuDropdown,
  BorderTableMenuOptionButton,
  BorderTableMenuSection,
} from '../styledContents';
import { ReactComponent as LeftAlignmentButton } from '../../editorFormattingIcons/leftAlignmentButton.svg';
import { ReactComponent as CenterAlignmentButton } from '../../editorFormattingIcons/centerAlignmentButton.svg';
import { ReactComponent as RightAlignmentButton } from '../../editorFormattingIcons/rightAlignmentButton.svg';
import { ReactComponent as TableDropDownIcon } from '../../editorFormattingIcons/dropDownMenu.svg';
import { CONSTANTS } from '../constants';

export const TableProperties = (props: CreateNewTableProps) => {
  const { onHide, onSubmitTableProperties, styles } = props;
  const borderStyleProps = styles?.borderStyle ? getCapitalizeWord(styles?.borderStyle) : 'solid';
  const borderCollapseProps = styles?.borderStyle
    ? getCapitalizeWord(styles?.borderCollapse)
    : 'solid';

  let tableAlignmentValue = 'left';
  if (styles?.alignment) {
    switch (styles?.alignment) {
      case '0px auto':
        tableAlignmentValue = 'center';
        break;
      case 'auto 0px auto auto':
        tableAlignmentValue = 'right';
        break;
    }
  }

  const [widthvalue, setWidthvalue] = useState(styles?.width ?? '100');
  const [alignmentValue, setAlignmentValue] = useState(tableAlignmentValue ?? 'left');
  const [borderWidth, setBorderwidth] = useState(styles?.border ?? '3');
  const [borderStyle, setBorderStyle] = useState(borderStyleProps);
  const [borderColor, setBorderColor] = useState(styles?.borderColor ?? CONSTANTS.BORDER_COLOR);
  const [borderCollapse, setBorderCollapse] = useState(borderCollapseProps);
  const [backgroundColor, setBackgroundColor] = useState(
    styles?.backgroundColor ?? CONSTANTS.BG_COLOR,
  );

  const widthSetup = (target: EventTarget) => {
    const value = (target as HTMLInputElement).value;
    if (parseInt(value) > 100) {
      setWidthvalue('100');
    } else if (parseInt(value) < 1) {
      setWidthvalue('1');
    } else {
      setWidthvalue(value);
    }
  };

  const borderWidthSetup = (target: EventTarget) => {
    const value = (target as HTMLInputElement).value;
    if (parseInt(value) < 0) {
      setBorderwidth('0');
    } else {
      setBorderwidth(value);
    }
  };

  const applyStyles = () => {
    const widthValueStyle = widthvalue ? widthvalue + '%' : '100%';
    let alignmentValueStyle = 'auto auto auto 0px';
    switch (alignmentValue) {
      case 'center':
        alignmentValueStyle = '0px auto';
        break;
      case 'right':
        alignmentValueStyle = 'auto 0px auto auto';
        break;
    }

    const borderFullStyle =
      (borderWidth ? borderWidth : '0') +
      'px ' +
      (borderStyle ? borderStyle : 'solid') +
      ' ' +
      (borderColor ? borderColor : '#000000');

    const borderCollapseStyle = borderCollapse ? borderCollapse : 'collapse';
    const backgroundColorStyle = backgroundColor ? backgroundColor : 'transparent';

    onSubmitTableProperties &&
      onSubmitTableProperties(
        widthValueStyle,
        alignmentValueStyle,
        borderFullStyle,
        borderCollapseStyle,
        backgroundColorStyle,
      );
    onHide();
  };

  return (
    <>
      <Modal.Body className='pb-0 pt-3'>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-6 sizeSection'>
            <b>Size</b>
            <div className='d-flex mt-0'>
              <Form.Label className='tablePropertyLabelSection'>
                Width<i>(%)</i>
              </Form.Label>
              <Form.Control
                type='number'
                inputMode='numeric'
                className='loree-table-text-element lintTableTextBox'
                min='0'
                max='100'
                tabIndex={0}
                defaultValue={styles?.width}
                value={widthvalue}
                onKeyDown={numberValidation}
                onChange={(e) => widthSetup(e.target)}
              />
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-6'>
            <b>Alignment</b>
            <div className='d-flex tableAlignmentpropertySection col-lg-10 col-md-10 col-sm-12'>
              <LeftAlignmentButton
                tabIndex={0}
                className={
                  alignmentValue === 'left'
                    ? 'alignmentButtonTableProperties active'
                    : 'alignmentButtonTableProperties'
                }
                onClick={() => setAlignmentValue('left')}
                onKeyDown={() => setAlignmentValue('left')}
              />
              <CenterAlignmentButton
                tabIndex={0}
                className={
                  alignmentValue === 'center'
                    ? 'alignmentButtonTableProperties active'
                    : 'alignmentButtonTableProperties'
                }
                onClick={() => setAlignmentValue('center')}
                onKeyDown={() => setAlignmentValue('center')}
              />
              <RightAlignmentButton
                tabIndex={0}
                className={
                  alignmentValue === 'right'
                    ? 'alignmentButtonTableProperties active'
                    : 'alignmentButtonTableProperties'
                }
                onClick={() => setAlignmentValue('right')}
                onKeyDown={() => setAlignmentValue('right')}
              />
            </div>
          </div>
          <b className='mt-2'>Border</b>
          <div className='d-flex'>
            <div className='col-lg-6 col-md-6 col-sm-6 col-6 d-flex mt-0 borderWidthSection'>
              <Form.Label className='tablePropertyLabelSection'>
                Width<i>(px)</i>
              </Form.Label>
              <Form.Control
                type='number'
                inputMode='numeric'
                className='loree-table-text-element lintTableTextBox borderWidthContent'
                min='0'
                tabIndex={0}
                value={borderWidth}
                onKeyDown={numberValidation}
                onChange={(e) => {
                  borderWidthSetup(e.target);
                }}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-6 col-6 d-flex padLeft12px mt-0'>
              <Form.Label className='tablePropertyLabelSection'>Border Color</Form.Label>
              <ColorPickerButton
                className='colorPicker'
                label='Background color'
                currentColor={borderColor}
                onChange={(color) => {
                  setBorderColor(color || CONSTANTS.BORDER_COLOR);
                }}
                globalColorList={[]}
                sharedColors={[]}
                colorPickerFeatures={CONSTANTS.LOREE_COLORPICKER_FEATURES}
                colorPickerType='Background'
                colorContrastRatio={() => {}}
              />
            </div>
          </div>
          <div className='col-lg-12 col-md-12 col-sm-12 d-flex mt-1'>
            <Form.Group className='d-flex w-100 position-relative'>
              <Form.Label className='tablePropertyLabelSection tableBorderStyleLabel w-auto'>
                Border Style
              </Form.Label>
              <BorderTableMenuSection className='w-100'>
                <BorderTableMenuDropdown
                  width='210px'
                  placement='bottom'
                  element={
                    <BorderTableMenuButton className='w-100'>
                      {getCapitalizeWord(borderStyle)}
                    </BorderTableMenuButton>
                  }
                >
                  {borderStyles.map((optionValue) => {
                    return (
                      <BorderTableMenuOptionButton
                        key={optionValue}
                        data-testid='add-table'
                        name='LintTables'
                        className={optionValue === getCapitalizeWord(borderStyle) ? 'active' : ''}
                        onClick={() => setBorderStyle(optionValue.toLowerCase())}
                      >
                        {optionValue}
                      </BorderTableMenuOptionButton>
                    );
                  })}
                </BorderTableMenuDropdown>
              </BorderTableMenuSection>
              <TableDropDownIcon className='borderSearchIcon' />
            </Form.Group>
          </div>
          <b className='mt-2'>Border Collapse</b>
          <div className='col-lg-12 col-md-12 col-sm-12 d-flex mt-2'>
            <Form.Group className='d-flex w-100 position-relative'>
              <BorderTableMenuSection className='w-100'>
                <BorderTableMenuDropdown
                  width='301px'
                  placement='bottom'
                  element={
                    <BorderTableMenuButton className='w-100'>
                      {getCapitalizeWord(borderCollapse)}
                    </BorderTableMenuButton>
                  }
                >
                  {borderCollapseOption.map((optionValue) => {
                    return (
                      <BorderTableMenuOptionButton
                        key={optionValue}
                        data-testid='add-table'
                        name='LintTables'
                        className={
                          optionValue === getCapitalizeWord(borderCollapse) ? 'active' : ''
                        }
                        onClick={() => setBorderCollapse(optionValue.toLowerCase())}
                      >
                        {optionValue}
                      </BorderTableMenuOptionButton>
                    );
                  })}
                </BorderTableMenuDropdown>
              </BorderTableMenuSection>
              <TableDropDownIcon className='borderSearchIcon' />
            </Form.Group>
          </div>
          <b className='mt-2'>Background</b>
          <div className='col-lg-6 col-md-6 col-sm-6 col-6 d-flex mt-0'>
            <Form.Label className='tablePropertyLabelSection mb-0'>Background Color</Form.Label>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-6 col-6 mt-0'>
            <ColorPickerButton
              className='colorPicker mt-2'
              label='Background color'
              currentColor={backgroundColor}
              onChange={(color) => {
                setBackgroundColor(color || CONSTANTS.BG_COLOR);
              }}
              globalColorList={[]}
              sharedColors={[]}
              colorPickerFeatures={CONSTANTS.LOREE_COLORPICKER_FEATURES}
              colorPickerType='Background'
              colorContrastRatio={() => {}}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='mx-auto d-flex border-0'>
        <Button className='modalCancelButton' onClick={() => onHide()}>
          Cancel
        </Button>
        <Button className='modalOkButton' onClick={() => applyStyles()}>
          Apply
        </Button>
      </Modal.Footer>
    </>
  );
};
