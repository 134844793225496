import React from 'react';
import { Form } from 'react-bootstrap';

interface MarginComponentInterface {
  top: string;
  bottom: string;
  SetBottom: Function;
  SetTop: Function;
}

const MarginComponent = (props: MarginComponentInterface) => {
  const { top, bottom, SetBottom, SetTop } = props;

  const handleMarginValue = (event: React.ChangeEvent, type: string) => {
    const { value } = event.target as HTMLInputElement;
    const finalValue = Number(value);
    if (finalValue < 0 || finalValue > 40) return;
    switch (type) {
      case 'top':
        SetTop({
          marginTop: value,
        });
        break;
      case 'bottom':
        SetBottom({
          marginBottom: value,
        });
    }
  };
  return (
    <Form.Group className='mb-3' data-testid='Margin'>
      <Form.Label className='design-section-text'>
        Margin <span style={{ fontStyle: 'italic' }}>(px)</span>
      </Form.Label>
      <div className='margin-group'>
        <Form.Control
          className='margin-top-input'
          type='number'
          min='0'
          max='40'
          name='fontSize'
          size='sm'
          value={top}
          onChange={(e) => handleMarginValue(e, 'top')}
        />
        <Form.Control
          className='margin-bottom-input'
          type='number'
          min='0'
          max='40'
          name='fontSize'
          size='sm'
          value={bottom}
          onChange={(e) => handleMarginValue(e, 'bottom')}
        />
      </div>
    </Form.Group>
  );
};

export default MarginComponent;
