import { Extension } from '@tiptap/core';
import { ExtensionOptions } from '../editorUtilityFunctions/lintEditorType';
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    paddings: {
      setLeftPadding: (paddingLeft: string) => ReturnType;
    };
  }
}

export const Padding = Extension.create<ExtensionOptions>({
  name: 'padding',

  addOptions() {
    return {
      types: ['paragraph', 'heading', 'CustomizedHeading'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          paddingLeft: {
            default: null,
            parseHTML: (element) => {
              if (element.style.paddingLeft) {
                return element.style.paddingLeft;
              }
              return undefined;
            },
            renderHTML: (attributes) => {
              if (!attributes['paddingLeft']) {
                return {};
              }

              return { style: `padding-left: ${attributes['paddingLeft']}` };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setLeftPadding:
        (paddingLeft: string) =>
        ({ commands }) => {
          return commands.updateNodeAttributes({ paddingLeft });
        },
    };
  },
});
