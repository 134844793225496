/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { buttonFormError } from '../../redux/button/button.actions';
import { makeFlipCardEdit } from '../../redux/flipCard/form.action';
import {
  addPanel,
  handleChange,
  rearangeArray,
  handleTitle,
  errorsFetch,
  removeClick,
  makeEdit,
  setType,
  setValue,
  setParameter,
  addSection,
  sortArray,
  removeElement,
  deleteImage,
  noOfLayout,
  fixedLayout,
} from '../../redux/form/form.actions';
import { FlipFormInterface } from '../../redux/interface';
import {
  addMcqPanel,
  handleMcqTitle,
  makeMcqEdit,
  setMcqValue,
} from '../../redux/multipleChoice/mcq.action';
import { ButtonFormInterface } from './button/form';
import * as formStore from '../../redux/form/form.actions';
import * as buttonStore from '../../redux/button/button.actions';
import * as flipcardStore from '../../redux/flipCard/form.action';
import * as mcqStore from '../../redux/multipleChoice/mcq.action';
import * as componentStore from '../../redux/components/reducer.action';

//state to redux
export const mapStateToProps = ({ form, button, flipform, mcqform }: any) => ({
  buttonError: button.errors,
  num: form.num,
  contentArr: form.contentArr,
  flipCardContentArr: flipform.contentArr,
  flipCardParameters: flipform.parameters,
  title: form.title,
  parameters: form.parameters,
  errors: form.errors,
  type: form.type,
  fontHeadings: form.fontHeadings,
  fontFamily: form.fontFamily,
  fontSize: form.fontSize,
  fontWeight: form.fontWeight,
  radius: form.radius,
  icon: form.icon,
  color: form.color,
  bgcolor: form.bgcolor,
  alignment: form.alignment,
  fontColor: form.fontColor,
  borderType: form.borderType,
  borderColor: form.borderColor,
  borderSize: form.borderSize,
  borderStyle: form.borderStyle,
  setcaptionAlignment: form.setcaptionAlignment,
  iconColor: form.iconColor,
  iconSize: form.iconSize,
  iconAlignment: form.iconAlignment,
  iconType: form.iconType,
  imageBgColor: form.imageBgColor,
  dndLayout: form.dndLayout,
  dndParameters: form.dndParameters,
  dndArray: form.dndArray,
  dragAreaType: form.dragAreaType,
  paddingTop: form.paddingTop,
  paddingLeft: form.paddingLeft,
  paddingBottom: form.paddingBottom,
  paddingRight: form.paddingRight,
  dropzoneBgColor: form.dropzoneBgColor,
  dropzoneOpacity: form.dropzoneOpacity,
  hotspotIcon: form.hotspotIcon,
  removeImage: form.removeImage,
  btnPaddingTop: form.btnPaddingTop,
  btnPaddingLeft: form.btnPaddingLeft,
  btnPaddingBottom: form.btnPaddingBottom,
  btnPaddingRight: form.btnPaddingRight,
  btnFontColor: form.btnFontColor,
  btnFontWeight: form.btnFontWeight,
  btnFontSize: form.btnFontSize,
  btnFontFamily: form.btnFontFamily,
  btnBgColor: form.btnBgColor,
  btnType: form.btnType,
  flipCardNum: flipform.num,
  flipCardErrors: flipform.errors,
  flipCardType: flipform.type,
  flipCardBgcolor: flipform.bgcolor,
  flipCardRadius: flipform.radius,
  flipCardBorderType: flipform.borderType,
  flipCardBorderColor: flipform.borderColor,
  flipCardBorderSize: flipform.borderSize,
  flipCardBorderStyle: flipform.borderStyle,
  backBorderRadius: flipform.backBorderRadius,
  frontBorderSizeRight: flipform.frontBorderSizeRight,
  frontBorderSizeTop: flipform.frontBorderSizeTop,
  frontBorderSizeBottom: flipform.frontBorderSizeBottom,
  backBorderSizeLeft: flipform.backBorderSizeLeft,
  backBorderSizeRight: flipform.backBorderSizeRight,
  backBorderSizeTop: flipform.backBorderSizeTop,
  backBorderSizeBottom: flipform.backBorderSizeBottom,
  backBorderStyle: flipform.backBorderStyle,
  backBorderColor: flipform.backBorderColor,
  flipCardFontFamily: flipform.fontFamily,
  backColor: flipform.backColor,
  height: flipform.height,
  noOfCard: flipform.noOfCard,
  noOfLayout: form.noOfLayout,
  fixedLayout: form.fixedLayout,
  flip: flipform.flip,
  cardType: flipform.cardType,
  frontTop: flipform.frontTop,
  frontRight: flipform.frontRight,
  frontBottom: flipform.frontBottom,
  frontLeft: flipform.frontLeft,
  backTop: flipform.backTop,
  backRight: flipform.backRight,
  backBottom: flipform.backBottom,
  backLeft: flipform.backLeft,
  frontTextColor: flipform.frontTextColor,
  backTextColor: flipform.backTextColor,
  frontHeader: flipform.frontHeader,
  frontSize: flipform.frontSize,
  frontWeight: flipform.frontWeight,
  backHeader: flipform.backHeader,
  backSize: flipform.backSize,
  backWeight: flipform.backWeight,
  borderTop: form.borderTop,
  borderRight: form.borderRight,
  borderLeft: form.borderLeft,
  borderBottom: form.borderBottom,
  borderRadius: form.borderRadius,
  marginBottom: form.marginBottom,
  marginTop: form.marginTop,
  clickBorderRadius: form.clickBorderRadius,
  clickBorderTop: form.clickBorderTop,
  clickBorderRight: form.clickBorderRight,
  clickBorderLeft: form.clickBorderLeft,
  clickBorderBottom: form.clickBorderBottom,
  clickBorderColor: form.clickBorderColor,
  clickBorderType: form.clickBorderType,
  clickBgColor: form.clickBgColor,
  revealBorderRadius: form.revealBorderRadius,
  revealBorderTop: form.revealBorderTop,
  revealBorderRight: form.revealBorderRight,
  revealBorderLeft: form.revealBorderLeft,
  revealBorderBottom: form.revealBorderBottom,
  revealBorderColor: form.revealBorderColor,
  revealBorderType: form.revealBorderType,
  revealBgColor: form.revealBgColor,
  clickPaddingTop: form.clickPaddingTop,
  clickPaddingRight: form.clickPaddingRight,
  clickPaddingLeft: form.clickPaddingLeft,
  clickPaddingBottom: form.clickPaddingBottom,
  revealPaddingTop: form.revealPaddingTop,
  revealPaddingRight: form.revealPaddingRight,
  revealPaddingLeft: form.revealPaddingLeft,
  revealPaddingBottom: form.revealPaddingBottom,
  displayActiveColorPicker: form.displayActiveColorPicker,
  inactivefontColor: form.inactivefontColor,
  separatorColor: form.separatorColor,
  separatorWidth: form.separatorWidth,
  mcqParameters: mcqform.parameters,
  mcqContentArr: mcqform.contentArr,
  mcqOptionId: mcqform.option_id,
  mcqError: mcqform.errors,
  mcqTitle: mcqform.title,
  customresult: mcqform.customresult,
  optiontype: mcqform.optiontype,
  checkedAnswer: mcqform.checkedAnswer,
  customtryagain: mcqform.customtryagain,
  shuffle: mcqform.shuffle,
  pbBgColor: mcqform.pbBgColor,
  pbFontColor: mcqform.pbFontColor,
  bgColor: mcqform.bgColor,
  opBgColor: mcqform.opBgColor,
  buttonFontColor: mcqform.buttonFontColor,
  buttonBgColor: mcqform.buttonBgColor,
  pbFontHeadings: mcqform.pbFontHeadings,
  pbFontFamily: mcqform.pbFontFamily,
  pbFontSize: mcqform.pbFontSize,
  pbFontWeight: mcqform.pbFontWeight,
  customPaddingtop: mcqform.customPaddingtop,
  customPaddingleft: mcqform.customPaddingleft,
  customPaddingbottom: mcqform.customPaddingbottom,
  customPaddingright: mcqform.customPaddingright,
  mcqBtnType: mcqform.btnType,
});
//props to redux
export const mapDispatchToProps = (dispatch: Function) => ({
  noOfLayouts: (form: any) => dispatch(noOfLayout(form)),
  fixedLayouts: (form: any) => dispatch(fixedLayout(form)),
  addPanels: (form: any) => dispatch(addPanel(form)),
  addFlipCardPanels: (flipform: FlipFormInterface) => dispatch(addPanel(flipform)),
  HandleChange: (form: any) => dispatch(handleChange(form)),
  RearangeArray: (form: any) => dispatch(rearangeArray(form)),
  HandleTitle: (form: any) => dispatch(handleTitle(form)),
  HandleFlipCardTitle: (flipform: FlipFormInterface) => dispatch(handleTitle(flipform)),
  ErrorsFetch: (form: any) => dispatch(errorsFetch(form)),
  RemoveCLick: (form: any) => dispatch(removeClick(form)),
  MakeEdit: (form: any) => dispatch(makeEdit(form)),
  MakeFlipCardEdit: (flipform: FlipFormInterface) => dispatch(makeFlipCardEdit(flipform)),
  SetType: (form: any) => dispatch(setType(form)),
  SetValue: (form: any) => dispatch(setValue(form)),
  SetParameter: (form: any) => dispatch(setParameter(form)),
  AddDndSection: (form: any) => dispatch(addSection(form)),
  SortDndArray: (form: any) => dispatch(sortArray(form)),
  RemoveElement: (form: any) => dispatch(removeElement(form)),
  DeleteImage: (form: any) => dispatch(deleteImage(form)),
  SetButtonFormError: (button: ButtonFormInterface) => dispatch(buttonFormError(button)),
  addMcqPannel: (mcqform: any) => dispatch(addMcqPanel(mcqform)),
  MakeMcqEdit: (mcqform: any) => dispatch(makeMcqEdit(mcqform)),
  SetMcqValue: (mcqForm: any) => dispatch(setMcqValue(mcqForm)),
  HandleMcqTitle: (mcqForm: any) => dispatch(handleMcqTitle(mcqForm)),
  ResetFormStore: () => dispatch(formStore.setDefault()),
  ResetButtonStore: () => dispatch(buttonStore.setDefault()),
  ResetFlipStore: () => dispatch(flipcardStore.setDefault()),
  ResetMcqStore: () => dispatch(mcqStore.setDefault()),
  ResetComponentStore: () => dispatch(componentStore.setDefault()),
});
