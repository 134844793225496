//initial state for redux
export const INITIAL_STATE = {
  num: 1,
  option_id: 2,
  contentArr: [['question_0', { options: ['option_0', 'option_1'] }]],
  title: '',
  isChecked: false,
  isShowtip: false,
  isShowfeedback: false,
  parameters: [
    {
      question: '',
      options: [
        { option: '', correct: '', mytip: '', myfeedback: '', check: '' },
        { option: '', correct: '', mytip: '', myfeedback: '', check: '' },
      ],
    },
  ],
  errors: {},
  type: '',
  optiontype: 'radio',
  checkedAnswer: true,
  customtryagain: true,
  shuffle: true,
  pbBgColor: '#112299',
  pbFontColor: '#000000',
  bgColor: '#ffffff',
  opBgColor: '#ffffff',
  buttonFontColor: '#ffffff',
  buttonBgColor: '#112299',
  fontFamily: 'Helvetica',
  fontSize: '16',
  fontWeight: 'Normal',
  pbFontHeadings: 'h2',
  pbFontFamily: 'Helvetica',
  pbFontSize: '24',
  pbFontWeight: 'Bold',
  alignment: 'left',
  btnType: 'btn btn-square btn-dark',
  customPaddingtop: '10',
  customPaddingleft: '10',
  customPaddingbottom: '10',
  customPaddingright: '10',
  removeImage: [],
  borderRadius: '0',
  borderTop: '0',
  borderRight: '0',
  borderLeft: '0',
  borderBottom: '0',
  borderSize: '1',
  borderStyle: 'solid',
  borderColor: '#000000',
  borderType: '',
};

//action creators with actions
const mcqReducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
    //add-panel action
    case 'ADD_PANEL':
      return {
        ...state,
        parameters: data.parameters,
        contentArr: data.contentArr,
        num: data.num,
        option_id: data.option_id,
      };
    //add-option action
    case 'ADD_OPTION':
      return {
        ...state,
        parameters: data.parameters,
        contentArr: data.contentArr,
        option_id: data.option_id,
      };

    //main-title action
    case 'HANDLE_TITLE':
      return {
        ...state,
        title: data.title,
      };
    //title within panel action
    case 'MCQ_HANDLE_CHANGE':
      return {
        ...state,
        parameters: data.parameters,
        errors: data.errors,
      };
    //Checkbox action true or false
    case 'SET_CHECK':
      return {
        ...state,
        isChecked: data.isChecked,
        errors: data.errors,
      };

    //Checkbox action true or false
    case 'SET_SHOWTIP':
      return {
        ...state,
        isShowtip: data.isShowtip,
      };
    //Checkbox action true or false
    case 'SET_SHOWFEEDBACK':
      return {
        ...state,
        isShowfeedback: data.isShowfeedback,
      };
    //validation action
    case 'ERRORS':
      return {
        ...state,
        errors: data.errors,
      };
    //remove action
    case 'REMOVE_CLICK':
      return {
        ...state,
        parameters: data.parameters,
        contentArr: data.contentArr,
        errors: data.errors,
      };
    //remove action
    case 'REMOVE_OPTION':
      return {
        ...state,
        parameters: data.parameters,
        contentArr: data.contentArr,
      };
    //rearange the parameters
    case 'REARANGE_ARRAY':
      return {
        ...state,
        parameters: data.parameters,
        contentArr: data.contentArr,
      };
    //Edit functionality
    case 'MAKE_EDIT':
      return {
        ...state,
        title: data.title,
        parameters: data.parameters,
        contentArr: data.contentArr,
        component: data.type,
        optiontype: data.optiontype,
        customtryagain: data.customtryagain,
        checkedAnswer: data.checkedAnswer,
        shuffle: data.shuffle,
        mytips: data.mytips,
        pbBgColor: data.pbBgColor,
        pbFontColor: data.pbFontColor,
        bgColor: data.bgColor,
        opBgColor: data.opBgColor,
        borderSize: data.borderSize,
        borderStyle: data.borderStyle,
        borderColor: data.borderColor,
        borderType: data.borderType,
        borderRadius: data.borderRadius,
        borderTop: data.borderTop,
        borderRight: data.borderRight,
        borderLeft: data.borderLeft,
        borderBottom: data.borderBottom,
        buttonFontColor: data.buttonFontColor,
        buttonBgColor: data.buttonBgColor,
        fontHeadings: data.fontHeadings,
        fontFamily: data.fontFamily,
        fontSize: data.fontSize,
        fontWeight: data.fontWeight,
        pbFontHeadings: data.pbFontHeadings,
        pbFontFamily: data.pbFontFamily,
        pbFontSize: data.pbFontSize,
        pbFontWeight: data.pbFontWeight,
        alignment: data.alignment,
        btnType: data.btnType,
      };
    //To set the type of the components
    case 'SET_TYPE':
      return {
        ...state,
        type: data.component,
      };
    //TO set the value for the pannel
    case 'SET_VALUE':
      return {
        ...state,
        num: data.num,
        option_id: data.option_id,
      };
    //For customization
    case 'CUSTOM_OPTION':
      return {
        ...state,
        optiontype: data.optiontype,
      };
    //Checkbox action true or false
    case 'CUSTOM_CHECKANSWER':
      return {
        ...state,
        checkedAnswer: data.checkedAnswer,
      };

    case 'TRY_AGAIN':
      return {
        ...state,
        customtryagain: data.customtryagain,
      };
    case 'CUSTOM_SHUFFLE':
      return {
        ...state,
        shuffle: data.shuffle,
      };
    case 'HANDLE_MYTIPS':
      return {
        ...state,
        mytips: data.mytips,
      };
    case 'PB_BGCOLOR':
      return {
        ...state,
        pbBgColor: data.pbBgColor,
      };
    case 'PB_FONTCOLOR':
      return {
        ...state,
        pbFontColor: data.pbFontColor,
      };
    case 'QU_FONT_SIZE':
      return {
        ...state,
        quFontSize: data.quFontSize,
      };
    case 'QU_FONT_WEIGHT':
      return {
        ...state,
        quFontWeight: data.quFontWeight,
      };
    case 'OP_FONT_WEIGHT':
      return {
        ...state,
        opFontWeight: data.opFontWeight,
      };
    case 'BORDER_TYPE':
      return {
        ...state,
        borderType: data.borderType,
      };

    case 'BORDER_COLOR':
      return {
        ...state,
        borderColor: data.borderColor,
      };

    case 'BORDER_SIZE':
      return {
        ...state,
        borderSize: data.borderSize,
      };

    case 'BORDER_STYLE':
      return {
        ...state,
        borderStyle: data.borderStyle,
      };
    case 'CUSTOM_BGCOLOR':
      return {
        ...state,
        bgColor: data.bgColor,
      };
    case 'OPTION_BGCOLOR':
      return {
        ...state,
        opBgColor: data.opBgColor,
      };
    case 'BUTTON_BGCOLOR':
      return {
        ...state,
        buttonBgColor: data.buttonBgColor,
      };
    case 'BUTTTON_FONTCOLOR':
      return {
        ...state,
        buttonFontColor: data.buttonFontColor,
      };

    case 'FONT_TYPE':
      return {
        ...state,
        fontFamily: data.component,
      };

    case 'FONT_SIZE':
      return {
        ...state,
        fontSize: data.component,
      };

    case 'FONT_WEIGHT':
      return {
        ...state,
        fontWeight: data.component,
      };
    case 'PBFONT_HEADINGS':
      return {
        ...state,
        pbFontHeadings: data.component,
      };

    case 'PBFONT_TYPE':
      return {
        ...state,
        pbFontFamily: data.component,
      };

    case 'PBFONT_SIZE':
      return {
        ...state,
        pbFontSize: data.component,
      };

    case 'PBFONT_WEIGHT':
      return {
        ...state,
        pbFontWeight: data.component,
      };
    case 'CUSTOM_ALIGNMENT':
      return {
        ...state,
        alignment: data.alignment,
      };
    case 'BTN_TYPE':
      return {
        ...state,
        btnType: data.btnType,
      };
    case 'CUSTOM_PADDINGTOP':
      return {
        ...state,
        customPaddingtop: data.customPaddingtop,
      };

    case 'CUSTOM_PADDINGLEFT':
      return {
        ...state,
        customPaddingleft: data.customPaddingleft,
      };

    case 'CUSTOM_PADDINGBOTTOM':
      return {
        ...state,
        customPaddingbottom: data.customPaddingbottom,
      };

    case 'CUSTOM_PADDINGRIGHT':
      return {
        ...state,
        customPaddingright: data.customPaddingright,
      };
    case 'REMOVE_IMAGE':
      return {
        ...state,
        removeImage: data.removeImage,
      };
    case 'BORDER_TOP':
      return {
        ...state,
        borderTop: data.borderTop,
      };
    case 'BORDER_BOTTOM':
      return {
        ...state,
        borderBottom: data.borderBottom,
      };
    case 'BORDER_RIGHT':
      return {
        ...state,
        borderRight: data.borderRight,
      };
    case 'BORDER_LEFT':
      return {
        ...state,
        borderLeft: data.borderLeft,
      };
    case 'BORDER_RADIUS':
      return {
        ...state,
        borderRadius: data.borderRadius,
      };
    case 'RESET':
      return {
        ...INITIAL_STATE,
        parameters: [
          {
            question: '',
            options: [
              { option: '', correct: '', mytip: '', myfeedback: '', check: '' },
              { option: '', correct: '', mytip: '', myfeedback: '', check: '' },
            ],
          },
        ],
        contentArr: [['question_0', { options: ['option_0', 'option_1'] }]],
      };
    default:
      return { ...state };
  }
};
export default mcqReducer;
