import React from 'react';
import { Row, Button, ListGroup } from 'react-bootstrap';
import hexToRgba from 'hex-to-rgba';
import { onDragEnter, onDragOver, onDragStart, onDrop, hoverOff, hoverOn } from './dndEvents';
import { isIOS, isMobileOnly, isMobileSafari } from 'react-device-detect';

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      element: '',
      nextStatus: true,
      selectedIndex: 0,
      result: [],
    };
  }

  componentDidMount() {
    this.setState({ index: this.props.idx });
    this.setComponent([this.props.data[this.props.idx]]);
    document.addEventListener('mousedown', this.closeOpenMenus);
    document.addEventListener('keyup', this.keyUp);
  }

  keyUp = (event) => {
    const { tagName, classList } = event.target;
    if (
      tagName !== 'div' &&
      this.state.isSelected &&
      event.shiftKey &&
      event.keyCode === 9 &&
      !classList.contains('listItems')
    ) {
      this.setState({
        selectedIndex: 0,
        isSelected: false,
      });
    } else if (tagName === 'BUTTON' && this.state.lastItemSelected) {
      this.setState({
        lastItemSelected: false,
        selectedIndex: 0,
        isSelected: false,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const listGroup = document.getElementById('listGroup');
    if (prevState.isSelected !== this.state.isSelected && listGroup) {
      document.body.style.height = `${document.body.clientHeight + listGroup.clientHeight}px`;
    } else if (!this.state.isSelected) {
      document.body.removeAttribute('style');
    }

    if (this.props.idx !== prevProps.idx) {
      this.setState({ index: this.props.idx });
      this.setComponent([this.props.data[this.props.idx]]);
    }
  }

  setComponent = (component) => {
    let styles = this.props.customisations;
    let bgColor = styles.dropZoneBgColor
      ? styles.dropZoneBgColor
      : styles.customisation.dropZoneBgColor;
    let opacity = styles.dropZoneOpacity
      ? styles.dropZoneOpacity
      : styles.customisation.dropZoneOpacity;
    component.map((content) => {
      const element = document.createElement('div');
      element.innerHTML = content.background;
      const image = element.getElementsByTagName('img');
      let source = image[0].src;
      let html = new Image();
      html.src = source;
      html.id = 'image';
      html.className = 'image-uploading';
      html.tabIndex = 0;
      html.alt = image[0].alt ? image[0].alt : '';
      html.role = html.alt === '' ? 'presentation' : '';
      let imageDiv = document.getElementById(`dragDrop_${this.props.idx}`);
      content.dropZone.map((value) => {
        bgColor = bgColor ? bgColor : '';
        opacity = opacity ? opacity : '';
        const bgColorRGB = hexToRgba(bgColor, opacity);
        html.addEventListener('load', function () {
          let dropZones = document.getElementById(value.data.text);
          let top =
            (value.data.y / (value.data.imgHeight ? value.data.imgHeight : image[0].height)) * 100 +
            '%';
          let left =
            (value.data.x / (value.data.imgWidth ? value.data.imgWidth : image[0].width)) * 100 +
            '%';
          let width =
            (value.data.width.split('px')[0] /
              (value.data.imgWidth ? value.data.imgWidth : image[0].width)) *
              100 +
            '%';
          let height =
            (value.data.height.split('px')[0] /
              (value.data.imgHeight ? value.data.imgHeight : image[0].height)) *
              100 +
            '%';
          if (!dropZones) return;
          dropZones.setAttribute(
            'style',
            `width:${width}; height:${height}; left:${left}; top:${top};
            background-color: ${opacity !== 0 && opacity !== '0' ? bgColorRGB : 'transparent'}; 
            border: ${opacity !== 0 && opacity !== '0' ? 'none' : '3px solid #112299'}`,
          );
        });
        return null;
      });
      if (imageDiv) imageDiv.appendChild(html);
      if (this.props.idx > 0) imageDiv.parentElement.scrollIntoView();
      return null;
    });
  };

  closeOpenMenus = (event) => {
    const { isSelected } = this.state;
    switch (true) {
      case event.target.classList.contains('listItems'):
        return;
      case isSelected:
        this.setState({ isSelected: false, selectedIndex: 0 });
        return;
      default:
        return;
    }
  };

  handleOnKeyDown = async (event, index, eventType) => {
    const { isSelected } = this.state;
    if (event.target.parentNode.className === 'dropZone') return;
    if (event.key === ' ' || (eventType === 'clickEvent' && !isSelected)) {
      this.setState({
        isSelected: true,
        selectedIndex: index,
      });
    } else if (eventType === 'clickEvent' && isSelected) {
      this.setState({
        isSelected: false,
        selectedIndex: 0,
      });
    }
  };

  handleListSelect = (event) => {
    const { selectedIndex } = this.state;
    event.preventDefault();
    const targetElement = document.getElementById(event.target.attributes.value.textContent);
    const selectedElement = document.getElementById(`key_${selectedIndex}`);
    const btnsHolder = document.getElementById('buttonsHolder');
    const currentBtn = targetElement.children[0];

    if (currentBtn) {
      currentBtn.classList.remove('buttonTextWrap');
      btnsHolder.appendChild(currentBtn);
    }

    selectedElement.classList.add('buttonTextWrap');
    targetElement.appendChild(selectedElement);
    if (this.props.customisations.mouseover) hoverOff(`key_${selectedIndex}`);
    this.setState({ isSelected: false, selectedIndex: 0 });
  };

  render() {
    const { customValue } = this.props;
    const { isSelected, selectedIndex } = this.state;
    let styles = this.props.customisations;
    let btntype = styles.dragAreaType ? styles.dragAreaType : styles.customisation.dragAreaType;

    return (
      <>
        {this.props.data.map((content, index) => {
          return (
            <React.Fragment key={index}>
              {this.props.idx === index ? (
                <div
                  id={`dragAndDrop_${index}`}
                  key={`dragAndDrop_${index}`}
                  role='button'
                  tabIndex={0}
                  aria-label='Drag and Drop'
                >
                  <div className='dndImageContainer'>
                    <div id={`dragDrop_${index}`} key={index} className='dndImage'>
                      {content.dropZone.map((area) => {
                        return (
                          <div
                            key={area.data.text}
                            className='dropZone'
                            onDrop={(e) => onDrop(e)}
                            onDragOver={(e) => onDragOver(e)}
                            id={area.data.text}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <Row
                    className='my-3 mx-0 position-relative'
                    id='buttonsHolder'
                    onDrop={(e) => onDrop(e)}
                    onDragOver={(e) => onDragOver(e)}
                    onDragEnter={(e) => onDragEnter(e)}
                  >
                    {content.dropZone.map((area, idx) => {
                      return (
                        <Button
                          key={area.data.text}
                          className={`${btntype} drop-btn me-2 mb-2`}
                          style={styles.mouseover ? styles.dragAreaUnhover : styles.dragAreaStyle}
                          title={area.data.text}
                          draggable
                          id={`key_${idx}`}
                          onClick={(e) => {
                            if (styles.mouseover) {
                              hoverOn(e.target, customValue);
                            }
                            if (isMobileOnly || isMobileSafari || isIOS)
                              this.handleOnKeyDown(e, idx, 'clickEvent');
                          }}
                          onDragStart={(e) => onDragStart(e)}
                          onMouseEnter={(e) => {
                            if (!styles.mouseover) return;
                            hoverOn(e.target, customValue);
                          }}
                          onMouseLeave={(e) => {
                            if (!styles.mouseover) return;
                            hoverOff(e.target);
                          }}
                          onKeyDown={(e) => {
                            this.handleOnKeyDown(e, idx);
                          }}
                        >
                          {area.data.text}
                          {isSelected && selectedIndex === idx ? (
                            <ListGroup
                              id='listGroup'
                              defaultActiveKey='#link_0'
                              className='position-absolute'
                            >
                              {content.dropZone.map((item, i) => (
                                <ListGroup.Item
                                  as='div'
                                  tabIndex={0}
                                  className='listItems'
                                  action
                                  active={this.selectedIndex === i}
                                  key={item.data.text}
                                  value={item.data.text}
                                  onKeyUp={(e) => {
                                    if (e.key === 'Enter') this.handleListSelect(e);
                                    this.setState({
                                      lastItemSelected:
                                        e.target.innerText ===
                                        content.dropZone[content.dropZone.length - 1].data.label,
                                      firstItemSelected:
                                        e.target.innerText === content.dropZone[0].data.label,
                                    });
                                  }}
                                  onClick={(e) => this.handleListSelect(e)}
                                >
                                  {item.data.label}
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          ) : null}
                        </Button>
                      );
                    })}
                  </Row>
                </div>
              ) : null}
            </React.Fragment>
          );
        })}
      </>
    );
  }
}

export default Carousel;
