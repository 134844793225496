import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import ProgressProvider from './progressProvider';
import 'react-circular-progressbar/dist/styles.css';
import './showScoreLayout.scss';

const ShowScoreComponent = (props: { percentage: number }) => {
  return (
    <div className='scoreContainer'>
      <div className='scoreText py-2'>You Scored</div>
      <ProgressProvider valueStart={0} valueEnd={props.percentage}>
        {(value: number) => (
          <CircularProgressbar
            value={value}
            text={`${value}%`}
            styles={{
              path: {
                stroke: value === 100 ? '#367b28' : '#112299',
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
              trail: {
                stroke: '#d6d6d6',
                strokeLinecap: 'butt',
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
              text: {
                fill: value === 100 ? '#367b28' : '#112299',
                fontSize: '16px',
              },
              background: {
                fill: '#3e98c7',
              },
            }}
          />
        )}
      </ProgressProvider>
    </div>
  );
};

export default ShowScoreComponent;
