/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { ToastManager, Tooltip } from '@crystaldelta/loree-ui-components';
import { MediaContentType } from './lintEditorType';
import { ReactComponent as MediaButton } from '../editorFormattingIcons/mediaButton.svg';
import lintStyle from '../editorUtilityFunctions/loreeInteractiveEditor.module.scss';
import MediaModal from '../mediaManagement/mediaModal';
import { fetchImages, uploadToS3 } from '../../../middleware/api';
import { setImageCollection } from '../../../redux/mediaManagement/images.action';
import { createSelectionState } from '../editorExtensions/utilityFunctions';
import { hideLinkInfoPopup } from '../../linkPopup/showLinkInfoPopup';

const mapState = ({ imageStorage }: any) => ({
  imageCollection: imageStorage.imageCollection,
});
const mapDispatch = (dispatch: Function) => ({
  SetImageCollection: (imageStorage: any) => dispatch(setImageCollection(imageStorage)),
});
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type MediaProps = PropsFromRedux & MediaContentType;

export function MediaContent(props: MediaProps) {
  let selectedNode = '';
  if (props.editor?.state) {
    const { selection } = props.editor.state;
    const selectionState = createSelectionState(selection);
    selectedNode = selectionState.selectedNode?.node.type.name ?? '';
  }

  const { editor, setIsUploading, SetImageCollection } = props;

  const [mediaType, setMediaType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [decorativeText, setDecorativeText] = useState('');
  const [description, setDescription] = useState('');
  const [isErrorInFetch, setIsErrorInFetch] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [imageType, setImageType] = useState('');
  const [imageFileDetails, setImageFileDetails] = useState<any>();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    const orgId = sessionStorage.getItem('org_id');
    void (async function () {
      try {
        setIsFetching(true);
        const getImages = await fetchImages(orgId);
        SetImageCollection({ imageCollection: getImages });
        setIsFetching(false);
      } catch (error) {
        console.error('Error in fetching images: ', error);
        setIsFetching(false);
      }
    })();
  }, [SetImageCollection]);

  if (!editor) {
    return null;
  }

  const handleModal = (mediaType: string, status: string) => {
    setMediaType(mediaType);
    setShowModal(!showModal);
    if (status === 'close') {
      return;
    }
  };

  const insertToEditor = (url: string, type: string) => {
    if (selectedNode === 'image' && type === 'image') {
      replaceImage(url);
      return;
    }
    if (type === 'image' || type === 'externalLink' || type === 'Media') {
      editor
        .chain()
        .focus()
        .setImage({
          src: url,
          alt: decorativeText ?? '',
          role: decorativeText ? '' : 'presentation',
        })
        .run();
    } else if (type === 'icon') {
      const iconContent = `<span class='material-icons' aria-describedby='${description}'><span style='color: #000000; font-size: 32px;'>${url}</span></span>`;
      editor.chain().focus().insertContent(iconContent).run();
      editor.chain().focus().addOrReplaceIconDescription(description).run();
    } else if (type === 'video') {
      const iframeContent = `<iframe title='Video content' src='${url}' />`;
      editor.chain().focus().insertContent(iframeContent).run();
    }

    setIsUploading(false);
  };

  const replaceImage = (url: string) => {
    hideLinkInfoPopup();
    editor
      .chain()
      .focus()
      .setImageProperty({
        src: url,
        alt: decorativeText ?? '',
        role: decorativeText ? '' : 'presentation',
      })
      .run();
  };

  const selectedLocalImageDetails = (type: string, file: {}) => {
    setImageType(type);
    setImageFileDetails(file);
  };

  const uploadLocalImage = (updatedFileName: string) => {
    setIsUploading(true);
    saveImageToS3(updatedFileName);
  };

  const updateImageCollection = async () => {
    try {
      setIsFetching(true);
      const orgId = sessionStorage.getItem('org_id');
      const getImages = await fetchImages(orgId);
      SetImageCollection({ imageCollection: getImages });
      setIsFetching(false);
    } catch (error) {
      console.error('Error occured while fetching images: ', error);
      setIsFetching(false);
      setIsErrorInFetch(true);
    }
  };

  const saveImageToS3 = async (updatedFileName: string) => {
    try {
      const updatedFileDetatils = new File([imageFileDetails], `${updatedFileName}`, {
        type: imageFileDetails.type,
      });
      const data = new FormData();
      data.append('image', updatedFileDetatils);
      const updatedFile = await uploadToS3(data);
      insertToEditor(updatedFile.url, mediaType);
      selectedLocalImageDetails('', {});
      await updateImageCollection();
    } catch (error) {
      console.error('Error in uploading images for Loree interactive ', error);
      setShowToast(true);
      setToastMessage('An error occured while uploading Images');
      setIsUploading(false);
    }
  };

  return (
    <>
      <Tooltip info='Media content'>
        <MediaButton
          className={lintStyle.formatButton}
          onClick={(event) => {
            event.preventDefault();
            handleModal('Media', 'open');
          }}
        />
      </Tooltip>
      <MediaModal
        show={showModal}
        setDecorativeText={(text: string) => {
          setDecorativeText(text);
        }}
        setDescription={(text: string) => {
          setDescription(text);
        }}
        decorativeText={decorativeText}
        isErrorInFetch={isErrorInFetch}
        isFetching={isFetching}
        imageType={imageType}
        mediaType={mediaType}
        setShow={(mediaType: string, status: string) => handleModal(mediaType, status)}
        handleAddElement={(elementDetail: string, type: string) =>
          insertToEditor(elementDetail, type)
        }
        handleLocalImageUpload={{
          setLocalImageDetails: (type: string, file: {}) => selectedLocalImageDetails(type, file),
          uploadImage: (fileName: string) => uploadLocalImage(fileName),
        }}
      />
      {showToast && (
        <ToastManager
          toastType='error'
          toastMessage={toastMessage}
          closeButton
          closeToast={() => setShowToast(false)}
        />
      )}
    </>
  );
}
export default connector(MediaContent);
