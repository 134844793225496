/* eslint-disable jsx-a11y/no-static-element-interactions */
/*eslint-disable no-useless-concat*/
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Row } from 'react-bootstrap';
import './flipcard.scss';
import { getBackCardStyle, getBorderStyle, getFrontCardStyle } from '../../../middleware/getStyle';

export const FlipcardComponent = (props: any) => {
  const { customisations, type, parameters } = props;
  const noofcard = customisations.noOfCard === '' ? 4 : customisations.noOfCard;
  const borderStyle = getBorderStyle(customisations);
  const frontCardStyle = getFrontCardStyle(customisations);
  const backCardStyle = getBackCardStyle(customisations);

  const orientation =
    type === 'view'
      ? `${customisations.cardType}-card-${noofcard}`
      : `${type}-${customisations.cardType}-card-${noofcard}`;

  const frontHeader = customisations.frontHeader !== '' ? customisations.frontHeader : 'div';
  const backHeader = customisations.backHeader !== '' ? customisations.backHeader : 'div';

  const flip = (idx: number) => {
    const element = document.getElementById(`flip_${idx}`);
    element?.classList.toggle('flipped');
    handleKeyBoardEvents(idx, element);
  };

  const handleKeyBoardEvents = (idx: number, element: HTMLElement | null) => {
    const frontCard = document.getElementById(`front_${idx}`);
    const backCard = document.getElementById(`back_${idx}`);
    if (element?.classList.contains('flipped')) {
      backCard?.removeAttribute('aria-hidden');
      frontCard?.removeAttribute('aria-live');
      backCard?.setAttribute('aria-live', 'polite');
      frontCard?.setAttribute('aria-hidden', 'true');
    } else {
      frontCard?.removeAttribute('aria-hidden');
      frontCard?.setAttribute('aria-live', 'polite');
      backCard?.removeAttribute('aria-live');
      backCard?.setAttribute('aria-hidden', 'true');
    }
  };

  const handleMouseOver = (idx: number) => {
    const element = document.getElementById(`flip_${idx}`);
    if (!element?.classList.contains('flipped')) return;
    element?.classList.toggle('flipped');
  };

  const keyUpEvent = (idx: number, event: { key: string }) => {
    if (event.key !== 'Enter' && event.key !== ' ') return;
    flip(idx);
  };
  const noOfCardsValues = () => {
    switch (true) {
      case noofcard === '4':
        return '3';
      case noofcard === '3':
        return '4';
      case noofcard === '2':
        return '6';
      case noofcard === '1':
        return '7';
      default:
        return '3';
    }
  };
  return (
    <Row
      className={noOfCardsValues() === '7' ? 'm-2 justify-content-center' : 'mx-2'}
      tabIndex={0}
      aria-label='Flipcard'
    >
      {parameters.map((content: { [x: string]: string }, idx: any) => (
        <div
          className={`p-2 flip col-sm-${noOfCardsValues()} ${
            customisations.cardType === 'square' ? '' : orientation
          } ${customisations.flip === '1' ? 'rotate' : ''}`}
          key={content['fronttext']}
          id={`flipcard_${idx}`}
        >
          <div
            id={`flip_${idx}`}
            className={`responsiveCard inner ${customisations.radius}`}
            onClick={
              customisations.flip === '1'
                ? () => {}
                : () => {
                    flip(idx);
                  }
            }
            onKeyUp={(event) => keyUpEvent(idx, event)}
          >
            <div
              className={`shadow front ${
                customisations.radius === 'rounded-0' ? 'rounded-0' : 'rounded-3'
              } h-100`}
              id={`front_${idx}`}
              role='button'
              style={{
                ...borderStyle,
                ...frontCardStyle,
              }}
              tabIndex={0}
            >
              <Row bsPrefix='flipcardRow' id={`frontCard_${idx}`} as={frontHeader}>
                {ReactHtmlParser(content['fronttext'])}
              </Row>
            </div>
            <div
              className={`shadow back h-100 ${
                customisations.radius === 'rounded-0' ? 'rounded-0' : 'rounded-3'
              }`}
              id={`back_${idx}`}
              aria-hidden='true'
              role='button'
              style={{
                ...borderStyle,
                ...backCardStyle,
              }}
              onMouseOver={customisations.flip === '1' ? () => handleMouseOver(idx) : () => {}}
            >
              <Row bsPrefix='flipcardRow' id={`backCard_${idx}`} as={backHeader}>
                {ReactHtmlParser(content['backtext'])}
              </Row>
            </div>
          </div>
        </div>
      ))}
    </Row>
  );
};
