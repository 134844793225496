/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Tooltip } from '@crystaldelta/loree-ui-components';
import { EditorFormatButton } from './styledContents';
import { LintEditorFormatPropsType } from './lintEditorType';
import lintStyle from '../editorUtilityFunctions/loreeInteractiveEditor.module.scss';
import { ReactComponent as UndoButton } from '../editorFormattingIcons/undoButton.svg';
import { ReactComponent as RedoButton } from '../editorFormattingIcons/redoButton.svg';

export function HistoryFormatButtons(props: LintEditorFormatPropsType) {
  const { editor } = props;
  if (!editor) {
    return null;
  }
  return (
    <>
      <EditorFormatButton
        className={lintStyle.historyButton}
        onClick={(e: any) => {
          e.preventDefault();
          editor.chain().undo().run();
        }}
        disabled={!editor.can().chain().undo().run() as boolean}
      >
        <Tooltip info='Undo'>
          <UndoButton />
        </Tooltip>
      </EditorFormatButton>
      <EditorFormatButton
        className={lintStyle.historyButton}
        onClick={() => editor.chain().redo().run()}
        disabled={!editor.can().chain().redo().run() as boolean}
      >
        <Tooltip info='Redo'>
          <RedoButton onClick={() => editor.chain().redo().run()} />
        </Tooltip>
      </EditorFormatButton>
    </>
  );
}
