/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { setDragandDropLayout } from '../../../../redux/form/form.actions';

const mapState = ({ form }: any) => ({
  dndLayout: form.dndLayout,
});

const mapDispatch = (dispatch: Function) => ({
  SetLayout: (form: {}) => dispatch(setDragandDropLayout(form)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  onLayoutChange: (layout: string) => void;
};

const LayoutCustomize = (props: Props) => {
  const { dndLayout, SetLayout, onLayoutChange } = props;

  const setCustomOption = (e: ChangeEvent<HTMLInputElement>) => {
    const layout = e.target.value;
    SetLayout({ dndLayout: layout });
    onLayoutChange(layout);
  };

  return (
    <div className='d-flex'>
      <Form.Check
        inline
        label='Custom'
        type='radio'
        value='CustomOverlay'
        id='CustomOverlay'
        onChange={setCustomOption}
        checked={dndLayout === 'CustomOverlay'}
        className='icon-label'
      />
      <Form.Check
        inline
        label='Fixed'
        type='radio'
        value='Fixed'
        id='Fixed'
        onChange={setCustomOption}
        checked={dndLayout === 'Fixed'}
        className='icon-label'
      />
    </div>
  );
};

export default connector(LayoutCustomize);
