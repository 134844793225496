/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { LoreeInteractiveEditor } from '../../editor/loreeInteractiveEditor';
import { CONSTANTS } from '../../editor/editorUtilityFunctions/constants';
import { LoreeInteractiveEditorDashboardContentType } from '../../editor/editorUtilityFunctions/lintEditorType';
import Tooltip from '../../utils/tooltip';
import ModalComponent from '../../utils/modal';
import { ReactComponent as SectionIcon } from '../../../assets/icons/sectionIcon.svg';
import { ReactComponent as SectionClose } from '../../../assets/icons/sectionClose.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/sectiondeleteIcon.svg';
import { ReactComponent as AddOptionIcon } from '../../../assets/icons/add.svg';
import { getModalDetails } from '../../../middleware/utils';
import { addOption, handleChange, removeOption } from '../../../redux/multipleChoice/mcq.action';
import { setFocus } from '../utils';

interface OptionSectionProps {
  index: number;
  editorConfig: LoreeInteractiveEditorDashboardContentType;
}

const mapState = ({ mcqform }: any) => ({
  componentParameter: mcqform.parameters,
  componentContentArray: mcqform.contentArr,
  optionId: mcqform.option_id,
  errors: mcqform.errors,
});

const mapDispatch = (dispatch: Function) => ({
  InsertOption: (data: {}) => dispatch(addOption(data)),
  DeleteOption: (data: any) => dispatch(removeOption(data)),
  HandleOptions: (data: {}) => dispatch(handleChange(data)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & OptionSectionProps;

const OptionSectionComponent = (props: Props) => {
  const {
    errors,
    index,
    componentParameter,
    componentContentArray,
    optionId,
    InsertOption,
    DeleteOption,
    HandleOptions,
    editorConfig,
  } = props;

  const [contentArray, setContentArray] = useState(componentContentArray);
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);

  useEffect(() => {
    setContentArray(componentContentArray);
  }, [componentContentArray]);

  const handleSectionToggle = () => {
    const isOpened = contentArray[index].indexOf('isOptionOpened');
    if (isOpened > -1) {
      contentArray[index].splice(isOpened, 1);
    } else {
      contentArray[index].push('isOptionOpened');
    }
    setContentArray([...contentArray]);
  };

  const handleAddOption = () => {
    const content = componentParameter;
    const contentArr = contentArray;
    content[index].options = [
      ...content[index].options,
      { option: '', correct: '', mytip: '', myfeedback: '', check: '' },
    ];
    contentArr[index][1].options = [...contentArr[index][1].options, `option_${optionId}`];
    InsertOption({
      parameters: content,
      contentArr: contentArr,
      option_id: optionId + 1,
    });
    setFocus(`option_${content[index].options.length}`);
  };

  const handleDeleteOption = () => {
    const content = [...componentParameter];
    const contentArr = contentArray;
    content[index].options.splice(selectedOption, 1);
    contentArr[index][1].options.splice(selectedOption, 1);
    DeleteOption({
      parameters: content,
      contentArr: contentArr,
    });
    setShowModal(false);
  };

  const handleEditorOption = (optionIndex: number, value: string) => {
    let content = componentParameter;
    let fail = errors;
    delete fail[contentArray[index][optionIndex + 1]];
    content[index].options[optionIndex] = {
      ...content[index].options[optionIndex],
      option: value,
    };
    HandleOptions({
      parameters: content,
      errors: fail,
    });
  };
  const handleCheckBox = (
    event: ChangeEvent<HTMLInputElement>,
    optionIndex: number,
    type: string,
  ) => {
    const { checked } = event.target;
    let content = [...componentParameter];
    content[index].options[optionIndex] = {
      ...content[index].options[optionIndex],
      [`${type}`]: checked,
    };
    HandleOptions({
      parameters: content,
      errors: errors,
    });
  };

  const handleTextArea = (event: ChangeEvent, optionIndex: number, type: string) => {
    const { value } = event.target as HTMLInputElement;
    let content = [...componentParameter];
    content[index].options[optionIndex] = {
      ...content[index].options[optionIndex],
      [`${type}`]: value,
    };
    HandleOptions({
      parameters: content,
      errors: errors,
    });
  };

  const getCheckBoxValue = (value: string | boolean) => {
    if (value === '') {
      return false;
    } else {
      return value as boolean;
    }
  };

  return (
    <div className='section-accordion'>
      <div className='inner-content text-capitalize' id='optionCollapse'>
        <div
          className='d-flex fw-bolder section-style position-relative'
          role='button'
          tabIndex={0}
          onKeyDown={() => handleSectionToggle()}
          onClick={() => handleSectionToggle()}
        >
          <div className='position-absolute'>
            {contentArray[index].indexOf('isOptionOpened') === -1 ? (
              <SectionIcon />
            ) : (
              <SectionClose />
            )}
            <> &nbsp; &nbsp; Options</>
          </div>
        </div>
      </div>
      {contentArray[index].indexOf('isOptionOpened') === -1 && (
        <>
          <Form.Group className='section-content pb-3'>
            {contentArray[index][1]?.options?.map((value: string, optionIndex: number) => {
              return (
                <Form.Group
                  className='option-content py-2'
                  id={`option_${optionIndex + 1}`}
                  key={`option_${optionIndex}`}
                >
                  <Form.Label className='title'>Option {optionIndex + 1} *</Form.Label>
                  {contentArray[index][1]?.options?.length >= 3 && (
                    <Button
                      size='sm'
                      onClick={() => {
                        setShowModal(true);
                        setSelectedOption(optionIndex);
                      }}
                      className='delete-icon float-end close-btn section-button'
                      id={`delete${index + 1}`}
                    >
                      <Tooltip info='Delete'>
                        <DeleteIcon />
                      </Tooltip>
                    </Button>
                  )}

                  <LoreeInteractiveEditor
                    initialValue={
                      componentParameter[index]?.options[optionIndex]?.option ??
                      CONSTANTS.LOREE_EDITOR_EMPTY_CONTENT
                    }
                    editorConfig={editorConfig}
                    updateEditorContent={(value: string) => handleEditorOption(optionIndex, value)}
                    indexValue={contentArray[index][1]?.options[optionIndex]}
                  />

                  {errors && <Form.Text className='text-danger'>{errors[`${value}`]}</Form.Text>}
                  <Form.Check
                    type='checkbox'
                    data-testid='correctanswer-check'
                    id={`correctanswerLabel-${index}-${optionIndex}`}
                    className='checkbox-label'
                    label='Correct Answer'
                    checked={getCheckBoxValue(
                      componentParameter[index].options[optionIndex]?.correct,
                    )}
                    onChange={(e) => handleCheckBox(e, optionIndex, 'correct')}
                  />
                  <div className='d-flex flex-row my-2'>
                    <Form.Group className='w-100 me-2'>
                      <Form.Label>Tip</Form.Label>
                      <Form.Control
                        type='text'
                        as='textarea'
                        className='text-area'
                        value={componentParameter[index].options[optionIndex]?.mytip}
                        onChange={(e) => handleTextArea(e, optionIndex, 'mytip')}
                      />
                      {errors && (
                        <Form.Text className='text-danger'>{errors[`${value}_tip`]}</Form.Text>
                      )}
                      <Form.Check
                        type='checkbox'
                        data-testid='showtip-check'
                        className='checkbox-label'
                        id={`tipLabel-${index}-${optionIndex}`}
                        label='Show Tip'
                        checked={getCheckBoxValue(
                          componentParameter[index].options[optionIndex]?.check,
                        )}
                        onChange={(e) => handleCheckBox(e, optionIndex, 'check')}
                      />
                    </Form.Group>
                    <Form.Group className='w-100 ms-2'>
                      <Form.Label>Feedback</Form.Label>
                      <Form.Control
                        type='text'
                        as='textarea'
                        className='text-area'
                        value={componentParameter[index].options[optionIndex]?.myfeedback}
                        onChange={(e) => handleTextArea(e, optionIndex, 'myfeedback')}
                      />
                      {errors && (
                        <Form.Text className='text-danger'>{errors[`${value}_feedback`]}</Form.Text>
                      )}
                      <Form.Check
                        type='checkbox'
                        data-testid='feedback-check'
                        id={`feedbackLabel-${index}-${optionIndex}`}
                        className='checkbox-label'
                        label='Show Feedback'
                        checked={getCheckBoxValue(
                          componentParameter[index].options[optionIndex]?.showFeedback,
                        )}
                        onChange={(e) => handleCheckBox(e, optionIndex, 'showFeedback')}
                      />
                    </Form.Group>
                  </div>
                </Form.Group>
              );
            })}
          </Form.Group>
          {contentArray[index][1]?.options.length < 100 && (
            <div key='addPanel' className='my-3 addpanel-div position-relative'>
              <Button
                className='mt-3 addoption-button position-absolute'
                size='sm'
                onClick={handleAddOption}
              >
                <AddOptionIcon />
                Add Option
              </Button>
            </div>
          )}
        </>
      )}
      <ModalComponent
        show={showModal}
        handleCancel={() => setShowModal(false)}
        modalDetails={getModalDetails('Remove Option', `Option ${selectedOption + 1}`)}
        confirmButton={{
          label: 'Remove',
          disabled: false,
          handleConfirm: handleDeleteOption,
        }}
        cancelButton={{
          label: 'Cancel',
        }}
      />
    </div>
  );
};

export default connector(OptionSectionComponent);
