/* eslint-disable @typescript-eslint/no-explicit-any */
import { mergeAttributes, Node } from '@tiptap/core';
import { optimizeStyle } from './utilityFunctions';
import { CommonListOptions } from '../editorUtilityFunctions/lintEditorType';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    orderedLists: {
      /**
       * Toggle a ordered list
       */
      toggleOrderedListContent: (numberingType: string) => ReturnType;
    };
  }
}

export const OrderedList = Node.create<CommonListOptions>({
  name: 'orderedList',

  addOptions() {
    return {
      itemTypeName: 'listItem',
      HTMLAttributes: {},
      keepMarks: false,
      keepAttributes: false,
    };
  },

  group: 'block list',
  atom: true,
  selectable: true,
  draggable: false,

  content() {
    return `${this.options.itemTypeName}+`;
  },

  addAttributes() {
    return {
      start: {
        default: 1,
        parseHTML: (element) => {
          return element.hasAttribute('start')
            ? parseInt(element.getAttribute('start') ?? '', 10)
            : 1;
        },
      },
      type: {
        parseHTML: (element) => element.getAttribute('type'),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'ol',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { start, ...attributesWithoutStart } = HTMLAttributes;

    return start === 1
      ? [
          'ol',
          optimizeStyle(mergeAttributes(this.options.HTMLAttributes, attributesWithoutStart)),
          0,
        ]
      : ['ol', optimizeStyle(mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)), 0];
  },

  addCommands() {
    return {
      toggleOrderedListContent:
        (numberingType: string) =>
        ({ commands, chain }) => {
          if (this.options.keepAttributes) {
            return chain().toggleLists(this.name, this.options.itemTypeName, numberingType).run();
          }
          return commands.toggleLists(this.name, this.options.itemTypeName, numberingType);
        },
    };
  },
});
