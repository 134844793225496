/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Customisation } from '../utils';

export const getMcqEditData = (data: any, parameters: any, contentArr: any, pathname: string) => {
  const { customisations } = data;
  const applyOldBorder = customisations.borderType !== '' ? customisations.borderSize : 0;

  return {
    title: pathname.includes('duplicate') ? '' : data.title,
    parameters: migrateMcqStyles(parameters, customisations),
    contentArr: contentArr,
    component: data.type,
    optiontype: customisations.optionType,
    customtryagain: customisations.retry,
    checkedAnswer: customisations.checkAnswer,
    shuffle: customisations.shuffle,
    pbBgColor: customisations.pbBgColor ? customisations.pbBgColor : '#1D77CC',
    pbFontColor: customisations.pbFontColor ? customisations.pbFontColor : '#ffffff',
    bgColor: customisations.bgColor ? customisations.bgColor : '#ffffff',
    opBgColor: customisations.opBgColor ? customisations.opBgColor : '#f8f9fa',
    borderSize: customisations.borderSize ? customisations.borderSize : '1',
    borderStyle: customisations.borderStyle ? customisations.borderStyle : 'solid',
    borderColor: customisations.borderColor ? customisations.borderColor : '#000000',
    borderType: customisations.borderType ? customisations.borderType : '',
    borderTop: customisations.borderTop ? customisations.borderTop : applyOldBorder,
    borderBottom: customisations.borderBottom ? customisations.borderBottom : applyOldBorder,
    borderLeft: customisations.borderLeft ? customisations.borderLeft : applyOldBorder,
    borderRight: customisations.borderRight ? customisations.borderRight : applyOldBorder,
    borderRadius: customisations.borderRadius ? customisations.borderRadius : '0',
    buttonFontColor: customisations.buttonFontColor ? customisations.buttonFontColor : '#ffffff',
    buttonBgColor: customisations.buttonBgColor ? customisations.buttonBgColor : '#1D77CC',
    fontHeadings: customisations.fontHeadings ? customisations.fontHeadings : 'Normal',
    fontFamily: customisations.fontFamily ? customisations.fontFamily : 'Helvetica',
    fontSize: customisations.fontSize ? customisations.fontSize : '16',
    fontWeight: '',
    pbFontHeadings: customisations.pbFontHeadings ? customisations.pbFontHeadings : 'Normal',
    pbFontFamily: customisations.pbFontFamily ? customisations.pbFontFamily : 'Helvetica',
    pbFontSize: customisations.pbFontSize ? customisations.pbFontSize : '16',
    pbFontWeight: customisations.pbFontWeight ? customisations.pbFontWeight : 'Normal',
    alignment: customisations.alignment ? customisations.alignment : 'left',
    btnType: customisations.btnType ? customisations.btnType : 'btn btn-square btn-dark',
    customPaddingtop: customisations.customPaddingtop ? customisations.customPaddingtop : '10',
    customPaddingleft: customisations.customPaddingleft ? customisations.customPaddingleft : '10',
    customPaddingbottom: customisations.customPaddingbottom
      ? customisations.customPaddingbottom
      : '10',
    customPaddingright: customisations.customPaddingright
      ? customisations.customPaddingright
      : '10',
  };
};

export const migrateMcqStyles = (parameters: any, customisation: Customisation) => {
  const { quFontSize, quFontWeight, opFontSize, opFontWeight } = customisation;
  const refinedParameters = [...parameters];
  if (!quFontSize) {
    return refinedParameters;
  } else {
    refinedParameters.forEach((element) => {
      const { question, options } = element;
      const wrapper = document.createElement('div');
      wrapper.innerHTML = question;
      Array.from(wrapper.children).forEach((childNode) => {
        if (childNode.tagName !== 'P') return;
        else {
          childNode.setAttribute('style', `font-size:${quFontSize}px;`);
          if (quFontWeight === 'Bold') {
            const boldedContent = `<strong>${childNode.innerHTML}</strong>`;
            childNode.innerHTML = boldedContent;
          }
        }
      });
      element.question = wrapper.innerHTML;
      options.forEach((optionElement: { option: string }) => {
        const optionWrapper = document.createElement('div');
        optionWrapper.innerHTML = optionElement.option;
        Array.from(optionWrapper.children).forEach((childNode) => {
          if (childNode.tagName !== 'P') return;
          else {
            childNode.setAttribute('style', `font-size:${opFontSize}px;`);
            if (opFontWeight === 'Bold') {
              const boldedContent = `<strong>${childNode.innerHTML}</strong>`;
              childNode.innerHTML = boldedContent;
            }
          }
        });
        optionElement.option = optionWrapper.innerHTML;
      });
    });
    return refinedParameters;
  }
};
