const initialState = {
  title: "",
  parameters: [],
  customisations:""
};
  
const componentReducer = ( state = initialState, action) => {
  switch(action.type) {
  case "PUT_ITEMS":
    return {
      ...state,
      title: action.title,
      parameters: action.parameters,
      customisations: action.customisations
    };
  default:
    return initialState;
  }
};
export default componentReducer;
