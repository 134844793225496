/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { getButtonStyle } from '../../formComponent/button/utils';

const ButtonComponent = (props: any) => {
  const [hoverState, setHoverState] = useState(false);
  const { parameters, customisations } = props;
  const isNewTab = parameters.isChecked ? '_blank' : '_top';
  const mouseOver = customisations.btnType === 'btn btn-outline-dark' ? true : false;
  const buttonstyle = getButtonStyle(customisations, 'buttonStyle');
  const buttonUnhover = getButtonStyle(customisations, 'unhover');

  const handleHover = (status: boolean) => {
    setHoverState(status);
  };

  return (
    <div
      className={`text-${customisations.alignment}`}
      role='button'
      tabIndex={0}
      aria-label='Button'
    >
      <Button
        role='button'
        aria-pressed='false'
        className={`${customisations.btnType}`}
        style={mouseOver ? (hoverState ? buttonstyle : buttonUnhover) : buttonstyle}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
        tabIndex={0}
        aria-label={parameters.name}
        as='a'
        href={parameters.link}
        target={isNewTab}
        onKeyDown={(event) => {
          if (event.key === ' ' || event.key === 'Enter') {
            event.preventDefault();
            window.open(parameters.link);
          }
        }}
      >
        {parameters.name}
      </Button>
    </div>
  );
};

export default ButtonComponent;
