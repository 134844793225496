import React from 'react';
import { Modal } from 'react-bootstrap';
import { TableModalProps, TableModalTitleType } from './lintEditorType';
import { CreateNewTableContent } from './tableModalContents/createTableModalContent';
import { TableContentProperties } from './tableModalContents/tableCellPropertiesContent';
import { TableProperties } from './tableModalContents/tablePropertiesContent';
{
  /* type Placement = "right-start" | Side | "top-start" | "top-end" | "right-end" | "bottom-start" | "bottom-end" | "left-start" | "left-end" */
}

export const TableModal = (props: TableModalProps) => {
  const {
    modalHeaderTitle,
    show,
    onHide,
    onSubmit,
    onSubmitTableProperties,
    onSubmitTableRowProperties,
    onSubmitTableCellProperties,
    styles,
  } = props;

  return (
    <>
      <Modal show={show} onHide={onHide} size='sm' backdrop='static' centered>
        <Modal.Header className='tableModalHeader' closeButton>
          <Modal.Title>
            <h5 className='tableHeaderTitle'>{modalHeaderTitle}</h5>
          </Modal.Title>
        </Modal.Header>
        {modalHeaderTitle === TableModalTitleType[0] && (
          <CreateNewTableContent onSubmit={onSubmit} onHide={onHide} />
        )}
        {modalHeaderTitle === TableModalTitleType[1] && (
          <TableContentProperties
            onSubmitTableCellProperties={onSubmitTableCellProperties}
            onHide={onHide}
            styles={styles}
            isCellProperties
          />
        )}
        {modalHeaderTitle === TableModalTitleType[2] && (
          <TableContentProperties
            onSubmitTableRowProperties={onSubmitTableRowProperties}
            onHide={onHide}
            styles={styles}
          />
        )}
        {modalHeaderTitle === TableModalTitleType[3] && (
          <TableProperties
            onSubmitTableProperties={onSubmitTableProperties}
            onHide={onHide}
            styles={styles}
          />
        )}
      </Modal>
    </>
  );
};
