/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useState } from 'react';
import { getSharedInteractives } from '../../middleware/api';
import { initializeReactGA } from '../../middleware/googleAnalytics';
import ContentList from './index';
import {
  getFeaturesList,
  getAdminDashboardFontFamilyList,
  getCustomHeaderFontDetails,
} from './utils';
import { RouteComponentProps } from 'react-router-dom';
import { getValueFromSession } from '../../middleware/utils';

const SharedInteractives = (props: RouteComponentProps) => {
  const { id } = props.match.params as { id: string };
  const [sharedInteractives, setSharedInteractives] = useState([]);
  const [config, setConfig] = useState({});
  const [fontFamilyList, setFontFamilyList] = useState({});
  const [customHeaderStyleList, setCustomHeaderStyleList] = useState({});
  const [fetching, setFetching] = useState<boolean>(true);

  useEffect(() => {
    try {
      const fetchData = async () => {
        initializeReactGA(props.location.pathname);
        if (!getValueFromSession('sharedAccount')) {
          props.history.push('/401');
        }

        setFetching(true);
        const interactiveLists = await getSharedInteractives();
        let config = interactiveLists.config ? JSON.parse(interactiveLists?.config) : null;
        let user = getValueFromSession('user');

        setFetching(false);
        if (!user) sessionStorage.setItem('user', interactiveLists.userEmail);
        config = getFeaturesList(config, 'shared');
        const featureConfig = JSON.parse(interactiveLists?.config ?? 'null');
        const fontFamilyList = getAdminDashboardFontFamilyList(featureConfig.configsList);
        const customHeaderStyleList = getCustomHeaderFontDetails(featureConfig.configsList);

        setSharedInteractives(interactiveLists.sharedInteractives ?? []);
        setConfig(config ?? {});
        setFontFamilyList(fontFamilyList);
        setCustomHeaderStyleList(customHeaderStyleList);
      };
      fetchData();
    } catch (error) {
      console.log('error in fetching Interactive lists', error);
    }
  }, [id, props]);

  return (
    <ContentList
      interactiveLists={sharedInteractives}
      history={props.history}
      location={props.location}
      config={config}
      fontFamilyList={fontFamilyList}
      customHeaderStyleList={customHeaderStyleList}
      fetching={fetching}
    />
  );
};

export default SharedInteractives;
