/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Carousel } from 'react-bootstrap';
import { setIcon } from './hotspotIcon';
import '../../../assets/scss/hotspot.scss';
import ModalComponent from '../../utils/modal';

const HotspotViewComponent = (props: any) => {
  const { parameters, customisations } = props;
  const [show, setShow] = useState(false);
  const [label, setLabel] = useState('');
  const [text, setText] = useState('');

  useEffect(() => {
    setComponent(parameters, customisations);
  }, [parameters, customisations]);

  const setComponent = (parameters: any, customisations: any) => {
    const icon = setIcon(customisations);
    parameters.map((content: any, index: number) => {
      const element = document.createElement('div');
      element.innerHTML = content.background;
      const image = element.getElementsByTagName('img');
      let source = image[0].src;
      let html = new Image();
      html.id = `image_${index}`;
      html.src = source;
      html.className = 'image-uploading';
      html.tabIndex = 0;
      html.alt = image[0].alt ? image[0].alt : '';
      html.role = html.alt === '' ? 'presentation' : '';
      let imageDiv = document.getElementById(`hotspot_${index}`);
      imageDiv?.getElementsByTagName('img')[0]?.remove();
      content.dropZone.map((value: any) => {
        html.addEventListener('load', function () {
          let hotspots = document.getElementById(`${value.data.label}_${index}`);
          let top =
            (value.data.y / (value.data.imgHeight ? value.data.imgHeight : image[0].height)) * 100 +
            '%';
          let left =
            (value.data.x / (value.data.imgWidth ? value.data.imgWidth : image[0].width)) * 100 +
            '%';
          let width =
            (customisations.fontSize /
              (value.data.imgWidth ? value.data.imgWidth : image[0].width)) *
              100 +
            '%';
          let height =
            (customisations.fontSize /
              (value.data.imgHeight ? value.data.imgHeight : image[0].height)) *
              100 +
            '%';
          if (hotspots)
            hotspots.setAttribute(
              'style',
              `left:${left};top:${top}; width:${width};height:${height};background-image:url(${icon});background-size:100% 100%;background-repeat:no-repeat`,
            );
        });
        return null;
      });
      imageDiv?.insertBefore(html, imageDiv.firstChild);
      return imageDiv;
    });
  };

  const handleModal = (show: boolean, label: string, text: string) => {
    const updatedText = text.replace(/<img /g, '<img style="max-width: 100%; max-height: 500px;" ');
    setShow(show);
    setLabel(label);
    setText(updatedText);
  };

  return (
    <div className='text-center' tabIndex={0} aria-label='Hotspot' role='button'>
      <Carousel id='hotspot' controls={parameters.length > 1} indicators={false} interval={null}>
        {parameters.map((content: any, idx: number) => (
          <Carousel.Item key={idx}>
            <div className='hotspot' id={`hotspot_${idx}`}>
              {content?.dropZone?.map(
                (dropArea: { data: { label: string; text: string } }, index: number) => (
                  <div
                    key={`${dropArea.data.label}_${index}`}
                    className='hotspotIcon'
                    aria-label={dropArea.data.label}
                    onClick={() => handleModal(true, dropArea.data.label, dropArea.data.text)}
                    id={`${dropArea.data.label}_${idx}`}
                    tabIndex={0}
                    role='button'
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' || event.key === ' ') {
                        event.preventDefault();
                        handleModal(true, dropArea.data.label, dropArea.data.text);
                      }
                    }}
                  />
                ),
              )}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <ModalComponent
        show={show}
        handleCancel={() => handleModal(false, '', '')}
        modalDetails={{
          title: label,
          body: <>{ReactHtmlParser(text)}</>,
        }}
        isCentered={true}
      />
    </div>
  );
};

export default HotspotViewComponent;
