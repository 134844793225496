import { Plugin, PluginKey, Transaction, EditorState } from 'prosemirror-state';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';
import { Attrs, Node as ProseMirrorNode } from 'prosemirror-model';
import { ensure } from '@crystaldelta/loree-util-general';
import {
  cellAround,
  getRowsFromTable,
  getTableFromCellPosition,
  pointsAtCell,
  setAttr,
  tableNodeTypes,
} from './util';
import { TableMap } from './tableMap';
import {
  ColumnResizingMeta,
  ColumnResizingProps,
} from '../../editorUtilityFunctions/lintEditorType';

export const key = new PluginKey<ResizeState>('tableColumnResizing');

export function columnResizing({
  handleWidth = 5,
  cellMinWidth = 25,
  nodeView,
  lastColumnResizable = true,
}: ColumnResizingProps) {
  const plugin = new Plugin<ResizeState>({
    key,
    state: {
      init(this: Plugin<ResizeState>, _, state) {
        const spec = this.spec;
        if (spec?.props?.nodeViews) {
          spec.props.nodeViews[tableNodeTypes(state.schema)['table'].name] = (node, view) =>
            new nodeView(node, cellMinWidth);
        }
        return new ResizeState(-1, null);
      },
      apply(tr, value) {
        return value.apply(tr);
      },
    },
    props: {
      attributes(state): Record<string, string> {
        const pluginState = key.getState(state);
        return pluginState && pluginState.activeHandle > -1 ? { class: 'resize-cursor' } : {};
      },

      handleDOMEvents: {
        mousemove(view, event: Event) {
          handleMouseMove(
            view,
            event as MouseEvent,
            handleWidth,
            cellMinWidth,
            lastColumnResizable,
          );
        },
        mouseleave(view, event: Event) {
          handleMouseLeave(view);
        },
        mousedown(view, event: Event) {
          handleMouseDown(view, event as MouseEvent, cellMinWidth);
        },
      },

      decorations(state: EditorState) {
        const pluginState = key.getState(state);
        if (pluginState && pluginState.activeHandle > -1)
          return createDecorations(state, pluginState.activeHandle);
        return null;
      },

      nodeViews: {},
    },
  });
  return plugin;
}

class ResizeState {
  activeHandle: number;
  dragging: { startX: number; startWidth: number } | null;

  constructor(activeHandle: number, dragging: { startX: number; startWidth: number } | null) {
    this.activeHandle = activeHandle;
    this.dragging = dragging;
  }

  apply(tr: Transaction) {
    const action = tr.getMeta(key) as ColumnResizingMeta;
    if (action?.setHandle != null) {
      return new ResizeState(action.setHandle, null);
    }

    if (action?.setDragging !== undefined) {
      return new ResizeState(this.activeHandle, action.setDragging);
    }

    if (this.activeHandle > -1 && tr.docChanged) {
      const handle: number = tr.mapping.map(this.activeHandle, -1);
      if (!pointsAtCell(tr.doc.resolve(handle))) {
        return new ResizeState(-1, null);
      }
      return new ResizeState(handle, this.dragging);
    }
    return this;
  }
}

function handleMouseMove(
  view: EditorView,
  event: MouseEvent,
  handleWidth: number,
  cellMinWidth: number,
  lastColumnResizable: boolean,
) {
  const pluginState = key.getState(view.state);
  if (!pluginState) {
    return;
  }

  if (!pluginState.dragging) {
    const target = domCellAround(event.target as HTMLElement);
    let cell = -1;
    if (target) {
      const { left, right } = target.getBoundingClientRect();
      if (event.clientX - left <= handleWidth) cell = edgeCell(view, event, 'left');
      else if (right - event.clientX <= handleWidth) cell = edgeCell(view, event, 'right');
    }

    if (cell != pluginState.activeHandle) {
      if (!lastColumnResizable && cell !== -1) {
        const $cell = view.state.doc.resolve(cell);
        const [table, start] = getTableFromCellPosition($cell);
        const map = TableMap.get(table);
        const col =
          map.getColumnIndexFromPos($cell.pos - start) + $cell.nodeAfter?.attrs['colspan'] - 1;

        if (col == map.width - 1) {
          return;
        }
      }

      updateHandle(view, cell);
    }
  }
}

function handleMouseLeave(view: EditorView) {
  const pluginState = key.getState(view.state);
  if (!pluginState) {
    return;
  }
  if (pluginState.activeHandle > -1 && !pluginState.dragging) {
    updateHandle(view, -1);
  }
}

function handleMouseDown(view: EditorView, event: MouseEvent, cellMinWidth: number) {
  const pluginState = key.getState(view.state);
  if (!pluginState || pluginState.activeHandle == -1 || pluginState.dragging) return false;

  const cellNode = view.state.doc.nodeAt(pluginState.activeHandle);
  if (cellNode) {
    const width = currentColWidth(
      view,
      pluginState.activeHandle,
      cellNode.attrs['colspan'],
      cellNode.attrs['colwidth'],
    );
    const meta: ColumnResizingMeta = { setDragging: { startX: event.clientX, startWidth: width } };
    view.dispatch(view.state.tr.setMeta(key, meta));
  }

  function finish(event: MouseEvent) {
    view.root.removeEventListener('mouseup', finish as EventListenerOrEventListenerObject);
    view.root.removeEventListener('mousemove', move as EventListenerOrEventListenerObject);
    const pluginState = key.getState(view.state);
    if (pluginState && pluginState.dragging) {
      updateColumnWidth(
        view,
        pluginState.activeHandle,
        draggedWidth(pluginState.dragging, event, cellMinWidth),
      );
      view.dispatch(view.state.tr.setMeta(key, { setDragging: null }));
    }
  }
  function move(event: MouseEvent) {
    if (!event.which) return finish(event);
    const pluginState = key.getState(view.state);
    if (!pluginState) {
      return;
    }
    const dragged = draggedWidth(ensure(pluginState.dragging), event, cellMinWidth);

    displayColumnWidth(view, pluginState.activeHandle, dragged, cellMinWidth);
  }

  view.root.addEventListener('mouseup', finish as EventListenerOrEventListenerObject);
  view.root.addEventListener('mousemove', move as EventListenerOrEventListenerObject);
  event.preventDefault();
  return true;
}

function currentColWidth(
  view: EditorView,
  cellPos: number,
  colspan: number,
  colwidth: number[] | undefined,
) {
  const width = colwidth && colwidth[colwidth.length - 1];
  if (width) return width;
  const dom = view.domAtPos(cellPos);
  const node = dom.node.childNodes[dom.offset];
  let domWidth = (node as HTMLElement).offsetWidth;
  let parts = colspan;
  if (colwidth)
    for (let i = 0; i < colspan; i++)
      if (colwidth[i]) {
        domWidth -= colwidth[i];
        parts--;
      }
  return domWidth / parts;
}

function domCellAround(target: HTMLElement | null): HTMLElement | null {
  while (target && target.nodeName != 'TD' && target.nodeName != 'TH')
    target = target.classList.contains('ProseMirror') ? null : (target.parentNode as HTMLElement);
  return target;
}

function edgeCell(view: EditorView, event: MouseEvent, side: 'left' | 'right') {
  const found = view.posAtCoords({ left: event.clientX, top: event.clientY });
  if (!found) return -1;
  const { pos } = found;
  const $cell = cellAround(view.state.doc.resolve(pos));
  if (!$cell) return -1;
  if (side == 'right') return $cell.pos;
  const [table, start] = getTableFromCellPosition($cell);
  const map = TableMap.get(table);
  const index = map.map.indexOf($cell.pos - start);
  return index % map.width == 0 ? -1 : start + map.map[index - 1];
}

function draggedWidth(
  dragging: { startX: number; startWidth: number },
  event: MouseEvent,
  cellMinWidth: number,
) {
  const offset = event.clientX - dragging.startX;
  return Math.max(cellMinWidth, dragging.startWidth + offset);
}

function updateHandle(view: EditorView, value: number) {
  const meta: ColumnResizingMeta = { setHandle: value };
  view.dispatch(view.state.tr.setMeta(key, meta));
}

function setColWidthAttr(attr: Attrs, maxCols: number, col: number, width: number) {
  if (!attr['data-colwidths']) {
    const r = zeroes(maxCols);
    r[col] = width;
    return setAttr(attr, 'data-colwidths', r);
  }
  let r: number[] = attr['data-colwidths'];
  while (r.length < maxCols) {
    r.push(0);
  }
  r = r.slice(0, maxCols);
  r[col] = width;
  return setAttr(attr, 'data-colwidths', r);
}

function updateColumnWidth(view: EditorView, cell: number, width: number) {
  const $cell = view.state.doc.resolve(cell);
  const [table, start] = getTableFromCellPosition($cell);
  const map = TableMap.get(table);
  const col = map.getColumnIndexFromPos($cell.pos - start) + $cell.nodeAfter?.attrs['colspan'] - 1;
  const tr = view.state.tr;
  const tableAttrs = table.attrs;
  const newAttr = setColWidthAttr(tableAttrs, map.width, col, width);

  tr.setNodeMarkup(start - 1, null, newAttr);
  for (let row = 0; row < map.height; row++) {
    const mapIndex = row * map.width + col;
    // Rowspanning cell that has already been handled
    if (row && map.map[mapIndex] == map.map[mapIndex - map.width]) continue;
    const pos = map.map[mapIndex];
    const { attrs } = ensure(table.nodeAt(pos));
    const index = attrs['colspan'] == 1 ? 0 : col - map.getColumnIndexFromPos(pos);
    if (attrs['colwidth'] && attrs['colwidth'][index] == width) continue;
    const colwidth = attrs['colwidth']
      ? (attrs['colwidth'] as number[]).slice()
      : zeroes(attrs['colspan']);
    colwidth[index] = width;
    tr.setNodeMarkup(start + pos, null, setAttr(attrs, 'colwidth', colwidth));
  }
  if (tr.docChanged) {
    view.dispatch(tr);
  }
}

function displayColumnWidth(view: EditorView, cell: number, width: number, cellMinWidth: number) {
  const $cell = view.state.doc.resolve(cell);
  const [table, start] = getTableFromCellPosition($cell);
  const col =
    TableMap.get(table).getColumnIndexFromPos($cell.pos - start) +
    $cell.nodeAfter?.attrs['colspan'] -
    1;
  let dom: Node = view.domAtPos($cell.start(-1)).node;
  while (dom.nodeName != 'TABLE') {
    dom = ensure(dom.parentNode);
  }
  const tr = view.state.tr;
  tr.setMeta('addToHistory', false);
  updateColumns(table, view, dom, TableMap.get(table), start, cellMinWidth, col, width, tr);
}

function zeroes(n: number) {
  const result: number[] = [];
  for (let i = 0; i < n; i++) result.push(0);
  return result;
}

function createDecorations(state: EditorState, cell: number) {
  const decorations: Decoration[] = [];
  const $cell = state.doc.resolve(cell);
  const [table, start] = getTableFromCellPosition($cell);
  const map = TableMap.get(table);
  const col =
    map.getColumnIndexFromPos($cell.pos - start) + ensure($cell.nodeAfter).attrs['colspan'];
  for (let row = 0; row < map.height; row++) {
    const index = col + row * map.width - 1;
    // For positions that are have either a different cell or the end
    // of the table to their right, and either the top of the table or
    // a different cell above them, add a decoration
    if (
      (col == map.width || map.map[index] != map.map[index + 1]) &&
      (row == 0 || map.map[index - 1] != map.map[index - 1 - map.width])
    ) {
      const cellPos = map.map[index];
      const pos = start + cellPos + ensure(table.nodeAt(cellPos)).nodeSize - 1;
      const dom = document.createElement('div');
      dom.className = 'column-resize-handle';
      decorations.push(Decoration.widget(pos, dom));
    }
  }
  return DecorationSet.create(state.doc, decorations);
}

export function updateColumns(
  node: ProseMirrorNode,
  view: EditorView,
  table: Node,
  map: TableMap,
  tableStart: number,
  cellMinWidth: number,
  overrideCol: number,
  overrideValue: number,
  tr: Transaction,
) {
  //   let totalWidth = 0;
  const rows = getRowsFromTable(node);

  for (let k = 0; k < rows.length; k++) {
    // totalWidth = 0;
    for (let i = 0; i < map.width; i++) {
      const colPos = map.map[k * map.width + i] + tableStart;
      const cellNode = ensure(node.nodeAt(colPos - tableStart));
      const { colwidth } = cellNode.attrs as { colspan: number; colwidth: number[] };
      const hasWidth = overrideCol == i ? overrideValue : colwidth?.[i];

      //   totalWidth += hasWidth || cellMinWidth;

      if (hasWidth) {
        tr.setNodeMarkup(colPos, null, setAttr(cellNode.attrs, 'colwidth', [hasWidth]));
      }
    }
  }
  const newAttr = setColWidthAttr(node.attrs, map.width, overrideCol, overrideValue);
  tr.setNodeMarkup(tableStart - 1, null, newAttr);
  tr.setMeta('forceUpdateFloaters', true);
  view.dispatch(tr);
}
