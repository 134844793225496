import debounce from 'lodash.debounce';

const PostHeightIframe = () => {
  let lastHeight = 0;
  const handleResize = () => {
    const documentHeight = document.documentElement.getBoundingClientRect();
    let height = documentHeight.height;
    const isDecimalNumber = height - Math.floor(height) !== 0;
    height = isDecimalNumber ? height + 1 : height;
    if (lastHeight === height) {
      return;
    }
    lastHeight = height;
    const event = {
      type: 'LOREE_WINDOW_SIZE',
      height: height,
      url: window.location.href,
    };
    const canvasWindow = window.parent;
    const url = document.referrer;
    if (url !== '' && document.referrer.indexOf(url) >= 0) {
      canvasWindow.postMessage(event, url);
    }
  };
  const resizeObserver = new ResizeObserver(
    debounce(handleResize, 100, { leading: true, trailing: true }),
  );
  resizeObserver.observe(document.body);
};

export default PostHeightIframe;
