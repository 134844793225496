export const sortElementComparision = (
  arg: { name: string },
  arg2: { name: string },
) => {
  const ProjectTitle1 = arg.name.toUpperCase().trim();
  const ProjectTitle2 = arg2.name.toUpperCase().trim();
  let data = 0;
  if (ProjectTitle1 > ProjectTitle2) {
    data = 1;
  } else if (ProjectTitle1 < ProjectTitle2) {
    data = -1;
  }
  return data;
};

export const getSortedDataCollection = (type: string, mediaCollection: any) => {
  const sortedData = [...mediaCollection.sort(sortElementComparision)];
  switch (type) {
    case 'Ascending':
      return sortedData;
    case 'Descending':
      return sortedData.reverse();
    case 'Recent':
      return mediaCollection.sort(
        (
          arg: { createdAt: string },
          arg2: { createdAt: string },
        ) => {
          const dateOfArg: number | Date = new Date(arg.createdAt);
          const dateOfArg2: number | Date = new Date(arg2.createdAt);
          return Number(dateOfArg2) - Number(dateOfArg);
        },
      );
    default:
      return mediaCollection;
  }
};
