/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import { moveNavigationLeft, moveNavigationRight } from './arrowFunction';
import '../../../assets/scss/tabComponent.scss';
import { getBorderStyle, getTabstyle } from '../../../middleware/getStyle';

const StyledTabs = styled(Tabs)<{
  separatorBorder?: string;
  activeTabColor?: string;
  inactivefontColor?: string;
  activefontColor?: string;
}>`
  li button {
    border-width: 0px !important;
    border-right: ${(props) => props?.separatorBorder} !important;
    border-radius: 0px !important;
    padding-left: 5px !important;
    color: ${(props) => props.inactivefontColor} !important;
  }
  li button.active {
    background-color: ${(props) => props.activeTabColor} !important;
    color: ${(props) => props.activefontColor} !important;
  }
  li:last-child .nav-link {
    border-right: unset !important;
  }
`;
const TabComponent = (props: any) => {
  const { parameters, customisations } = props;
  const borderStyle = getBorderStyle(customisations);
  const tabStyle = getTabstyle(customisations);
  const borderRightStyle = `${customisations.separatorWidth ?? '0'}px solid ${
    customisations.separatorColor ?? '#000000'
  }`;
  const activeTabColor = customisations.displayActiveColorPicker ?? '#ffffff';
  const activefontColor = customisations.fontColor ?? '#000000';
  const inactivefontColor = customisations.inactivefontColor ?? '#000000';
  const handleLeftArrow = () => {
    moveNavigationLeft();
  };

  const handleRightArrow = () => {
    moveNavigationRight();
  };

  return (
    <div
      className={`border m-2 mt-4 w-100 ${customisations.radius}`}
      role='button'
      tabIndex={0}
      aria-label='Tabs'
    >
      <div className='m-2 d-flex'>
        {parameters.length > 5 && (
          <button
            className='btn btn-link tab-control'
            tabIndex={0}
            aria-label='left'
            onClick={handleLeftArrow}
          >
            <i className='fa fa-caret-left arrow' />
          </button>
        )}
        <div
          className={`d-inline-block ${
            parameters.length > 5 ? 'custom-tab-container' : ' tab-container'
          }`}
        >
          <StyledTabs
            activeTabColor={activeTabColor}
            inactivefontColor={inactivefontColor}
            activefontColor={activefontColor}
            defaultActiveKey='0'
            className={`${customisations.fontHeadings} list-tab ${customisations.radius} li-tab ${
              parameters.length > 5 ? 'interactivetab' : ''
            }`}
            style={tabStyle}
            onKeyDown={(e) => {
              if ((e.key === 'ArrowLeft' || e.key === 'ArrowRight') && parameters.length > 5) {
                handleLeftArrow();
              } else if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                const activeTab = document.querySelector('.tab-pane.active');
                const qlEditorDiv = activeTab?.querySelector('.m-3.ql-editor') as HTMLElement;
                qlEditorDiv.focus();
              }
            }}
            separatorBorder={borderRightStyle}
          >
            {parameters?.map((list: { title: string; text: string }, index: number) => (
              <Tab
                id={`tab-${index}`}
                eventKey={`${index}`}
                title={<span title={list['title']}>{list['title']}</span>}
                key={`${index}`}
                className='tabcontent'
                style={borderStyle}
              >
                <div className='m-3 ql-editor' role='button' tabIndex={0}>
                  {ReactHtmlParser(list['text'])}
                </div>
              </Tab>
            ))}
          </StyledTabs>
        </div>
        {parameters.length > 5 && (
          <button
            className='btn btn-link tab-control'
            tabIndex={0}
            aria-label='right'
            onClick={handleRightArrow}
          >
            <i className='fa fa-caret-right arrow' />
          </button>
        )}
      </div>
    </div>
  );
};

export default TabComponent;
