import React from 'react';
import './mediaPreview.scss';
import ImagePreviewRightContainer from './images/imagePreview';
import IconPreviewRightContainer from './icons/iconPreview';

interface MediaPreviewInterface {
  selectedMediaDetails: {
    url: string;
    fileName: string;
    elementType: string;
    name: string;
    mediaType: string;
  };
  mediaType: string;
  handleConfirmState: Function;
  setDecorativeText: Function;
  setDescription: Function;
  imageType: string;
  handleSelectedElement: Function;
  fileNameDetails: { type?: string; isError?: boolean };
  setFileNameDetails: Function;
}

const MediaPreview = (props: MediaPreviewInterface) => {
  const {
    selectedMediaDetails,
    handleConfirmState,
    setDecorativeText,
    setDescription,
    imageType,
    handleSelectedElement,
    fileNameDetails,
    setFileNameDetails,
  } = props;

  return (
    <div
      id='lint-media-modal-preview'
      className='d-flex flex-column flex-md-row media-details-modal-wrapper p-0'
    >
      <div className='col-12 col-md-6 pe-2'>
        <div className='media-details-preview-wrapper'>
          <div className='d-flex media-preview-block justify-content-center align-items-center'>
            {selectedMediaDetails.mediaType === 'image' && (
              <img
                className='lint-image-preview'
                src={selectedMediaDetails.url}
                alt={selectedMediaDetails.fileName}
              />
            )}
            {selectedMediaDetails.mediaType === 'icon' && (
              <span className='material-icons'>{selectedMediaDetails.name}</span>
            )}
            {selectedMediaDetails.mediaType === 'video' && (
              <iframe src={selectedMediaDetails.url} title='Video content' />
            )}
          </div>
        </div>
      </div>
      <div className='col-12 col-md-6 ps-2'>
        <div className='media-details-specifications d-flex flex-column'>
          {selectedMediaDetails.mediaType === 'image' && (
            <ImagePreviewRightContainer
              selectedMediaDetails={selectedMediaDetails}
              handleConfirmState={handleConfirmState}
              setDecorativeText={setDecorativeText}
              imageType={imageType}
              setSelectedMediaDetails={handleSelectedElement}
              fileNameDetails={fileNameDetails}
              setFileNameDetails={setFileNameDetails}
            />
          )}
          {selectedMediaDetails.mediaType === 'icon' && (
            <IconPreviewRightContainer
              selectedMediaDetails={selectedMediaDetails}
              handleConfirmState={handleConfirmState}
              setDescription={setDescription}
            />
          )}
          {selectedMediaDetails.mediaType === 'video' && (
            <div className='d-flex flex-column'>
              <label className='pb-1 mb-0' htmlFor='videoTitle'>
                Title name*
              </label>
              <input
                id='videoTitle'
                type='text'
                autoComplete='off'
                className='p-1'
                onChange={(event) => {
                  const value = event.target.value;
                  const confirmButtonState = value.trim() === '';
                  handleConfirmState(confirmButtonState);
                  setDescription(value);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaPreview;
