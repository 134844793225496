import React from "react";
import { Card, useAccordionButton } from "react-bootstrap";

//Customisation functionality for plus/minus else up-arrow/down-arrow icon
export const changeIcon = (targetElement, icon) => {
  if (icon === "plus/minus")
    changeParentNoddeIcon(targetElement, "icon-minus", "icon-plus");
  else
    changeParentNoddeIcon(targetElement, "icon-down-arrow", "icon-right-arrow");
};

export const getBorderStyle = (customisations) => {
  const { borderType, borderSize, borderStyle, borderColor } = customisations;
  const borderValue = `${borderSize}px  ${borderStyle} ${borderColor}`;
  let border;
  switch (true) {
  case borderType === "border-left border-dark":
    border = { borderLeft: borderValue };
    break;
  case borderType === "border-right border-dark":
    border = { borderRight: borderValue };
    break;
  case borderType === "border-top border-dark":
    border = { borderTop: borderValue };
    break;
  case borderType === "border-bottom border-dark":
    border = { borderBottom: borderValue };
    break;
  case borderType === "border border-dark":
    border = { border: borderValue };
    break;
  default:
    border = null;
  }
  return border;
};

const changeParentNoddeIcon = (targetElement, prevIcon, nextIcon) => {
  const accordionCollection = document.getElementsByClassName("card-body");
  for (const accordionItem of accordionCollection) {
    accordionItem.className = accordionItem.className.replace(
      prevIcon,
      nextIcon
    );
  }
  changeTargetNodeIcon(targetElement, prevIcon, nextIcon);
};

const changeTargetNodeIcon = (targetElement, prevIcon, nextIcon) => {
  const collapsed =
    targetElement.parentNode.nextSibling.classList.contains("show");

  switch (true) {
  case !collapsed:
    targetElement.className = targetElement.className.replace(
      nextIcon,
      prevIcon
    );
    break;
  case collapsed:
    targetElement.className = targetElement.className.replace(
      prevIcon,
      nextIcon
    );
    break;
  default:
    break;
  }
};

export function CustomToggle({
  children,
  eventKey,
  onClick,
  className,
  style,
  headerStyle,
  borderRadius,
}) {
  const decoratedOnClick = useAccordionButton(eventKey, (event) =>
    onClick(event)
  );

  const fontheadings = headerStyle ? headerStyle : "div";

  const handleKeyUpEvent = (event) => {
    if (event.key !== "Enter" && event.key !== " ") return;
    decoratedOnClick(event);
  };

  return (
    <Card.Header
      as={fontheadings}
      role="button"
      tabIndex="0"
      onKeyUp={(event) => handleKeyUpEvent(event)}
      onClick={decoratedOnClick}
      className={`${className} ${borderRadius}`}
      style={style}
    >
      {children}
    </Card.Header>
  );
}
