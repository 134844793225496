import { mergeAttributes, Node } from '@tiptap/core';
import { filterSpans, optimizeStyle, simpleElementNodeView } from './utilityFunctions';

export interface TableCellOptions {
  HTMLAttributes: Record<string, never>;
}

export const CustomizedTableCell = Node.create<TableCellOptions>({
  name: 'tableCell',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  selectable: true,
  atom: true,
  content: 'block*',

  addAttributes() {
    return {
      colspan: {
        default: 1,
      },
      rowspan: {
        default: 1,
      },
      colwidth: {
        default: null,
        parseHTML: (element) => {
          const colwidth = element.getAttribute('colwidth');
          return colwidth ? [parseInt(colwidth, 10)] : null;
        },
        /*eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        renderHTML: (attr: Record<string, any>) => {
          if (!attr['colwidth'] || attr['colspan'] != 1) {
            return null;
          }
          return {
            style: `width: ${attr['colwidth'] as number}px`,
            colwidth: `${attr['colwidth'] as string}`,
          };
        },
      },
      headers: {},
      style: { default: null },
      scope: { default: null },
    };
  },

  tableRole: 'cell',

  isolating: true,

  parseHTML() {
    return [{ tag: 'td' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'td',
      optimizeStyle(filterSpans(mergeAttributes(this.options.HTMLAttributes, HTMLAttributes))),
      0,
    ];
  },

  addNodeView() {
    return simpleElementNodeView('td');
  },
});
