/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form, FormControl, Button } from 'react-bootstrap';
import { ReactComponent as AddSectionIcon } from '../../assets/icons/addSection.svg';
import SectionComponent from './sectionComponent';
import ButtonFormComponent from './button/form';
import { getContentArrayValue, getParamsValue, setFocus } from './utils';
import { LoreeInteractiveEditorDashboardContentType } from '../editor/editorUtilityFunctions/lintEditorType';

interface ComponentProps {
  handleTitleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  alreadyExist: string | null;
  titleValue: string;
  errors: any;
  contentArr: any[];
  addPanelHandel: any;
  parameter: any;
  number: any;
  type: string;
  handleChange: any;
  handleTitle: any;
  id: string;
  editContentData: any;
  pathName: string;
  optionId: number;
  dndLayout: string;
  editorConfig: LoreeInteractiveEditorDashboardContentType;
}

const ComponentBody = (props: ComponentProps) => {
  const {
    handleTitleChange,
    alreadyExist,
    titleValue,
    errors,
    contentArr,
    addPanelHandel,
    parameter,
    number,
    type,
    handleChange,
    handleTitle,
    editContentData,
    id,
    pathName,
    optionId,
    dndLayout,
    editorConfig,
  } = props;

  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    if (!isRendered) {
      setFocus('input-form');
      setIsRendered(true);
    }
  }, [isRendered]);

  const addpanel = () => {
    const params = getParamsValue(type, dndLayout);
    const contentValue = getContentArrayValue(type, dndLayout, number, optionId);
    addPanelHandel({
      parameters: [...parameter, params],
      contentArr: [...contentArr, contentValue],
      num: number + 1,
      option_id: optionId + 2,
    });
    setFocus(`collapse${parameter.length + 1}`);
  };

  return (
    <div className='p-2 pb-4 form-body-wrapper' id='form-wrapper'>
      {type === 'Button' && (
        <ButtonFormComponent
          formError={errors}
          id={id}
          editContent={editContentData}
          pathName={pathName}
        />
      )}
      {type !== 'Button' && (
        <>
          <Form.Group id='input-form'>
            <Form.Label className='title'>Interactive Title *</Form.Label>
            <FormControl
              className='lint-custom-input'
              name='title'
              id='title-input'
              onChange={handleTitleChange}
              value={titleValue}
              autoComplete='off'
            />
            {alreadyExist && <Form.Text className='text-danger'>{alreadyExist}</Form.Text>}
            {errors.title && <Form.Text className='text-danger'>{errors.title}</Form.Text>}
          </Form.Group>
          <SectionComponent
            errors={errors}
            number={number}
            type={type}
            handleChange={handleChange}
            handleTitle={handleTitle}
            editorConfig={editorConfig}
          />
          {contentArr.length < 100 && (
            <div key='addPanel' className='addpanel-div position-relative my-5'>
              <Button
                className='mt-3 addpanel-button position-absolute'
                onClick={addpanel}
                size='sm'
                id='Add section'
              >
                Add Section
                <AddSectionIcon />
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ComponentBody;
