import { DragEvent } from 'react';

export interface CustomInterface {
  btnBgColor?: string;
  btnFontColor?: string;
  bgcolor?: string;
  fontSize?: string;
  fontFamily?: string;
  fontColor?: string;
  paddingTop?: string;
  paddingLeft?: string;
  paddingBottom?: string;
  paddingRight?: string;
  btnFontSize?: string;
  btnFontFamily?: string;
  btnPaddingTop?: string;
  btnPaddingLeft?: string;
  btnPaddingBottom?: string;
  btnPaddingRight?: string;
}

export const onDragStart = (event: DragEvent) => {
  const targetElement = event.target as HTMLElement;
  event.dataTransfer.setData('text', targetElement.id);
};

export const onDragOver = (event: DragEvent) => {
  event.preventDefault();
};

export const onDragEnter = (event: DragEvent) => {
  const targetElement = event.target as HTMLInputElement;
  if (targetElement.type !== 'button') targetElement.style.border = '3px dashed grey';
};

export const onDragLeave = (event: DragEvent) => {
  const targetElement = event.target as HTMLElement;
  targetElement.style.border = '';
};

export const onDrop = (event: DragEvent) => {
  event.preventDefault();
  const target = event.target;
  const btnsHolder = document.getElementById('buttonsHolder');
  if (btnsHolder) btnsHolder.style.border = '';
  const data = event.dataTransfer.getData('text');
  const element = document.getElementById(data);
  insertElementInDropZone(event, element as HTMLElement, target);
  removeExistingElement(element as HTMLElement, target);
};

export const insertIcon = (component: HTMLElement | null, dndLayout: string) => {
  const innerText = component?.textContent;
  const childNode = component?.children[0] as HTMLElement;
  let correctAnswer;
  if (innerText === component?.id) {
    correctAnswer = innerText;
    let icon = `<i class="fas fa-check ${
      dndLayout === 'Fixed' ? 'fixed-tick-circle' : 'overlay-tick-circle'
    }"></i>`;
    childNode.style.background = '#9dd8bb';
    childNode.innerHTML += icon;
  } else {
    let icon = `<i class="fas fa-times ${
      dndLayout === 'Fixed' ? 'fixed-cross-circle' : 'overlay-cross-circle'
    }"></i>`;
    childNode.style.background = '#f7d0d0';
    childNode.innerHTML += icon;
  }
  return correctAnswer;
};

const insertElementInDropZone = (event: DragEvent, element: HTMLElement, target: EventTarget) => {
  const parentNodeId = (target as HTMLElement)?.parentNode as HTMLElement;
  if (
    element &&
    target !== element &&
    (target as HTMLInputElement).type !== 'button' &&
    parentNodeId?.id !== 'buttonsHolder'
  ) {
    if ((target as HTMLElement).id !== 'buttonsHolder') {
      element.classList.add('buttonTextWrap');
      element.title = element.innerText;
    } else {
      element.classList.remove('buttonTextWrap');
      element.title = '';
      (event.target as HTMLElement).style.border = '';
    }
    (target as HTMLElement).appendChild(element);
  } else if (element && target !== element && parentNodeId.id !== 'buttonsHolder') {
    element.classList.add('buttonTextWrap');
    parentNodeId.appendChild(element);
  }
};

const removeExistingElement = (element: HTMLElement, target: EventTarget) => {
  const btnsHolder = document.getElementById('buttonsHolder');
  const parentNode = (target as HTMLElement).parentNode as HTMLElement;
  let currentBtn: HTMLElement;
  if (
    element &&
    (target as HTMLInputElement).type === 'button' &&
    parentNode?.id !== 'buttonsHolder' &&
    parentNode?.children?.length > 0 &&
    target !== element
  ) {
    currentBtn = parentNode?.children[0] as HTMLElement;
    currentBtn.classList.remove('buttonTextWrap');
    currentBtn.title = '';
    btnsHolder?.appendChild(currentBtn);
  }
  (document.activeElement as HTMLElement).blur();
};

export const hoverOn = (element: HTMLElement, customisations: CustomInterface) => {
  const { btnBgColor, btnFontColor } = customisations;
  element.style.background = btnBgColor as string;
  element.style.color = btnFontColor as string;
};

//mouse leave from button
export const hoverOff = (element: HTMLElement) => {
  if (element.tagName !== 'BUTTON') {
    element = element.parentElement as HTMLElement;
  }
  element.style.background = 'white';
  element.style.color = '#000000';
};

export const getDragAreaStyle = (content: CustomInterface) => {
  const style = {
    background: `${content.bgcolor}`,
    fontSize: `${content.fontSize}px`,
    border: `2px solid ${content.bgcolor}`,
    fontFamily: `${content.fontFamily}`,
    color: `${content.fontColor}`,
    paddingTop: content.paddingTop === '' ? 0 : `${content.paddingTop}px`,
    paddingLeft: content.paddingLeft === '' ? 0 : `${content.paddingLeft}px`,
    paddingBottom: content.paddingBottom === '' ? 0 : `${content.paddingBottom}px`,
    paddingRight: content.paddingRight === '' ? 0 : `${content.paddingRight}px`,
  };
  return style;
};

export const getDragAreaHoverStyle = (content: CustomInterface) => {
  const style = {
    background: 'white',
    fontSize: `${content.fontSize}px`,
    border: ` 2px solid ${content.bgcolor}`,
    fontFamily: `${content.fontFamily}`,
    color: '#000000',
    paddingTop: content.paddingTop === '' ? 0 : `${content.paddingTop}px`,
    paddingLeft: content.paddingLeft === '' ? 0 : `${content.paddingLeft}px`,
    paddingBottom: content.paddingBottom === '' ? 0 : `${content.paddingBottom}px`,
    paddingRight: content.paddingRight === '' ? 0 : `${content.paddingRight}px`,
  };
  return style;
};

export const getBtnStyle = (content: CustomInterface) => {
  const style = {
    background: content.btnBgColor ? `${content.btnBgColor}` : '#1D77CC',
    fontSize: content.btnFontSize ? `${content.btnFontSize}px` : '16px',
    border: content.btnBgColor ? `2px solid ${content.btnBgColor}` : '2px solid #1D77CC',
    fontFamily: content.btnFontFamily ? `${content.btnFontFamily}` : 'Helvetica',
    color: content.btnFontColor ? `${content.btnFontColor}` : '#ffffff',
    paddingTop:
      content.btnPaddingTop === ''
        ? 0
        : content.btnPaddingTop
        ? `${content.btnPaddingTop}px`
        : '10px',
    paddingLeft:
      content.btnPaddingLeft === ''
        ? 0
        : content.btnPaddingLeft
        ? `${content.btnPaddingLeft}px`
        : '10px',
    paddingBottom:
      content.btnPaddingBottom === ''
        ? 0
        : content.btnPaddingBottom
        ? `${content.btnPaddingBottom}px`
        : '10px',
    paddingRight:
      content.btnPaddingRight === ''
        ? 0
        : content.btnPaddingRight
        ? `${content.btnPaddingRight}px`
        : '10px',
  };

  return style;
};

export const getBtnHoverStyle = (content: CustomInterface) => {
  const style = {
    background: 'white',
    fontSize: content.btnFontSize ? `${content.btnFontSize}px` : '16px',
    border: content.btnBgColor ? `2px solid ${content.btnBgColor}` : '2px solid #1D77CC',
    fontFamily: content.btnFontFamily ? `${content.btnFontFamily}` : 'Helvetica',
    color: '#000000',
    paddingTop:
      content.btnPaddingTop === ''
        ? 0
        : content.btnPaddingTop
        ? `${content.btnPaddingTop}px`
        : '10px',
    paddingLeft:
      content.btnPaddingLeft === ''
        ? 0
        : content.btnPaddingLeft
        ? `${content.btnPaddingLeft}px`
        : '10px',
    paddingBottom:
      content.btnPaddingBottom === ''
        ? 0
        : content.btnPaddingBottom
        ? `${content.btnPaddingBottom}px`
        : '10px',
    paddingRight:
      content.btnPaddingRight === ''
        ? 0
        : content.btnPaddingRight
        ? `${content.btnPaddingRight}px`
        : '10px',
  };
  return style;
};
