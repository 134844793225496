// Because working with row and column-spanning cells is not quite
// trivial, this code builds up a descriptive structure for a given
// table node. The structures are cached with the (persistent) table
// nodes as key, so that they only have to be recomputed when the
// content of the table changes.
//
// This does mean that they have to store table-relative, not
// document-relative positions. So code that uses them will typically
// compute the start position of the table and offset positions passed
// to or gotten from this structure by that amount.
import { Node as ProseMirrorNode, Attrs } from 'prosemirror-model';
import { getRowsFromTable } from './util';

let readFromCache: (k: ProseMirrorNode) => TableMap | undefined;
let addToCache: (k: ProseMirrorNode, v: TableMap) => TableMap;
// Prefer using a weak map to cache table maps. Fall back on a
// fixed-size cache if that's not supported.
if (typeof WeakMap != 'undefined') {
  // eslint-disable-next-line
  let cache = new WeakMap<ProseMirrorNode, TableMap>();
  readFromCache = (key: ProseMirrorNode) => cache.get(key);
  addToCache = (key: ProseMirrorNode, value: TableMap) => {
    cache.set(key, value);
    return value;
  };
} else {
  const cache: (TableMap | ProseMirrorNode)[] = [];
  const cacheSize = 10;
  let cachePos = 0;
  readFromCache = (key: ProseMirrorNode) => {
    for (let i = 0; i < cache.length; i += 2) if (cache[i] == key) return cache[i + 1] as TableMap;
    return undefined;
  };
  addToCache = (key: ProseMirrorNode, value: TableMap) => {
    if (cachePos == cacheSize) cachePos = 0;
    cache[cachePos++] = key;
    return (cache[cachePos++] = value);
  };
}

export class Rect {
  left: number;
  top: number;
  right: number;
  bottom: number;

  constructor(left: number, top: number, right: number, bottom: number) {
    this.left = left;
    this.top = top;
    this.right = right;
    this.bottom = bottom;
  }
}

interface TableMapProblem {
  type: 'missing' | 'colwidth mismatch' | 'collision' | 'overlong_rowspan';
  row: number;
  n: number;
  pos: number;
  colwidth: number[] | number;
}

// ::- A table map describes the structure of a given table. To avoid
// recomputing them all the time, they are cached per table node. To
// be able to do that, positions saved in the map are relative to the
// start of the table, rather than the start of the document.
export class TableMap {
  map: number[];
  height: number;
  width: number;
  problems: TableMapProblem[] | null;

  constructor(width: number, height: number, map: number[], problems: TableMapProblem[] | null) {
    // :: number The width of the table
    this.width = width;
    // :: number The table's height
    this.height = height;
    // :: [number] A width * height array with the start position of
    // the cell covering that part of the table in each slot
    this.map = map;
    // An optional array of problems (cell overlap or non-rectangular
    // shape) for the table, used by the table normalizer.
    this.problems = problems;
  }

  // :: (number) → Rect
  // Find the dimensions of the cell at the given position.
  findCell(pos: number): Rect {
    for (let i = 0; i < this.map.length; i++) {
      const curPos = this.map[i];
      if (curPos != pos) continue;
      const left = i % this.width;
      const top = (i / this.width) | 0;

      let right = left + 1;
      let bottom = top + 1;
      for (let j = 1; right < this.width && this.map[i + j] == curPos; j++) right++;
      for (let j = 1; bottom < this.height && this.map[i + this.width * j] == curPos; j++) bottom++;
      return new Rect(left, top, right, bottom);
    }
    throw new RangeError('No cell with offset ' + pos + ' found');
  }

  // :: (number) → number
  // Find the left side of the cell at the given position.
  getColumnIndexFromPos(pos: number): number {
    for (let i = 0; i < this.map.length; i++) if (this.map[i] == pos) return i % this.width;
    throw new RangeError('No cell with offset ' + pos + ' found');
  }

  // :: (number, string, number) → ?number
  // Find the next cell in the given direction, starting from the cell
  // at `pos`, if any.
  nextCell(pos: number, axis: 'horiz' | 'vert', dir: number) {
    const { left, right, top, bottom } = this.findCell(pos);
    if (axis == 'horiz') {
      if (dir < 0 ? left == 0 : right == this.width) return null;
      return this.map[top * this.width + (dir < 0 ? left - 1 : right)];
    } else {
      if (dir < 0 ? top == 0 : bottom == this.height) return null;
      return this.map[left + this.width * (dir < 0 ? top - 1 : bottom)];
    }
  }

  // :: (number, number) → Rect
  // Get the rectangle spanning the two given cells.
  rectBetween(a: number, b: number): Rect {
    const { left: leftA, right: rightA, top: topA, bottom: bottomA } = this.findCell(a);
    const { left: leftB, right: rightB, top: topB, bottom: bottomB } = this.findCell(b);
    return new Rect(
      Math.min(leftA, leftB),
      Math.min(topA, topB),
      Math.max(rightA, rightB),
      Math.max(bottomA, bottomB),
    );
  }

  // :: (Rect) → [number]
  // Return the position of all cells that have the top left corner in
  // the given rectangle.
  cellsInRect(rect: Rect): number[] {
    const result = [];
    const seen: Record<number, boolean> = {};
    for (let row = rect.top; row < rect.bottom; row++) {
      for (let col = rect.left; col < rect.right; col++) {
        const index = row * this.width + col;
        const pos = this.map[index];
        if (seen[pos]) continue;
        seen[pos] = true;
        if (
          (col != rect.left || !col || this.map[index - 1] != pos) &&
          (row != rect.top || !row || this.map[index - this.width] != pos)
        )
          result.push(pos);
      }
    }
    return result;
  }

  // :: (number, number, Node) → number
  // Return the position at which the cell at the given row and column
  // starts, or would start, if a cell started there.
  positionAt(row: number, col: number, table: ProseMirrorNode) {
    const rows = getRowsFromTable(table);
    let index = col + row * this.width;
    const rowEndIndex = (row + 1) * this.width;
    const rowStart = rows[row][1];
    const rowEnd = rowStart + rows[row][0].nodeSize;
    while (index < rowEndIndex && this.map[index] < rowStart) index++;
    return index == rowEndIndex ? rowEnd - 1 : this.map[index];
  }

  // :: (Node) → TableMap
  // Find the table map for the given table node.
  static get(table: ProseMirrorNode): TableMap {
    return readFromCache(table) || addToCache(table, computeMap(table));
  }
}

// Compute a table map.
// eslint-disable-next-line complexity,max-statements
function computeMap(table: ProseMirrorNode) {
  if (table.type.spec['tableRole'] != 'table')
    throw new RangeError('Not a table node: ' + table.type.name);
  const rows = getRowsFromTable(table);
  const width = findWidth(rows);
  const height = rows.length;
  const map: number[] = [];
  let mapPos = 0;
  let problems: TableMapProblem[] | null = null;
  const colWidths = [];
  for (let i = 0, e = width * height; i < e; i++) map[i] = 0;

  for (let row = 0, pos = 0; row < height; row++) {
    const rowNode = rows[row][0];
    pos = rows[row][1] + 1;
    for (let i = 0; ; i++) {
      while (mapPos < map.length && map[mapPos] != 0) mapPos++;
      if (i == rowNode.childCount) break;
      const cellNode = rowNode.child(i);
      const { colspan, rowspan, colwidth } = cellNode.attrs as {
        colspan: number;
        rowspan: number;
        colwidth: number[];
      };
      for (let h = 0; h < rowspan; h++) {
        if (h + row >= height) {
          (problems || (problems = [])).push({
            type: 'overlong_rowspan',
            pos,
            n: rowspan - h,
            row: 0,
            colwidth: 0,
          });
          break;
        }
        const start = mapPos + h * width;
        for (let w = 0; w < colspan; w++) {
          if (map[start + w] == 0) map[start + w] = pos;
          else
            (problems || (problems = [])).push({
              type: 'collision',
              row,
              pos,
              n: colspan - w,
              colwidth: 0,
            });
          const colW = colwidth?.[w];
          if (colW) {
            const widthIndex = ((start + w) % width) * 2;
            const prev: number = colWidths[widthIndex];
            if (prev == null || (prev != colW && colWidths[widthIndex + 1] == 1)) {
              colWidths[widthIndex] = colW;
              colWidths[widthIndex + 1] = 1;
            } else if (prev == colW) {
              colWidths[widthIndex + 1]++;
            }
          }
        }
      }
      mapPos += colspan;
      pos += cellNode.nodeSize;
    }
    const expectedPos = (row + 1) * width;
    let missing = 0;
    while (mapPos < expectedPos) if (map[mapPos++] == 0) missing++;
    if (missing)
      (problems || (problems = [])).push({ type: 'missing', row, n: missing, pos: 0, colwidth: 0 });
    pos++;
  }

  const tableMap = new TableMap(width, height, map, problems);
  let badWidths = false;

  // For columns that have defined widths, but whose widths disagree
  // between rows, fix up the cells whose width doesn't match the
  // computed one.
  for (let i = 0; !badWidths && i < colWidths.length; i += 2)
    if (colWidths[i] != null && colWidths[i + 1] < height) badWidths = true;
  if (badWidths) findBadColWidths(tableMap, colWidths, table);

  return tableMap;
}

function findWidth(rows: [ProseMirrorNode, number][]) {
  let width = -1;
  let hasRowSpan = false;
  for (let row = 0; row < rows.length; row++) {
    const rowNode = rows[row][0];
    let rowWidth = 0;
    if (hasRowSpan) {
      for (let j = 0; j < row; j++) {
        const prevRow = rows[j][0];
        for (let i = 0; i < prevRow.childCount; i++) {
          const cell = prevRow.child(i);
          if (j + cell.attrs['rowspan'] > row) rowWidth += cell.attrs['colspan'];
        }
      }
    }
    for (let i = 0; i < rowNode.childCount; i++) {
      const cell = rowNode.child(i);
      rowWidth += cell.attrs['colspan'];
      if (cell.attrs['rowspan'] > 1) hasRowSpan = true;
    }
    if (width == -1) width = rowWidth;
    else if (width != rowWidth) width = Math.max(width, rowWidth);
  }
  return width;
}

function findBadColWidths(map: TableMap, colWidths: number[], table: ProseMirrorNode) {
  if (!map.problems) {
    map.problems = [];
  }

  for (let i = 0, seen: Record<number, boolean> = {}; i < map.map.length; i++) {
    const cellPos = map.map[i];
    if (seen[cellPos]) {
      continue;
    }

    seen[cellPos] = true;
    const cellNode = table.nodeAt(cellPos);
    let updated: number[] | null = null;
    if (!cellNode) {
      return;
    }

    for (let j = 0; j < cellNode.attrs['colspan']; j++) {
      const colIndex = (i + j) % map.width;
      const colWidth = colWidths[colIndex * 2];
      if (
        colWidth != null &&
        (!cellNode.attrs['colwidth'] || cellNode.attrs['colwidth'][j] != colWidth)
      )
        (updated || (updated = freshColWidth(cellNode.attrs)))[j] = colWidth;
    }
    if (updated)
      map.problems.unshift({
        type: 'colwidth mismatch',
        pos: cellPos,
        colwidth: updated,
        n: 0,
        row: 0,
      });
  }
}

function freshColWidth(attrs: Attrs): number[] {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  if (attrs['colwidth']) {
    return (attrs['colwidth'] as number[]).slice();
  }
  const result: number[] = [];
  for (let i = 0; i < attrs['colspan']; i++) {
    result.push(0);
  }
  return result;
}
