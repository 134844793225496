/* eslint-disable @typescript-eslint/no-explicit-any */
import { mergeAttributes, Node } from '@tiptap/core';
import { optimizeStyle, simpleElementNodeView } from './utilityFunctions';

export interface VideoTagOptions {
  inline: boolean;
  allowBase64: boolean;
  HTMLAttributes: Record<string, any>;
}

export const CustomizedVideoElement = Node.create<VideoTagOptions>({
  name: 'videoTag',

  addOptions() {
    return {
      inline: false,
      allowBase64: false,
      HTMLAttributes: {},
    };
  },

  group: 'block',
  draggable: false,
  selectable: true,
  atom: true,
  content: 'block*',

  addAttributes() {
    return {
      src: {
        parseHTML: (element) => element.getAttribute('src'),
      },
      id: {
        parseHTML: (element) => element.getAttribute('id'),
      },
      ariaHidden: {
        parseHTML: (element) => element.getAttribute('aria-hidden'),
      },
      width: {
        parseHTML: (element) => element.getAttribute('width'),
      },
      height: {
        parseHTML: (element) => element.getAttribute('height'),
      },
      ariaLabel: {
        parseHTML: (element) => element.getAttribute('aria-label'),
      },
      controls: {
        parseHTML: (element) => element.getAttribute('controls'),
      },
      poster: {
        parseHTML: (element) => element.getAttribute('poster'),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'video',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'video',
      optimizeStyle(mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)),
      0,
    ];
  },

  addNodeView() {
    return simpleElementNodeView('video');
  },
});
