export const allMathSymbolsByChar: Record<string, string> = {
  '&#xA0;': '\\textrm{ }',
  '&#x2203;': '\\exists',
  '&#x2200;': '\\forall',
  '&#x21D4;': '\\iff',
  '&#x21D2;': '=>',
  '&#xAC;': '\\neg',
  '&#x2124;': '\\mathbb{Z}',
  '&#x211D;': '\\mathbb{R}',
  '&#x211A;': '\\mathbb{Q}',
  '&#x2115;': '\\mathbb{N}',
  '&#x2102;': 'CC',
  '&#x25A1;': '\\square',
  '&#x22C4;': '\\diamond',
  '&#x25B3;': '\\triangle',
  '&#x2322;': '\\frown',
  '&#x2220;': '\\angle',
  '&#x22F1;': '\\ddots',
  '&#x22EE;': '\\vdots',
  '&#x2235;': '\\because',
  '&#x2234;': '\\therefore',
  '&#x2135;': '\\aleph',
  '&#x2205;': '\\oslash',
  '&#xB1;': '\\pm',
  '&#x2207;': '\\nabla',
  '&#x2202;': '\\partial',
  '&#x222E;': '\\oint',
  '&#x222B;': '\\int',
  '&#x22C3;': '\\cup',
  '&#x222A;': '\\cup',
  '&#x22C2;': '\\cap',
  '&#x2229;': '\\cap',
  '&#x22C1;': '\\vee',
  '&#x2228;': '\\vee',
  '&#x22C0;': '\\wedge',
  '&#x2227;': '\\wedge',
  '&#x220F;': '\\prod',
  '&#x2211;': '\\sum',
  '&#x2299;': '\\bigodot',
  '&#x2297;': '\\bigoplus',
  '&#x2295;': 'o+',
  '&#x2218;': '@',
  '&#x22C8;': '\\bowtie',
  '&#x22CA;': '\\rtimes',
  '&#x22C9;': '\\ltimes',
  '&#xF7;': '\\div',
  '&#xD7;': '\\times',
  '\\': '\\backslash',
  '&#x22C6;': '\\star',
  '&#x2217;': '\\star',
  '&#x22C5;': '\\cdot',
  '&#x3A9;': '\\Omega',
  '&#x3C9;': '\\omega',
  '&#x3A8;': '\\Psi',
  '&#x3C8;': '\\psi',
  '&#x3C7;': '\\chi',
  '&#x3C6;': '\\varphi',
  '&#x3A6;': '\\Phi',
  '&#x3D5;': '\\phi',
  '&#x3C5;': '\\upsilon',
  '&#x3C4;': '\\tau',
  '&#x3A3;': '\\Sigma',
  '&#x3C3;': '\\sigma',
  '&#x3C1;': '\\rho',
  '&#x3A0;': '\\Pi',
  '&#x3C0;': '\\pi',
  '&#x39E;': '\\Xi',
  '&#x3BE;': '\\xi',
  '&#x3BD;': '\\nu',
  '&#x3BC;': '\\mu',
  '&#x39B;': '\\Lambda',
  '&#x3BB;': '\\lambda',
  '&#x3BA;': '\\kappa',
  '&#x3B9;': '\\iota',
  '&#x3D1;': '\\vartheta',
  '&#x398;': '\\Theta',
  '&#x3B8;': '\\theta',
  '&#x3B7;': '\\eta',
  '&#x3B6;': '\\zeta',
  '&#x25B;': '\\varepsilon',
  '&#x3B5;': '\\epsilon',
  '&#x394;': '\\Delta',
  '&#x3B4;': '\\delta',
  '&#x393;': '\\Gamma',
  '&#x3B3;': '\\gamma',
  '&#x3B2;': '\\beta',
  '&#x3B1;': '\\alpha',
  '&#x221E;': '\\infty',
  '\u202c': '\\text{\\textdir TRT}',
  '\u200e': '\\text{\\textdir LTR}',
};
