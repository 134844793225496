import { Attrs, Node as ProseMirrorNode } from 'prosemirror-model';
import { EditorState, Transaction } from 'prosemirror-state';
import { CellSelection } from '../editorExtensions/tableElementContent/cellSelection';

function removeAndUpdateMark(
  tr: Transaction,
  node: ProseMirrorNode,
  pos: number,
  attribute?: string,
  style?: Record<string, string>,
) {
  if (node.marks.length > 0) {
    ['textStyle', 'link'].forEach((markStyle) => {
      const textStyleMark = node.marks.find((mark) => mark.type.name === markStyle);
      if (!textStyleMark?.attrs) return;
      const updateAttr: Record<string, null | string> = {};
      if (attribute !== undefined) {
        updateAttr[attribute] = null;
        if (textStyleMark.type.name === 'link' && style && style[attribute] !== undefined) {
          updateAttr[attribute] = style[attribute];
        }
      }
      tr.removeMark(pos, pos + node.nodeSize, textStyleMark?.type);
      const newMark = textStyleMark.type.create({
        ...textStyleMark.attrs,
        ...updateAttr,
      });
      tr.addMark(pos, pos + node.nodeSize, newMark);
    });
  }
}

function updateNodeAttributesForRange(
  state: EditorState,
  from: number,
  to: number,
  typeNames: string | string[],
  nodes: ProseMirrorNode[],
  tr: Transaction,
  attribute?: string,
  style?: Record<string, string>,
  attrs?: Attrs,
) {
  state.doc.nodesBetween(from, to, (node, pos) => {
    const parentElement = state.doc.resolve(pos)?.parent;
    if (typeNames.includes(node.type.name) && parentElement?.type?.name !== 'iconTag') {
      nodes.push(node);
      if (node.type.name === 'text') {
        removeAndUpdateMark(tr, node, pos, attribute, style);
      } else {
        const dom = document.createElement('div');
        dom.style.cssText = node.attrs['style'];
        Object.assign(dom.style, style);
        tr.setNodeMarkup(pos, null, { ...node.attrs, ...{ style: dom.style.cssText }, ...attrs });
        dom.remove();
      }
    }
    return true;
  });
}

export function updateNodeStylesInRange(
  typeNames: string[],
  style: Record<string, string>,
  attribute: string,
) {
  return (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    const nodes: ProseMirrorNode[] = [];
    const tr = state.tr;
    const sel = state.selection;
    if (sel instanceof CellSelection) {
      sel.forEachCell((cell, pos) => {
        const from = pos;
        const to = pos + cell.nodeSize - 1;
        updateNodeAttributesForRange(state, from, to, typeNames, nodes, tr, attribute, style);
      });
    } else {
      const { $from } = sel;
      const depth = $from.depth;
      const from = sel?.$from?.before(depth);
      const to = sel?.to;
      updateNodeAttributesForRange(state, from, to, typeNames, nodes, tr, attribute, style);
    }
    if (nodes.length < 1) {
      return false;
    }
    if (dispatch) {
      dispatch(tr);
    }
    return true;
  };
}
