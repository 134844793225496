import { Placement } from 'popper.js';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import styled, { StyleSheetManager } from 'styled-components';

const PopperDiv = styled.div<{ zIndex?: number }>`
  z-index: ${(props) => props.zIndex ?? 4};

  .floater-arrow,
  .floater-arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 0;
    background: inherit;
    border: inherit;
    transform: rotate(22.5deg);
  }
  .floater-arrow {
    visibility: hidden;
  }
  .floater-arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
  &[data-popper-placement^='bottom'] > .floater-arrow::before {
    border-right: none;
    border-bottom: none;
  }
  &[data-popper-placement^='right'] > .floater-arrow::before {
    border-right: none;
    border-top: none;
  }
  &[data-popper-placement^='left'] > .floater-arrow::before {
    border-left: none;
    border-bottom: none;
  }
  &[data-popper-placement^='top'] > .floater-arrow::before {
    border-right: none;
    border-bottom: none;
  }
  &[data-popper-placement^='top'] > .floater-arrow {
    top: -6px;
  }
  &[data-popper-placement^='bottom'] > .floater-arrow {
    top: -6px;
  }
  &[data-popper-placement^='left'] > .floater-arrow {
    right: -6px;
  }
  &[data-popper-placement^='right'] > .floater-arrow {
    left: -6px;
  }

  &[data-popper-reference-hidden='true'] {
    visibility: hidden;
  }
  &[data-popper-reference-hidden='true'] > .floater-arrow::before {
    visibility: hidden;
  }
  border: solid 1px #112299;
  border-radius: 3px;
  background-color: white;
  display: block;

  .glob {
    border: solid 1px #112299;
    border-radius: 3px;
    background-color: white;
    height: 72px;
  }
`;

const PopperBackground = styled.div<{ zIndex?: number }>`
  position: fixed;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: ${(props) => props.zIndex ?? 3999};
`;

type FloaterPlacement = Placement;

export interface FloaterProps {
  readonly referenceElement: Element;
  readonly iframe?: HTMLIFrameElement;
  readonly referenceIFrame?: HTMLIFrameElement | null;
  readonly component: JSX.Element;
  readonly placement?: FloaterPlacement;
  readonly noBackground?: boolean;
  readonly arrow?: boolean;
  readonly className?: string;
  readonly onClose: () => void;
  readonly fallbackPlacement?: string[];
  readonly zIndex?: number;
  readonly offsetX?: number;
  readonly offsetY?: number;
  readonly preventOverflow?: boolean;
  readonly gap?: number;
}

// Get the type of the 3rd parameter to usePopper
type PopperConfigType = Parameters<typeof usePopper>[2];

function setupClosers(onClose: () => void, props: FloaterProps) {
  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  const handleClick = (e: MouseEvent) => {
    onClose();
  };

  if (props.referenceIFrame) {
    props.referenceIFrame?.contentDocument?.body.addEventListener('keydown', handleKeyPress);
    props.referenceIFrame?.contentDocument?.body.addEventListener('mousedown', handleClick);
  }
  document.body.addEventListener('keydown', handleKeyPress);

  return () => {
    if (props.referenceIFrame) {
      props.referenceIFrame?.contentDocument?.body.removeEventListener('keydown', handleKeyPress);
      props.referenceIFrame?.contentDocument?.body.removeEventListener('mousedown', handleClick);
    }
    document.body.removeEventListener('keydown', handleKeyPress);
  };
}

export function Floater(props: FloaterProps) {
  const { referenceElement, onClose, iframe, noBackground } = props;
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  let offsetX: number | undefined;
  let offsetY: number | undefined;

  if (props.referenceIFrame) {
    const r = props.referenceIFrame.getBoundingClientRect();
    offsetX = r.x;
    offsetY = r.y;
  }

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const popperData = useMemo(() => {
    const offsetY = props.placement === 'top' ? -32 : 4;
    const mods: object[] = [
      {
        name: 'flip',
        enabled: false,
      },
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, offsetY],
        },
      },
    ];
    if (props.preventOverflow === true) {
      mods.push({
        name: 'preventOverflow',
        options: {},
      });
    }
    if (props.arrow) {
      mods.push({
        name: 'arrow',
        enabled: true,
        options: {
          element: arrowElement,
        },
      });
    }

    const data: PopperConfigType = {
      placement: props.placement ?? 'bottom-start',
      modifiers: mods,
    };
    return data;
  }, [props.preventOverflow, props.arrow, props.placement, arrowElement]);

  const { styles, attributes, update } = usePopper(referenceElement, popperElement, popperData);

  const onBackgroundClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Escape') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    return setupClosers(onClose, props);
  }, [onClose, props]);

  // If we are injecting into an iframe, we need to inject the styles into the iframe header
  const header = useMemo(() => {
    if (iframe) {
      return iframe.contentDocument?.head;
    }
    return undefined;
  }, [iframe]);

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    update?.();
  }, [update]);

  return (
    <StyleSheetManager target={header}>
      <React.StrictMode>
        {!noBackground && (
          <PopperBackground
            onClick={onBackgroundClick}
            zIndex={props.zIndex ? props.zIndex - 1 : props.zIndex}
          />
        )}
        <PopperDiv
          ref={setPopperElement}
          id='moo'
          style={styles['popper']}
          {...attributes['popper']}
          onKeyDown={handleKeyDown}
          className={props.className ? props.className : 'glob'}
        >
          <div
            style={{
              position: 'relative',
              left: `${(props.offsetX ?? 0) + (offsetX ?? 0)}px`,
              top: `${(props.offsetY ?? 0) + (offsetY ?? 0)}px`,
            }}
          >
            {props.component}
          </div>
          {props.arrow && (
            <div ref={setArrowElement} className='floater-arrow' style={styles['arrow']} />
          )}
        </PopperDiv>
      </React.StrictMode>
    </StyleSheetManager>
  );
}
