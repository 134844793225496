/* eslint-disable @typescript-eslint/no-explicit-any */
import { mergeAttributes, Node } from '@tiptap/core';
import { optimizeStyle } from './utilityFunctions';
import { Attrs } from 'prosemirror-model';

export declare type Level = 1 | 2 | 3 | 4 | 5 | 6 | 0;

export interface CustomHeadingOptions {
  levels: Level[];
  HTMLAttributes?: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    heading1: {
      setCustomHeading: (attributes: {
        level: Level;
        style?: string | null;
        attrs?: Attrs | null;
      }) => ReturnType;
    };
  }
}

export const CustomizedHeading = Node.create<CustomHeadingOptions>({
  name: 'heading',
  // priority will load earlier the extension
  priority: 1001,

  addOptions() {
    return {
      levels: [1, 2, 3, 4, 5, 6],
    };
  },

  content: 'inline*',

  group: 'block',

  defining: true,

  addAttributes() {
    return {
      level: {
        default: 1,
        rendered: false,
      },
    };
  },

  parseHTML() {
    return this.options.levels.map((level: Level) => ({
      tag: `h${level}`,
      attrs: { level },
    }));
  },

  renderHTML({ node, HTMLAttributes }) {
    const hasLevel = this.options.levels.includes(node.attrs['level']);
    const selectedLevel = hasLevel ? node.attrs['level'] : this.options.levels[0];

    return [
      `h${selectedLevel}`,
      optimizeStyle(mergeAttributes(this.options.HTMLAttributes || {}, HTMLAttributes)),
      0,
    ];
  },

  addCommands() {
    return {
      setCustomHeading:
        (attributes) =>
        ({ commands }) => {
          if (!this.options.levels.includes(attributes.level)) {
            return false;
          }
          const mergedAttrs = {
            ...attributes.attrs,
            level: attributes.level,
            style: attributes.style ?? '',
          };
          return commands.setNode(this.name, mergedAttrs ?? {});
        },
    };
  },
});
