import React from 'react';

const HandleErrorComponent = () => {
  return (
    <div data-testid='errorHandler' className='no-content-container'>
      Error in loading interactives...
    </div>
  );
};

export default HandleErrorComponent;
