import { EditorState, Transaction } from '@tiptap/pm/state';
import { Attrs, Node as ProseMirrorNode } from 'prosemirror-model';

/**
 * Create a Prosemirror command to update a node's attributes based on the node typeName.
 * Will not descend past the first matching node
 * @param attrs
 */
export function updateNodeAttributes(attrs: Attrs) {
  return (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    const { from, to } = state.selection;
    const nodes: ProseMirrorNode[] = [];
    const tr = state.tr;
    state.doc.nodesBetween(from, to, (node, pos) => {
      if (nodes.length) {
        return false;
      }
      nodes.push(node);
      tr.setNodeMarkup(pos, null, { ...node.attrs, ...attrs }, node.marks);
      return true;
    });
    if (nodes.length < 1) {
      return false;
    }

    if (dispatch) {
      dispatch(tr);
    }
    return true;
  };
}

export function updateNodeAttributesAtPos(attrs: Attrs, position: number, typeName: string) {
  return (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    if (!position) {
      return false;
    }
    const tr = state.tr;
    const nodes: ProseMirrorNode[] = [];
    const node = state.doc.nodeAt(position);
    if (!node) {
      return false;
    }
    state.doc.nodesBetween(position, position + node.nodeSize, (node, pos) => {
      if (nodes.length) {
        return false;
      }
      if (node.type.name === typeName) {
        nodes.push(node);
        tr.setNodeMarkup(pos, null, { ...node.attrs, ...attrs }, node.marks);
      }
      return true;
    });
    if (nodes.length < 1) {
      return false;
    }
    if (dispatch) {
      dispatch(tr);
    }
    return true;
  };
}
