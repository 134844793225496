/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import '@tiptap/extension-text-style';
import { Extension } from '@tiptap/core';
import { FontSizeData } from '../editorUtilityFunctions/lintEditorType';
import { formatFontSize, parseFontSize } from './utilityFunctions';

type FontSizeOptions = {
  types: string[];
  defaultSize: string;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontSize: {
      setFontSize: (size: FontSizeData) => ReturnType;
      unsetFontSize: () => ReturnType;
    };
  }
}

export const CustomizedFontSize = Extension.create<FontSizeOptions>({
  name: 'fontSize',

  addOptions() {
    return {
      types: ['textStyle'],
      defaultSize: '16px',
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            parseHTML: (element) => parseFontSize(element.style.fontSize.replace(/['"]+/g, '')),
            renderHTML: (attributes: Record<string, FontSizeData>) => {
              if (!attributes['fontSize']) {
                return {};
              }
              return {
                style: `font-size: ${formatFontSize(attributes['fontSize'])}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontSize:
        (fontSize: FontSizeData) =>
        ({ chain }) => {
          return chain().setMark('textStyle', { fontSize }).run();
        },
      unsetFontSize:
        () =>
        ({ chain }) => {
          return chain().setMark('textStyle', { fontSize: null }).removeAllEmptySpan().run();
        },
    };
  },
});
