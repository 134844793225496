/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { LineHeightButtonStyle, StyledLineHeightOption } from './styledContents';
import { LineHeightButtonProps } from './lintEditorType';
import { ReactComponent as SvgLineHeightIcon } from '../editorFormattingIcons/LineHeightIcon.svg';

export function LineHeightButton(props: LineHeightButtonProps) {
  const { lineHeight, onChangeLineHeight } = props;
  function generateNumberArray() {
    const arr = [];
    for (
      let lineHeightOptions = 0.8;
      lineHeightOptions <= 2.6;
      lineHeightOptions = lineHeightOptions + 0.1
    ) {
      arr.push(Number(lineHeightOptions).toFixed(1));
    }
    return arr;
  }
  const lineHeightArray = generateNumberArray();
  return (
    <LineHeightButtonStyle
      value={!lineHeight ? '1.0' : Number(lineHeight).toFixed(1)}
      aria-label='lineHeight'
      data-testid='lineHeight-select'
      onChange={(e: any) => onChangeLineHeight(e)}
      className='lineHeightSelect lineHeightDropdown'
      render={(lineHeightArraySize: number) => {
        if (lineHeightArraySize < 0) {
          return <div />;
        }
        return (
          <div>
            <SvgLineHeightIcon /> {lineHeightArray[lineHeightArraySize]}
          </div>
        );
      }}
    >
      {lineHeightArray.map((ele, index) => (
        <StyledLineHeightOption
          data-testid='lineHeight-select-option'
          key={`${ele + '_' + index}`}
          value={ele}
        >
          {ele}
        </StyledLineHeightOption>
      ))}
    </LineHeightButtonStyle>
  );
}
