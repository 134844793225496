import { fetchGaID } from './api';

export const initializeReactGA = async (url: string) => {
  const prodEnvUrl = window.location.href;

  //ga works only on production
  if (prodEnvUrl.includes('loree-int.crystaldelta.net')) {
    let trackingId = sessionStorage.getItem('trakingId');
    let orgId = sessionStorage.getItem('org_id');
    let userId = sessionStorage.getItem('user_id');
    let gaScript = document.getElementById('script');
    let gaFunction = document.getElementById('scriptText');

    if (gaScript && (gaScript !== null || gaScript !== undefined)) {
      gaScript.remove();
      gaFunction?.remove();
    }

    //Fetch GaID If the user directly edit the component inside the loreeditor
    if (trackingId === undefined || trackingId === null) {
      trackingId = await fetchGaID(orgId);
    }

    if (trackingId !== null) {
      const googleAnalyticsScript = document.createElement('script');
      googleAnalyticsScript.async = true;
      googleAnalyticsScript.id = 'script';
      googleAnalyticsScript.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
      const gaFunctionScript = document.createElement('script');
      gaFunctionScript.id = 'scriptText';
      const innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date()); gtag('config', '${trackingId}', {'page_path': '${url}', 'user_id': '${userId}',  'cookie_prefix': 'gaCookie','cookie_domain': 'auto', 'cookie_expires': 60 * 60 * 24 * 28, 'cookie_update': 'true'});gtag('set', {'cookie_flags': 'SameSite=None;Secure'});`;
      gaFunctionScript.innerText = innerText;

      document.body.appendChild(googleAnalyticsScript);
      document.body.appendChild(gaFunctionScript);
    }
  }
};
