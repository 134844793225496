/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { MouseEventHandler, useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Button, Form } from 'react-bootstrap';
import Rnd from './rnd';
import './imageView.scss';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/sectiondeleteIcon.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/editIcon.svg';
import { ReactComponent as AddIcon } from '../../../assets/icons/cirlcedPlus.svg';
import { LoreeInteractiveEditor } from '../../editor/loreeInteractiveEditor';
import Tooltip from '../../utils/tooltip';
import { CONSTANTS } from '../../editor/editorUtilityFunctions/constants';
import { LoreeInteractiveEditorDashboardContentType } from '../../editor/editorUtilityFunctions/lintEditorType';

interface AreaViewComponent {
  type: string;
  screenType: string;
  setScreenType: Function;
  label: {
    value: string;
    setLabel: Function;
  };
  text: {
    value: string;
    setText: Function;
  };
  setDimension: Function;
  parameters: any;
  fontSize: string;
  icons: {
    status: boolean;
    setStatus: Function;
  };
  dropZone: {
    activeIndex: number;
    setActiveIndex: Function;
  };
  handleEdit: MouseEventHandler;
  handleDelete: MouseEventHandler;
  setImageDimension: Function;
  errorHandler: {
    error: any;
    setError: Function;
  };
  editorConfig: LoreeInteractiveEditorDashboardContentType;
}

const ImageViewComponent = (props: AreaViewComponent) => {
  const {
    type,
    parameters,
    screenType,
    fontSize,
    setScreenType,
    label,
    text,
    setDimension,
    icons,
    dropZone,
    handleEdit,
    handleDelete,
    setImageDimension,
    errorHandler,
    editorConfig,
  } = props;
  const [backgroundImage, setBackgroundImage] = useState<HTMLImageElement>();

  useEffect(() => {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = parameters.background;
    setBackgroundImage(wrapper.getElementsByTagName('img')[0]);
  }, [parameters]);

  const handleText = (value: string) => {
    const { error, setError } = errorHandler;
    text.setText(value);
    delete error['text'];
    setError(error);
  };

  return (
    <div className='editor-modal'>
      {screenType === 'view' && (
        <div className='d-flex flex-row'>
          <div className='button-wrapper'>
            <Button
              size='sm'
              onClick={() => {
                setImageDimension(document.getElementById('dndImage')?.getBoundingClientRect());
                setScreenType('details');
              }}
              className='delete-icon mb-3 close-btn'
            >
              <Tooltip info='Add'>
                <AddIcon />
              </Tooltip>
            </Button>
            <Button
              size='sm'
              onClick={handleEdit}
              className='delete-icon my-3 close-btn'
              disabled={icons.status}
            >
              <Tooltip info='Edit'>
                <EditIcon />
              </Tooltip>
            </Button>
            <Button
              size='sm'
              onClick={handleDelete}
              className='delete-icon my-3 close-btn'
              disabled={icons.status}
            >
              <Tooltip info='Delete'>
                <DeleteIcon />
              </Tooltip>
            </Button>
          </div>
          <div className='image-container ms-4'>
            <div
              className='boundry centered-div'
              id='dndImage'
              style={{
                backgroundImage: `url('${backgroundImage?.src}')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: ' 100% 100%',
              }}
            >
              {parameters.dropZone?.map((data: any, idx: number) => {
                return (
                  <Rnd
                    key={idx}
                    prop={data}
                    setActiveZone={(index: number) => {
                      dropZone.setActiveIndex(index);
                      icons.setStatus(false);
                    }}
                    dimensions={setDimension}
                    type={type}
                    size={fontSize}
                    image={backgroundImage}
                    activeIndex={dropZone.activeIndex}
                  >
                    {type === 'DragandDrop' ? (
                      <h4 key={data.data.label} className='rnd-label'>
                        {data.data.label}
                      </h4>
                    ) : (
                      <></>
                    )}
                  </Rnd>
                );
              })}
              {ReactHtmlParser(parameters.background)}
            </div>
          </div>
        </div>
      )}
      {(screenType === 'details' || screenType === 'editDetails') && (
        <>
          <Form.Group>
            <Form.Label>Label *</Form.Label>
            <Form.Control
              type='text'
              value={label.value}
              onChange={(event) => {
                const { error, setError } = errorHandler;
                label.setLabel(event?.target.value);
                delete error['label'];
                setError(error);
              }}
            />
            {errorHandler.error['label'] && (
              <Form.Text className='text-danger'>{errorHandler.error['label']}</Form.Text>
            )}
          </Form.Group>
          <Form.Group className='py-3'>
            <Form.Label>Text *</Form.Label>
            {type === 'DragandDrop' && (
              <Form.Control
                type='text'
                value={text.value}
                onChange={(event) => handleText(event.target.value)}
              />
            )}
            {type === 'Hotspot' && (
              <LoreeInteractiveEditor
                initialValue={text.value ?? CONSTANTS.LOREE_EDITOR_EMPTY_CONTENT}
                editorConfig={editorConfig}
                updateEditorContent={(value: string) => handleText(value)}
              />
            )}
            {errorHandler.error['text'] && (
              <Form.Text className='text-danger'>{errorHandler.error['text']}</Form.Text>
            )}
          </Form.Group>
        </>
      )}
    </div>
  );
};

export default ImageViewComponent;
