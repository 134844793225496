/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { validateExternalLink } from '../../middleware/utils';
import { FormInterface } from '../../redux/interface';
import { ButtonFormInterface } from './button/form';

export const isEmpty = (htmlString: string) => {
  const parser = new DOMParser();

  if (htmlString === '') return true;

  const { textContent } = parser.parseFromString(htmlString, 'text/html').documentElement;
  const isImage =
    parser.parseFromString(htmlString, 'text/html').body.getElementsByTagName('img').length === 0;
  const isIframe =
    parser.parseFromString(htmlString, 'text/html').body.getElementsByTagName('iframe').length ===
    0;

  return !textContent?.trim() && isImage && isIframe;
};

export const validateButtonForm = (props: ButtonFormInterface, ErrorsFetch: Function) => {
  const { link, name, title } = props;
  let fail: any = {};
  let isValidUrl: any = validateExternalLink(link);
  if (title === '') {
    fail['title'] = '* Title field is required';
  }

  if (name === '') {
    fail['name'] = '* Name field is required';
  }

  if (isValidUrl.error) {
    fail['link'] = link !== '' ? '* Please Enter Valid URL' : '* Link field is required';
  }

  ErrorsFetch({
    errors: fail,
  });
  return fail;
};

export const validateMcqForm = (props: any, setError: Function, pathName: string, type: string) => {
  const { mcqParameters, mcqContentArr, title } = props;
  let fail: any = {};
  if (title?.trim() === '') {
    fail['title'] = pathName.includes('/duplicate') ? '' : '* Interactive Title is required';
  }
  mcqParameters.forEach((param: any, paramIndex: number) => {
    mcqContentArr.forEach((contentArr_el: any, contentArr_index: number) => {
      // Need one option selected - removing this fail when one check is true at line:23
      if (paramIndex === contentArr_index) {
        fail[`opt_${contentArr_el[0]}`] = '* Atleast one correct option need to checked';
        if (param.question === '' || isEmpty(param.question)) {
          fail[`${contentArr_el[0]}`] = '* Question field is required';
        }
        param.options.forEach((option: any, optionIndex: number) => {
          if (!option.option || isEmpty(option.option)) {
            fail[`${mcqContentArr[paramIndex][1].options[optionIndex]}`] =
              '* Option field is required';
          }
          if (option.check && option.mytip?.trim() === '') {
            fail[`${mcqContentArr[paramIndex][1].options[optionIndex]}_tip`] = '* Tip is required';
          } else if (!option.check || option.mytip.trim() !== '') {
            delete fail[`${mcqContentArr[paramIndex][1].options[optionIndex]}_tip`];
          }
          if (option.showFeedback && option.myfeedback?.trim() === '') {
            fail[`${mcqContentArr[paramIndex][1].options[optionIndex]}_feedback`] =
              '* Feedback is required';
          } else if (option.myfeedback?.trim() !== '' || !option.showFeedback) {
            delete fail[`${mcqContentArr[paramIndex][1].options[optionIndex]}_feedback`];
          }
          if (option.correct === true) {
            delete fail[`opt_${contentArr_el[0]}`];
          }
        });
      }
    });
  });
  setError({
    errors: fail,
  });
  return fail;
};
export const validateFormComponent = (
  props: any,
  setError: Function,
  pathName: string,
  type: string,
) => {
  const { parameters, contentArr, flipCardContentArr, title, flipCardParameters } = props;
  let fail: any = {};
  (type === 'flipcard' ? flipCardParameters : parameters).map(
    (parametersValue: any, parametersIndex: number) => {
      (type === 'flipcard' ? flipCardContentArr : contentArr).map(
        (contentArrValue: any, contentArrIndex: number) => {
          if (parametersIndex === contentArrIndex) {
            let values = Object.values(parametersValue);
            if (values[0] === '' || isEmpty(values[0] as string)) {
              fail[`${contentArrValue[0]}`] = getTitleErrorMessage(type);
            }
            if (values[1] === '' || isEmpty(values[1] as string)) {
              fail[contentArrValue[1]] = getTextErrorMessage(type);
            }
            if (
              getAltValueError(values[1] as string) &&
              (type === 'imageslider' || type === 'DragandDrop')
            ) {
              fail[`alt_${contentArrValue[1]}`] = `* Alternative text is required`;
            }
            if (type === 'imageslider' && getTitleValueError(values[1] as string)) {
              fail[`title_${contentArrValue[1]}`] = `* Title is required`;
            }
          }
        },
      );
    },
  );

  if (title?.trim() === '') {
    fail['title'] = pathName.includes('/duplicate') ? '' : 'Interactive Title is required';
  }

  setError({
    errors: fail,
  });

  return fail;
};

const getTitleErrorMessage = (type: string) => {
  switch (type) {
    case 'flipcard':
      return 'Front view is required';
    case 'ClickAndRevealInteractions':
      return 'Reveal Content is required';
    case 'imageslider':
      return 'Caption is required';
    case 'DragandDrop':
      return '* Droppable Text is required';
    default:
      return 'Section Header is required';
  }
};

const getTextErrorMessage = (type: string) => {
  switch (type) {
    case 'flipcard':
      return 'Back view is required';
    case 'ClickAndRevealInteractions':
      return 'Click Content is required';
    case 'imageslider':
      return 'Image/video is required';
    case 'DragandDrop':
      return '* Background Image is required';
    default:
      return 'Section Body is required';
  }
};

const getAltValueError = (imageValue: string) => {
  if (imageValue === '' || imageValue === '<p><br></p>') return false;
  const wrapper = document.createElement('div');
  wrapper.innerHTML = imageValue;
  const imageElement = wrapper?.getElementsByTagName('img')[0];
  if (imageElement && !imageElement.alt) {
    imageElement.alt = '';
    imageElement.role = 'presentation';
  }
  return imageElement?.alt?.trim() === '' && (!imageElement?.role || imageElement?.role === '');
};

const getTitleValueError = (videoValue: string) => {
  if (videoValue === '' || videoValue === '<p><br></p>') return false;
  const wrapper = document.createElement('div');
  wrapper.innerHTML = videoValue;
  const videoElement = wrapper?.getElementsByTagName('iframe')[0];
  return videoElement?.title?.trim() === '';
};

const validateDndCustomOverlay = (
  props: FormInterface,
  setError: Function,
  pathName: string,
  type: string,
) => {
  const { dndParameters, dndArray, title } = props;
  let fail: any = {};
  dndParameters?.forEach((parameters_el: {}, parameters_index: number) => {
    dndArray?.forEach((contentArr_el: string[], contentArr_index: number) => {
      if (parameters_index === contentArr_index) {
        let values: any = Object.values(parameters_el);
        if (values[0] === '' || values[0] === '<p><br></p>')
          fail[contentArr_el[0]] = '* Background Image is required';
        if (values[1].length === 0)
          fail[contentArr_el[1]] = `* Atleast one ${
            type === 'Hotspot' ? 'hotspot' : 'drop zone'
          } needs to be added`;
        if (getAltValueError(values[0]))
          fail[`alt_${contentArr_el[0]}`] = `* Alternative text is required`;
      }
    });
  });

  if (title?.trim() === '') {
    fail['title'] = pathName.includes('/duplicate') ? '' : 'Interactive Title is required';
  }

  setError({
    errors: fail,
  });

  return fail;
};

export const getValidationFunction = (type: string, dndLayout: string) => {
  switch (type) {
    case 'multiplechoice':
      return validateMcqForm;
    case 'DragandDrop':
      return dndLayout === 'CustomOverlay' ? validateDndCustomOverlay : validateFormComponent;
    case 'Hotspot':
      return validateDndCustomOverlay;
    default:
      return validateFormComponent;
  }
};

export const validateDropZone = (
  parameters: any,
  label: string,
  text: string,
  activeZone: number,
  editStatus: boolean,
) => {
  const array = { ...parameters };
  const textArr: Array<string> = [];
  const labelArr: Array<string> = [];
  let fail: any = {};
  const dropZoneData = array.dropZone.map((a: {}) => ({ ...a }));
  const elementsIndex = array.dropZone.findIndex(
    (element: { id: number }) => element.id === activeZone,
  );
  if (editStatus) {
    if (elementsIndex !== -1) {
      dropZoneData.splice(elementsIndex, 1);
      array.dropZone = dropZoneData;
    }
  }

  array.dropZone.map((dropArea: { data: { text: string; label: string } }) => {
    textArr.push(dropArea.data.text);
    labelArr.push(dropArea.data.label);
    return null;
  });

  if (label.trim() === '' || labelArr.includes(label)) {
    fail['label'] = isLabelExists(label, labelArr);
  }

  if (isEmpty(text) || textArr.includes(text)) {
    fail['text'] = isTextExists(text, textArr);
  }

  return fail;
};

const isTextExists = (text: string, textArr: Array<string>) => {
  switch (true) {
    case textArr.includes(text):
      return 'Text already exists';
    default:
      return 'Text is required';
  }
};

const isLabelExists = (label: string, labelArr: Array<string>) => {
  switch (true) {
    case labelArr.includes(label):
      return 'Label already exists';
    default:
      return 'Label is required';
  }
};
