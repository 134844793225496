/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import iconList from '../../../library/iconMeta';

interface IconLibraryInterface {
  searchText: string;
  handleSelectedIcon: Function;
  enableOrDisableContainers: Function;
  handleConfirmState: Function;
}

export const IconLibrary = (props: IconLibraryInterface) => {
  const { searchText, handleSelectedIcon, enableOrDisableContainers } = props;
  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [filteredIconList, setFilteredIconList] = useState<Array<{ name: string }>>(iconList.icons);

  useEffect(() => {
    if (categoryList.length === 0) {
      const list: string[] = ['all'];
      iconList.icons.forEach((icon) => {
        icon.categories.forEach((cat) => {
          if (!list.includes(cat)) list.push(cat);
        });
      });
      setCategoryList(list);
    }
    const categoryType = categoryList.find(
      (category) => category.toLowerCase() === selectedCategory,
    );
    let filterIconList: Array<{ name: string }> = [];
    iconList.icons.forEach((category) => {
      if (category.categories.includes(categoryType as string)) {
        filterIconList.push(category);
      }
    });
    filterIconList = selectedCategory === 'all' ? iconList.icons : filterIconList;
    setFilteredIconList([
      ...filterIconList.filter((item: { name: string }) =>
        item.name.toLowerCase().includes(searchText.toLowerCase()),
      ),
    ]);
  }, [selectedCategory, searchText]);

  const handleCategory = (e: React.MouseEvent) => {
    const selected = (e.target as HTMLElement).getAttribute('title')?.toLowerCase();
    if (selected) {
      setSelectedCategory(selected);
      props.handleConfirmState(true);
    }
  };

  return (
    <Row className='icon-container ps-4 py-2 m-0'>
      <Col sm={2} className='p-0 pe-1 category-list'>
        {categoryList.map((category) => {
          return (
            <div
              role='button'
              tabIndex={0}
              key={category}
              onKeyDown={() => {}}
              title={category}
              className={`text-capitalize text-truncate mb-3 ${
                selectedCategory === category ? 'selected-category' : ''
              }`}
              onClick={(e) => handleCategory(e)}
            >
              {category}
            </div>
          );
        })}
      </Col>
      <Col sm={10} className='p-0 icon-list d-flex flex-wrap m-0 ps-3'>
        {}
        {filteredIconList.length > 0 ? (
          filteredIconList.map((icon: { name: string }, index) => {
            return (
              <div
                key={`${icon.name}_${index}`}
                className='lint-icon'
                role='button'
                tabIndex={0}
                onKeyDown={() => {}}
                onClick={(event) => {
                  handleSelectedIcon(icon, index, event);
                  enableOrDisableContainers(false, true, true);
                }}
              >
                <span title={icon.name} className='material-icons'>
                  {icon.name}
                </span>
              </div>
            );
          })
        ) : (
          <div className='text-center pt-2 mx-auto'>No results Found</div>
        )}
      </Col>
    </Row>
  );
};
