import { EditorState, Transaction } from 'prosemirror-state';

export function applyLinkToNode(url: string, newWindow: Boolean | null) {
  return (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    const { from, to } = state.selection;
    const nodeTypes = ['image', 'iconTag', 'icon'];
    const tr = state.tr;
    const attrs = { href: url, target: newWindow ? '_blank' : '_self' };
    state.doc.nodesBetween(from, to, (node, pos) => {
      if (nodeTypes.includes(node.type.name)) {
        tr.setNodeMarkup(pos, null, node.attrs, [state.schema.marks['link'].create(attrs)]);
      }
      return true;
    });

    if (dispatch) {
      dispatch(tr);
    }
    return true;
  };
}
