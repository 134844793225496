/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Form } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { customCheck, retry, customshuffle } from '../../../../redux/multipleChoice/mcq.action';

const mapState = ({ mcqform }: any) => ({
  checkedAnswer: mcqform.checkedAnswer,
  customtryagain: mcqform.customtryagain,
  shuffle: mcqform.shuffle,
});

const mapDispatch = (dispatch: Function) => ({
  HanldeCheck: (form: {}) => dispatch(customCheck(form)),
  HanldeRetry: (form: {}) => dispatch(retry(form)),
  HanldeShuffle: (form: {}) => dispatch(customshuffle(form)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const BehaviourSettingCustomize = (props: Props) => {
  const { checkedAnswer, customtryagain, shuffle, HanldeCheck, HanldeRetry, HanldeShuffle } = props;

  const handleSettings = (type: string) => {
    switch (type) {
      case 'check':
        HanldeCheck({
          checkedAnswer: !checkedAnswer,
        });
        break;
      case 'retry':
        HanldeRetry({
          customtryagain: !customtryagain,
        });
        break;
      default:
        HanldeShuffle({
          shuffle: !shuffle,
        });
        break;
    }
  };
  return (
    <>
      <Form.Check
        type='switch'
        className='switch-dark'
        id='custom-switch1'
        label='Check Answer'
        checked={checkedAnswer}
        onChange={() => handleSettings('check')}
      />
      <small id='emailHelp' className='mb-3 form-text text-muted'>
        {`Individual answer details will ${checkedAnswer ? '' : 'not'} be shown`}
      </small>
      <Form.Check
        type='switch'
        className='switch-dark'
        id='custom-switch2'
        label='Retry Button'
        onChange={() => handleSettings('retry')}
        checked={customtryagain}
      />
      <small id='emailHelp' className='mb-3 form-text text-muted'>
        {customtryagain ? 'Able to retry the test' : 'No retry option after complete test'}
      </small>
      <Form.Check
        type='switch'
        className='switch-dark'
        id='custom-switch3'
        label='Shuffle Order'
        onChange={() => handleSettings('shuffle')}
        checked={shuffle}
      />
      <small id='emailHelp' className='mb-3 form-text text-muted'>
        {shuffle ? 'Options shuffle in each try' : 'Options display in same order'}
      </small>
    </>
  );
};

export default connector(BehaviourSettingCustomize);
