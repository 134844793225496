/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { customOption } from '../../../../redux/multipleChoice/mcq.action';

const mapState = ({ mcqform }: any) => ({
  optiontype: mcqform.optiontype,
});

const mapDispatch = (dispatch: Function) => ({
  Customoption: (form: {}) => dispatch(customOption(form)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const QuestionTypeCustomize = (props: Props) => {
  const { optiontype, Customoption } = props;

  const setCustomOption = (e: ChangeEvent<HTMLInputElement>) => {
    Customoption({
      optiontype: e.target.value,
    });
  };

  return (
    <div className='icon-items container-inner'>
      <span className='design-section-text'>Type</span>
      <div className='my-2'>
        <Form.Check
          inline
          label='Single Select'
          type='radio'
          name='icon'
          value='radio'
          id='singleselect-button'
          onChange={setCustomOption}
          checked={optiontype === 'radio'}
          className='icon-label'
        />
        <Form.Check
          inline
          label='Multi Select'
          type='radio'
          name='icon'
          value='checkbox'
          id='multiselect-button'
          onChange={setCustomOption}
          checked={optiontype === 'checkbox'}
          className='icon-label'
        />
      </div>
    </div>
  );
};

export default connector(QuestionTypeCustomize);
