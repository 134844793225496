/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Row, Col, Carousel, Card } from 'react-bootstrap';
import '../../../assets/scss/imageslider.scss';
import { CustomisationInterface } from '../../../middleware/getStyle';
import { carouselUpdate, wrapIframeInVideoWrapper } from './utils';

interface ImageSliderComponentProps {
  parameters: Array<{ title: string; text: string }>;
  customisations: CustomisationInterface;
}

const ImageSlider = (props: ImageSliderComponentProps) => {
  const { parameters, customisations } = props;
  const [refinedParameters, setRefinedParameters] = useState(parameters);
  const caption = customisations.setcaptionAlignment;
  const fontHeadingsValue: any =
    customisations.fontHeadings !== '' ? customisations.fontHeadings : 'div';
  const sliderStyle = {
    fontFamily: `${customisations.fontFamily}`,
    fontWeight: `${customisations.fontWeight}`,
    fontSize: `${customisations.fontSize}px`,
    color: `${customisations.fontColor}`,
    backgroundColor: `${customisations.bgcolor}`,
  };
  let params;
  let videoVal;
  for (params of parameters) {
    videoVal = params['text']?.includes('iframe');
  }
  useEffect(() => {
    carouselUpdate(customisations);
    setRefinedParameters(wrapIframeInVideoWrapper(parameters));
  }, [parameters, customisations]);

  return (
    <Row className='d-block'>
      <Col tabIndex={0} aria-label='Image slider'>
        <Carousel
          className='carousel-view'
          controls={refinedParameters.length === 1 ? false : true}
          pause='hover'
          interval={videoVal === true ? null : 5000}
        >
          {refinedParameters.map((content: { title: string; text: string }, idx: number) => {
            let extraTag = content['text'].replace('<p><br></p>', '');
            content['text'] = extraTag;
            return (
              <Carousel.Item key={idx}>
                <Card
                  className='text-center'
                  style={{ display: caption === 'bottom' ? 'none' : ' ' }}
                >
                  <Card.Body
                    tabIndex={0}
                    aria-live='polite'
                    as={fontHeadingsValue}
                    style={sliderStyle}
                  >
                    {content['title']}
                  </Card.Body>
                </Card>
                <div
                  className='carousel-content d-flex justify-content-center align-items-center'
                  role='button'
                  tabIndex={0}
                  aria-live='polite'
                >
                  {ReactHtmlParser(content['text'])}
                </div>
                <Card className='text-center' style={{ display: caption === 'top' ? 'none' : ' ' }}>
                  <Card.Body
                    tabIndex={0}
                    aria-live='polite'
                    as={fontHeadingsValue}
                    style={sliderStyle}
                  >
                    {content['title']}
                  </Card.Body>
                </Card>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </Col>
    </Row>
  );
};

export default ImageSlider;
