import { Extension } from '@tiptap/core';
import '@tiptap/extension-text-style';

export type BackgroundColorOptions = {
  types: string[];
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    backgroundColor: {
      unsetBackgroundColor: () => ReturnType;
      setBackgroundColor: (backgroundColor: string) => ReturnType;
    };
  }
}

export const BackgroundColor = Extension.create<BackgroundColorOptions>({
  name: 'backgroundColor',

  addOptions() {
    return {
      types: ['textStyle'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          backgroundColor: {
            default: null,
            parseHTML: (element) => element.style.backgroundColor.replace(/['"]+/g, ''),
            renderHTML: (attributes: Record<string, string>) => {
              if (!attributes['backgroundColor']) {
                return {};
              }
              return {
                style: `background-color: ${attributes['backgroundColor']}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      unsetBackgroundColor:
        () =>
        ({ chain }) => {
          return chain().setMark('textStyle', { backgroundColor: null }).removeAllEmptySpan().run();
        },
      setBackgroundColor:
        (backgroundColor) =>
        ({ chain, tr }) => {
          return chain().setMark('textStyle', { backgroundColor }).run();
        },
    };
  },
});
