import { FlipFormInterface } from '../interface';

//initial state for redux
const INITIAL_STATE = {
  num: 0,
  contentArr: [['fronttext_0', 'backtext_0']],
  title: '',
  parameters: [{ fronttext: '', backtext: '' }],
  errors: {},
  type: '',
  bgcolor: '#ffffff',
  backColor: '#ffffff',
  height: '253',
  noOfCard: '4',
  borderType: '',
  borderColor: '#000000',
  backBorderColor: '#000000',
  borderSize: '1',
  frontBorderSizeRight: '1',
  frontBorderSizeTop: '1',
  frontBorderSizeBottom: '1',
  backBorderSizeLeft: '1',
  backBorderSizeRight: '1',
  backBorderSizeTop: '1',
  backBorderSizeBottom: '1',
  borderStyle: 'solid',
  backBorderStyle: 'solid',
  radius: '0',
  backBorderRadius: '0',
  flip: '0',
  removeImage: [],
  cardType: 'square',
  frontTop: '10',
  frontRight: '10',
  frontBottom: '10',
  frontLeft: '10',
  backTop: '10',
  backRight: '10',
  backBottom: '10',
  backLeft: '10',
  frontTextColor: '#000000',
  backTextColor: '#000000',
  fontFamily: 'Helvetica',
  frontHeader: '',
  frontSize: '16',
  frontWeight: 'Normal',
  backHeader: '',
  backSize: '16',
  backWeight: 'Normal',
};

//action creators with actions
const formReducer = (state = INITIAL_STATE, data: FlipFormInterface) => {
  switch (data.type) {
    //add panel action
    case 'ADD_PANEL':
      return {
        ...state,
        parameters: data.parameters,
        contentArr: data.contentArr,
        num: data.num,
      };
    //title within panel action
    case 'HANDLE_CHANGE':
      return {
        ...state,
        parameters: data.parameters,
        errors: data.errors,
      };

    //rearange the parameters
    case 'REARANGE_ARRAY':
      return {
        ...state,
        parameters: data.parameters,
        contentArr: data.contentArr,
      };

    //main-title action
    case 'HANDLE_TITLE':
      return {
        ...state,
        title: data.title,
      };

    //remove action
    case 'REMOVE_CLICK':
      return {
        ...state,
        parameters: data.parameters,
        contentArr: data.contentArr,
        errors: data.errors,
      };

    //validation action
    case 'ERRORS':
      return {
        ...state,
        errors: data.errors,
      };
    case 'MAKE_EDIT':
      return {
        ...state,
        parameters: data.parameters,
        contentArr: data.contentArr,
        title: data.title,
        type: data.component,
        bgcolor: data.bgcolor,
        backColor: data.backColor,
        height: data.height,
        noOfCard: data.noOfCard,
        radius: data.radius,
        backBorderRadius: data.backBorderRadius,
        borderType: data.borderType,
        borderColor: data.borderColor,
        backBorderColor: data.backBorderColor,
        borderSize: data.borderSize,
        frontBorderSizeRight: data.frontBorderSizeRight,
        frontBorderSizeTop: data.frontBorderSizeTop,
        frontBorderSizeBottom: data.frontBorderSizeBottom,
        backBorderSizeLeft: data.backBorderSizeLeft,
        backBorderSizeRight: data.backBorderSizeRight,
        backBorderSizeTop: data.backBorderSizeTop,
        backBorderSizeBottom: data.backBorderSizeBottom,
        borderStyle: data.borderStyle,
        backBorderStyle: data.backBorderStyle,
        flip: data.flip,
        frontTop: data.frontTop,
        frontRight: data.frontRight,
        frontBottom: data.frontBottom,
        frontLeft: data.frontLeft,
        backTop: data.backTop,
        backRight: data.backRight,
        backBottom: data.backBottom,
        backLeft: data.backLeft,
        frontTextColor: data.frontTextColor,
        backTextColor: data.backTextColor,
        cardType: data.cardType,
        fontFamily: data.fontFamily,
        frontHeader: data.frontHeader,
        frontSize: data.frontSize,
        frontWeight: data.frontWeight,
        backHeader: data.backHeader,
        backSize: data.backSize,
        backWeight: data.backWeight,
      };
    case 'SET_TYPE':
      return {
        ...state,
        type: data.component,
      };

    // To set the ID for the panels
    case 'SET_VALUE':
      return {
        ...state,
        num: data.num,
      };

    case 'CUSTOM_BGCOLOR':
      return {
        ...state,
        bgcolor: data.bgcolor,
      };

    case 'CUSTOM_TEXTCOLOR':
      return {
        ...state,
        frontTextColor: data.frontTextColor,
      };

    case 'CUSTOM_BACKTEXTCOLOR':
      return {
        ...state,
        backTextColor: data.backTextColor,
      };

    case 'CUSTOM_BACKCOLOR':
      return {
        ...state,
        backColor: data.backColor,
      };
    case 'CUSTOM_HEIGHT':
      return {
        ...state,
        height: data.height,
      };
    case 'CUSTOM_NOOFCARD':
      return {
        ...state,
        noOfCard: data.noOfCard,
      };

    case 'CUSTOM_BORDER':
      return {
        ...state,
        borderType: data.borderType,
      };

    case 'BORDER_COLOR':
      return {
        ...state,
        borderColor: data.borderColor,
      };
    case 'BACK_BORDER_COLOR':
      return {
        ...state,
        backBorderColor: data.backBorderColor,
      };
    case 'BACK_BORDER_RADIUS':
      return {
        ...state,
        backBorderRadius: data.backBorderRadius,
      };
    case 'BACK_BORDER_STYLE':
      return {
        ...state,
        backBorderStyle: data.backBorderStyle,
      };
    case 'FRONT_BORDER_SIZE_RIGHT':
      return {
        ...state,
        frontBorderSizeRight: data.frontBorderSizeRight,
      };
    case 'FRONT_BORDER_SIZE_TOP':
      return {
        ...state,
        frontBorderSizeTop: data.frontBorderSizeTop,
      };
    case 'FRONT_BORDER_SIZE_BOTTOM':
      return {
        ...state,
        frontBorderSizeBottom: data.frontBorderSizeBottom,
      };
    case 'BACK_BORDER_SIZE_LEFT':
      return {
        ...state,
        backBorderSizeLeft: data.backBorderSizeLeft,
      };
    case 'BACK_BORDER_SIZE_RIGHT':
      return {
        ...state,
        backBorderSizeRight: data.backBorderSizeRight,
      };
    case 'BACK_BORDER_SIZE_TOP':
      return {
        ...state,
        backBorderSizeTop: data.backBorderSizeTop,
      };
    case 'BACK_BORDER_SIZE_BOTTOM':
      return {
        ...state,
        backBorderSizeBottom: data.backBorderSizeBottom,
      };
    case 'BORDER_SIZE':
      return {
        ...state,
        borderSize: data.borderSize,
      };

    case 'BORDER_STYLE':
      return {
        ...state,
        borderStyle: data.borderStyle,
      };
    case 'CUSTOM_RADIUS':
      return {
        ...state,
        radius: data.radius,
      };
    case 'CUSTOM_FLIP':
      return {
        ...state,
        flip: data.flip,
      };
    case 'REMOVE_IMAGE':
      return {
        ...state,
        removeImage: data.removeImage,
      };
    case 'CUSTOM_CARDTYPE':
      return {
        ...state,
        cardType: data.cardType,
      };
    case 'CUSTOM_FRONTTOP':
      return {
        ...state,
        frontTop: data.frontTop,
      };

    case 'CUSTOM_FRONTRIGHT':
      return {
        ...state,
        frontRight: data.frontRight,
      };

    case 'CUSTOM_FRONTBOTTOM':
      return {
        ...state,
        frontBottom: data.frontBottom,
      };

    case 'CUSTOM_FRONTLEFT':
      return {
        ...state,
        frontLeft: data.frontLeft,
      };
    case 'CUSTOM_BACKTOP':
      return {
        ...state,
        backTop: data.backTop,
      };

    case 'CUSTOM_BACKRIGHT':
      return {
        ...state,
        backRight: data.backRight,
      };

    case 'CUSTOM_BACKBOTTOM':
      return {
        ...state,
        backBottom: data.backBottom,
      };

    case 'CUSTOM_BACKLEFT':
      return {
        ...state,
        backLeft: data.backLeft,
      };
    case 'CUSTOM_FONTFAMILY':
      return {
        ...state,
        fontFamily: data.fontFamily,
      };
    case 'CUSTOM_FRONT_SIZE':
      return {
        ...state,
        frontSize: data.frontSize,
      };
    case 'CUSTOM_FRONT_HEADER':
      return {
        ...state,
        frontHeader: data.frontHeader,
      };
    case 'CUSTOM_FRONT_WEIGHT':
      return {
        ...state,
        frontWeight: data.frontWeight,
      };
    case 'CUSTOM_BACK_SIZE':
      return {
        ...state,
        backSize: data.backSize,
      };
    case 'CUSTOM_BACK_HEADER':
      return {
        ...state,
        backHeader: data.backHeader,
      };
    case 'CUSTOM_BACK_WEIGHT':
      return {
        ...state,
        backWeight: data.backWeight,
      };
    case '':
      return {
        ...INITIAL_STATE,
        contentArr: [['fronttext_0', 'backtext_0']],
        parameters: [{ fronttext: '', backtext: '' }],
      };
    default:
      return {
        ...state,
      };
  }
};

export default formReducer;
