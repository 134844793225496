/* eslint-disable @typescript-eslint/no-explicit-any */
import { ElementType } from 'react';

export interface CustomisationInterface {
  borderType: string;
  borderSize: string;
  borderStyle: string;
  borderColor: string;
  borderLeft: string;
  borderBottom: string;
  borderTop: string;
  borderRight: string;
  bgcolor: string;
  frontTop: string;
  frontLeft: string;
  frontBottom: string;
  frontRight: string;
  frontTextColor: string;
  fontFamily: string;
  frontSize: string;
  frontWeight: string;
  backColor: string;
  backTop: string;
  backLeft: string;
  backBottom: string;
  backRight: string;
  backTextColor: string;
  backSize: string;
  backWeight: string;
  fontWeight: string;
  fontSize: string;
  fontColor: string;
  borderRadius: string;
  fontHeadings: string | ElementType;
  alignment: any;
  radius: string;
  clickBorderRadius: string;
  clickBorderTop: string;
  clickBorderRight: string;
  clickBorderLeft: string;
  clickBorderBottom: string;
  clickBorderColor: string;
  clickBorderType: string;
  clickBgColor: string;
  revealBorderRadius: string;
  revealBorderTop: string;
  revealBorderRight: string;
  revealBorderLeft: string;
  revealBorderBottom: string;
  revealBorderColor: string;
  revealBorderType: string;
  revealBgColor: string;
  clickPaddingTop: string;
  clickPaddingRight: string;
  clickPaddingLeft: string;
  clickPaddingBottom: string;
  revealPaddingTop: string;
  revealPaddingRight: string;
  revealPaddingLeft: string;
  revealPaddingBottom: string;
  separatorWidth: string;
  separatorColor: string;
  displayActiveColorPicker: string;
  backBorderRadius: string;
  frontBorderSizeRight: string;
  frontBorderSizeTop: string;
  frontBorderSizeBottom: string;
  backBorderSizeLeft: string;
  backBorderSizeRight: string;
  backBorderSizeTop: string;
  backBorderSizeBottom: string;
  backBorderStyle: string;
  backBorderColor: string;
  setcaptionAlignment: string;
}

export const getBorderStyle = (customisations: CustomisationInterface) => {
  const {
    borderType,
    borderSize,
    borderStyle,
    borderColor,
    borderLeft,
    borderBottom,
    borderTop,
    borderRight,
    borderRadius,
  } = customisations;
  if (borderLeft) {
    return {
      borderLeft: `${borderLeft}px  ${borderStyle} ${borderColor}`,
      borderRight: `${borderRight}px  ${borderStyle} ${borderColor}`,
      borderTop: `${borderTop}px  ${borderStyle} ${borderColor}`,
      borderBottom: `${borderBottom}px  ${borderStyle} ${borderColor}`,
      borderRadius: `${borderRadius}px`,
    };
  }
  const borderValue = `${borderSize}px  ${borderStyle} ${borderColor}`;
  let border;
  switch (true) {
    case borderType === 'border-start border-dark':
      border = { borderLeft: borderValue };
      break;
    case borderType === 'border-end border-dark':
      border = { borderRight: borderValue };
      break;
    case borderType === 'border-top border-dark':
      border = { borderTop: borderValue };
      break;
    case borderType === 'border-bottom border-dark':
      border = { borderBottom: borderValue };
      break;
    case borderType === 'border border-dark':
      border = { border: borderValue };
      break;
    default:
      border = {};
  }
  return border;
};

export const getFrontCardStyle = (customisations: CustomisationInterface) => {
  return {
    background: customisations.bgcolor,
    paddingTop: !customisations.frontTop ? '25px' : `${customisations.frontTop}px`,
    paddingLeft: !customisations.frontLeft ? '25px' : `${customisations.frontLeft}px`,
    paddingBottom: !customisations.frontBottom ? '0px' : `${customisations.frontBottom}px`,
    paddingRight: !customisations.frontRight ? '25px' : `${customisations.frontRight}px`,
    color: !customisations.frontTextColor ? '#000000' : customisations.frontTextColor,
    fontFamily: `${customisations.fontFamily}`,
    fontSize: `${customisations.frontSize}px`,
    fontWeight: customisations.frontWeight,
    borderLeft: `${customisations.borderSize}px  ${customisations.borderStyle} ${customisations.borderColor}`,
    borderRight: `${customisations.frontBorderSizeRight}px  ${customisations.borderStyle} ${customisations.borderColor}`,
    borderTop: `${customisations.frontBorderSizeTop}px  ${customisations.borderStyle} ${customisations.borderColor}`,
    borderBottom: `${customisations.frontBorderSizeBottom}px  ${customisations.borderStyle} ${customisations.borderColor}`,
  };
};

export const getBackCardStyle = (customisations: CustomisationInterface) => {
  const borderStyle = customisations.backBorderStyle ? customisations.backBorderStyle : 'solid';
  const borderColor = customisations.backBorderColor ? customisations.backBorderColor : '#00000';
  const borderRight = customisations.backBorderSizeRight
    ? customisations.backBorderSizeRight + 'px'
    : '1px';
  const borderLeft = customisations.backBorderSizeLeft
    ? customisations.backBorderSizeLeft + 'px'
    : '1px';
  const borderTop = customisations.backBorderSizeTop
    ? customisations.backBorderSizeTop + 'px'
    : '1px';
  const borderBottom = customisations.backBorderSizeBottom
    ? customisations.backBorderSizeBottom + 'px'
    : '1px';

  return {
    background: customisations.backColor,
    paddingTop: !customisations.backTop ? '25px' : `${customisations.backTop}px`,
    paddingLeft: !customisations.backLeft ? '25px' : `${customisations.backLeft}px`,
    paddingBottom: !customisations.backBottom ? '0px' : `${customisations.backBottom}px`,
    paddingRight: !customisations.backRight ? '25px' : `${customisations.backRight}px`,
    color: !customisations.backTextColor ? '#000000' : customisations.backTextColor,
    fontFamily: `${customisations.fontFamily}`,
    fontSize: `${customisations.backSize}px`,
    fontWeight: customisations.backWeight,
    borderLeft: `${borderLeft}  ${borderStyle}  ${borderColor}`,
    borderRight: `${borderRight} ${borderStyle}  ${borderColor}`,
    borderTop: `${borderTop}  ${borderStyle}  ${borderColor}`,
    borderBottom: `${borderBottom} ${borderStyle}  ${borderColor}`,
  };
};

export const getTabstyle = (customisations: CustomisationInterface) => {
  return {
    fontFamily: `${customisations.fontFamily}`,
    fontWeight: `${customisations.fontWeight}`,
    backgroundColor: `${customisations.bgcolor}`,
    fontSize: `${customisations.fontSize}px`,
    color: `${customisations.fontColor}` ? `${customisations.fontColor}` : '#007bff',
    'min-height': `35px`,
  };
};

export const getClickCardStyle = (customisations: CustomisationInterface) => {
  const {
    clickBorderLeft,
    clickBorderTop,
    clickBorderRight,
    clickBorderBottom,
    clickBorderType,
    clickBorderColor,
    clickBgColor,
    clickBorderRadius,
    clickPaddingTop,
    clickPaddingRight,
    clickPaddingLeft,
    clickPaddingBottom,
  } = customisations;
  return {
    background: clickBgColor,
    borderLeft: `${clickBorderLeft}px  ${clickBorderType} ${clickBorderColor}`,
    borderRight: `${clickBorderRight}px  ${clickBorderType} ${clickBorderColor}`,
    borderTop: `${clickBorderTop}px  ${clickBorderType} ${clickBorderColor}`,
    borderBottom: `${clickBorderBottom}px  ${clickBorderType} ${clickBorderColor}`,
    borderRadius: `${clickBorderRadius}px`,
    paddingLeft: `${clickPaddingLeft}px `,
    paddingRight: `${clickPaddingRight}px `,
    paddingTop: `${clickPaddingTop}px `,
    paddingBottom: `${clickPaddingBottom}px `,
  };
};

export const getRevealCardStyle = (customisations: CustomisationInterface) => {
  const {
    revealBorderLeft,
    revealBorderTop,
    revealBorderRight,
    revealBorderBottom,
    revealBorderType,
    revealBorderColor,
    revealBgColor,
    revealBorderRadius,
    revealPaddingTop,
    revealPaddingRight,
    revealPaddingLeft,
    revealPaddingBottom,
  } = customisations;
  return {
    background: revealBgColor,
    borderLeft: `${revealBorderLeft}px  ${revealBorderType} ${revealBorderColor}`,
    borderRight: `${revealBorderRight}px  ${revealBorderType} ${revealBorderColor}`,
    borderTop: `${revealBorderTop}px  ${revealBorderType} ${revealBorderColor}`,
    borderBottom: `${revealBorderBottom}px  ${revealBorderType} ${revealBorderColor}`,
    borderRadius: `${revealBorderRadius}px`,
    paddingLeft: `${revealPaddingLeft}px `,
    paddingRight: `${revealPaddingRight}px `,
    paddingTop: `${revealPaddingTop}px `,
    paddingBottom: `${revealPaddingBottom}px `,
  };
};
