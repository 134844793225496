import { Extension } from '@tiptap/core';
import '@tiptap/extension-text-style';

export type BorderColorOptions = {
  types: string[];
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    borderColor: {
      setBorderColor: (borderColor: string) => ReturnType;
      setBorderWidth: (borderWidth: string) => ReturnType;
      setBorderStyle: (borderStyle: string) => ReturnType;
    };
  }
}

export const BorderColor = Extension.create<BorderColorOptions>({
  name: 'borderColor',

  addOptions() {
    return {
      types: ['textStyle'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          borderColor: {
            default: null,
            parseHTML: (element) => element.style.borderColor.replace(/['"]+/g, ''),
            renderHTML: (attributes: Record<string, string>) => {
              if (!attributes['borderColor']) {
                return {};
              }
              return {
                style: `border-color: ${attributes['borderColor']}`,
              };
            },
          },
          borderWidth: {
            default: null,
            parseHTML: (element) => element.style.borderWidth.replace(/['"]+/g, ''),
            renderHTML: (attributes: Record<string, string>) => {
              if (!attributes['borderWidth']) {
                return {};
              }
              return {
                style: `border-width: ${attributes['borderWidth']}`,
              };
            },
          },
          borderStyle: {
            default: null,
            parseHTML: (element) => element.style.borderStyle.replace(/['"]+/g, ''),
            renderHTML: (attributes: Record<string, string>) => {
              if (!attributes['borderStyle']) {
                return {};
              }
              return {
                style: `border-style: ${attributes['borderStyle']}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setBorderColor:
        (borderColor) =>
        ({ chain }) => {
          return chain().setMark('textStyle', { borderColor }).run();
        },
      setBorderWidth:
        (borderWidth) =>
        ({ chain }) => {
          return chain().setMark('textStyle', { borderWidth }).run();
        },
      setBorderStyle:
        (borderStyle) =>
        ({ chain }) => {
          return chain().setMark('textStyle', { borderStyle }).run();
        },
    };
  },
});
