import React, { Component } from "react";
import { Alert, Button, Container, Table } from "react-bootstrap";
import {
  deleteDuplicatedUsers,
  fetchDuplicatedUsers,
  updateUserId,
} from "./api";

class Migration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      duplicatedUserData: [],
      orgName: "",
    };
  }

  async componentDidMount() {
    const duplicatedUserData = await fetchDuplicatedUsers(
      this.props.match.params.orgId
    );

    this.setState({
      duplicatedUserData: duplicatedUserData.duplicatedUrserDetails,
      orgName: duplicatedUserData.orgName,
    });
  }

  async handleMigrate(index) {
    const { duplicatedUserData } = this.state;

    const selectedUserData = duplicatedUserData[index];
    const userDetails = [...selectedUserData.details];
    const parent_user_id = userDetails.shift().id;
    const duplicateUsers_id = [];

    for (const element of userDetails) {
      duplicateUsers_id.push(element.id);
    }

    const updatedUserDetails = await updateUserId({
      parent_user_id,
      duplicateUsers_id: JSON.stringify(duplicateUsers_id),
    });

    if (updatedUserDetails.status === 200) {
      selectedUserData.migrateStatus = true;
      selectedUserData.deleteStatus = false;
      selectedUserData.duplicateUsers = JSON.stringify(duplicateUsers_id);

      this.setState({
        duplicatedUserData: duplicatedUserData,
        show: true,
        variant: "success",
        alertMessage: `Duplicated user of ${selectedUserData.email} migrated succssfully`,
      });
    } else {
      this.setState({
        show: true,
        variant: "danger",
        alertMessage: `Error while migrating the duplicated user of ${selectedUserData.email}`,
      });
    }
  }

  async handleDelete(index) {
    const { duplicatedUserData } = this.state;

    const selectedUserData = duplicatedUserData[index];
    const deletedUsers = await deleteDuplicatedUsers({
      duplicateUsers_id: selectedUserData.duplicateUsers,
    });

    if (deletedUsers.status === 200) {
      delete duplicatedUserData[index];

      this.setState({
        duplicatedUserData: duplicatedUserData,
        show: true,
        variant: "success",
        alertMessage: `Duplicated user of ${selectedUserData.email} deleted succssfully`,
      });
    } else {
      this.setState({
        show: true,
        variant: "danger",
        alertMessage: `Error while deleting the duplicated user of ${selectedUserData.email}`,
      });
    }
  }

  render() {
    const {
      duplicatedUserData,
      orgName,
      show,
      variant,
      alertMessage,
    } = this.state;

    return (
      <React.Fragment>
        {show ? (
          <Alert
            id="alert"
            variant={variant}
            onClose={() => this.setState({ show: false })}
            dismissible
          >
            <Alert.Heading>{alertMessage}</Alert.Heading>
          </Alert>
        ) : null}
        <Container>
          {!orgName ? (
            <h1>Organisation does not exists</h1>
          ) : (
            <h1 className="text-capitalize">{orgName}</h1>
          )}
          {duplicatedUserData?.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Index</th>
                  <th>Primary Email</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {duplicatedUserData?.map((userDetail, index) => {
                  return (
                    <tr key={`row_${index}`} id={`row_${index}`}>
                      <td>{index}</td>
                      <td>{userDetail.email}</td>
                      <td>
                        <Button
                          disabled={userDetail.migrateStatus}
                          variant="btn btn-dark btn-sm btn-pill btn btn-primary m-2"
                          onClick={() => this.handleMigrate(index)}
                          id={`migrate_${index}`}
                        >
                          Migrate
                        </Button>
                        <Button
                          disabled={userDetail.deleteStatus}
                          variant="btn btn-sm btn-pill btn btn-danger m-2"
                          onClick={() => this.handleDelete(index)}
                          id={`delete_${index}`}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : !orgName ? null : (
            <h1>No Duplicated users</h1>
          )}
        </Container>
      </React.Fragment>
    );
  }
}

export default Migration;
