/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { filterPtag } from '../../../middleware/defaultValue';
import './flipcard.scss';
import { FlipcardComponent } from './view';

const FlipcardPreview = (props: { parameters: any; customisations: any }) => {
  const [refinedParameters, setRefinedParameters] = useState([]);

  useEffect(() => {
    updateParameters();
  }, []);

  const updateParameters = () => {
    const refineParameters = props.parameters;
    refineParameters.forEach((element: { [x: string]: any }) => {
      element['fronttext'] = filterPtag(element['fronttext']);
      element['backtext'] = filterPtag(element['backtext']);
    });
    setRefinedParameters(refineParameters);
  };

  return (
    <div className='m-2'>
      <FlipcardComponent
        parameters={refinedParameters}
        customisations={props.customisations}
        type='preview'
      />
    </div>
  );
};

export default FlipcardPreview;
