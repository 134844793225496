/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Org and User auth check
// Request generator for create content
export let userVerification = async (user: string, org_id: string) => {
  let userVerify = {
    method: 'POST',
    endPoint: '/user/verify',
    body: { user, org_id },
  };
  let verifiedUser = (await callApi(userVerify)) as any;
  sessionStorage.setItem('user', user);
  sessionStorage.setItem('org_id', org_id);
  sessionStorage.setItem('user_id', verifiedUser.user);
  return verifiedUser;
};

// To fetch the trackingId of each organization
export let fetchGaID = async (orgId: string | null) => {
  let data = {
    method: 'GET',
    endPoint: '/gaId',
    body: orgId,
  };
  let trackingId = (await callApi(data)) as any;
  sessionStorage.setItem('trakingId', trackingId.gaID);
  return trackingId;
};
// Fetching the library list
export let getLibrary = async () => {
  let data = {
    method: 'GET',
    endPoint: '/library',
  };
  let libraryResult = await callApi(data);
  return libraryResult;
};

// Request generator to fetch content
export const fetchContent = async (id: string | null, user: string | null) => {
  let apiData = {
    method: 'GET',
    endPoint: `/content/${id}`,
    headers: { user_id: user },
  };
  return await callApi(apiData);
};

// Fetching the pagination content list
export let getPaginationContents = async (user_id: string, page: string, limit: string) => {
  let data = {
    method: 'GET',
    endPoint: `/content/paginate/${page}/${limit}`,
    headers: {
      user_id: user_id,
      org_id: sessionStorage.getItem('org_id'),
    },
  };
  let contentResult = await callApi(data);
  return contentResult;
};

//view exact content
export const viewContent = async (id: string) => {
  let apiData = {
    method: 'GET',
    endPoint: `/content/view/${id}`,
  };
  return await callApi(apiData);
};

// Fetching the contents list
export let getContents = async (user_id: string) => {
  let data = {
    method: 'GET',
    endPoint: '/content',
    headers: { user_id: user_id, org_id: sessionStorage.getItem('org_id') },
  };
  let contentResult = await callApi(data);
  return contentResult;
};

// Get Individual content
export let getContent = async (id: string, user: string) => {
  let data = {
    method: 'GET',
    endPoint: `/content/${id}`,
    headers: { user_id: user },
  };
  return await callApi(data);
};

// Request generator for create content
export let createContent = async (data: {}) => {
  // data.user_id = 1;
  let apiData = {
    method: 'POST',
    endPoint: '/content',
    body: data,
  };
  return await callApi(apiData);
};

export let titleCheck = async (data: {}) => {
  let apiData = {
    method: 'POST',
    endPoint: '/content/titlecheck',
    body: data,
  };
  return await callApi(apiData);
};

export let createGlobalContent = async (data: {}) => {
  let apiData = {
    method: 'POST',
    endPoint: '/shareInteractiveContent',
    body: data,
  };
  return await callApi(apiData);
};

// Delete individual content
export let deleteContent = async (user_id: string, id?: string) => {
  let apiData = {
    method: 'DELETE',
    endPoint: `/content/${id}`,
    headers: { user_id },
  };
  return await callApi(apiData);
};

// Edit individual content
export let editContent = (data: { id: string; body: {} }) => {
  let apiData = {
    method: 'PUT',
    endPoint: `/content/${data.id}`,
    body: data.body,
  };
  return callApi(apiData);
};

//To Edit individual status of the created component
export let toggleActive = (user_id: string, status: boolean, id?: string, lib_id?: number) => {
  let apiData = {
    method: 'PUT',
    endPoint: `/content/update/${id}`,
    body: { id, user_id, lib_id, active: status },
  };
  return callApi(apiData);
};

// To upload the images form editor to s3
export let uploadToS3 = (file: {}) => {
  let apiData = {
    method: 'POST',
    endPoint: '/s3',
    headers: {
      orgId: sessionStorage.getItem('org_id'),
      userId: sessionStorage.getItem('user_id'),
    },
    body: file,
  };
  return callApi(apiData);
};

export let getUserInteractives = async (user_id: string) => {
  let data = {
    method: 'GET',
    endPoint: '/api/user/interactives',
    headers: { userid: user_id, orgid: sessionStorage.getItem('org_id') },
  };
  let contentResult = await callApi(data);
  return contentResult;
};

export let userVerify = async (user_id: string) => {
  let data = {
    method: 'GET',
    endPoint: '/api/user/verify',
    headers: { userid: user_id },
  };
  let contentResult = await callApi(data);
  return contentResult;
};

export let verifyOrg = async (org_id: string) => {
  let data = {
    method: 'GET',
    endPoint: '/api/org/verify',
    headers: { orgid: org_id },
  };
  let contentResult = await callApi(data);
  return contentResult;
};

export let getGlobalInteractives = async (orgId: string) => {
  let data = {
    method: 'GET',
    endPoint: '/api/global/interactives',
    headers: { orgid: orgId, userid: sessionStorage.getItem('user_id') },
  };
  let contentResult = await callApi(data);
  return contentResult;
};

export let getSharedInteractives = async () => {
  let data = {
    method: 'GET',
    endPoint: '/api/shared/interactives',
    headers: {
      orgid: sessionStorage.getItem('org_id'),
      accountid: sessionStorage.getItem('sharedAccount'),
      userid: sessionStorage.getItem('user_id'),
    },
  };
  let contentResult = await callApi(data);
  return contentResult;
};

/* Will be deleted once all the images of the content are migrated to s3 */
export const getComponentById = async (id: string) => {
  let apiData = {
    method: 'GET',
    endPoint: `/content/component/${id}`,
  };

  return await callApi(apiData);
};

/* Will be deleted once all the images of the content are migrated to s3 */
export const migrateImagesTos3 = async (inputDetails: {}) => {
  let apiData = {
    method: 'POST',
    endPoint: '/s3/migrate',
    body: inputDetails,
  };

  return await callApi(apiData);
};

/* Will be deleted once all the images of the content are migrated to s3 */
export let saveUpdatedContent = (data: { id: string; parameters: {} }) => {
  let apiData = {
    method: 'PUT',
    endPoint: `/content/migrate/${data.id}`,
    body: data.parameters,
  };
  return callApi(apiData);
};

/* will be deleted when the content is migrated from v1 to v2 */

export let fetchDuplicatedUsers = (orgId: string) => {
  return callApi({
    method: 'GET',
    endPoint: `/migration/duplicateUser`,
    headers: { org_id: orgId },
  });
};

export let updateUserId = (inputData: {}) => {
  return callApi({
    method: 'PUT',
    endPoint: `/migration/updateContent`,
    body: inputData,
  });
};

export let deleteDuplicatedUsers = (inputData: {}) => {
  return callApi({
    method: 'DELETE',
    endPoint: `/migration/deleteUser`,
    body: inputData,
  });
};

export let fetchImages = async (org_id: string | null) => {
  let data = {
    method: 'GET',
    endPoint: `/images/${org_id}`,
  };
  let contentResult = await callApi(data);
  return contentResult;
};
// Send request to API
let callApi = async (data: any) => {
  let response = await axios({
    method: data.method,
    url: data.endPoint,
    headers: data.headers,
    data: data.body,
  });
  return response.data;
};
