export const moveNavigationLeft = () => {
  let navigation = document.getElementsByClassName('interactivetab')[0];
  if (window.outerWidth > 992) {
    navigation.scrollLeft = navigation.scrollLeft - 210;
  } else if (window.outerWidth > 320) {
    navigation.scrollLeft = navigation.scrollLeft - 110;
  } else {
    navigation.scrollLeft = navigation.scrollLeft - 90;
  }
};

export const moveNavigationRight = () => {
  let navigation = document.getElementsByClassName('interactivetab')[0];
  if (window.outerWidth > 992) {
    navigation.scrollLeft = navigation.scrollLeft + 210;
  } else if (window.outerWidth > 320) {
    navigation.scrollLeft = navigation.scrollLeft + 110;
  } else {
    navigation.scrollLeft = navigation.scrollLeft + 90;
  }
};
