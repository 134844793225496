import { FlipFormInterface } from '../interface';

//handles add panel functionality
export const addFlipCardPanel = (data: FlipFormInterface) => ({
  type: 'ADD_PANEL',
  parameters: data.parameters,
  contentArr: data.contentArr,
  num: data.num,
});

//handles title value within the panel
export const handleChange = (data: FlipFormInterface) => ({
  type: 'HANDLE_CHANGE',
  parameters: data.parameters,
  errors: data.errors,
});
export const rearangeArray = (data: FlipFormInterface) => ({
  type: 'REARANGE_ARRAY',
  parameters: data.parameters,
  contentArr: data.contentArr,
});

//handles the value of main-title of the form
export const handleTitle = (data: FlipFormInterface) => ({
  type: 'HANDLE_TITLE',
  title: data.title,
});

//handles the remove functionality for selected panel
export const removeClick = (data: FlipFormInterface) => ({
  type: 'REMOVE_CLICK',
  parameters: data.parameters,
  contentArr: data.contentArr,
  errors: data.errors,
});

//validation for empty feildssta
export const errorsFetch = (data: FlipFormInterface) => ({
  type: 'ERRORS',
  errors: data.errors,
});

export const makeFlipCardEdit = (data: FlipFormInterface) => ({
  type: 'MAKE_EDIT',
  title: data.title,
  parameters: data.parameters,
  contentArr: data.contentArr,
  component: data.type,
  bgcolor: data.bgcolor,
  backColor: data.backColor,
  height: data.height,
  noOfCard: data.noOfCard,
  radius: data.radius,
  backBorderRadius: data.backBorderRadius,
  borderType: data.borderType,
  borderColor: data.borderColor,
  backBorderColor: data.backBorderColor,
  borderSize: data.borderSize,
  frontBorderSizeRight: data.frontBorderSizeRight,
  frontBorderSizeTop: data.frontBorderSizeTop,
  frontBorderSizeBottom: data.frontBorderSizeBottom,
  backBorderSizeLeft: data.backBorderSizeLeft,
  backBorderSizeRight: data.backBorderSizeRight,
  backBorderSizeTop: data.backBorderSizeTop,
  backBorderSizeBottom: data.backBorderSizeBottom,
  borderStyle: data.borderStyle,
  backBorderStyle: data.backBorderStyle,
  flip: data.flip,
  frontTop: data.frontTop,
  frontRight: data.frontRight,
  frontBottom: data.frontBottom,
  frontLeft: data.frontLeft,
  backTop: data.backTop,
  backRight: data.backRight,
  backBottom: data.backBottom,
  backLeft: data.backLeft,
  frontTextColor: data.frontTextColor,
  backTextColor: data.backTextColor,
  cardType: data.cardType,
  fontFamily: data.fontFamily,
  frontHeader: data.frontHeader,
  frontSize: data.frontSize,
  frontWeight: data.frontWeight,
  backHeader: data.backHeader,
  backSize: data.backSize,
  backWeight: data.backWeight,
});

export const setType = (data: FlipFormInterface) => ({
  type: 'SET_TYPE',
  component: data.type,
});

//To set the ID for the panels
export const setValue = (data: any) => ({
  type: 'SET_VALUE',
  num: data.num,
  option_id: data.option_id,
});

export const setBgColor = (data: FlipFormInterface) => ({
  type: 'CUSTOM_BGCOLOR',
  bgcolor: data.bgcolor,
});

export const setTextColor = (data: FlipFormInterface) => ({
  type: 'CUSTOM_TEXTCOLOR',
  frontTextColor: data.frontTextColor,
});

export const setBackTextColor = (data: FlipFormInterface) => ({
  type: 'CUSTOM_BACKTEXTCOLOR',
  backTextColor: data.backTextColor,
});

export const backColor = (data: FlipFormInterface) => ({
  type: 'CUSTOM_BACKCOLOR',
  backColor: data.backColor,
});

export const height = (data: FlipFormInterface) => ({
  type: 'CUSTOM_HEIGHT',
  height: data.height,
});

export const noOfCard = (data: FlipFormInterface) => ({
  type: 'CUSTOM_NOOFCARD',
  noOfCard: data.noOfCard,
});

export const radius = (data: FlipFormInterface) => ({
  type: 'CUSTOM_RADIUS',
  radius: data.radius,
});

export const borderType = (data: FlipFormInterface) => ({
  type: 'CUSTOM_BORDER',
  borderType: data.borderType,
});

export const setFrontBorderColor = (data: FlipFormInterface) => ({
  type: 'BORDER_COLOR',
  borderColor: data.borderColor,
});

export const setBackBorderColor = (data: FlipFormInterface) => ({
  type: 'BACK_BORDER_COLOR',
  backBorderColor: data.backBorderColor,
});
export const setBackBorderRadius = (data: FlipFormInterface) => ({
  type: 'BACK_BORDER_RADIUS',
  backBorderRadius: data.backBorderRadius,
});
export const setBackBorderSizeLeft = (data: FlipFormInterface) => ({
  type: 'BACK_BORDER_SIZE_LEFT',
  backBorderSizeLeft: data.backBorderSizeLeft,
});
export const setBackBorderSizeRight = (data: FlipFormInterface) => ({
  type: 'BACK_BORDER_SIZE_RIGHT',
  backBorderSizeRight: data.backBorderSizeRight,
});
export const setBackBorderSizeTop = (data: FlipFormInterface) => ({
  type: 'BACK_BORDER_SIZE_TOP',
  backBorderSizeTop: data.backBorderSizeTop,
});
export const setBackBorderSizeBottom = (data: FlipFormInterface) => ({
  type: 'BACK_BORDER_SIZE_BOTTOM',
  backBorderSizeBottom: data.backBorderSizeBottom,
});
export const setFrontBorderSizeRight = (data: FlipFormInterface) => ({
  type: 'FRONT_BORDER_SIZE_RIGHT',
  frontBorderSizeRight: data.frontBorderSizeRight,
});
export const setFrontBorderSizeTop = (data: FlipFormInterface) => ({
  type: 'FRONT_BORDER_SIZE_TOP',
  frontBorderSizeTop: data.frontBorderSizeTop,
});
export const setFrontBorderSizeBottom = (data: FlipFormInterface) => ({
  type: 'FRONT_BORDER_SIZE_BOTTOM',
  frontBorderSizeBottom: data.frontBorderSizeBottom,
});
export const setBackBorderStyle = (data: FlipFormInterface) => ({
  type: 'BACK_BORDER_STYLE',
  backBorderStyle: data.backBorderStyle,
});
export const setBorderSize = (data: FlipFormInterface) => ({
  type: 'BORDER_SIZE',
  borderSize: data.borderSize,
});

export const setBorderStyle = (data: FlipFormInterface) => ({
  type: 'BORDER_STYLE',
  borderStyle: data.borderStyle,
});

export const flip = (data: FlipFormInterface) => ({
  type: 'CUSTOM_FLIP',
  flip: data.flip,
});

export const deleteImage = (data: FlipFormInterface) => ({
  type: 'REMOVE_IMAGE',
  removeImage: data.removeImage,
});

export const customFrontPaddingtop = (data: FlipFormInterface) => ({
  type: 'CUSTOM_FRONTTOP',
  frontTop: data.frontTop,
});

export const customFrontPaddingleft = (data: FlipFormInterface) => ({
  type: 'CUSTOM_FRONTLEFT',
  frontLeft: data.frontLeft,
});

export const customFrontPaddingbottom = (data: FlipFormInterface) => ({
  type: 'CUSTOM_FRONTBOTTOM',
  frontBottom: data.frontBottom,
});

export const customFrontPaddingright = (data: FlipFormInterface) => ({
  type: 'CUSTOM_FRONTRIGHT',
  frontRight: data.frontRight,
});

export const customBackPaddingtop = (data: FlipFormInterface) => ({
  type: 'CUSTOM_BACKTOP',
  backTop: data.backTop,
});

export const customBackPaddingleft = (data: FlipFormInterface) => ({
  type: 'CUSTOM_BACKLEFT',
  backLeft: data.backLeft,
});

export const customBackPaddingbottom = (data: FlipFormInterface) => ({
  type: 'CUSTOM_BACKBOTTOM',
  backBottom: data.backBottom,
});

export const customBackPaddingright = (data: FlipFormInterface) => ({
  type: 'CUSTOM_BACKRIGHT',
  backRight: data.backRight,
});

export const cardType = (data: FlipFormInterface) => ({
  type: 'CUSTOM_CARDTYPE',
  cardType: data.cardType,
});

export const fontFamily = (data: FlipFormInterface) => ({
  type: 'CUSTOM_FONTFAMILY',
  fontFamily: data.fontFamily,
});

export const frontSize = (data: FlipFormInterface) => ({
  type: 'CUSTOM_FRONT_SIZE',
  frontSize: data.frontSize,
});

export const frontHeader = (data: FlipFormInterface) => ({
  type: 'CUSTOM_FRONT_HEADER',
  frontHeader: data.frontHeader,
});

export const frontWeight = (data: FlipFormInterface) => ({
  type: 'CUSTOM_FRONT_WEIGHT',
  frontWeight: data.frontWeight,
});

export const backSize = (data: FlipFormInterface) => ({
  type: 'CUSTOM_BACK_SIZE',
  backSize: data.backSize,
});

export const backHeader = (data: FlipFormInterface) => ({
  type: 'CUSTOM_BACK_HEADER',
  backHeader: data.backHeader,
});

export const backWeight = (data: FlipFormInterface) => ({
  type: 'CUSTOM_BACK_WEIGHT',
  backWeight: data.backWeight,
});

export const setDefault = () => ({
  type: '',
});
