/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { CustomFontFamilySelector } from './customFontFamilySelector';
import { LintEditorFormatPropsType, FontSizeData } from './lintEditorType';
import { EditorFontSizeSelector, EditorFontUnitSelector } from './styledContents';
import {
  applyStylesToList,
  formatFontSize,
  isBulletList,
  isOrderedList,
  isWholeContentSelected,
} from '../editorExtensions/utilityFunctions';

export function FontSectionContent(props: LintEditorFormatPropsType) {
  const { editor, editorConfig, fontFamilyName, fontSizeValue } = props;
  const [fontFamilyState, setFontFamilyState] = useState(fontFamilyName);
  const [fontSizeValueState, setFontSizeValueState] = useState(fontSizeValue);

  useEffect(() => {
    setFontFamilyState(fontFamilyName);
    setFontSizeValueState(fontSizeValue);
  }, [fontFamilyName, fontSizeValue]);
  if (!editor) {
    return null;
  }

  const setFontName = (fontName: string): void => {
    // if (this.isLinkText()) {
    //   this.editor.chain().focus().setLinkFont(f).run();
    //   return;
    // }
    if ((isBulletList(editor) || isOrderedList(editor)) && isWholeContentSelected(editor).status) {
      applyStylesToList({ fontFamily: fontName }, 'fontFamily', editor);
      return;
    }
    setFontFamilyState(fontName);
    if (fontName === '') {
      editor.chain().focus().unsetFontFamily().run();
      return;
    }
    if (isWholeContentSelected(editor).status) {
      applyStylesToList({ fontFamily: fontName }, 'fontFamily', editor);
      return;
    }
    editor.chain().focus().setFontFamily(fontName).run();
  };

  const setFontWithMetrics = (size: FontSizeData, isTableCell?: boolean): void => {
    if (size === null) {
      editor.chain().focus().unsetFontSize().run();
      return;
    }
    if (
      isTableCell ||
      ((isBulletList(editor) || isOrderedList(editor)) &&
        (isWholeContentSelected(editor)?.status ||
          editor.state.selection.$anchor.parent !== editor.state.selection.$head.parent))
    ) {
      applyStylesToList({ fontSize: formatFontSize(size) }, 'fontSize', editor);
      return;
    }
    setFontSizeValueState(size);
    if (isWholeContentSelected(editor).status) {
      editor.chain().focus().unsetFontSize().run();
      editor
        .chain()
        .updateNodeStyles(isWholeContentSelected(editor).type, {
          'font-size': `${size.size}${size.unit}`,
        })
        .run();
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    editor.chain().focus().setFontSize(size).run();
  };
  const setFontSizeMetricData = (fontSizeDataContent: FontSizeData | null) => {
    if (!fontSizeDataContent) return;
    setFontWithMetrics(fontSizeDataContent);
  };

  return (
    <>
      <CustomFontFamilySelector
        customFontList={editorConfig?.fontFamilyList ?? []}
        fontName={fontFamilyState}
        onFontFamilyChange={(value) => {
          setFontName(value);
        }}
      />
      <EditorFontSizeSelector activeSize={fontSizeValueState} onChange={setFontSizeMetricData} />
      <EditorFontUnitSelector activeSize={fontSizeValueState} onChange={setFontSizeMetricData} />
    </>
  );
}
