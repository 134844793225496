//initial state for redux
import { FormInterface } from '../interface';

export const INITIAL_STATE = {
  num: 0,
  contentArr: [['title_0', 'text_0']],
  dndArray: [['background_0', 'text_0']],
  title: '',
  parameters: [{ title: '', text: '' }],
  dndParameters: [{ background: '', dropZone: [] }],
  errors: {},
  type: '',
  fontHeadings: '',
  fontFamily: 'Helvetica',
  fontSize: '16',
  fontWeight: 'Normal',
  radius: 'rounded-0',
  icon: 'arrow',
  bgcolor: '#F2F2F2',
  alignment: 'left',
  fontColor: '#000000',
  noOfLayout: '4',
  fixedLayout: false,
  inactivefontColor: '#000000',
  borderType: '',
  borderColor: '#000000',
  borderSize: '1',
  borderStyle: 'solid',
  setcaptionAlignment: 'bottom',
  iconColor: '#000000',
  iconSize: '10px',
  iconAlignment: 'Default',
  iconType: '0%',
  imageBgColor: '#ffffff',
  dndLayout: 'CustomOverlay',
  dragAreaType: 'btn btn-square btn-dark',
  borderRadius: '0',
  borderTop: '0',
  borderRight: '0',
  borderLeft: '0',
  borderBottom: '0',
  paddingTop: '10',
  paddingLeft: '10',
  paddingBottom: '10',
  paddingRight: '10',
  dropzoneBgColor: '#F2F2F2',
  dropzoneOpacity: '0.5',
  hotspotIcon: {
    draw: 'M3898.577,496.06h-4.792V491.27a1.354,1.354,0,0,0-2.709,0v4.791h-4.79a1.355,1.355,0,1,0,0,2.709h4.79v4.791a1.354,1.354,0,0,0,2.709,0V498.77h4.792a1.355,1.355,0,0,0,0-2.709Z',
    iconType: 'Plus',
  },
  removeImage: [],
  btnPaddingTop: '10',
  btnPaddingLeft: '10',
  btnPaddingBottom: '10',
  btnPaddingRight: '10',
  btnFontFamily: 'Helvetica',
  btnFontSize: '16',
  btnFontWeight: 'Normal',
  btnFontColor: '#ffffff',
  btnBgColor: '#112299',
  btnType: 'btn btn-square btn-dark',
  marginTop: '0',
  marginBottom: '0',
  clickBorderRadius: '0',
  clickBorderTop: '0',
  clickBorderRight: '0',
  clickBorderLeft: '0',
  clickBorderBottom: '0',
  clickBorderColor: '#000000',
  clickBorderType: 'solid',
  clickBgColor: '#ffffff',
  revealBorderRadius: '0',
  revealBorderTop: '0',
  revealBorderRight: '0',
  revealBorderLeft: '0',
  revealBorderBottom: '0',
  revealBorderColor: '#000000',
  revealBorderType: 'solid',
  revealBgColor: '#ffffff',
  clickPaddingTop: '0',
  clickPaddingRight: '0',
  clickPaddingLeft: '0',
  clickPaddingBottom: '0',
  revealPaddingTop: '0',
  revealPaddingRight: '0',
  revealPaddingLeft: '0',
  revealPaddingBottom: '0',
  displayActiveColorPicker: '#FFFFFF',
  separatorColor: '#000000',
  separatorWidth: '0',
};

//action creators with actions
const formReducer = (state = INITIAL_STATE, data: FormInterface) => {
  switch (data.type) {
    //add panel action
    case 'ADD_PANEL':
      return {
        ...state,
        parameters: data.parameters,
        contentArr: data.contentArr,
        num: data.num,
      };

    //title within panel action
    case 'HANDLE_CHANGE':
      return {
        ...state,
        parameters: data.parameters,
        errors: data.errors,
      };

    //rearange the parameters
    case 'REARANGE_ARRAY':
      return {
        ...state,
        parameters: data.parameters,
        contentArr: data.contentArr,
      };

    //main-title action
    case 'HANDLE_TITLE':
      return {
        ...state,
        title: data.title,
      };

    //remove action
    case 'REMOVE_CLICK':
      return {
        ...state,
        parameters: data.parameters,
        contentArr: data.contentArr,
        errors: data.errors,
      };

    //validation action
    case 'ERRORS':
      return {
        ...state,
        errors: data.errors,
      };
    case 'MAKE_EDIT':
      return {
        ...state,
        parameters: data.parameters,
        contentArr: data.contentArr,
        title: data.title,
        type: data.component,
        fontHeadings: data.fontHeadings,
        fontFamily: data.fontFamily,
        fontSize: data.fontSize,
        fontWeight: data.fontWeight,
        radius: data.radius,
        bgcolor: data.bgcolor,
        icon: data.icon,
        alignment: data.alignment,
        fontColor: data.fontColor,
        inactivefontColor: data.inactivefontColor,
        borderType: data.borderType,
        borderColor: data.borderColor,
        borderSize: data.borderSize,
        borderStyle: data.borderStyle,
        setcaptionAlignment: data.setcaptionAlignment,
        iconColor: data.iconColor,
        iconSize: data.iconSize,
        iconAlignment: data.iconAlignment,
        iconType: data.iconType,
        imageBgColor: data.imageBgColor,
        dndLayout: data.dndLayout,
        dndParameters: data.dndParameters,
        dndArray: data.dndArray,
        dragAreaType: data.dragAreaType,
        paddingTop: data.paddingTop,
        paddingLeft: data.paddingLeft,
        paddingBottom: data.paddingBottom,
        paddingRight: data.paddingRight,
        dropzoneBgColor: data.dropzoneBgColor,
        dropzoneOpacity: data.dropzoneOpacity,
        hotspotIcon: data.hotspotIcon,
        btnPaddingTop: data.btnPaddingTop,
        btnPaddingLeft: data.btnPaddingLeft,
        btnPaddingBottom: data.btnPaddingBottom,
        btnPaddingRight: data.btnPaddingRight,
        btnFontColor: data.btnFontColor,
        btnFontWeight: data.btnFontWeight,
        btnFontSize: data.btnFontSize,
        btnFontFamily: data.btnFontFamily,
        btnBgColor: data.btnBgColor,
        btnType: data.btnType,
        borderTop: data.borderTop,
        borderRight: data.borderRight,
        borderLeft: data.borderLeft,
        borderBottom: data.borderBottom,
        borderRadius: data.borderRadius,
        marginTop: data.marginTop,
        marginBottom: data.marginBottom,
        clickBorderRadius: data.clickBorderRadius,
        clickBorderTop: data.clickBorderTop,
        clickBorderRight: data.clickBorderRight,
        clickBorderLeft: data.clickBorderLeft,
        clickBorderBottom: data.clickBorderBottom,
        clickBorderColor: data.clickBorderColor,
        clickBorderType: data.clickBorderType,
        clickBgColor: data.clickBgColor,
        revealBorderRadius: data.revealBorderRadius,
        revealBorderTop: data.revealBorderTop,
        revealBorderRight: data.revealBorderRight,
        revealBorderLeft: data.revealBorderLeft,
        revealBorderBottom: data.revealBorderBottom,
        revealBorderColor: data.revealBorderColor,
        revealBorderType: data.revealBorderType,
        revealBgColor: data.revealBgColor,
        clickPaddingTop: data.clickPaddingTop,
        clickPaddingRight: data.clickPaddingRight,
        clickPaddingLeft: data.clickPaddingLeft,
        clickPaddingBottom: data.clickPaddingBottom,
        revealPaddingTop: data.revealPaddingTop,
        revealPaddingRight: data.revealPaddingRight,
        revealPaddingLeft: data.revealPaddingLeft,
        revealPaddingBottom: data.revealPaddingBottom,
        displayActiveColorPicker: data.displayActiveColorPicker,
        separatorColor: data.separatorColor,
        separatorWidth: data.separatorWidth,
        noOfLayout: data.noOfLayout,
        fixedLayout: data.fixedLayout,
      };
    case 'SET_TYPE':
      return {
        ...state,
        type: data.component,
      };

    // To set the ID for the panels
    case 'SET_VALUE':
      return {
        ...state,
        num: data.num,
      };

    //customization actions
    case 'FONT_HEADINGS':
      return {
        ...state,
        fontHeadings: data.component,
      };

    case 'FONT_TYPE':
      return {
        ...state,
        fontFamily: data.component,
      };

    case 'FONT_SIZE':
      return {
        ...state,
        fontSize: data.component,
      };

    case 'FONT_WEIGHT':
      return {
        ...state,
        fontWeight: data.fontWeight,
      };

    case 'CUSTOM_BORDER':
      return {
        ...state,
        borderType: data.borderType,
      };

    case 'BORDER_COLOR':
      return {
        ...state,
        borderColor: data.borderColor,
      };

    case 'BORDER_SIZE':
      return {
        ...state,
        borderSize: data.borderSize,
      };

    case 'BORDER_STYLE':
      return {
        ...state,
        borderStyle: data.borderStyle,
      };

    case 'SET_ICON':
      return {
        ...state,
        icon: data.icon,
      };
    case 'CUSTOM_RADIUS':
      return {
        ...state,
        radius: data.radius,
      };
    case 'CUSTOM_BGCOLOR':
      return {
        ...state,
        bgcolor: data.bgcolor,
      };

    case 'CUSTOM_ALIGNMENT':
      return {
        ...state,
        alignment: data.alignment,
      };
    case 'CUSTOM_FONTCOLOR':
      return {
        ...state,
        fontColor: data.fontColor,
      };
    case 'CUSTOM_INACTIVEFONTCOLOR':
      return {
        ...state,
        inactivefontColor: data.inactivefontColor,
      };
    case 'CUSTOM_CAPTIONALIGNMENT':
      return {
        ...state,
        setcaptionAlignment: data.setcaptionAlignment,
      };
    case 'ICON_COLOR':
      return {
        ...state,
        iconColor: data.iconColor,
      };

    case 'ICON_SIZE':
      return {
        ...state,
        iconSize: data.iconSize,
      };

    case 'ICON_TYPE':
      return {
        ...state,
        iconType: data.iconType,
      };

    case 'IMAGE_BG_COLOR':
      return {
        ...state,
        imageBgColor: data.imageBgColor,
      };

    case 'CUSTOM_ICON_ALIGNMENT':
      return {
        ...state,
        iconAlignment: data.iconAlignment,
      };

    case 'DRAG_AND_DROP_LAYOUT':
      return {
        ...state,
        dndLayout: data.dndLayout,
        errors: data.errors,
      };

    case 'SET_BACKGROUND_IMG':
      return {
        ...state,
        dndParameters: data.dndParameters,
        errors: data.errors,
      };

    case 'SET_ERROR':
      return {
        ...state,
        errors: data.errors,
      };

    case 'ADD_SECTION':
      return {
        ...state,
        dndParameters: data.dndParameters,
        dndArray: data.dndArray,
        num: data.num,
      };

    //rearange the parameters of drangdrop
    case 'SORT_ARRAY':
      return {
        ...state,
        dndParameters: data.dndParameters,
        dndArray: data.dndArray,
      };

    case 'REMOVE_ELEMENT':
      return {
        ...state,
        dndParameters: data.dndParameters,
        dndArray: data.dndArray,
        errors: data.errors,
      };

    case 'DRAG_AREA_TYPE':
      return {
        ...state,
        dragAreaType: data.dragAreaType,
      };

    case 'CUSTOM_PADDINGTOP':
      return {
        ...state,
        paddingTop: data.paddingTop,
      };

    case 'CUSTOM_PADDINGLEFT':
      return {
        ...state,
        paddingLeft: data.paddingLeft,
      };

    case 'CUSTOM_PADDINGBOTTOM':
      return {
        ...state,
        paddingBottom: data.paddingBottom,
      };

    case 'CUSTOM_PADDINGRIGHT':
      return {
        ...state,
        paddingRight: data.paddingRight,
      };

    case 'DROPZONE_BGCOLOR':
      return {
        ...state,
        dropzoneBgColor: data.dropzoneBgColor,
      };

    case 'DROPZONE_OPACITY':
      return {
        ...state,
        dropzoneOpacity: data.dropzoneOpacity,
      };

    case 'SET_HOTSPOT_ICON':
      return {
        ...state,
        hotspotIcon: data.hotspotIcon,
      };
    case 'REMOVE_IMAGE':
      return {
        ...state,
        removeImage: data.removeImage,
      };

    case 'BORDER_TOP':
      return {
        ...state,
        borderTop: data.borderTop,
      };
    case 'BORDER_BOTTOM':
      return {
        ...state,
        borderBottom: data.borderBottom,
      };
    case 'BORDER_RIGHT':
      return {
        ...state,
        borderRight: data.borderRight,
      };
    case 'BORDER_LEFT':
      return {
        ...state,
        borderLeft: data.borderLeft,
      };
    case 'BORDER_RADIUS':
      return {
        ...state,
        borderRadius: data.borderRadius,
      };
    case 'PADDINGTOP':
      return {
        ...state,
        btnPaddingTop: data.btnPaddingTop,
      };

    case 'PADDINGLEFT':
      return {
        ...state,
        btnPaddingLeft: data.btnPaddingLeft,
      };

    case 'PADDINGBOTTOM':
      return {
        ...state,
        btnPaddingBottom: data.btnPaddingBottom,
      };

    case 'PADDINGRIGHT':
      return {
        ...state,
        btnPaddingRight: data.btnPaddingRight,
      };

    case 'FONTSIZE':
      return {
        ...state,
        btnFontSize: data.btnFontSize,
      };

    case 'FONTCOLOR':
      return {
        ...state,
        btnFontColor: data.btnFontColor,
      };

    case 'FONTFAMILY':
      return {
        ...state,
        btnFontFamily: data.btnFontFamily,
      };

    case 'FONTWEIGHT':
      return {
        ...state,
        btnFontWeight: data.btnFontWeight,
      };

    case 'BG_COLOR':
      return {
        ...state,
        btnBgColor: data.btnBgColor,
      };
    case 'ACTIVE_COLOR_TAB':
      return {
        ...state,
        displayActiveColorPicker: data.displayActiveColorPicker,
      };

    case 'SEPARATOR_COLOR':
      return {
        ...state,
        separatorColor: data.separatorColor,
      };

    case 'SEPARATOR_WIDTH':
      return {
        ...state,
        separatorWidth: data.separatorWidth,
      };
    case 'BUTTON_TYPE':
      return {
        ...state,
        btnType: data.btnType,
      };
    case 'MARGIN_TOP':
      return {
        ...state,
        marginTop: data.marginTop,
      };
    case 'MARGIN_BOTTOM':
      return {
        ...state,
        marginBottom: data.marginBottom,
      };
    case 'CLICK_BORDER_TOP':
      return {
        ...state,
        clickBorderTop: data.clickBorderTop,
      };
    case 'CLICK_BORDER_BOTTOM':
      return {
        ...state,
        clickBorderBottom: data.clickBorderBottom,
      };
    case 'CLICK_BORDER_RIGHT':
      return {
        ...state,
        clickBorderRight: data.clickBorderRight,
      };
    case 'CLICK_BORDER_LEFT':
      return {
        ...state,
        clickBorderLeft: data.clickBorderLeft,
      };
    case 'CLICK_BORDER_RADIUS':
      return {
        ...state,
        clickBorderRadius: data.clickBorderRadius,
      };
    case 'CLICK_BORDER_TYPE':
      return {
        ...state,
        clickBorderType: data.clickBorderType,
      };
    case 'CLICK_BORDER_COLOR':
      return {
        ...state,
        clickBorderColor: data.clickBorderColor,
      };
    case 'CLICK_BG_COLOR':
      return {
        ...state,
        clickBgColor: data.clickBgColor,
      };
    case 'REVEAL_BORDER_TOP':
      return {
        ...state,
        revealBorderTop: data.revealBorderTop,
      };
    case 'REVEAL_BORDER_BOTTOM':
      return {
        ...state,
        revealBorderBottom: data.revealBorderBottom,
      };
    case 'REVEAL_BORDER_RIGHT':
      return {
        ...state,
        revealBorderRight: data.revealBorderRight,
      };
    case 'REVEAL_BORDER_LEFT':
      return {
        ...state,
        revealBorderLeft: data.revealBorderLeft,
      };
    case 'REVEAL_BORDER_RADIUS':
      return {
        ...state,
        revealBorderRadius: data.revealBorderRadius,
      };
    case 'REVEAL_BORDER_TYPE':
      return {
        ...state,
        revealBorderType: data.revealBorderType,
      };
    case 'REVEAL_BORDER_COLOR':
      return {
        ...state,
        revealBorderColor: data.revealBorderColor,
      };
    case 'REVEAL_BG_COLOR':
      return {
        ...state,
        revealBgColor: data.revealBgColor,
      };
    case 'CLICK_PADDING_TOP':
      return {
        ...state,
        clickPaddingTop: data.clickPaddingTop,
      };
    case 'CLICK_PADDING_BOTTOM':
      return {
        ...state,
        clickPaddingBottom: data.clickPaddingBottom,
      };
    case 'CLICK_PADDING_RIGHT':
      return {
        ...state,
        clickPaddingRight: data.clickPaddingRight,
      };
    case 'CLICK_PADDING_LEFT':
      return {
        ...state,
        clickPaddingLeft: data.clickPaddingLeft,
      };
    case 'REVEAL_PADDING_TOP':
      return {
        ...state,
        revealPaddingTop: data.revealPaddingTop,
      };
    case 'REVEAL_PADDING_BOTTOM':
      return {
        ...state,
        revealPaddingBottom: data.revealPaddingBottom,
      };
    case 'REVEAL_PADDING_RIGHT':
      return {
        ...state,
        revealPaddingRight: data.revealPaddingRight,
      };
    case 'REVEAL_PADDING_LEFT':
      return {
        ...state,
        revealPaddingLeft: data.revealPaddingLeft,
      };
    case 'RESET':
      return {
        ...INITIAL_STATE,
        parameters: [{ title: '', text: '' }],
        contentArr: [['title_0', 'text_0']],
        dndParameters: [{ background: '', dropZone: [] }],
      };
    case 'NO_OF_LAYOUT':
      return {
        ...state,
        noOfLayout: data.noOfLayout,
      };
    case 'FIXED_LAYOUT':
      return {
        ...state,
        fixedLayout: data.fixedLayout,
      };
    default:
      return {
        ...state,
      };
  }
};

export default formReducer;
