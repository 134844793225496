import React from 'react';
import { DropdownColorPicker } from '@crystaldelta/loree-ui-color-picker';
import { EditorMenuButton } from '../editorUtilityFunctions/styledContents';
import { ReactComponent as ForegroundColorPicker } from '../editorFormattingIcons/foregroundColorButton.svg';
import { TextColorButtonProps } from '../editorUtilityFunctions/lintEditorType';
import { Tooltip } from '@crystaldelta/loree-ui-components';

export function TextColorButton(props: TextColorButtonProps) {
  const {
    currentColor,
    onChange,
    globalColorList,
    sharedColors,
    colorPickerFeatures,
    colorContrastRatio,
    colorPickerType,
  } = props;

  return (
    <DropdownColorPicker
      onChange={onChange}
      element={
        <EditorMenuButton data-testid='text-color-btn'>
          <Tooltip info='Text Color'>
            <ForegroundColorPicker />
          </Tooltip>
        </EditorMenuButton>
      }
      currentColor={currentColor}
      globalColorList={globalColorList}
      sharedColors={sharedColors}
      colorPickerFeatures={colorPickerFeatures}
      colorContrastRatio={colorContrastRatio}
      colorPickerType={colorPickerType}
    />
  );
}
