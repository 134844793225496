export const putItems = data => ({
  type: "PUT_ITEMS",
  title: data.title,
  parameters: data.parameters,
  customisations:data.customisations
});

export const setDefault = () => ({
  type:"",
});
