/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { ReactComponent as AccordionExpand } from '../../../../assets/icons/accordionExpand.svg';
import { ReactComponent as AccordionHide } from '../../../../assets/icons/accordionHide.svg';
import LayoutCustomize from './dropZoneLayout';
import DropZoneCustomize from './dropZoneArea';
import ButtonCustomize from './buttonCustomize';
import FontHandlerComponent from '../../../customizeComponentLayout/fontHandler';
import { FormInterface } from '../../../../redux/interface';
import { connect } from 'react-redux';
import { customStyles } from '../../../customizeComponentLayout/styles';
import { fixedLayout, noOfLayout } from '../../../../redux/form/form.actions';

const DragAndDropCustomize = (props: any) => {
  const { actionType } = props;
  const [collapseID, setCollapseId] = useState(
    (actionType === 'edit' && props.fixedLayout) || actionType === 'create'
      ? 'Drop Zone Layout'
      : 'Draggable Area',
  );
  const [selectedLayout, setSelectedLayout] = useState('');
  const sectionCollection = ['Drop Zone Layout', 'Draggable Area', 'Drop Zone Area', 'Button'];

  const toggleCollapse = (id: string) => {
    setCollapseId(id !== collapseID ? id : '');
  };
  const handleLayoutChange = (layout: string) => {
    props.fixedLayouts({ fixedLayout: layout === 'Fixed' });
    setSelectedLayout(layout);
  };

  return (
    <Accordion
      className='mx-2'
      defaultActiveKey={
        (actionType === 'edit' && props.fixedLayout) || actionType === 'create'
          ? 'Drop Zone Layout'
          : 'Draggable Area'
      }
    >
      {sectionCollection.map((sectionTitle: string) => {
        return (
          <div key={sectionTitle}>
            {actionType === 'edit' && sectionTitle == 'Drop Zone Layout' ? (
              <>
                {props.fixedLayout && (
                  <>
                    <Accordion.Item eventKey={sectionTitle} className='border-0 my-3'>
                      <Accordion.Header
                        as='div'
                        className={`custom-header-title m-0 ${
                          collapseID === sectionTitle && 'selected-section'
                        }`}
                        onClick={() => toggleCollapse(sectionTitle)}
                      >
                        {collapseID === sectionTitle ? <AccordionExpand /> : <AccordionHide />}
                        <div
                          className={`px-2 ${collapseID === sectionTitle && 'selected-section'}`}
                        >
                          {sectionTitle}
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className='pb-0 px-2 pt-3'>
                        {sectionTitle === 'Drop Zone Layout' && (
                          <>
                            <FontHandlerComponent
                              componentType='Number of layouts in a row'
                              setFontProperty={(data: any) => props.noOfLayouts(data)}
                              fontPropertyValue={props.noOfLayout}
                              options={customStyles.cardsInLayout}
                            />
                            {selectedLayout === 'Fixed' && (
                              <FontHandlerComponent
                                componentType='Number of layouts in a row'
                                setFontProperty={(data: any) => {
                                  props.noOfLayouts(data);
                                }}
                                fontPropertyValue={props.noOfLayout}
                                options={customStyles.cardsInLayout}
                              />
                            )}{' '}
                          </>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                )}
              </>
            ) : (
              <Accordion.Item eventKey={sectionTitle} className='border-0 my-3'>
                <Accordion.Header
                  as='div'
                  className={`custom-header-title m-0 ${
                    collapseID === sectionTitle && 'selected-section'
                  }`}
                  onClick={() => toggleCollapse(sectionTitle)}
                >
                  {collapseID === sectionTitle ? <AccordionExpand /> : <AccordionHide />}
                  <div className={`px-2 ${collapseID === sectionTitle && 'selected-section'}`}>
                    {sectionTitle}
                  </div>
                </Accordion.Header>
                <Accordion.Body className='pb-0 px-2 pt-3'>
                  {sectionTitle === 'Drop Zone Layout' && (
                    <>
                      <LayoutCustomize onLayoutChange={handleLayoutChange} />
                      {selectedLayout === 'Fixed' && (
                        <FontHandlerComponent
                          componentType='Number of layouts in a row'
                          setFontProperty={(data: any) => {
                            props.noOfLayouts(data);
                          }}
                          fontPropertyValue={props.noOfLayout}
                          options={customStyles.cardsInLayout}
                        />
                      )}{' '}
                    </>
                  )}
                  {sectionTitle === 'Draggable Area' && (
                    <ButtonCustomize
                      componentType={sectionTitle}
                      actionType={actionType}
                      fontFamilyPropsList={props.fontFamilyPropsList}
                    />
                  )}
                  {sectionTitle === 'Drop Zone Area' && <DropZoneCustomize />}
                  {sectionTitle === 'Button' && (
                    <ButtonCustomize
                      componentType={sectionTitle}
                      fontFamilyPropsList={props.fontFamilyPropsList}
                    />
                  )}
                </Accordion.Body>
              </Accordion.Item>
            )}
          </div>
        );
      })}
    </Accordion>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  noOfLayouts: (form: FormInterface) => dispatch(noOfLayout(form)),
  fixedLayouts: (form: FormInterface) => {
    dispatch(fixedLayout(form));
  },
});
const mapStateToProps = ({ form }: any) => {
  return {
    noOfLayout: form.noOfLayout,
    fixedLayout: form.fixedLayout,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DragAndDropCustomize);
