/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
//state from redux
export const mapStateToProps = ({ form, flipform }: any) => ({
  clickBorderRadius: form.clickBorderRadius,
  clickBorderTop: form.clickBorderTop,
  clickBorderRight: form.clickBorderRight,
  clickBorderLeft: form.clickBorderLeft,
  clickBorderBottom: form.clickBorderBottom,
  clickBorderColor: form.clickBorderColor,
  clickBorderType: form.clickBorderType,
  clickBgColor: form.clickBgColor,
  revealBorderRadius: form.revealBorderRadius,
  revealBorderTop: form.revealBorderTop,
  revealBorderRight: form.revealBorderRight,
  revealBorderLeft: form.revealBorderLeft,
  revealBorderBottom: form.revealBorderBottom,
  revealBorderColor: form.revealBorderColor,
  revealBorderType: form.revealBorderType,
  revealBgColor: form.revealBgColor,
  clickPaddingTop: form.clickPaddingTop,
  clickPaddingRight: form.clickPaddingRight,
  clickPaddingLeft: form.clickPaddingLeft,
  clickPaddingBottom: form.clickPaddingBottom,
  revealPaddingTop: form.revealPaddingTop,
  revealPaddingRight: form.revealPaddingRight,
  revealPaddingLeft: form.revealPaddingLeft,
  revealPaddingBottom: form.revealPaddingBottom,
  frontTop: flipform.frontTop,
  frontBottom: flipform.frontBottom,
  frontLeft: flipform.frontLeft,
  frontRight: flipform.frontRight,
  backTop: flipform.backTop,
  backBottom: flipform.backBottom,
  backRight: flipform.backRight,
  backLeft: flipform.backLeft,
  bgcolor: flipform.bgcolor,
  backColor: flipform.backColor,
  backBorderColor: flipform.backBorderColor,
  borderColor: flipform.borderColor,
  radius: flipform.radius,
  backBorderRadius: flipform.backBorderRadius,
  backBorderStyle: flipform.backBorderStyle,
  borderStyle: flipform.borderStyle,
  borderSize: flipform.borderSize,
  frontBorderSizeRight: flipform.frontBorderSizeRight,
  frontBorderSizeTop: flipform.frontBorderSizeTop,
  frontBorderSizeBottom: flipform.frontBorderSizeBottom,
  backBorderSizeLeft: flipform.backBorderSizeLeft,
  backBorderSizeRight: flipform.backBorderSizeRight,
  backBorderSizeTop: flipform.backBorderSizeTop,
  backBorderSizeBottom: flipform.backBorderSizeBottom,
});

interface CardsStyleProperty {
  clickPaddingLeft: string;
  revealPaddingLeft: string;
  frontLeft: string;
  backLeft: string;
  clickPaddingTop: string;
  revealPaddingTop: string;
  frontTop: string;
  backTop: string;
  clickPaddingRight: string;
  revealPaddingRight: string;
  frontRight: string;
  backRight: string;
  clickPaddingBottom: string;
  revealPaddingBottom: string;
  frontBottom: string;
  backBottom: string;
  clickBorderLeft: string;
  revealBorderLeft: string;
  borderSize: string;
  backBorderSizeLeft: string;
  clickBorderTop: string;
  revealBorderTop: string;
  frontBorderSizeTop: string;
  backBorderSizeTop: string;
  clickBorderRight: string;
  revealBorderRight: string;
  frontBorderSizeRight: string;
  backBorderSizeRight: string;
  clickBorderBottom: string;
  revealBorderBottom: string;
  frontBorderSizeBottom: string;
  backBorderSizeBottom: string;
  clickBgColor: string;
  revealBgColor: string;
  bgcolor: string;
  backColor: string;
  clickBorderColor: string;
  revealBorderColor: string;
  borderColor: string;
  backBorderColor: string;
  clickBorderType: string;
  revealBorderType: string;
  borderStyle: string;
  backBorderStyle: string;
  clickBorderRadius: string;
  revealBorderRadius: string;
  radius: string;
  backBorderRadius: string;
  customizeType: string;
}

export const cardsPaddingLeft = (props: CardsStyleProperty) => {
  switch (props.customizeType) {
    case 'click':
      return props.clickPaddingLeft;
    case 'reveal':
      return props.revealPaddingLeft;
    case 'frontcard':
      return props.frontLeft;
    case 'backcard':
      return props.backLeft;
    default:
      return '';
  }
};

export const cardsPaddingTop = (props: CardsStyleProperty) => {
  switch (props.customizeType) {
    case 'click':
      return props.clickPaddingTop;
    case 'reveal':
      return props.revealPaddingTop;
    case 'frontcard':
      return props.frontTop;
    case 'backcard':
      return props.backTop;
    default:
      return '';
  }
};

export const cardsPaddingRight = (props: CardsStyleProperty) => {
  switch (props.customizeType) {
    case 'click':
      return props.clickPaddingRight;
    case 'reveal':
      return props.revealPaddingRight;
    case 'frontcard':
      return props.frontRight;
    case 'backcard':
      return props.backRight;
    default:
      return '';
  }
};

export const cardsPaddingBottom = (props: CardsStyleProperty) => {
  switch (props.customizeType) {
    case 'click':
      return props.clickPaddingBottom;
    case 'reveal':
      return props.revealPaddingBottom;
    case 'frontcard':
      return props.frontBottom;
    case 'backcard':
      return props.backBottom;
    default:
      return '';
  }
};

export const cardsBorderLeft = (props: CardsStyleProperty) => {
  switch (props.customizeType) {
    case 'click':
      return props.clickBorderLeft;
    case 'reveal':
      return props.revealBorderLeft;
    case 'frontcard':
      return props.borderSize;
    case 'backcard':
      return props.backBorderSizeLeft;
    default:
      return '';
  }
};

export const cardsBorderTop = (props: CardsStyleProperty) => {
  switch (props.customizeType) {
    case 'click':
      return props.clickBorderTop;
    case 'reveal':
      return props.revealBorderTop;
    case 'frontcard':
      return props.frontBorderSizeTop;
    case 'backcard':
      return props.backBorderSizeTop;
    default:
      return '';
  }
};

export const cardsBorderRight = (props: CardsStyleProperty) => {
  switch (props.customizeType) {
    case 'click':
      return props.clickBorderRight;
    case 'reveal':
      return props.revealBorderRight;
    case 'frontcard':
      return props.frontBorderSizeRight;
    case 'backcard':
      return props.backBorderSizeRight;
    default:
      return '';
  }
};

export const cardsBorderBottom = (props: CardsStyleProperty) => {
  switch (props.customizeType) {
    case 'click':
      return props.clickBorderBottom;
    case 'reveal':
      return props.revealBorderBottom;
    case 'frontcard':
      return props.frontBorderSizeBottom;
    case 'backcard':
      return props.backBorderSizeBottom;
    default:
      return '';
  }
};

export const cardsBgColor = (props: CardsStyleProperty) => {
  switch (props.customizeType) {
    case 'click':
      return props.clickBgColor;
    case 'reveal':
      return props.revealBgColor;
    case 'frontcard':
      return props.bgcolor;
    case 'backcard':
      return props.backColor;
    default:
      return '';
  }
};

export const cardsBorderColor = (props: CardsStyleProperty) => {
  switch (props.customizeType) {
    case 'click':
      return props.clickBorderColor;
    case 'reveal':
      return props.revealBorderColor;
    case 'frontcard':
      return props.borderColor;
    case 'backcard':
      return props.backBorderColor;
    default:
      return '';
  }
};

export const cardsBorderRadius = (props: CardsStyleProperty) => {
  switch (props.customizeType) {
    case 'click':
      return props.clickBorderRadius;
    case 'reveal':
      return props.revealBorderRadius;
    case 'frontcard':
      return props.radius === 'rounded-0' ? '0' : props.radius;
    case 'backcard':
      return props.backBorderRadius === 'rounded-0' ? '0' : props.backBorderRadius;
    default:
      return '';
  }
};

export const cardsBorderStyle = (props: CardsStyleProperty) => {
  switch (props.customizeType) {
    case 'click':
      return props.clickBorderType;
    case 'reveal':
      return props.revealBorderType;
    case 'frontcard':
      return props.borderStyle;
    case 'backcard':
      return props.backBorderStyle;
    default:
      return '';
  }
};
