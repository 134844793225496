export const setTitle = (data) => ({
  type: 'SET_TITLE',
  title: data.title,
  errors: data.errors,
});

export const setName = (data) => ({
  type: 'SET_NAME',
  name: data.name,
  errors: data.errors,
});

export const setLink = (data) => ({
  type: 'SET_LINK',
  link: data.link,
  errors: data.errors,
});

export const setCheck = (data) => ({
  type: 'SET_CHECK',
  isChecked: data.isChecked,
});

//validation for empty feildssta
export const buttonFormError = (data) => ({
  type: 'ERRORS',
  errors: data.errors,
});

export const makeEdit = (data) => ({
  type: 'MAKE_EDIT',
  title: data.title,
  name: data.name,
  link: data.link,
  isChecked: data.isChecked,
  color: data.color,
  btnType: data.btnType,
  btnSize: data.btnSize,
  fontFamily: data.fontFamily,
  fontSize: data.fontSize,
  fontColor: data.fontColor,
  alignment: data.alignment,
  customPaddingtop: data.customPaddingtop,
  customPaddingleft: data.customPaddingleft,
  customPaddingbottom: data.customPaddingbottom,
  customPaddingright: data.customPaddingright,
});

export const setBtnColor = (data) => ({
  type: 'BG_COLOR',
  color: data.color,
});

export const setBtnType = (data) => ({
  type: 'BTN_TYPE',
  btnType: data.btnType,
});

export const setBtnSize = (data) => ({
  type: 'BTN_SIZE',
  btnSize: data.btnSize,
});

export const fontType = (data) => ({
  type: 'FONT_TYPE',
  component: data.fontFamily,
});

export const fontSize = (data) => ({
  type: 'FONT_SIZE',
  component: data.fontSize,
});
export const fontColor = (data) => ({
  type: 'CUSTOM_FONTCOLOR',
  fontColor: data.fontColor,
});
export const inactivefontColor = (data) => ({
  type: 'CUSTOM_INACTIVEFONTCOLOR',
  fontColor: data.inactivefontColorfontColor,
});
export const setAlignment = (data) => ({
  type: 'CUSTOM_ALIGNMENT',
  alignment: data.alignment,
});

export const customPaddingtop = (data) => ({
  type: 'CUSTOM_PADDINGTOP',
  customPaddingtop: data.customPaddingtop,
});

export const customPaddingleft = (data) => ({
  type: 'CUSTOM_PADDINGLEFT',
  customPaddingleft: data.customPaddingleft,
});

export const customPaddingbottom = (data) => ({
  type: 'CUSTOM_PADDINGBOTTOM',
  customPaddingbottom: data.customPaddingbottom,
});

export const customPaddingright = (data) => ({
  type: 'CUSTOM_PADDINGRIGHT',
  customPaddingright: data.customPaddingright,
});

export const setType = (data) => ({
  type: 'SET_TYPE',
  component: data.type,
});

export const setDefault = () => ({
  type: '',
});
