import React, { Suspense, useEffect, useState } from 'react';
import { i18n } from 'i18next';
import { I18nextProvider } from 'react-i18next';
import loreei18n from './i18n/i18n';
import './App.scss';
import '../src/assets/font/customFont.css';
import '../src/assets/scss/fontawesome.min.css';
import Routing from './routes/index';

function App() {
  const [i18nValue, setI18nValue] = useState<i18n | null>(null);

  useEffect(() => {
    loreei18n
      .then((i) => {
        setI18nValue(i);
      })
      .catch((e) => {
        return new Error(e);
      });
  }, []);

  if (i18nValue === null) {
    return <></>;
  }

  return (
    <>
      <I18nextProvider i18n={i18nValue}>
        <Suspense fallback={<></>}>
          <Routing />
        </Suspense>
      </I18nextProvider>
    </>
  );
}

export default App;
