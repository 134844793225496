//handles add panel functionality
export const addMcqPanel = (data) => ({
  type: 'ADD_PANEL',
  parameters: data.parameters,
  contentArr: data.contentArr,
  num: data.num,
  option_id: data.option_id,
});

//handles add option functionality
export const addOption = (data) => ({
  type: 'ADD_OPTION',
  parameters: data.parameters,
  contentArr: data.contentArr,
  option_id: data.option_id,
});
//handles the data while dragging the pane
export const handleChange = (data) => ({
  type: 'MCQ_HANDLE_CHANGE',
  parameters: data.parameters,
  errors: data.errors,
});
//get the value of the main title
export const handleMcqTitle = (data) => ({
  type: 'HANDLE_TITLE',
  title: data.title,
});
//get the value from the checkbox
export const setCheck = (data) => ({
  type: 'SET_CHECK',
  isChecked: data.isChecked,
  errors: data.errors,
});
export const setShowTip = (data) => ({
  type: 'SET_SHOWTIP',
  isShowtip: data.isShowtip,
});
export const setShowFeedback = (data) => ({
  type: 'SET_SHOWFEEDBACK',
  isShowfeedback: data.isShowfeedback,
});
//validation for empty feildssta
export const errorsFetch = (data) => ({
  type: 'ERRORS',
  errors: data.errors,
});
//handles the remove functionality for selected panel
export const removeClick = (data) => ({
  type: 'REMOVE_CLICK',
  parameters: data.parameters,
  contentArr: data.contentArr,
  errors: data.errors,
});

export const removeMcqSection = (data) => ({
  type: 'REMOVE_CLICK',
  parameters: data.parameters,
  contentArr: data.contentArr,
  errors: data.errors,
});

//handles the remove functionality for selected option
export const removeOption = (data) => ({
  type: 'REMOVE_OPTION',
  parameters: data.parameters,
  contentArr: data.contentArr,
});
//drag and drop functionality
export const rearangeArray = (data) => ({
  type: 'REARANGE_ARRAY',
  parameters: data.parameters,
  contentArr: data.contentArr,
});
//For edit functionality
export const makeMcqEdit = (data) => ({
  type: 'MAKE_EDIT',
  title: data.title,
  parameters: data.parameters,
  contentArr: data.contentArr,
  component: data.type,
  optiontype: data.optiontype,
  customtryagain: data.customtryagain,
  checkedAnswer: data.checkedAnswer,
  shuffle: data.shuffle,
  pbBgColor: data.pbBgColor,
  pbFontColor: data.pbFontColor,
  bgColor: data.bgColor,
  opBgColor: data.opBgColor,
  borderSize: data.borderSize,
  borderStyle: data.borderStyle,
  borderColor: data.borderColor,
  borderType: data.borderType,
  borderRadius: data.borderRadius,
  borderTop: data.borderTop,
  borderRight: data.borderRight,
  borderLeft: data.borderLeft,
  borderBottom: data.borderBottom,
  buttonFontColor: data.buttonFontColor,
  buttonBgColor: data.buttonBgColor,
  fontHeadings: data.fontHeadings,
  fontFamily: data.fontFamily,
  fontSize: data.fontSize,
  fontWeight: data.fontWeight,
  pbFontHeadings: data.pbFontHeadings,
  pbFontFamily: data.pbFontFamily,
  pbFontSize: data.pbFontSize,
  pbFontWeight: data.pbFontWeight,
  alignment: data.alignment,
  btnType: data.btnType,
  customPaddingtop: data.customPaddingtop,
  customPaddingleft: data.customPaddingleft,
  customPaddingbottom: data.customPaddingbottom,
  customPaddingright: data.customPaddingright,
});
//to set the type of the component
export const setType = (data) => ({
  type: 'SET_TYPE',
  component: data.type,
});
//to set the value for the panels
export const setValue = (data) => ({
  type: 'SET_VALUE',
  num: data.num,
  option_id: data.option_id,
});

export const setMcqValue = (data) => ({
  type: 'SET_VALUE',
  num: data.num,
  option_id: data.option_id,
});

//For customization
export const customOption = (data) => ({
  type: 'CUSTOM_OPTION',
  optiontype: data.optiontype,
});
export const customCheck = (data) => ({
  type: 'CUSTOM_CHECKANSWER',
  checkedAnswer: data.checkedAnswer,
});
export const retry = (data) => ({
  type: 'TRY_AGAIN',
  customtryagain: data.customtryagain,
});
export const customshuffle = (data) => ({
  type: 'CUSTOM_SHUFFLE',
  shuffle: data.shuffle,
});
export const handlemytips = (data) => ({
  type: 'HANDLE_MYTIPS',
  mytips: data.mytips,
});
export const pbBgColor = (data) => ({
  type: 'PB_BGCOLOR',
  pbBgColor: data.pbBgColor,
});
export const pbFontColor = (data) => ({
  type: 'PB_FONTCOLOR',
  pbFontColor: data.pbFontColor,
});
export const borderType = (data) => ({
  type: 'BORDER_TYPE',
  borderType: data.borderType,
});
export const borderColor = (data) => ({
  type: 'BORDER_COLOR',
  borderColor: data.borderColor,
});
export const borderSize = (data) => ({
  type: 'BORDER_SIZE',
  borderSize: data.borderSize,
});

export const borderStyle = (data) => ({
  type: 'BORDER_STYLE',
  borderStyle: data.borderStyle,
});
export const bgColor = (data) => ({
  type: 'CUSTOM_BGCOLOR',
  bgColor: data.bgColor,
});
export const opBgColor = (data) => ({
  type: 'OPTION_BGCOLOR',
  opBgColor: data.opBgColor,
});
export const buttonBgColor = (data) => ({
  type: 'BUTTON_BGCOLOR',
  buttonBgColor: data.buttonBgColor,
});
export const buttonFontColor = (data) => ({
  type: 'BUTTTON_FONTCOLOR',
  buttonFontColor: data.buttonFontColor,
});
export const fontType = (data) => ({
  type: 'FONT_TYPE',
  component: data.fontFamily,
});
export const fontSize = (data) => ({
  type: 'FONT_SIZE',
  component: data.fontSize,
});
export const fontWeight = (data) => ({
  type: 'FONT_WEIGHT',
  component: data.fontWeight,
});
export const pbFontHeadings = (data) => ({
  type: 'PBFONT_HEADINGS',
  component: data.pbFontHeadings,
});

export const pbFontType = (data) => ({
  type: 'PBFONT_TYPE',
  component: data.pbFontFamily,
});

export const pbFontSize = (data) => ({
  type: 'PBFONT_SIZE',
  component: data.pbFontSize,
});
export const pbFontWeight = (data) => ({
  type: 'PBFONT_WEIGHT',
  component: data.pbFontWeight,
});
export const setAlignment = (data) => ({
  type: 'CUSTOM_ALIGNMENT',
  alignment: data.alignment,
});
export const setBtnType = (data) => ({
  type: 'BTN_TYPE',
  btnType: data.btnType,
});
export const customPaddingtop = (data) => ({
  type: 'CUSTOM_PADDINGTOP',
  customPaddingtop: data.customPaddingtop,
});

export const customPaddingleft = (data) => ({
  type: 'CUSTOM_PADDINGLEFT',
  customPaddingleft: data.customPaddingleft,
});

export const customPaddingbottom = (data) => ({
  type: 'CUSTOM_PADDINGBOTTOM',
  customPaddingbottom: data.customPaddingbottom,
});

export const customPaddingright = (data) => ({
  type: 'CUSTOM_PADDINGRIGHT',
  customPaddingright: data.customPaddingright,
});

export const deleteImage = (data) => ({
  type: 'REMOVE_IMAGE',
  removeImage: data.removeImage,
});

export const setDefault = () => ({
  type: 'RESET',
});

export const setBorderTop = (data) => ({
  type: 'BORDER_TOP',
  borderTop: data.borderTop,
});

export const setBorderRight = (data) => ({
  type: 'BORDER_RIGHT',
  borderRight: data.borderRight,
});

export const setBorderLeft = (data) => ({
  type: 'BORDER_LEFT',
  borderLeft: data.borderLeft,
});

export const setBorderBottom = (data) => ({
  type: 'BORDER_BOTTOM',
  borderBottom: data.borderBottom,
});

export const setBorderRadius = (data) => ({
  type: 'BORDER_RADIUS',
  borderRadius: data.borderRadius,
});
