import { Node, mergeAttributes } from '@tiptap/core';
import { optimizeStyle, simpleElementNodeView, structuredParseHtml } from './utilityFunctions';

type IconOptions = {
  inline: boolean;
  HTMLAttributes: Record<string, string>;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    iconTag: {
      addOrReplaceIconDescription: (text: string) => ReturnType;
    };
  }
}

export const IconTag = Node.create<IconOptions>({
  name: 'iconTag',
  addOptions() {
    return {
      inline: false,
      HTMLAttributes: {},
    };
  },
  priority: 1005,
  group: 'block',
  content: 'inline*',
  selectable: false,
  atom: false,
  draggable: false,
  isolating: true,
  defining: false,

  addAttributes() {
    return {
      class: {
        default: 'material-icons',
      },
      'aria-describedby': {
        parseHTML: (element) => element.getAttribute('aria-describedby'),
      },
      style: {
        parseHTML: (element) => element.getAttribute('style'),
      },
      contenteditable: { default: false },
    };
  },

  parseHTML() {
    return [structuredParseHtml('span[aria-describedby]')];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      optimizeStyle(mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {})),
      0,
    ];
  },

  addNodeView() {
    return simpleElementNodeView('span');
  },

  addCommands() {
    return {
      addOrReplaceIconDescription:
        (text) =>
        ({ commands }) => {
          return commands.updateAttributes('iconTag', { 'aria-describedby': text });
        },
    };
  },
});
