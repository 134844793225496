import React from 'react';
import { BorderStyleSelect, BorderWidthInput } from '@crystaldelta/loree-ui-components';
import { useState } from 'react';
import styled from 'styled-components';

interface BorderColorPickerPanelProps {
  currentSize: string | null;
  currentStyle: string | null;

  onChangeSize: (size: string) => void;
  onChangeStyle: (style: string) => void;
}

const BorderStyleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0px 8px 0px;
`;

export function BorderColorPickerPanel(props: BorderColorPickerPanelProps) {
  const { currentSize, onChangeSize, currentStyle, onChangeStyle } = props;
  const [borderSize, setBorderSize] = useState(currentSize);
  return (
    <BorderStyleContainer className='border-element-container'>
      <BorderWidthInput {...{ borderSize, onChangeSize, setBorderSize }} />
      <BorderStyleSelect
        {...{
          currentStyle,
          onChangeStyle,
          disabled: !!(!borderSize || borderSize === '0'),
        }}
      />
    </BorderStyleContainer>
  );
}
