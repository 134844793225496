import React from 'react';

const NoContentComponent = () => {
  return (
    <div data-testid='204' className='no-content-container'>
      Content unavailable...
    </div>
  );
};

export default NoContentComponent;
