import { mergeAttributes, Node } from '@tiptap/core';
import { filterSpans, optimizeStyle, simpleElementNodeView } from './utilityFunctions';

export interface TableHeaderOptions {
  HTMLAttributes: Record<string, never>;
}
export const CustomizedTableHeader = Node.create<TableHeaderOptions>({
  name: 'tableHeader',
  atom: true,
  selectable: true,
  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  content: 'block*',

  addAttributes() {
    return {
      colspan: {
        default: 1,
      },
      rowspan: {
        default: 1,
      },
      colwidth: {
        default: null,
        parseHTML: (element) => {
          const colwidth = element.getAttribute('colwidth');
          return colwidth ? [parseInt(colwidth, 10)] : null;
        },
        /*eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        renderHTML: (attr: Record<string, any>) => {
          if (!attr['colwidth'] || attr['colspan'] != 1) {
            return null;
          }
          return {
            style: `width: ${attr['colwidth'] as number}px`,
            colwidth: `${attr['colwidth'] as string}`,
          };
        },
      },
      scope: { default: null },
      headers: { default: null },
      style: { default: null },
    };
  },

  tableRole: 'header_cell',
  isolating: true,
  parseHTML() {
    return [{ tag: 'th' }];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'th',
      optimizeStyle(filterSpans(mergeAttributes(this.options.HTMLAttributes, HTMLAttributes))),
      0,
    ];
  },

  addNodeView() {
    return simpleElementNodeView('th');
  },
});
