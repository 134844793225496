/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bgColor, opBgColor } from '../../../../redux/multipleChoice/mcq.action';
import ColorPickerComponent from '../../../customizeComponentLayout/colorPicker';

const mapState = ({ mcqform }: any) => ({
  bgColor: mcqform.bgColor,
  opBgColor: mcqform.opBgColor,
});

const mapDispatch = (dispatch: Function) => ({
  BgColor: (form: {}) => dispatch(bgColor(form)),
  OpBgColor: (form: {}) => dispatch(opBgColor(form)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const SectionCustomize = (props: Props) => {
  const { bgColor, opBgColor, BgColor, OpBgColor } = props;
  return (
    <>
      <ColorPickerComponent
        type='questionBgColor'
        title='Background'
        SetColor={BgColor}
        color={bgColor}
      />
      <ColorPickerComponent
        type='optionBgColor'
        title='Option Background'
        SetColor={OpBgColor}
        color={opBgColor}
      />
    </>
  );
};

export default connector(SectionCustomize);
