export const CONSTANTS = {
  LOREE_INTERACTIVE_TIPTAP_EDITOR: 'loree-tiptap-editor',
  LOREE_INTERACTIVE_PROSEMIRROR: 'ProseMirror',
  LOREE_TEXT_INDENT_INDEX: 3,
  LOREE_INDENT_SPACE: [
    { className: 'ql-indent-1', value: '3em', type: 'a', tag: 'ol' },
    { className: 'ql-indent-2', value: '6em', type: 'i', tag: 'ol' },
    { className: 'ql-indent-3', value: '9em', type: '1', tag: 'ol' },
    { className: 'ql-indent-4', value: '12em', type: 'a', tag: 'ol' },
    { className: 'ql-indent-5', value: '15em', type: 'i', tag: 'ol' },
    { className: 'ql-indent-6', value: '18em', type: '1', tag: 'ol' },
    { className: 'ql-indent-7', value: '21em', type: 'a', tag: 'ol' },
    { className: 'ql-indent-8', value: '24em', type: 'i', tag: 'ol' },
    { className: 'ql-indent-9', value: '24em', type: '1', tag: 'ol' },
  ],
  LOREE_ALIGNMENT_VALUES: [
    { className: 'ql-align-left', value: 'left' },
    { className: 'ql-align-right', value: 'right' },
    { className: 'ql-align-center', value: 'center' },
    { className: 'ql-align-justify', value: 'justify' },
  ],
  LOREE_COLORPICKER_FEATURES: {
    showHSV: true,
    showOpacity: true,
    showColorList: true,
    showHexSelector: true,
    showSecondaryColorPalette: true,
    basicColorPicker: true,
  },
  LOREE_FONTFAMILY_VALUES: [
    { className: 'ql-font-none', value: 'SourceSansPro-Regular' },
    { className: 'ql-font-arial', value: 'Arial' },
    { className: 'ql-font-poppins', value: 'Poppins' },
    { className: 'ql-font-poppinsBold', value: 'Poppins-Bold' },
    { className: 'ql-font-poppinsSemiBold', value: 'Poppins SemiBold' },
    { className: 'ql-font-arialBlack', value: 'Arial Black' },
    { className: 'ql-font-brushScriptMT', value: 'Brush Script MT' },
    { className: 'ql-font-comicNewCourier', value: 'Comic New Courier, monospace' },
    { className: 'ql-font-georgia', value: 'Georgia' },
    { className: 'ql-font-helvetica', value: 'Helvetica' },
    { className: 'ql-font-latoBold', value: 'Lato Bold' },
    { className: 'ql-font-latoItalic', value: 'Lato Italic' },
    { className: 'ql-font-latoRegular', value: 'Lato Regular' },
    { className: 'ql-font-lucida', value: 'Lucida' },
    { className: 'ql-font-lucidaSansUnicode', value: 'Lucida Sans Unicode' },
    { className: 'ql-font-tahoma', value: 'Tahoma' },
    { className: 'ql-font-timesNewRoman', value: 'Times New Roman' },
    { className: 'ql-font-trebuchetMS', value: 'Trebuchet MS' },
    { className: 'ql-font-verdana', value: 'Verdana, Geneva, sans-serif' },
    { className: 'ql-font-openSans', value: 'Open Sans, sans-serif' },
  ],
  LOREE_EDITOR_EMPTY_CONTENT: '<p>&nbsp;</p>',
  LOREE_EDITOR_TABLE_ACTIVE_MENU: 'activeTableSubMenu',
  // default colors
  CUSTOM_FONT_COLOR: '#FFFFFF',
  CUSTOM_BG_COLOR: '#FFFFFF',
  FONT_COLOR: '#000000',
  INACTIVE_FONT_COLOR: '#000000',
  BG_COLOR: '#F2F2F2',
  BORDER_COLOR: '#000000',
  BTN_FONT_COLOR: '#FFFFFF',
  BTN_BG_COLOR: '#112299',
  SEPERATOR_COLOR: '#000000',
  INACTIVE_BG_COLOR: '#FFFFFF',
  QUESTION_BG_COLOR: '#FFFFFF',
  OPTION_BG_COLOR: '#FFFFFF',
  PB_FONT_COLOR: '#000000',
  PB_BG_COLOR: '#112299',
  MCQ_BTN_FONT_COLOR: '#FFFFFF',
  MCQ_BTN_BG_COLOR: '#112299',
  ICON_COLOR: '#000000',
  DROPZONE_BG_COLOR: '#F2F2F2',
  DRAG_BG_COLOR: '#112299',
  IMAGE_BG_COLOR: '#FFFFFF',
  // Popper value
  EDITOR_POPPER_ID: 'editor-popper-container',
  EDITOR_POPPER: 'editor-popper-div',

  LOREE_GOOGLE_FONT_API_URL: 'https://fonts.googleapis.com/css2?',
  LINT_FONT_LIST: [
    {
      fontFamilyValue: 'Arial',
      name: 'Arial',
      label: 'arial',
    },
    {
      fontFamilyValue: 'Arial Black',
      name: 'Arial Black',
      label: 'arialBlack',
    },
    {
      fontFamilyValue: 'Brush Script MT',
      name: 'Brush Script MT',
      label: 'brushScriptMT',
    },
    {
      fontFamilyValue: 'Comic New Courier, monospace',
      name: 'Comic New Courier',
      label: 'comicNewCourier',
    },
    {
      fontFamilyValue: 'Georgia',
      name: 'Georgia',
      label: 'georgia',
    },
    {
      fontFamilyValue: 'Helvetica',
      name: 'Helvetica',
      label: 'helvetica',
    },
    {
      fontFamilyValue: 'Lato Bold',
      name: 'Lato Bold',
      label: 'latoBold',
    },
    {
      fontFamilyValue: 'Lato Italic',
      name: 'Lato Italic',
      label: 'latoItalic',
    },
    {
      fontFamilyValue: 'Lato Regular',
      name: 'Lato Regular',
      label: 'latoRegular',
    },
    {
      fontFamilyValue: 'Lucida',
      name: 'Lucida',
      label: 'lucida',
    },
    {
      fontFamilyValue: 'Open Sans, sans-serif',
      name: 'Open Sans',
      label: 'openSans',
    },
    {
      fontFamilyValue: 'Lucida Sans Unicode',
      name: 'Lucida Sans Unicode',
      label: 'lucidaSansUnicode',
    },
    {
      fontFamilyValue: 'Tahoma',
      name: 'Tahoma',
      label: 'tahoma',
    },
    {
      fontFamilyValue: 'Times New Roman',
      name: 'Times New Roman',
      label: 'timesNewRoman',
    },
    {
      fontFamilyValue: 'Trebuchet MS',
      name: 'Trebuchet MS',
      label: 'trebuchetMS',
    },
    {
      fontFamilyValue: 'Verdana, Geneva, sans-serif',
      name: 'Verdana',
      label: 'verdana',
    },
    {
      fontFamilyValue: 'Poppins SemiBold',
      name: 'Poppins Semi Bold',
      label: 'poppinsSemiBold',
    },
    {
      fontFamilyValue: 'Poppins-Bold',
      name: 'Poppins Bold',
      label: 'poppinsBold',
    },
    {
      fontFamilyValue: 'Poppins',
      name: 'Poppins',
      label: 'poppins',
    },
    {
      fontFamilyValue: 'SourceSansPro-Regular',
      name: 'Source sans pro',
      label: 'sourceSansPro',
    },
  ],
};
