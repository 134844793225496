/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

const ProgressBar = (props: any) => {
  const pbFontFamily = props.customisation.pbFontFamily
    ? props.customisation.pbFontFamily
    : 'Helvetica';
  const pbFontSize = props.customisation.pbFontSize ? props.customisation.pbFontSize : '16';
  const pbFontWeight = props.customisation.pbFontWeight
    ? props.customisation.pbFontWeight
    : 'Normal';
  const pbFontHeading = props.customisation.pbFontHeadings
    ? props.customisation.pbFontHeadings
    : 'Normal';
  return (
    <div
      role='button'
      tabIndex={0}
      aria-live='polite'
      className={`progress ${pbFontHeading}`}
      style={{
        fontSize: `${pbFontSize}px`,
        fontWeight: `${pbFontWeight}`,
        fontFamily: `${pbFontFamily}`,
      }}
    >
      <p className='counter' style={{ color: props.pbFontColor }}>
        <span>
          Question {props.currentQuestion + 1} of {props.questionLength}
        </span>
      </p>
      <div
        className='progress-bar'
        style={{
          width: ((props.currentQuestion + 1) / props.questionLength) * 100 + '%',
          background: props.pbBgColor,
        }}
      />
    </div>
  );
};

export default ProgressBar;
