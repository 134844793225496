import React from 'react';
import { Row, Button, ListGroup } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { onDragEnter, onDragOver, onDragStart, onDrop, hoverOff, hoverOn } from './dndEvents';
import hexToRgba from 'hex-to-rgba';
import { isIOS, isMobileOnly, isMobileSafari } from 'react-device-detect';

class FixedDropzone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      element: '',
      nextStatus: true,
      selectedIndex: 0,
      result: [],
      bgColorRGB: '',
      opacity: 0,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.closeOpenMenus);
    document.addEventListener('keyup', this.keyUp);
    this.setBgColor(this.props.customisations);
  }

  componentDidUpdate(prevProps, prevState) {
    const styles = this.props.customisations;
    const listGroup = document.getElementById('listGroup');
    if (
      styles.customisation.dropZoneBgColor !==
      prevProps.customisations.customisation.dropZoneBgColor
    ) {
      this.setBgColor(styles);
    }
    if (prevState.isSelected !== this.state.isSelected && listGroup) {
      document.body.style.height = `${document.body.clientHeight + listGroup.clientHeight}px`;
    } else if (!this.state.isSelected) {
      document.body.removeAttribute('style');
    }
  }
  noOfLayoutsValues = () => {
    const nooflayout = this.props.customisations.customisation.noOfLayout ?? '4';
    if (nooflayout === '1') {
      return 'col-lg-12 col-md-12';
    } else if (nooflayout === '2') {
      return 'col-lg-6 col-md-6';
    } else if (nooflayout === '3') {
      return 'col-lg-4 col-md-4';
    } else if (nooflayout === '4') {
      return 'col-lg-3 col-md-3';
    } else {
      return 'col-lg-3 col-md-3';
    }
  };

  setBgColor = (styles) => {
    let bgColor = styles.dropZoneBgColor
      ? styles.dropZoneBgColor
      : styles.customisation.dropZoneBgColor;
    let opacity = styles.dropZoneOpacity
      ? styles.dropZoneOpacity
      : styles.customisation.dropZoneOpacity;
    const bgColorRGB = hexToRgba(bgColor, opacity);
    this.setState({ bgColorRGB: bgColorRGB, opacity: opacity });
  };

  keyUp = (event) => {
    const { tagName, classList } = event.target;
    if (
      tagName !== 'div' &&
      this.state.isSelected &&
      event.shiftKey &&
      event.keyCode === 9 &&
      !classList.contains('listItems')
    ) {
      this.setState({
        selectedIndex: 0,
        isSelected: false,
      });
    } else if (tagName === 'BUTTON' && this.state.lastItemSelected) {
      this.setState({
        lastItemSelected: false,
        selectedIndex: 0,
        isSelected: false,
      });
    }
  };

  closeOpenMenus = (event) => {
    const { isSelected } = this.state;
    switch (true) {
      case event.target.classList.contains('listItems'):
        return;
      case isSelected:
        this.setState({ isSelected: false, selectedIndex: 0 });
        return;
      default:
        return;
    }
  };

  handleOnKeyDown = async (event, index, eventType) => {
    const { isSelected } = this.state;
    const className = event.target.parentNode.className;
    if (className.includes('droptarget')) return;
    if (event.key === ' ' || (eventType === 'clickEvent' && !isSelected)) {
      this.setState({
        isSelected: true,
        selectedIndex: index,
      });
    } else if (eventType === 'clickEvent' && isSelected) {
      this.setState({
        isSelected: false,
        selectedIndex: 0,
      });
    }
  };

  handleListSelect = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { selectedIndex } = this.state;
    const targetElement = document.getElementById(event.target.title);
    const selectedElement = document.getElementById(`key_${selectedIndex}`);
    const btnsHolder = document.getElementById('buttonsHolder');
    const currentBtn = targetElement.children[0];

    if (currentBtn) {
      currentBtn.classList.remove('buttonTextWrap');
      btnsHolder.appendChild(currentBtn);
    }

    selectedElement.classList.add('buttonTextWrap');
    targetElement.appendChild(selectedElement);
    if (this.props.customisations.mouseover) hoverOff(selectedElement);
    this.setState({ isSelected: false, selectedIndex: 0 });
  };

  render() {
    const { customisations, data, image, dragText } = this.props;
    const { isSelected, selectedIndex, opacity, bgColorRGB } = this.state;
    let styles = this.props.customisations;
    let btntype = styles.dragAreaType ? styles.dragAreaType : styles.customisation.dragAreaType;

    return (
      <>
        <div id='dragdrop' tabIndex={0} role='button' aria-label='Drag and Drop' className='mx-2'>
          <Row className='fixedDndContainer'>
            {customisations.customisation.dndLayout === 'Fixed'
              ? data?.map((content, idx) => (
                  <div
                    className={`${this.noOfLayoutsValues()} col-sm-12 col-xs-12 drag my-2`}
                    key={`img_${idx}`}
                    id={`dropZone_${idx}`}
                  >
                    <div tabIndex={0} role='button'>
                      {ReactHtmlParser(content['text'])}
                    </div>
                    <div
                      id={content['title']}
                      className='droptarget mt-1'
                      style={{
                        backgroundColor: `${
                          opacity !== 0 && opacity !== '0' ? bgColorRGB : 'transparent'
                        }`,
                        border: `${
                          opacity !== 0 && opacity !== '0' ? 'none' : '3px solid #112299'
                        }`,
                      }}
                      onDrop={(e) => onDrop(e)}
                      onDragOver={(e) => onDragOver(e)}
                      onDragEnter={(e) => onDragEnter(e)}
                    />
                  </div>
                ))
              : image?.map((content, idx) => (
                  <div
                    id={content.title}
                    key={`img_${idx}`}
                    style={{
                      backgroundImage: `url("${content.src}")`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                    }}
                    className='drop col-md-2 m-2'
                    onDrop={(e) => onDrop(e)}
                    onDragOver={(e) => onDragOver(e)}
                    onDragEnter={(e) => onDragEnter(e)}
                  />
                ))}
          </Row>
          <div
            className={`row ${
              customisations.customisation.dndLayout === 'Fixed' ? 'mt-4' : 'col-md-11 mt-4'
            }`}
            id='buttonsHolder'
            onDrop={(e) => onDrop(e)}
            onDragOver={(e) => onDragOver(e)}
            onDragEnter={(e) => onDragEnter(e)}
          >
            {dragText?.map((content, idx) => (
              <Button
                key={content}
                className={`${btntype} drop-btn me-2 mb-2`}
                style={styles.mouseover ? styles.dragAreaUnhover : styles.dragAreaStyle}
                title={content}
                draggable
                id={`key_${idx}`}
                onClick={(e) => {
                  if (styles.mouseover) {
                    hoverOn(e.target, customisations.customisation);
                  }
                  if (isMobileOnly || isMobileSafari || isIOS)
                    this.handleOnKeyDown(e, idx, 'clickEvent');
                }}
                onDragStart={(e) => onDragStart(e)}
                onMouseEnter={(e) => {
                  if (!styles.mouseover) return;
                  hoverOn(e.target, customisations.customisation);
                }}
                onMouseLeave={(e) => {
                  if (!styles.mouseover) return;
                  hoverOff(e.target);
                }}
                onKeyDown={(e) => {
                  this.handleOnKeyDown(e, idx);
                }}
              >
                {content}
                {isSelected && selectedIndex === idx ? (
                  <ListGroup
                    id='listGroup'
                    defaultActiveKey='#link_0'
                    className='position-absolute'
                  >
                    {this.props.data.map((item, i) => (
                      <ListGroup.Item
                        as='div'
                        tabIndex={0}
                        className='listItems'
                        action
                        active={this.selectedIndex === i}
                        key={item['text']}
                        title={item['title']}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') this.handleListSelect(e);
                          this.setState({
                            lastItemSelected: e.target.innerText === data[data.length - 1]['title'],
                          });
                        }}
                        onClick={(e) => this.handleListSelect(e)}
                      >
                        {item['title']}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                ) : null}
              </Button>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default FixedDropzone;
