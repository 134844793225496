import React from 'react';
import ReactDOM from 'react-dom/client';
import { findUpwardsByTag, isElementOneOf } from '@crystaldelta/loree-util-dom';
import { LinkInfo } from './linkInfo';
import { Floater } from './floater';
import { Editor } from '@tiptap/react';
import { removeLink } from '../editor/editorExtensions/utilityFunctions';
import { CONSTANTS } from '../editor/editorUtilityFunctions/constants';
import ImageOptions from './imageOptionsModal';
let removalFunction = () => {
  return;
};

export function hideLinkInfoPopup() {
  removalFunction();
  removalFunction = () => {
    return;
  };
}

export function showLinkInfoPopup(
  referenceElement: HTMLElement,
  onRemove: () => void,
  url: string,
  elementType: string,
  editor: Editor,
  popperID: string,
) {
  let div = document.getElementById(CONSTANTS.EDITOR_POPPER);
  const container = document.getElementById(popperID);
  if (!div && container) {
    container.innerHTML = `<div id='${CONSTANTS.EDITOR_POPPER}'></div>`;
    div = document.getElementById(CONSTANTS.EDITOR_POPPER);
  }
  const root = ReactDOM.createRoot(div as HTMLDivElement);

  removalFunction = () => {
    if (div) {
      root.unmount();
      div.remove();
    }
  };

  root.render(
    <>
      {elementType !== 'image' && (
        <Floater
          referenceElement={referenceElement}
          onClose={hideLinkInfoPopup}
          placement='bottom'
          noBackground
          className='linkInfo'
          arrow
          gap={10}
          component={<LinkInfo onRemove={onRemove} url={url} />}
        />
      )}
      {elementType !== 'text' && (
        <Floater
          referenceElement={referenceElement}
          onClose={hideLinkInfoPopup}
          placement='top'
          noBackground
          className='linkInfo'
          arrow
          gap={10}
          component={<ImageOptions editor={editor} />}
        />
      )}
    </>,
  );
}

export function showLinkDetails(
  targetElement: HTMLElement | null,
  editor: Editor,
  nodeType: string,
  popperID: string,
) {
  const tags = ['P', 'SPAN', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'IMG', 'LI', 'UL', 'OL'];
  const anchorElement: HTMLAnchorElement | null = findUpwardsByTag(targetElement, 'a');

  const isImage = targetElement?.tagName === 'IMG';
  const isText = anchorElement && nodeType !== 'image';
  hideLinkInfoPopup();
  if (!targetElement) {
    return;
  }
  if (!isText && !isImage) {
    if (isElementOneOf(targetElement, tags)) {
      hideLinkInfoPopup();
    }
    return;
  }

  let referenceElement;
  let elementType;

  if (nodeType === 'image') {
    if (!anchorElement) {
      elementType = nodeType;
    } else {
      elementType = 'imageWithLink';
    }
    referenceElement = targetElement;
  } else {
    elementType = 'text';
    referenceElement = anchorElement;
  }

  if (!referenceElement) return;
  showLinkInfoPopup(
    referenceElement,
    () => {
      hideLinkInfoPopup();
      if (nodeType !== 'image') {
        removeLink(editor);
        return;
      } else {
        editor.commands.removeLinkFromNode();
      }
    },
    anchorElement?.getAttribute('href') ?? '',
    elementType,
    editor,
    popperID,
  );
}
