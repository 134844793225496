import { Extension } from '@tiptap/core';
import { ExtensionOptions } from '../editorUtilityFunctions/lintEditorType';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    lineHeight: {
      setLineHeight: (height: string) => ReturnType;
    };
  }
}

export const LineHeight = Extension.create<ExtensionOptions>({
  name: 'lineHeight',

  addOptions() {
    return {
      types: ['paragraph', 'heading', 'list_item', 'textStyle'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          lineHeight: {
            default: null,
            parseHTML: (element) => element.style.lineHeight,
            renderHTML: (attributes: Record<string, string>) => {
              if (!attributes['lineHeight']) {
                return {};
              }

              return { style: `line-height: ${attributes['lineHeight']}` };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setLineHeight:
        (height: string) =>
        ({ commands }) =>
          this.options.types.every((type) =>
            commands.updateAttributes(type, { lineHeight: height }),
          ),
    };
  },
});
