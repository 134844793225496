/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Card, Accordion } from 'react-bootstrap';
import { changeIcon, CustomToggle } from './events';
import { getBorderStyle } from '../../../middleware/getStyle';

const AccordionComponent = (props: any) => {
  const { parameters, customisations } = props;
  const fontHeadings = customisations.fontHeadings !== '' ? customisations.fontHeadings : 'div';
  const borderStyle = getBorderStyle(customisations);
  const headerStyle = {
    fontFamily: `${customisations.fontFamily}`,
    backgroundColor: `${customisations.bgcolor}`,
    fontWeight: `${customisations.fontWeight}`,
    fontSize: `${customisations.fontSize}px`,
    color: `${customisations.fontColor}`,
    textAlign: `${customisations.alignment}` as any,
  };
  const marginStyle = {
    marginTop: customisations.marginTop ? `${customisations.marginTop}px` : '0',
    marginBottom: customisations.marginBottom ? `${customisations.marginBottom}px` : '0',
  };

  const handleClick = (event: any) => {
    const { icon } = props.customisations;
    changeIcon(event.target, icon);
    setTimeout(() => {
      event.target.scrollIntoView({
        behavior: 'auto',
        block: props.isLMSView ? 'nearest' : 'end',
        inline: 'nearest',
      });
    }, 500);
  };

  return (
    <Accordion
      id='customAccordion'
      className={customisations.radius === 'rounded-lg' ? 'rounded-3' : customisations.radius}
      tabIndex={0}
      aria-label='Accordion'
    >
      {parameters?.map((content: { title: string; text: string }, idx: string) => (
        <Accordion.Item eventKey={idx} key={content['title']} style={marginStyle}>
          <Card style={headerStyle}>
            <Card.Body
              as={CustomToggle}
              headerStyle={fontHeadings}
              style={headerStyle}
              className={`${
                customisations.icon === 'plus/minus' ? 'icon-plus' : 'icon-right-arrow'
              }`}
              borderRadius={customisations.radius}
              onKeyDown={(e) => handleClick(e)}
              onClick={(e) => handleClick(e)}
              id={idx}
              eventKey={idx}
            >
              {content['title']}
            </Card.Body>
          </Card>
          <Accordion.Body className='ql-editor' style={borderStyle} tabIndex={0}>
            {ReactHtmlParser(content['text'])}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default AccordionComponent;
