/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { connect, ConnectedProps } from 'react-redux';
import React, { ChangeEvent, useEffect } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import {
  buttonFormError,
  makeEdit,
  setCheck,
  setLink,
  setName,
  setTitle,
} from '../../../redux/button/button.actions';
export interface ButtonFormInterface {
  title: string;
  name: string;
  link: string;
  color: string;
  isChecked: boolean;
  errors: any;
  btnType: string;
  fontFamily: string;
  fontSize: string;
  alignment: string;
  fontColor: string;
  customPaddingtop: string;
  customPaddingleft: string;
  customPaddingbottom: string;
  customPaddingright: string;
}

interface RootState {
  button: ButtonFormInterface;
}

const mapState = ({ button }: RootState) => ({
  title: button.title,
  name: button.name,
  link: button.link,
  color: button.color,
  isChecked: button.isChecked,
  errors: button.errors,
  btnType: button.btnType,
  fontFamily: button.fontFamily,
  fontSize: button.fontSize,
  alignment: button.alignment,
  fontColor: button.fontColor,
  customPaddingtop: button.customPaddingtop,
  customPaddingleft: button.customPaddingleft,
  customPaddingbottom: button.customPaddingbottom,
  customPaddingright: button.customPaddingright,
});

const mapDispatch = (dispatch: Function) => ({
  SetTitle: (data: {}) => dispatch(setTitle(data)),
  SetName: (data: {}) => dispatch(setName(data)),
  SetLink: (data: {}) => dispatch(setLink(data)),
  SetCheck: (data: {}) => dispatch(setCheck(data)),
  MakeEdit: (data: {}) => dispatch(makeEdit(data)),
  SetError: (data: {}) => dispatch(buttonFormError(data)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
  ButtonFormInterface & {
    editContent: any;
    pathName: string;
    formError: {
      title?: string;
      name?: string;
      link?: string;
    };
    id?: string;
  };

const ButtonFormComponent = (props: Props) => {
  const {
    id,
    name,
    link,
    title,
    errors,
    isChecked,
    formError,
    SetName,
    SetLink,
    SetTitle,
    SetCheck,
    SetError,
    MakeEdit,
    editContent,
    pathName,
  } = props;

  useEffect(() => {
    if (id) {
      const isDuplicate = pathName.includes('duplicate');
      if (isDuplicate) {
        SetError({
          errors: { title: '* DUPLICATED INTERACTIVE - Please enter a new title' },
        });
      }
      const { parameters, customisations, title } = editContent;
      MakeEdit({
        title: isDuplicate ? '' : title,
        name: parameters.name,
        link: parameters.link,
        isChecked: parameters.isChecked,
        color: customisations.bgColor !== '' ? customisations.bgColor : '#007bff',
        btnType: customisations.btnType !== '' ? customisations.btnType : 'btn btn-square btn-dark',
        fontFamily: customisations.fontFamily !== '' ? customisations.fontFamily : 'Helvetica',
        fontSize: customisations.fontSize !== '' ? customisations.fontSize : '16',
        alignment: customisations.alignment !== '' ? customisations.alignment : 'left',
        fontColor: customisations.fontColor !== '' ? customisations.fontColor : '#ffffff',
        customPaddingtop: customisations.customPaddingtop ? customisations.customPaddingtop : '10',
        customPaddingleft: customisations.customPaddingleft
          ? customisations.customPaddingleft
          : '10',
        customPaddingbottom: customisations.customPaddingbottom
          ? customisations.customPaddingbottom
          : '10',
        customPaddingright: customisations.customPaddingright
          ? customisations.customPaddingright
          : '10',
      });
    }
  }, [id, editContent, MakeEdit, SetError, pathName]);

  const handleInputChange = (event: ChangeEvent, type: string) => {
    let fail = errors;
    delete fail[`${type}`];
    const { value } = event.target as HTMLInputElement;
    switch (type) {
      case 'title':
        SetTitle({
          title: value.trimStart(),
          errors: fail,
        });
        break;
      case 'name':
        SetName({
          name: value.trimStart(),
          errors: fail,
        });
        break;
      case 'link':
        SetLink({
          link: value,
          errors: fail,
        });
        break;
    }
  };

  const handleCheck = (status: boolean) => {
    SetCheck({
      isChecked: status,
    });
  };

  return (
    <Form className='' onSubmit={(e) => e.preventDefault()}>
      <Form.Group className='py-2'>
        <Form.Label className='title'>Interactive Title *</Form.Label>
        <FormControl
          required
          value={title.replace(/ +/g, ' ')}
          className='lint-custom-input'
          data-testid='interactive-title'
          id='interactive-title'
          autoComplete='off'
          type='text'
          onChange={(event) => handleInputChange(event, 'title')}
          maxLength={100}
        />
        {formError.title && <Form.Text className='text-danger'>{errors.title}</Form.Text>}
      </Form.Group>
      <Form.Group className='py-2'>
        <Form.Label className='title'>Button Name *</Form.Label>
        <FormControl
          required
          value={name.replace(/ +/g, ' ')}
          className='lint-custom-input'
          data-testid='button-name'
          id='button-name'
          onChange={(event) => handleInputChange(event, 'name')}
          autoComplete='off'
        />
        {formError.name && <Form.Text className='text-danger'>{errors.name}</Form.Text>}
      </Form.Group>
      <Form.Group className='py-2'>
        <Form.Label className='title'>Button Link *</Form.Label>
        <FormControl
          required
          value={link.replace(/ +/g, ' ')}
          className='lint-custom-input'
          data-testid='button-link'
          id='button-link'
          autoComplete='off'
          placeholder='eg: https://www.soaringed.com'
          onChange={(event) => handleInputChange(event, 'link')}
          maxLength={256}
        />
        {formError.link && <Form.Text className='text-danger'>{errors.link}</Form.Text>}
      </Form.Group>
      <Form.Group className='py-2'>
        <Form.Label className='title'>How do you want to open this link?</Form.Label>
        <Form.Group className='d-flex flex-wrap title'>
          <input
            className='customRadioButton'
            checked={isChecked}
            onChange={() => handleCheck(true)}
            type='radio'
          />
          <Form.Label className='m-2 customRadioButton' onClick={() => handleCheck(true)}>
            New Tab
          </Form.Label>
          <input
            className='ms-3 customRadioButton'
            checked={!isChecked}
            onChange={() => handleCheck(false)}
            type='radio'
          />
          <Form.Label className='m-2 customRadioButton' onClick={() => handleCheck(false)}>
            Same Tab
          </Form.Label>
        </Form.Group>
      </Form.Group>
    </Form>
  );
};

export default connector(ButtonFormComponent);
