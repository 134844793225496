import { mergeAttributes, Node } from '@tiptap/core';
import { optimizeStyle } from './utilityFunctions';

export interface TableBodyOptions {
  HTMLAttributes: Record<string, never>;
}

export const CustomizedTableBody = Node.create<TableBodyOptions>({
  name: 'tableBody',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  // group: 'tableBlock',
  content: 'tableRow+',
  isolating: true,
  tableRole: 'body',

  parseHTML() {
    return [{ tag: 'tbody' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'tbody',
      optimizeStyle(mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)),
      0,
    ];
  },
});
