import React, { useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';
import youtubeVideoIcon from '../../../../assets/icons/youtubeVideoIcon.svg';
import vimeoVideoIcon from '../../../../assets/icons/vimeoVideoIcon.svg';
import externalLinkIcon from '../../../../assets/icons/externalLinkIcon.svg';
import './videoModal.scss';

interface VideoModalContentInterface {
  handleConfirmState: Function;
  handleSelectedElement: Function;
}

export const VideoModalContent = (props: VideoModalContentInterface) => {
  const [youtubeInputValue, setYoutubeInputValue] = useState('');
  const [vimeoInputValue, setVimeoInputValue] = useState('');
  const [externalLinksInputValue, setExternalLinksInputValue] = useState('');
  const [inputType, setInputType] = useState('');
  const getVimeoVideoID = (url: string) => {
    const videoUrl = url.split(/video\/|https?:\/\/vimeo\.com\//);
    return undefined !== videoUrl[1] ? videoUrl[1].split(/[?&]/)[0] : videoUrl[0];
  };

  const getYouTubeVideoID = (url: string) => {
    const videoUrl = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return undefined !== videoUrl[2] ? videoUrl[2].split(/[^0-9a-z_\\-]/i)[0] : videoUrl[0];
  };
  const validateUrl = useCallback(
    (url: string) => {
      if (inputType === 'youtube') {
        const videoID = getYouTubeVideoID(url);
        return `https://www.youtube.com/embed/${videoID}`;
      } else if (inputType === 'vimeo') {
        const videoID = getVimeoVideoID(url);
        return `https://player.vimeo.com/video/${videoID}`;
      } else {
        return url;
      }
    },
    [inputType],
  );
  const handleInputOnClick = (type: string) => {
    switch (type) {
      case 'youtube':
        setVimeoInputValue('');
        setExternalLinksInputValue('');
        break;
      case 'vimeo':
        setYoutubeInputValue('');
        setExternalLinksInputValue('');
        break;
      case 'externalLink':
        setYoutubeInputValue('');
        setVimeoInputValue('');
        break;
      default:
        break;
    }
    setInputType(type);
  };
  const handleInputOnchange = (event: React.ChangeEvent, type: string) => {
    const value = (event.target as HTMLInputElement).value;
    switch (type) {
      case 'youtube':
        setYoutubeInputValue(value);
        break;
      case 'vimeo':
        setVimeoInputValue(value);
        break;
      case 'externalLink':
        setExternalLinksInputValue(value);
        break;
      default:
        break;
    }
    const url = validateUrl(value);
    props.handleSelectedElement({
      elementType: 'video',
      mediaType: 'video',
      url: !url.startsWith('http://') && !url.startsWith('https://') ? 'https://' + url : url,
    });
    props.handleConfirmState(value.trim() === '');
  };
  return (
    <div className='video-modal-container'>
      <Form>
        <div className='external-links-video-icon'>
          <img src={youtubeVideoIcon} alt='youtube-video' />
        </div>
        <Form.Control
          className='external-links-tab-input youtube-video'
          autoComplete='off'
          placeholder='Youtube link'
          value={youtubeInputValue.replace(/ +/g, ' ')}
          onClick={() => handleInputOnClick('youtube')}
          onChange={(e) => handleInputOnchange(e, 'youtube')}
        />
      </Form>
      <Form>
        <div className='external-links-video-icon'>
          <img src={vimeoVideoIcon} alt='vimeo-video' />
        </div>
        <Form.Control
          className='external-links-tab-input vimeo-video'
          autoComplete='off'
          placeholder='Vimeo link'
          value={vimeoInputValue.replace(/ +/g, ' ')}
          onClick={() => handleInputOnClick('vimeo')}
          onChange={(e) => handleInputOnchange(e, 'vimeo')}
        />
      </Form>
      <Form>
        <div className='external-links-video-icon'>
          <img src={externalLinkIcon} alt='externalLinks-video' />
        </div>
        <Form.Control
          className='external-links-tab-input external-links'
          autoComplete='off'
          placeholder='External link'
          value={externalLinksInputValue.replace(/ +/g, ' ')}
          onClick={() => handleInputOnClick('externalLink')}
          onChange={(e) => handleInputOnchange(e, 'externalLink')}
        />
      </Form>
    </div>
  );
};
