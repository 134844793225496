import { Extension } from '@tiptap/core';
import '@tiptap/extension-text-style';

type TextTransformOptions = {
  types: string[];
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    textTransform: {
      setTextTransform: (transform: '' | 'uppercase' | 'lowercase' | 'capitalize') => ReturnType;
    };
  }
}

export const CustomizedTextTransform = Extension.create<TextTransformOptions>({
  name: 'textTransform',

  addOptions() {
    return {
      types: ['textStyle'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          textTransform: {
            default: null,
            parseHTML: (element) => element.style.textTransform.replace(/['"]+/g, ''),
            renderHTML: (attributes: Record<string, string>) => {
              if (!attributes['textTransform']) {
                return {};
              }
              return {
                style: `text-transform: ${attributes['textTransform']}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setTextTransform:
        (textTransform) =>
        ({ chain }) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          chain().setMark('textStyle', { textTransform }).run(),
    };
  },
});
