/* eslint-disable @typescript-eslint/no-explicit-any */
import { mergeAttributes, Node } from '@tiptap/core';
import { optimizeStyle, simpleElementNodeView } from './utilityFunctions';
import { CommonListOptions } from '../editorUtilityFunctions/lintEditorType';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    bulletLists: {
      /**
       * Toggle a bullet list
       */
      toggleBulletListContent: (numberingType: string) => ReturnType;
    };
  }
}

export const BulletList = Node.create<CommonListOptions>({
  name: 'bulletList',

  addOptions() {
    return {
      itemTypeName: 'listItem',
      HTMLAttributes: {},
      keepMarks: false,
      keepAttributes: false,
    };
  },

  group: 'block list',
  atom: true,
  selectable: true,
  draggable: false,

  content() {
    return `${this.options.itemTypeName}+`;
  },

  parseHTML() {
    return [{ tag: 'ul' }];
  },

  addAttributes() {
    return {
      type: {
        parseHTML: (element) => element.getAttribute('type'),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'ul',
      optimizeStyle(mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {})),
      0,
    ];
  },

  addNodeView() {
    return simpleElementNodeView('ul');
  },

  addCommands() {
    return {
      toggleBulletListContent:
        (numberingType: string) =>
        ({ commands, chain }) => {
          if (this.options.keepAttributes) {
            return chain().toggleLists(this.name, this.options.itemTypeName, numberingType).run();
          }
          return commands.toggleLists(this.name, this.options.itemTypeName, numberingType);
        },
    };
  },
});
