import React from 'react';
import { createPopper, Placement, State } from '@popperjs/core';
import ReactDOM from 'react-dom';
import { CONSTANTS } from '../editor/editorUtilityFunctions/constants';
import { ColorPicker } from '@crystaldelta/loree-ui-color-picker';

export interface ColorPickerApiHandlers {
  onClear?: () => void;
  onChange?: (color: string) => void;
  onClose?: () => void;
}
let colorPickerPopperInstance: {
  destroy: () => void;
  state?: State;
  forceUpdate?: () => void;
  update?: () => Promise<Partial<State>>;
} | null = null;

function getWrapperDiv(doc: Document): HTMLDivElement {
  let div = doc.querySelector<HTMLDivElement>('#loree-cp2');
  if (div) return div;

  div = doc.createElement('div');
  doc.body.appendChild(div);
  div.id = 'loree-cp2';
  div.style.overflow = 'visible';
  return div;
}

export function ShowColorPicker(
  element: HTMLElement,
  defaultColor: string,
  handlers: ColorPickerApiHandlers,
  iframe?: HTMLIFrameElement | null,
  placement?: Placement | undefined,
): void {
  const destroyPopperInstance = () => {
    removeListenersOnDestroy();
    handlers.onClose?.();

    const colorPickerElement = document.getElementById('color-picker');

    colorPickerPopperInstance?.destroy();
    colorPickerPopperInstance = null;
    if (colorPickerElement) colorPickerElement.style.display = 'none';

    const div = document.querySelector<HTMLDivElement>('#loree-cp2');
    if (div) {
      // eslint-disable-next-line react/no-deprecated
      ReactDOM.unmountComponentAtNode(div);
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      handlers.onClose?.();
    }
  };

  const handleClick = (e: MouseEvent) => {
    const coloPicker = document.getElementById('color-picker');
    let lookupElement = e?.target as HTMLElement;
    const addColorBtn = 'add-color-btn';
    if (lookupElement?.classList?.contains(addColorBtn)) {
      let currentTargetElement = e.target as HTMLElement;
      while (currentTargetElement.tagName.toLowerCase() !== 'button') {
        currentTargetElement = currentTargetElement.parentElement as HTMLElement;
        lookupElement = currentTargetElement;
      }
    }

    // to verify if the cick is inside color picker
    if (
      !coloPicker?.contains(e.target as HTMLElement) &&
      (!lookupElement.id || lookupElement.id !== element.id)
    )
      destroyPopperInstance();
    else if (lookupElement.id === element.id) removeListenersOnDestroy();
  };

  const removeListenersOnDestroy = () => {
    document.body.removeEventListener('keydown', handleKeyPress);
    document.body.removeEventListener('mousedown', handleClick);
  };

  const addListenersOnCreate = () => {
    document.body.addEventListener('keydown', handleKeyPress);
    document.body.addEventListener('mousedown', handleClick);
  };

  let offsetX: number;
  let offsetY: number;

  if (iframe) {
    const r = iframe.getBoundingClientRect();
    offsetX = r.x;
    offsetY = r.y;
  }

  if (colorPickerPopperInstance) {
    destroyPopperInstance();
  } else {
    const div = getWrapperDiv(document);
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(
      <ColorPicker
        initialColor={defaultColor}
        onChange={(v) => handlers.onChange?.(v)}
        onClear={() => {
          handlers?.onClear?.();
          destroyPopperInstance();
        }}
        globalColorList={[]}
        sharedColors={[]}
        colorPickerFeatures={CONSTANTS.LOREE_COLORPICKER_FEATURES}
        colorPickerType=''
        colorContrastRatio={() => {}}
      />,
      div,
    );
    const colorPickerElement = document.getElementById('color-picker');

    if (colorPickerElement) {
      colorPickerPopperInstance = createPopper(element, colorPickerElement, {
        placement: placement ?? 'bottom-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              offset: ({ placement }: any) => {
                if (placement === 'top-start' || placement === 'top-end') {
                  return [offsetX, offsetY - 124];
                } else {
                  return [offsetX, offsetY + 10];
                }
              },
            },
          },
        ],
      });
      colorPickerElement.style.display = 'block';
      addListenersOnCreate();
    }
  }
}
