import { Node as ProseMirrorNode } from 'prosemirror-model';
import { NodeView } from 'prosemirror-view';

export function updateColumns(
  node: ProseMirrorNode,
  colgroup: Element,
  table: Element,
  cellMinWidth: number,
  overrideCol?: number,
  overrideValue?: never,
) {
  let totalWidth: number = 0;
  let fixedWidth = true;
  let nextDOM = colgroup.firstChild;
  const row = node.firstChild;

  if (row) {
    for (let i = 0, col = 0; i < row.childCount; i += 1) {
      const { colspan, colwidth } = row.child(i).attrs as { colspan: number; colwidth: number[] };

      for (let j = 0; j < colspan; j += 1, col += 1) {
        const hasWidth = overrideCol === col ? overrideValue : colwidth?.[j];
        const cssWidth = hasWidth ? `${hasWidth}px` : '';

        totalWidth += hasWidth || cellMinWidth;

        if (!hasWidth) {
          fixedWidth = false;
        }

        if (!nextDOM) {
          colgroup.appendChild(document.createElement('col')).style.width = cssWidth;
        } else {
          if ((nextDOM as HTMLElement).style.width !== cssWidth) {
            (nextDOM as HTMLElement).style.width = cssWidth;
          }

          nextDOM = nextDOM.nextSibling;
        }
      }
    }
  }

  while (nextDOM) {
    const after = nextDOM.nextSibling;

    nextDOM?.parentNode?.removeChild(nextDOM);
    nextDOM = after;
  }

  if (fixedWidth) {
    (table as HTMLElement).style.width = `${totalWidth}px`;
    (table as HTMLElement).style.minWidth = '';
  } else {
    (table as HTMLElement).style.width = '';
    (table as HTMLElement).style.minWidth = `${totalWidth}px`;
  }
}

export interface TableViewConstructor {
  new (node: ProseMirrorNode, cellMinWidth: number): TableView;
}

export class TableView implements NodeView {
  node: ProseMirrorNode;

  cellMinWidth: number;

  dom: Element;

  table: HTMLTableElement;

  colgroup: HTMLTableColElement | null = null;

  contentDOM: HTMLElement | null;

  constructor(node: ProseMirrorNode, cellMinWidth: number) {
    this.node = node;
    this.cellMinWidth = cellMinWidth;
    this.table = this.dom = document.createElement('table');
    if (node.attrs['class']) {
      this.table.className = node.attrs['class'] as string;
    }
    if (node.attrs['style']) {
      this.table.setAttribute('style', node.attrs['style'] as string);
    }
    this.contentDOM = this.table;
  }

  update(node: ProseMirrorNode) {
    if (node.type !== this.node.type) {
      return false;
    }
    this.node = node;
    if (this.node.attrs['data-colwidths']) {
      const widths = this.node.attrs['data-colwidths'] as number[];
      const sum = widths.reduce((t, v) => t + v, 0);
      if (widths.filter((x) => x === 0).length > 0 && sum > 0) {
        this.table.style.minWidth = `${sum}px`;
      }
      if (widths.filter((x) => x === 0).length == 0 && sum > 0) {
        this.table.style.width = `${sum}px`;
      }
    }
    return true;
  }

  ignoreMutation(mutation: MutationRecord | { type: 'selection'; target: Element }) {
    return (
      mutation.type === 'attributes' &&
      (mutation.target === this.table ||
        (this.colgroup !== null && this.colgroup.contains(mutation.target)))
    );
  }
}
