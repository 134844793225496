export const mathNumberByGlyph: Record<string, string> = {
  '₀': '_{0}',
  '₁': '_{1}',
  '₂': '_{2}',
  '₃': '_{3}',
  '₄': '_{4}',
  '₅': '_{5}',
  '₆': '_{6}',
  '₇': '_{7}',
  '₈': '_{8}',
  '₉': '_{9}',
  '⁰': '^{0}',
  '¹': '^{1}',
  '²': '^{2}',
  '³': '^{3}',
  '⁴': '^{4}',
  '⁵': '^{5}',
  '⁶': '^{6}',
  '⁷': '^{7}',
  '⁸': '^{8}',
  '⁹': '^{9}',
  ⁿ: '^{n}',
  ₙ: '_{n}',
  '⓪': '\\textcircled{0}',
  '①': '\\textcircled{1}',
  '②': '\\textcircled{2}',
  '③': '\\textcircled{3}',
  '④': '\\textcircled{4}',
  '⑤': '\\textcircled{5}',
  '⑥': '\\textcircled{6}',
  '⑦': '\\textcircled{7}',
  '⑧': '\\textcircled{8}',
  '⑨': '\\textcircled{9}',
  '⑩': '\\textcircled{10}',
  '⑪': '\\textcircled{11}',
  '⑫': '\\textcircled{12}',
  '⑬': '\\textcircled{13}',
  '⑭': '\\textcircled{14}',
  '⑮': '\\textcircled{15}',
  '⑯': '\\textcircled{16}',
  '⑰': '\\textcircled{17}',
  '⑱': '\\textcircled{18}',
  '⑲': '\\textcircled{19}',
  '⑳': '\\textcircled{20}',
  '㉑': '\\textcircled{21}',
  '㉒': '\\textcircled{22}',
  '㉓': '\\textcircled{23}',
  '㉔': '\\textcircled{24}',
  '㉕': '\\textcircled{25}',
  '㉖': '\\textcircled{26}',
  '㉗': '\\textcircled{27}',
  '㉘': '\\textcircled{28}',
  '㉙': '\\textcircled{29}',
  '㉚': '\\textcircled{30}',
  '㉛': '\\textcircled{31}',
  '㉜': '\\textcircled{32}',
  '㉝': '\\textcircled{33}',
  '㉞': '\\textcircled{34}',
  '㉟': '\\textcircled{35}',
  '㊱': '\\textcircled{36}',
  '㊲': '\\textcircled{37}',
  '㊳': '\\textcircled{38}',
  '㊴': '\\textcircled{39}',
  '㊵': '\\textcircled{40}',
  '㊶': '\\textcircled{41}',
  '㊷': '\\textcircled{42}',
  '㊸': '\\textcircled{43}',
  '㊹': '\\textcircled{44}',
  '㊺': '\\textcircled{45}',
  '㊻': '\\textcircled{46}',
  '㊼': '\\textcircled{47}',
  '㊽': '\\textcircled{48}',
  '㊾': '\\textcircled{49}',
  '㊿': '\\textcircled{50}',
  '½': '\\dfrac{1}{2}',
  '⅓': '\\dfrac{1}{3}',
  '⅔': '\\dfrac{2}{3}',
  '¼': '\\dfrac{1}{4}',
  '¾': '\\dfrac{3}{4}',
  '⅕': '\\dfrac{1}{5}',
  '⅖': '\\dfrac{2}{5}',
  '⅗': '\\dfrac{3}{5}',
  '⅘': '\\dfrac{4}{5}',
  '⅙': '\\dfrac{1}{6}',
  '⅚': '\\dfrac{5}{6}',
  '⅐': '\\dfrac{1}{7}',
  '⅛': '\\dfrac{1}{8}',
  '⅜': '\\dfrac{3}{8}',
  '⅝': '\\dfrac{5}{8}',
  '⅞': '\\dfrac{7}{8}',
  '⅑': '\\dfrac{1}{9}',
  '⅒': '\\dfrac{1}{10}',
};
