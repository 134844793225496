export const INITIAL_STATE = {
  imageCollection: [],
  isFileNameExists: false,
};

const imageStorageReducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
    case "SET_IMAGE_COLLECTION":
      return {
        ...state,
        imageCollection: data.imageCollection,
      };
    case "SET_FILE_NAME_EXISTS_STATE":
      return {
        ...state,
        isFileNameExists: data.isFileNameExists,
      };
    default:
      return state;
  }
};

export default imageStorageReducer;
