import React, { ElementType } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Card, Row, Col } from 'react-bootstrap';
import {
  CustomisationInterface,
  getBorderStyle,
  getClickCardStyle,
  getRevealCardStyle,
} from '../../../middleware/getStyle';
import '../../../assets/scss/interactiveCard.scss';

interface ClickAndRevealComponentProps {
  parameters: Array<{ title: string; text: string }>;
  customisations: CustomisationInterface;
}

const ClickAndRevealComponent = (props: ClickAndRevealComponentProps) => {
  const { parameters, customisations } = props;
  const fontHeadings = customisations.fontHeadings !== '' ? customisations.fontHeadings : 'div';
  const borderStyle = getBorderStyle(customisations);
  const cardStyle = {
    fontFamily: `${customisations.fontFamily}`,
    fontWeight: `${customisations.fontWeight}`,
    fontSize: `${customisations.fontSize}px`,
    textAlign: customisations.alignment,
    color: `${customisations.fontColor}`,
  };

  const handleClick = (idx: number, event: React.KeyboardEvent | React.MouseEvent) => {
    const { key, type } = event as React.KeyboardEvent;
    if (type === 'keyup' && key !== 'Enter' && key !== ' ') return;
    (document.getElementById(`sub_card${idx}`) as HTMLElement).classList?.remove('d-none');
  };

  return (
    <>
      {parameters.map((content: { title: string; text: string }, idx: number) => (
        <Row className='my-3' key={content['text']}>
          <Col xs={6} id={`click_content_${idx}`} className='interactive-imageView'>
            <Card
              role='button'
              tabIndex={0}
              onKeyUp={(event) => handleClick(idx, event)}
              key={`${content['text']}_${content['title']}`}
              id={`card${idx}`}
              style={
                customisations.clickBorderType ? getClickCardStyle(customisations) : borderStyle
              }
              className={`w-75 cardHeight shadow ${
                customisations.clickBorderType ? '' : customisations.radius
              }`}
              onClick={(event) => handleClick(idx, event)}
            >
              <Card.Body className='interactive-textstyle'>
                {ReactHtmlParser(content['text'])}
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={6}
            key={content['title']}
            id={`sub_card${idx}`}
            className='interactive-imageView d-none'
          >
            <Card
              tabIndex={0}
              className={`w-75 cardHeight shadow ${
                customisations.revealBorderType ? '' : customisations.radius
              }`}
              style={
                content['title'].startsWith('<') && content['title'].endsWith('>')
                  ? getRevealCardStyle(customisations)
                  : borderStyle
              }
              id={`reveal_content_${idx}`}
            >
              <Card.Body
                as={
                  (content['title'].startsWith('<') && content['title'].endsWith('>')
                    ? 'div'
                    : fontHeadings) as ElementType
                }
                className='interactive-textstyle'
                style={
                  content['title'].startsWith('<') && content['title'].endsWith('>')
                    ? {}
                    : cardStyle
                }
              >
                {ReactHtmlParser(content['title'])}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ))}
    </>
  );
};

export default ClickAndRevealComponent;
