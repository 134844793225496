import React from 'react';
import './loader.scss';

interface LoaderProps {
  iconSize?: string;
  title?: string;
  style?: React.CSSProperties;
}

const LoaderComponent: React.FC<LoaderProps> = (prop) => {
  return (
    <>
      <div
        title={prop.title ? prop.title : 'Loading, please wait'}
        className={`fas fa-spinner fa-spin ${prop.iconSize ? prop.iconSize : 'fa-3x'}`}
      />
      {prop.title && (
        <h6 className='font-italic' style={prop.style ?? {}}>
          {prop.title}
        </h6>
      )}
    </>
  );
};

export default LoaderComponent;
