export const allMathOperatorsByGlyph: Record<string, string> = {
  _: '\\underline',
  '⏡': '\\underbrace',
  '⏠': '\\overbrace',
  '⏟': '\\underbrace',
  '⏞': '\\overbrace',
  '⏝': '\\underbrace',
  '⏜': '\\overbrace',
  '⎵': '\\underbrace',
  '⎴': '\\overbrace',
  '⃜': '\\square',
  '⃛': '\\square',
  '⁤': '',
  '⁗': "''''",
  '‾': '\\overline',
  '‷': '```',
  '‶': '``',
  '‵': '`',
  '‴': "'''",
  '″': "''",
  '‟': '``',
  '„': ',,',
  '‛': '`',
  '‚': ',',
  '^': '\\hat',
  '˷': '\\sim',
  '˝': '\\sim',
  '˜': '\\sim',
  '˚': '\\circ',
  '˙': '\\cdot',
  '˘': ' ',
  ˍ: '\\_',
  ˋ: 'ˋ',
  ˊ: 'ˊ',
  ˉ: 'ˉ',
  ˇ: '',
  ˆ: '\\hat',
  º: 'o',
  '¹': '1',
  '¸': ',',
  '´': '´',
  '³': '3',
  '²': '2',
  '°': '\\circ',
  '¯': '\\bar',
  ª: 'a',
  '↛': '\\nrightarrow',
  '¨': '\\cdot\\cdot',
  '~': '\\sim',
  '`': '`',
  '--': '--',
  '++': '++',
  '&': '\\&',
  '∜': '\\sqrt[4]{}',
  '∛': '\\sqrt[3]{}',
  '√': '\\sqrt{}',
  ⅆ: 'd',
  ⅅ: '\\mathbb{D}',
  '?': '?',
  '@': '@',
  '//': '//',
  '!!': '!!',
  '!': '!',
  '♯': '\\#',
  '♮': '',
  '♭': '',
  '′': "'",
  '<>': '<>',
  '**': '\\star\\star',
  '∇': '\\nabla',
  '∂': '\\partial',
  '⊙': '\\bigodot',
  '¬': '\\neg',
  '∢': '\\measuredangle',
  '∡': '\\measuredangle',
  '∠': '\\angle',
  '÷': '\\div',
  '/': '/',
  '∖': '\\backslash',
  '\\': '\\backslash',
  '%': '\\%',
  '⊗': '\\bigotimes',
  '·': '\\cdot',
  '⨿': '\\coprod',
  '⨯': '\\times',
  '⋅': '\\cdot',
  '⊡': '\\boxdot',
  '⊠': '\\boxtimes',
  '⁢': '',
  '⁃': '-',
  '•': '\\cdot',
  '.': '.',
  '*': '\\star',
  '∪': '\\cup',
  '∩': '\\cap',
  '∐': '\\coprod',
  '∏': '\\prod',
  '≀': '',
  '⫿': '',
  '⫼': '\\mid\\mid\\mid',
  '⨉': '\\times',
  '⨈': '',
  '⨇': '',
  '⨆': '\\sqcup',
  '⨅': '\\sqcap',
  '⨂': '\\otimes',
  '⨀': '\\odot',
  '⋂': '\\cap',
  '⋁': '\\vee',
  '⋀': '\\wedge',
  '⨄': '\\uplus',
  '⨃': '\\cup',
  '⋃': '\\cup',
  '⨜': '\\underline{\\int}',
  '⨛': '\\overline{\\int}',
  '⨚': '\\int',
  '⨙': '\\int',
  '⨘': '\\int',
  '⨗': '\\int',
  '⨖': '\\oint',
  '⨕': '\\oint',
  '⨔': '\\int',
  '⨓': '\\int',
  '⨒': '\\int',
  '⨑': '\\int',
  '⨐': '\\int',
  '⨏': '\\bcancel{\\int}',
  '⨎': '',
  '⨍': '\\hcancel{\\int}',
  '⨌': '\\iiiint',
  '∳': '\\oint',
  '∲': '\\oint',
  '∱': '\\int',
  '∰': '\\oiint',
  '∯': '\\oiint',
  '∮': '\\oint',
  '∫': '\\int',
  '⨁': '\\oplus',
  '⊘': '\\oslash',
  '⊖': '\\ominus',
  '⊕': '\\oplus',
  '∭': '\\iiint',
  '∬': '\\iint',
  '⨋': '',
  '⨊': '',
  '∑': '\\sum',
  '⊟': '\\boxminus',
  '⊞': '\\boxplus',
  '∔': '\\dot{+}',
  '∓': '+-',
  '−': '-',
  '±': '\\pm',
  '-': '-',
  '+': '+',
  '⭆': '\\Rrightarrow',
  '⭅': '\\Lleftarrow',
  '⧴': ':\\rightarrow',
  '⧯': '',
  '⧟': '\\bullet-\\bullet',
  '⦟': '\\angle',
  '⦞': '\\measuredangle',
  '⦝': '\\measuredangle',
  '⦜': '\\perp',
  '⦛': '\\measuredangle',
  '⦚': '',
  '⦙': '\\vdots',
  '⥿': '',
  '⥾': '',
  '⥽': '\\prec',
  '⥼': '\\succ',
  '⥻': '\\underset{\\rightarrow}{\\supset}',
  '⥺': '',
  '⥹': '\\underset{\\rightarrow}{\\subset}',
  '⥸': '\\underset{\\rightarrow}{>}',
  '⥷': '',
  '⥶': '\\underset{\\leftarrow}{<}',
  '⥵': '\\underset{\\approx}{\\rightarrow}',
  '⥴': '\\underset{\\sim}{\\rightarrow}',
  '⥳': '\\underset{\\sim}{\\leftarrow}',
  '⥲': '\\overset{\\sim}{\\rightarrow}',
  '⥱': '\\overset{=}{\\rightarrow}',
  '⥰': '',
  '⥯': '',
  '⥮': '',
  '⥭': '\\overline{\\rightharpoondown}',
  '⥬': '\\underline{\\rightharpoonup}',
  '⥫': '\\overline{\\leftharpoondown}',
  '⥪': '\\underline{\\leftharpoonup}',
  '⥩': '\\rightleftharpoons',
  '⥨': '\\rightleftharpoons',
  '⥧': '\\rightleftharpoons',
  '⥦': '\\rightleftharpoons',
  '⥥': '\\Downarrow',
  '⥤': '\\Rightarrow',
  '⥣': '\\Uparrow',
  '⥢': '\\Leftarrow',
  '⥡': '\\downarrow',
  '⥠': '\\uparrow',
  '⥟': '\\rightarrow',
  '⥞': '\\leftarrow',
  '⥝': '\\downarrow',
  '⥜': '\\uparrow',
  '⥛': '\\rightarrow',
  '⥚': '\\leftarrow',
  '⥙': '\\downarrow',
  '⥘': '\\uparrow',
  '⥗': '\\rightarrow',
  '⥖': '\\leftarrow',
  '⥕': '\\downarrow',
  '⥔': '\\uparrow',
  '⥓': '\\rightarrow',
  '⥒': '\\leftarrow',
  '⥑': '\\updownarrow',
  '⥐': '\\leftrightarrow',
  '⥏': '\\updownarrow',
  '⥎': '\\leftrightarrow',
  '⥍': '\\updownarrow',
  '⥌': '\\updownarrow',
  '⥋': '\\leftrightarrow',
  '⥊': '\\leftrightarrow',
  '⥉': '',
  '⥈': '\\leftrightarrow',
  '⥇': '\\nrightarrow',
  '⥆': '',
  '⥅': '',
  '⥄': '\\rightleftarrows',
  '⥃': '\\leftrightarrows',
  '⥂': '\\rightleftarrows',
  '⥁': '\\circlearrowright',
  '⥀': '\\circlearrowleft',
  '⤿': '\\rightarrow',
  '⤾': '\\leftarrow',
  '⤽': '\\leftarrow',
  '⤼': '\\rightarrow',
  '⤻': '\\rightarrow',
  '⤺': '\\leftarrow',
  '⤹': '\\downarrow',
  '⤸': '\\downarrow',
  '⤷': '\\Rsh',
  '⤶': '\\Lsh',
  '⤵': '\\downarrow',
  '⤴': '\\uparrow',
  '⤳': '\\rightarrow',
  '⤲': '\\leftarrow',
  '⤱': ' ',
  '⤰': ' ',
  '⤯': ' ',
  '⤮': ' ',
  '⤭': ' ',
  '⤬': '\\times',
  '⤫': '\\times',
  '⤪': ' ',
  '⤩': ' ',
  '⤨': ' ',
  '⤧': ' ',
  '⤦': ' ',
  '⤥': ' ',
  '⤤': ' ',
  '⤣': ' ',
  '⤢': ' ',
  '⤡': ' ',
  '⤠': '\\mapsto\\cdot',
  '⤟': '\\cdot\\leftarrow',
  '⤞': '\\rightarrow\\cdot',
  '⤝': '\\leftarrow',
  '⤜': '\\rightarrow',
  '⤛': '\\leftarrow',
  '⤚': '\\rightarrow',
  '⤙': '\\leftarrow',
  '⤘': '\\rightarrow',
  '⤗': '\\rightarrow',
  '⤖': '\\rightarrow',
  '⤕': '\\rightarrow',
  '⤔': '\\rightarrow',
  '⤓': '\\downarrow',
  '⤒': '\\uparrow',
  '⤑': '\\rightarrow',
  '⤐': '\\rightarrow',
  '⤏': '\\rightarrow',
  '⤎': '\\leftarrow',
  '⤍': '\\rightarrow',
  '⤌': '\\leftarrow',
  '⤋': '\\Downarrow',
  '⤊': '\\Uparrow',
  '⤉': '\\uparrow',
  '⤈': '\\downarrow',
  '⤇': '\\Rightarrow',
  '⤆': '\\Leftarrow',
  '⤅': '\\mapsto',
  '⤄': '\\nLeftrightarrow',
  '⤃': '\\nRightarrow',
  '⤂': '\\nLeftarrow',
  '⤁': '\\rightsquigarrow',
  '⤀': '\\rightsquigarrow',
  '⟿': '\\rightsquigarrow',
  '⟾': '\\Rightarrow',
  '⟽': '\\Leftarrow',
  '⟼': '\\mapsto',
  '⟻': '\\leftarrow',
  '⟺': '\\Longleftrightarrow',
  '⟹': '\\Longrightarrow',
  '⟸': '\\Longleftarrow',
  '⟷': '\\leftrightarrow',
  '⟶': '\\rightarrow',
  '⟵': '\\leftarrow',
  '⟱': '\\Downarrow',
  '⟰': '\\Uparrow',
  '⊸': '\\rightarrow',
  '⇿': '\\leftrightarrow',
  '⇾': '\\rightarrow',
  '⇽': '\\leftarrow',
  '⇼': '\\nleftrightarrow',
  '⇻': '\\nrightarrow',
  '⇺': '\\nleftarrow',
  '⇹': '\\nleftrightarrow',
  '⇸': '\\nrightarrow',
  '⇷': '\\nleftarrow',
  '⇶': '\\Rrightarrow',
  '⇵': '',
  '⇴': '\\rightarrow',
  '⇳': '\\Updownarrow',
  '⇲': '\\searrow',
  '⇱': '\\nwarrow',
  '⇰': '\\Leftarrow',
  '⇯': '\\Uparrow',
  '⇮': '\\Uparrow',
  '⇭': '\\Uparrow',
  '⇬': '\\Uparrow',
  '⇫': '\\Uparrow',
  '⇪': '\\Uparrow',
  '⇩': '\\Downarrow',
  '⇨': '\\Rightarrow',
  '⇧': '\\Uparrow',
  '⇦': '\\Leftarrow',
  '⇥': '\\rightarrow',
  '⇤': '\\leftarrow',
  '⇣': '\\downarrow',
  '⇢': '\\rightarrow',
  '⇡': '\\uparrow',
  '⇠': '\\leftarrow',
  '⇟': '\\downarrow',
  '⇞': '\\uparrow',
  '⇝': '\\rightsquigarrow',
  '⇜': '\\leftarrow',
  '⇛': '\\Rrightarrow',
  '⇚': '\\Lleftarrow',
  '⇙': '\\swarrow',
  '⇘': '\\searrow',
  '⇗': '\\nearrow',
  '⇖': '\\nwarrow',
  '⇕': '\\Updownarrow',
  '⇔': '\\Leftrightarrow',
  '⇓': '\\Downarrow',
  '⇒': '\\Rightarrow',
  '⇑': '\\Uparrow',
  '⇐': '\\Leftarrow',
  '⇏': '\\nRightarrow',
  '⇎': '\\nLeftrightarrow',
  '⇍': '\\nLeftarrow',
  '⇌': '\\rightleftharpoons',
  '⇋': '\\leftrightharpoons',
  '⇊': '\\downdownarrows',
  '⇉': '\\rightrightarrows',
  '⇈': '\\upuparrows',
  '⇇': '\\leftleftarrows',
  '⇆': '\\leftrightarrows',
  '⇅': '',
  '⇄': '\\rightleftarrows',
  '⇃': '\\downharpoonleft',
  '⇂': '\\downharpoonright',
  '⇁': '\\rightharpoondown',
  '⇀': '\\rightharpoonup',
  '↿': '\\upharpoonleft',
  '↾': '\\upharpoonright',
  '↽': '\\leftharpoondown',
  '↼': '\\leftharpoonup',
  '↻': '\\circlearrowright',
  '↺': '\\circlearrowleft',
  '↹': '\\leftrightarrows',
  '↸': '\\overline{\\nwarrow}',
  '↷': '\\curvearrowright',
  '↶': '\\curvearrowleft',
  '↵': '\\swarrow',
  '↴': '\\searrow',
  '↳': '\\Rsh',
  '↲': '\\Lsh',
  '↱': '\\Rsh',
  '↰': '\\Lsh',
  '↯': '\\swarrow',
  '↮': '',
  '↭': '\\leftrightsquigarrow',
  '↬': '\\looparrowright',
  '↫': '\\looparrowleft',
  '↪': '\\hookrightarrow',
  '↩': '\\hookleftarrow',
  '↨': '\\underline{\\updownarrow}',
  '↧': '\\downarrow',
  '↦': '\\rightarrowtail',
  '↥': '\\uparrow',
  '↤': '\\leftarrowtail',
  '↣': '\\rightarrowtail',
  '↢': '\\leftarrowtail',
  '↡': '\\downarrow',
  '↠': '\\twoheadrightarrow',
  '↟': '\\uparrow',
  '↞': '\\twoheadleftarrow',
  '↝': '\\nearrow',
  '↜': '\\nwarrow',
  '↚': '',
  '↙': '\\swarrow',
  '↘': '\\searrow',
  '↗': '\\nearrow',
  '↖': '\\nwarrow',
  '↕': '\\updownarrow',
  '↔': '\\leftrightarrow',
  '↓': '\\downarrow',
  '→': '\\rightarrow',
  '↑': '\\uparrow',
  '←': '\\leftarrow',
  '|||': '\\left|||\\right.',
  '||': '\\left||\\right.',
  '|': '\\mid',
  '⫾': '',
  '⫽': '//',
  '⫻': '///',
  '⫺': '',
  '⫹': '',
  '⫸': '',
  '⫷': '',
  '⫶': '\\vdots',
  '⫵': '',
  '⫴': '',
  '⫳': '',
  '⫲': '\\nparallel',
  '⫱': '',
  '⫰': '',
  '⫯': '',
  '⫮': '\\bcancel{\\mid}',
  '⫭': '',
  '⫬': '',
  '⫫': '',
  '⫪': '',
  '⫩': '',
  '⫨': '\\underline{\\perp}',
  '⫧': '\\overline{\\top}',
  '⫦': '',
  '⫥': '',
  '⫤': '',
  '⫣': '',
  '⫢': '',
  '⫡': '',
  '⫠': '\\perp',
  '⫟': '\\top',
  '⫞': '\\dashv',
  '⫝̸': '',
  '⫝': '',
  '⫛': '\\pitchfork',
  '⫚': '',
  '⫙': '',
  '⫘': '',
  '⫗': '',
  '⫖': '',
  '⫕': '',
  '⫔': '',
  '⫓': '',
  '⫒': '',
  '⫑': '',
  '⫐': '',
  '⫏': '',
  '⫎': '',
  '⫍': '',
  '⫌': '\\underset{\\neq}{\\supset}',
  '⫋': '\\underset{\\neq}{\\subset}',
  '⫊': '\\underset{\\approx}{\\supset}',
  '⫉': '\\underset{\\approx}{\\subset}',
  '⫈': '\\underset{\\sim}{\\supset}',
  '⫇': '\\underset{\\sim}{\\subset}',
  '⫆': '\\supseteqq',
  '⫅': '\\subseteqq',
  '⫄': '\\dot{\\supseteq}',
  '⫃': '\\dot{\\subseteq}',
  '⫂': '\\underset{\\times}{\\supset}',
  '⫁': '\\underset{\\times}{\\subset}',
  '⫀': '\\underset{+}{\\supset}',
  '⪿': '\\underset{+}{\\subset}',
  '⪾': '',
  '⪽': '',
  '⪼': '\\gg ',
  '⪻': '\\ll',
  '⪺': '\\underset{\\cancel{\\approx}}{\\succ}',
  '⪹': '\\underset{\\cancel{\\approx}}{\\prec}',
  '⪸': '\\underset{\\approx}{\\succ}',
  '⪷': '\\underset{\\approx}{\\prec}',
  '⪶': '\\underset{\\cancel{=}}{\\succ}',
  '⪵': '\\underset{\\cancel{=}}{\\prec}',
  '⪴': '\\underset{=}{\\succ}',
  '⪳': '\\underset{=}{\\prec}',
  '⪲': '',
  '⪱': '',
  '⪮': '',
  '⪭': '\\underline{\\hcancel{>}}',
  '⪬': '\\underline{\\hcancel{>}}',
  '⪫': '\\hcancel{>}',
  '⪪': '\\hcancel{<}',
  '⪩': '',
  '⪨': '',
  '⪧': '\\vartriangleright',
  '⪦': '\\vartriangleleft',
  '⪥': '><',
  '⪤': '><',
  '⪣': '\\underline{\\ll}',
  '⪢̸': '\\cancel{\\gg}',
  '⪢': '\\gg',
  '⪡̸': '\\cancel{\\ll}',
  '⪡': '\\ll',
  '⪠': '\\overset{\\sim}{\\geqq}',
  '⪟': '\\overset{\\sim}{\\leqq}',
  '⪞': '\\overset{\\sim}{>}',
  '⪝': '\\overset{\\sim}{<}',
  '⪜': '',
  '⪛': '',
  '⪚': '\\overset{=}{>}',
  '⪙': '\\overset{=}{<}',
  '⪘': '',
  '⪗': '',
  '⪖': '',
  '⪕': '',
  '⪔': '',
  '⪓': '',
  '⪒': '\\underset{=}{\\gtrless}',
  '⪑': '\\underset{=}{\\lessgtr}',
  '⪐': '\\underset{<}{\\gtrsim}',
  '⪏': '\\underset{>}{\\lesssim}',
  '⪎': '\\underset{\\simeq}{>}',
  '⪍': '\\underset{\\simeq}{<}',
  '⪌': '\\gtreqqless',
  '⪋': '\\lesseqqgtr',
  '⪊': '\\underset{\\cancel{\\approx}}{>}',
  '⪉': '\\underset{\\approx}{<}',
  '⪆': '\\underset{\\approx}{>}',
  '⪅': '\\underset{\\approx}{<}',
  '⪄': '',
  '⪃': '',
  '⪂': '',
  '⪁': '',
  '⪀': '',
  '⩿': '',
  '⩾̸': '\\bcancel{\\geq}',
  '⩾': '\\geq',
  '⩽̸': '\\bcancel{\\leq}',
  '⩽': '\\leq',
  '⩼': '',
  '⩻': '',
  '⩺': '',
  '⩹': '',
  '⩸': '\\overset{\\dots}{\\equiv}',
  '⩷': '',
  '⩶': '===',
  '⩵': '==',
  '⩴': '::=',
  '⩳': '',
  '⩲': '\\underset{=}{+}',
  '⩱': '\\overset{=}{+}',
  '⩰': '\\overset{\\approx}{=}',
  '⩯': '\\overset{\\wedge}{=}',
  '⩮': '\\overset{*}{=}',
  '⩭': '\\dot{\\approx}',
  '⩬': '',
  '⩫': '',
  '⩪': '\\dot{\\sim}',
  '⩩': '',
  '⩨': '',
  '⩧': '\\dot{\\equiv}',
  '⩦': '\\underset{\\cdot}{=}',
  '⩥': '',
  '⩤': '',
  '⩣': '\\underset{=}{\\vee}',
  '⩢': '\\overset{=}{\\vee}',
  '⩡': 'ul(vv)',
  '⩠': '\\underset{=}{\\wedge}',
  '⩟': '\\underline{\\wedge}',
  '⩞': '\\overset{=}{\\wedge}',
  '⩝': '\\hcancel{\\vee}',
  '⩜': '\\hcancel{\\wedge}',
  '⩛': '',
  '⩚': '',
  '⩙': '',
  '⩘': '\\vee',
  '⩗': '\\wedge',
  '⩖': '',
  '⩕': '',
  '⩔': '',
  '⩓': '',
  '⩒': '\\dot{\\vee}',
  '⩑': '\\dot{\\wedge}',
  '⩐': '',
  '⩏': '',
  '⩎': '',
  '⩍': '\\overline{\\cap}',
  '⩌': '\\overline{\\cup}',
  '⩋': '',
  '⩊': '',
  '⩉': '',
  '⩈': '',
  '⩇': '',
  '⩆': '',
  '⩅': '',
  '⩄': '',
  '⩃': '\\overline{\\cap}',
  '⩂': '\\overline{\\cup}',
  '⩁': '',
  '⩀': '',
  '⨾': '',
  '⨽': '\\llcorner',
  '⨼': '\\lrcorner',
  '⨻': '',
  '⨺': '',
  '⨹': '',
  '⨸': '',
  '⨷': '',
  '⨶': '\\hat{\\otimes}',
  '⨵': '',
  '⨴': '',
  '⨳': '',
  '⨲': '\\underline{\\times}',
  '⨱': '\\underline{\\times}',
  '⨰': '\\dot{\\times}',
  '⨮': '\\bigodot',
  '⨭': '\\bigodot',
  '⨬': '',
  '⨫': '',
  '⨪': '',
  '⨩': '',
  '⨨': '',
  '⨧': '',
  '◻': '\\Box',
  '⨦': '\\underset{\\sim}{+}',
  '⨥': '\\underset{\\circ}{+}',
  '⨤': '\\overset{\\sim}{+}',
  '⨣': '\\hat{+}',
  '⨢': '\\dot{+}',
  '⨡': '\\upharpoonright',
  '⨠': '>>',
  '⨟': '',
  '⨞': '\\triangleleft',
  '⨝': '\\bowtie',
  '⧿': '',
  '⧾': '+',
  '⧻': '\\hcancel{|||}',
  '⧺': '\\hcancel{||}',
  '⧹': '\\backslash',
  '⧸': '/',
  '⧷': 'hcancel{\backslash}',
  '⧶': '',
  '⧵': '\\backslash',
  '⧲': '\\Phi',
  '⧱': '',
  '⧰': '',
  '⧮': '',
  '⧭': '',
  '⧬': '',
  '⧫': '\\lozenge',
  '⧪': '',
  '⧩': '',
  '⧨': '',
  '⧧': '\\ddagger',
  '⧢': '\\sqcup\\sqcup',
  '⧡': '',
  '⧠': '\\square',
  '⧞': '',
  '⧝': '',
  '⧜': '',
  '⧛': '\\{\\{',
  '⧙': '\\{',
  '⧘': '\\}',
  '⧗': '',
  '⧖': '',
  '⧕': '\\bowtie',
  '⧔': '\\bowtie',
  '⧓': '\\bowtie',
  '⧒': '\\bowtie',
  '⧑': '\\bowtie',
  '⧐̸': '| \\not\\triangleright',
  '⧐': '| \\triangleright',
  '⧏̸': '\\not\\triangleleft |',
  '⧏': '\\triangleleft |',
  '⧎': '',
  '⧍': '\\triangle',
  '⧌': '',
  '⧋': '\\underline{\\triangle}',
  '⧊': '\\dot{\\triangle}',
  '⧉': '',
  '⧈': '\\boxed{\\circ}',
  '⧇': '\\boxed{\\circ}',
  '⧆': '\\boxed{\\rightarrow}',
  '⧅': '\\bcancel{\\square}',
  '⧄': '\\cancel{\\square}',
  '⧃': '\\odot',
  '⧂': '\\odot',
  '⦿': '\\odot',
  '⦾': '\\odot',
  '⦽': '\\varnothing',
  '⦼': '\\oplus',
  '⦻': '\\otimes',
  '⦺': '',
  '⦹': '\\varnothing',
  '⦸': '\\varnothing',
  '⦷': '\\ominus',
  '⦶': '\\ominus',
  '⦵': '\\ominus',
  '⦴': '\\vec{\\varnothing}',
  '⦳': '\\vec{\\varnothing}',
  '⦲': '\\dot{\\varnothing}',
  '⦱': '\\overline{\\varnothing}',
  '⦰': '\\varnothing',
  '⦯': '\\measuredangle',
  '⦮': '\\measuredangle',
  '⦭': '\\measuredangle',
  '⦬': '\\measuredangle',
  '⦫': '\\measuredangle',
  '⦪': '\\measuredangle',
  '⦩': '\\measuredangle',
  '⦨': '\\measuredangle',
  '⦧': '',
  '⦦': '',
  '⦥': '',
  '⦤': '',
  '⦣': '\\ulcorner',
  '⦢': '\\measuredangle',
  '⦡': '\\not\\lor',
  '⦠': '\\bcancel{>}',
  '⦂': ':',
  '⦁': '\\cdot',
  '❘': '\\mid',
  '▲': '\\bigtriangleup',
  '⋿': '\\Epsilon',
  '⋾': '\\overline{\\ni}',
  '⋽': '\\overline{\\ni}',
  '⋼': '\\in',
  '⋻': '\\in',
  '⋺': '\\in',
  '⋹': '\\underline{\\in}',
  '⋸': '\\underline{\\in}',
  '⋷': '\\overline{\\in}',
  '⋶': '\\overline{\\in}',
  '⋵': '\\dot{\\in}',
  '⋴': '\\in',
  '⋳': '\\in',
  '⋲': '\\in',
  '⋰': '\\ddots',
  '։': ':',
  '⋩': '\\underset{\\sim}{\\succ}',
  '⋨': '\\underset{\\sim}{\\prec}',
  '⋧': '\\underset{\\not\\sim}{>}',
  '⋦': '\\underset{\\not\\sim}{<}',
  '⋥': '\\not\\sqsupseteq',
  '⋤': '\\not\\sqsubseteq',
  '⋣': '\\not\\sqsupseteq',
  '⋢': '\\not\\sqsubseteq',
  '⋡': '\\nsucc',
  '⋠': '\\nprec',
  '⋟': '\\succ',
  '⋞': '\\prec',
  '⋝': '\\overline{>}',
  '⋜': '\\overline{<}',
  '⋛': '\\underset{>}{\\leq}',
  '⋚': '\\underset{<}{\\geq}',
  '⋕': '\\#',
  '⋓': '\\cup',
  '⋒': '\\cap',
  '⋑': '\\supset',
  '⋐': '\\subset',
  '⋏': '\\wedge',
  '⋎': '\\vee',
  '⋍': '\\simeq',
  '⋈': '\\Join',
  '⋇': '\\ast',
  '⋆': '\\star',
  '⋄': '\\diamond',
  '⊿': '\\triangle',
  '⊾': '\\measuredangle',
  '⊽': '\\overline{\\lor}',
  '⊼': '\\overline{\\land}',
  '⊻': '\\underline{\\lor}',
  '⊺': '\\top',
  土: '\\pm',
  十: '+',
  '⊹': '',
  '⊷': '\\circ\\multimap',
  '⊶': '\\circ\\multimap',
  '⊳': '\\triangleright',
  '⊲': '\\triangleleft',
  '⊱': '\\succ',
  '⊰': '\\prec',
  '⊫': '|\\models',
  '⊪': '|\\models',
  '⊧': '\\models',
  '⊦': '\\vdash',
  '⊝': '\\ominus',
  '⊜': '\\ominus',
  '⊛': '\\odot',
  '⊚': '\\odot',
  '⊔': '\\sqcup',
  '⊓': '\\sqcap',
  '⊒': '\\sqsupseteq',
  '⊑': '\\sqsubseteq',
  '⊐̸': '\\not\\sqsupset',
  '⊐': '\\sqsupset',
  '⊏̸': '\\not\\sqsubset',
  '⊏': '\\sqsubset',
  '⊎': '\\cup',
  '⊍': '\\cup',
  '⊌': '\\cup',
  '≿̸': '\\not\\succsim',
  '≿': '\\succsim',
  '≾': '\\precsim',
  '≹': '\\not\\overset{>}{<}',
  '≸': '\\not\\overset{>}{<}',
  '≷': '\\overset{>}{<}',
  '≶': '\\overset{<}{>}',
  '≵': '\\not\\geg',
  '≴': '\\not\\leq',
  '≳': '\\geg',
  '≲': '\\leq',
  '≬': '',
  '≧': '\\geg',
  '≦̸': '\\not\\leq',
  '≦': '\\leq',
  '≣': '\\overset{=}{=} ',
  '≞': '\\overset{m}{=} ',
  '≝': '\\overset{def}{=}',
  '≘': '=',
  '≖': '=',
  '≕': '=:',
  '≓': '\\doteq',
  '≒': '\\doteq',
  '≑': '\\doteq',
  '≐': '\\doteq',
  '≏̸': '',
  '≏': '',
  '≎̸': '',
  '≎': '',
  '≌': '\\approx',
  '≋': '\\approx',
  '≊': '\\approx',
  '≂̸': '\\neq',
  '≂': '=',
  '∿': '\\sim',
  '∾': '\\infty',
  '∽̱': '\\sim',
  '∽': '\\sim',
  '∻': '\\sim',
  '∺': ':-:',
  '∹': '-:',
  '∸': '\\bot',
  '∷': '::',
  '∶': ':',
  '∣': '\\mid',
  '∟': '\\llcorner',
  '∘': '\\circ',
  '∗': '*',
  '∕': '/',
  '∎': '\\square',
  '∍': '\\ni',
  '∊': '\\in',
  '∆': '\\Delta',
  '⁄': '/',
  '⪰̸': '\\nsucceq',
  '⪰': '\\succeq',
  '⪯̸': '\\npreceq',
  '⪯': '\\preceq',
  '⪈': '\\ngeqslant',
  '⪇': '\\nleqslant',
  '⧳': '\\Phi',
  '⧦': '\\models',
  '⧥': '\\not\\equiv',
  '⧤': '\\approx\\neq',
  '⧣': '\\neq',
  '⧁': '\\circle',
  '⧀': '\\circle',
  '◦': '\\circle',
  '◗': '\\circle',
  '◖': '\\circle',
  '●': '\\circle',
  '◎': '\\circledcirc',
  '◍': '\\circledcirc',
  '◌': '\\circledcirc',
  '◉': '\\circledcirc',
  '◈': '\\diamond',
  '◇': '\\diamond',
  '◆': '\\diamond',
  '◅': '\\triangleleft',
  '◄': '\\triangleleft',
  '◃': '\\triangleleft',
  '◂': '\\triangleleft',
  '◁': '\\triangleleft',
  '◀': '\\triangleleft',
  '▿': '\\triangledown',
  '▾': '\\triangledown',
  '▽': '\\triangledown',
  '▼': '\\triangledown',
  '▹': '\\triangleright',
  '▸': '\\triangleright',
  '▷': '\\triangleright',
  '▶': '\\triangleright',
  '▵': '\\triangle',
  '▴': '\\triangle',
  '△': '\\triangle',
  '▱': '\\square',
  '▰': '\\blacksquare',
  '▯': '\\square',
  '▮': '\\blacksquare',
  '▭': '\\square',
  '▫': '\\square',
  '▪': '\\square',
  '□': '\\square',
  '■': '\\blacksquare',
  '⋭': '\\not\\triangleright',
  '⋬': '\\not\\triangleleft',
  '⋫': '\\not\\triangleright',
  '⋪': '\\not\\triangleleft',
  '⋙': '\\ggg',
  '⋘': '\\lll',
  '⋗': '*>',
  '⋖': '<*',
  '⋔': '\\pitchfork',
  '⋌': '',
  '⋋': '\\bowtie',
  '⋊': '\\ltimes',
  '⋉': '\\rtimes',
  '⊵': '\\triangleright',
  '\\triangleleft': '',
  '⊥': '\\bot',
  '⊁': '\\nsucc',
  '⊀': '\\preceq',
  '≽': '\\succeq',
  '≼': '\\preceq',
  '≻': '\\succ',
  '≺': '\\prec',
  '≱': '\\geq/',
  '≰': '\\leq/',
  '≭': '\\neq',
  '≫̸': '\\not\\gg',
  '≫': '\\gg',
  '≪̸': '\\not\\ll',
  '≪': '\\ll',
  '≩': '\\ngeqslant',
  '≨': '\\nleqslant',
  '≡': '\\equiv',
  '≟': '\\doteq',
  '≜': '\\triangleq',
  '≛': '\\doteq',
  '≚': '\\triangleq',
  '≙': '\\triangleq',
  '≗': '\\doteq',
  '≔': ':=',
  '≍': '\\asymp',
  '≇': '\\ncong',
  '≆': '\\ncong',
  '≅': '\\cong',
  '≄': '\\not\\simeq',
  '≃': '\\simeq',
  '≁': '\\not\\sim',
  '∦': '\\not\\parallel',
  '∥': '\\parallel',
  '∤': '\\not|',
  '∝': '\\propto',
  '==': '==',
  '=': '=',
  ':=': ':=',
  '/=': '=',
  '-=': '-=',
  '+=': '+=',
  '*=': '*=',
  '!=': '!=',
  '≠': '\\neq',
  '≢': '\\equiv /',
  '≉': '\\approx /',
  '∼': 'sim',
  '≈': '\\approx',
  '≮': '</',
  '<': '<',
  '≯': '>/',
  '>=': '>=',
  '>': '>',
  '≥': '\\geq',
  '≤': '\\leq',
  '<=': '<=',
  '⊋': '\\supsetneq',
  '⊊': '\\subsetneq',
  '⊉': '\\nsupseteq',
  '⊈': '\\nsubseteq',
  '⊇': '\\supseteq',
  '⊆': '\\subseteq',
  '⊅': '\\not\\supset',
  '⊄': '\\not\\subset',
  '⊃⃒': '\\supset |',
  '⊃': '\\supset',
  '⊂⃒': '\\subset |',
  '⊂': '\\subset',
  '∌': '\\not\\in',
  '∉': '\\notin',
  '∈': '\\in',
  '∁': 'C',
  '∄': '\\nexists',
  '∃': '\\exists',
  '∀': '\\forall',
  '∧': '\\land',
  '&&': '\\&\\&',
  '∨': '\\lor',
  '⊯': '\\cancel{\\vDash}',
  '⊮': '\\cancel{\\Vdash}',
  '⊭': '\\nvDash',
  '⊬': '\\nvDash',
  '⊩': '\\Vdash',
  '⊨': '\\vDash',
  '⊤': '\\top',
  '⊣': '\\dashv',
  '⊢': '\\vdash',
  '∋': '\\ni',
  '⋱': '\\ddots',
  '⋯': '\\hdots',
  '⋮': '\\vdots',
  '϶': '\\ni',
  ':': ':',
  '...': '\\cdots',
  '..': '..',
  '->': '->',
  '∵': '\\because',
  '∴': '\\therefore ',
  '⁣': '\\llbracket',
  ',': ',',
  ';': ';',
  '⧽': '\\}',
  '⧼': '\\{',
  '⦘': '\\]',
  '⦗': '\\[',
  '⦖': '\\ll',
  '⦕': '\\gg',
  '⦔': '\\gg',
  '⦓': '\\ll',
  '⦒': '\\gg',
  '⦑': '\\ll',
  '⦐': '\\]',
  '⦏': '\\]',
  '⦎': '\\]',
  '⦍': '\\[',
  '⦌': '\\[',
  '⦋': '\\]',
  '⦊': '\\triangleright',
  '⦉': '\\triangleleft',
  '⦈': '|\\)',
  '⦇': '\\(|',
  '⦆': '|\\)',
  '⦅': '\\(\\(',
  '⦄': '|\\}',
  '⦃': '\\{|',
  '⦀': '\\||',
  '⟯': '\\left. \\right]',
  '⟮': '\\left[ \\right.',
  '⟭': '\\left. \\right]]',
  '⟬': '\\left[[ \\right.',
  '⟫': '\\gg',
  '⟪': '\\ll',
  '⟧': '\\)|',
  '⟦': '\\(|',
  '❳': '\\left.\\right)',
  '❲': '\\left(\\right.',
  '〉': '\\rangle',
  '〈': '\\langle',
  '⌋': '\\rfloor',
  '⌊': '\\lfloor',
  '⌉': '\\rceil',
  '⌈': '\\lceil',
  '‖': '\\parallel',
  '}': '\\left.\\right}',
  '{': '\\left{\\right.',
  ']': '\\left]\\right.',
  '[': '\\left[\\right.',
  ')': '\\left.\\right)',
  '(': '\\left(\\right.',
  '”': '\\"',
  '“': '\\text{``}',
  '’': "'",
  '‘': '`',
  α: '\\alpha',
  β: '\\beta',
  γ: '\\gamma',
  Γ: '\\Gamma',
  δ: '\\delta',
  Δ: '\\Delta',
  ϵ: '\\epsilon',
  ζ: '\\zeta',
  η: '\\eta',
  θ: '\\theta',
  Θ: '\\Theta',
  ι: '\\iota',
  κ: '\\kappa',
  λ: '\\lambda',
  ν: '\\nu',
  ο: '\\omicron',
  π: '\\pi',
  Π: '\\Pi',
  ρ: '\\rho',
  σ: '\\sigma',
  Σ: '\\Sigma',
  τ: '\\tau',
  υ: '\\upsilon',
  Υ: '\\Upsilon',
  ϕ: '\\phi',
  Φ: '\\Phi',
  χ: '\\chi',
  ψ: '\\psi',
  Ψ: '\\Psi',
  ω: '\\omega',
  Ω: '\\Omega',
  Ω: '\\Omega',
  '∅': '\\emptyset',
  '⟲': '\\circlearrowleft',
  '⟳': '\\circlearrowright',
  '×': '\\times',
  '½': '\\dfrac{1}{2}',
  μ: '\\mu',
  Ө: '\\theta',
  '✓': '\\checkmark',
  '⟩': '\\rangle',
  '⟨': '\\langle',
  '¼': '\\dfrac{1}{4}',
  '…': '\\ldots',
  ℏ: '\\hbar',
  ℜ: '\\mathfrak{R}',
  Ѳ: '\\theta',
  Ø: '\\emptyset',
  ϱ: '\\varrho',
  ф: '\\phi',
  ℇ: '\\varepsilon',
  T: 'T',
  '∙': '\\cdot',
  Ρ: 'P',
  '∞': '\\infty',
  ᐁ: '\\nabla',
  ƞ: '\\eta',
  '⁺': '^{+}',
  '⁻': '^{-}',
  '⁼': '^{=}',
  '⁽': '^{(}',
  '⁾': '^{)}',
  '〗': '\\)|',
  '〖': '\\langle',
  ';': ';',
  '൦': '\\circ',
  '┴': '\\perp',
  '✕': '\\times',
  '⎻': '-',
  '»': '\\gg',
  '⬆': '\\uparrow',
  '⬇': '\\downarrow',
  '⬅': '\\leftarrow',
  '➡': '\\rightarrow',
  '⎼': '-',
  '⎜': '\\mid',
  '⎥': '\\mid',
  ħ: '\\hbar',
  '⮕': '\\rightarrow',
  '・': '\\cdot',
  '¦': '\\mid',
  '£': '\\pounds',
  '¥': '\\yen',
  '✗': '\\times',
  '✔': '\\checkmark',
  ⁿ: '^{n}',
  '«': '\\ll',
  เ: '\\prime',
  '†': '\\dagger',
  '│': '\\mid',
  $: '\\$',
  '#': '\\#',
  '℃': '\\text{\\textdegree C}',
  '℉': '\\text{\\textdegree F}',
  '█': '\\blacksquare',
  '℧': '\\mho',
  ⅇ: '\\text{e}',
  ɼ: 'r',
  '‡': '\\ddagger',
  ἱ: 'i',
  ϒ: '\\Upsilon',
  '𝛿': '\\delta',
  '˳': '\\cdot',
  ѳ: '\\theta',
  '𝜙': '\\phi',
  П: '\\prod',
  о: 'o',
  ђ: '\\hbar',
  Ʌ: '\\Lambda',
  '।': '\\mid',
  '€': '\\euro',
  ῡ: '\\bar{u}',
  φ: '\\varphi',
  ȼ: 'c',
  '𝞮': '\\epsilon',
  Χ: '\\mathsf{X}',
  ₙ: '_{n}',
};
