import React from 'react';
import { Rnd } from 'react-rnd';

const WrapChild = ({
  children,
  prop,
  dimensions,
  type,
  setActiveZone,
  size,
  image,
  activeIndex,
}) => {
  const ref = React.useRef();
  let dragState = false;
  let resizeState = false;

  const updateBoundaries = (prop, boundary, dimensions) => {
    const obj = {
      x: boundary.x,
      y: boundary.y,
      w: prop.data.width,
      h: prop.data.height,
    };
    dimensions(obj, prop.id);
    updatePropValue(obj);
    dragState = false;
  };

  const updatePropValue = (obj) => {
    const updateProp = { ...prop.data };
    updateProp.x = obj.x;
    updateProp.y = obj.y;
    updateProp.width = obj.w;
    updateProp.height = obj.h;
  };

  const updateBoundsOnResize = (id, style, position) => {
    const obj = {
      x: position.x,
      y: position.y,
      w: style.width,
      h: style.height,
    };
    updatePropValue(obj);
    dimensions(obj, id);
    resizeState = false;
  };

  const child = React.Children.only(children);
  const minWidth = image?.width >= 100 ? 100 : image?.width;
  const minHeight = image?.height >= 100 ? 100 : image?.height;
  const sizeDimension = {
    width: type === 'Hotspot' ? size : prop.data.width,
    height: type === 'Hotspot' ? size : prop.data.height,
  };

  return (
    <Rnd
      id={`rnd_${prop.id}`}
      style={{
        backgroundColor: 'transparent',
        display: 'inline-block',
        border: '2px solid #112299',
        position: 'absolute',
      }}
      className={activeIndex === prop.id ? 'active' : ''}
      position={{ x: prop.data.x, y: prop.data.y }}
      size={sizeDimension}
      bounds='.boundry'
      minWidth={type === 'Hotspot' ? size : minWidth}
      minHeight={type === 'Hotspot' ? size : minHeight}
      maxWidth='100%'
      maxHeight='100%'
      enableResizing={type === 'Hotspot' ? false : true}
      onGotPointerCapture={() => setActiveZone(prop.id)}
      onClick={() => setActiveZone(prop.id)}
      onDrag={() => (dragState = true)}
      onDragStop={(e, boundaries) => {
        if (!dragState) return;
        updateBoundaries(prop, boundaries, dimensions);
      }}
      onResize={() => (resizeState = true)}
      onResizeStop={(e, direction, ref, delta, position) => {
        if (!resizeState) return;
        updateBoundsOnResize(prop.id, ref.style, position);
      }}
    >
      <div>{React.cloneElement(child, { ref })}</div>
    </Rnd>
  );
};

export default WrapChild;
