/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useCallback, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import DndHotspotFormComponent from './DndAndHotspot/index';
import ImageSliderFormBodyComponent from './imageEditorComponentBody';
import MCQFormBodyComponent from './multipleChoice/formBody';
import SectionBodyComponent from './sectionComponentBody';
import { getContentArrayValue, getParamsValue, setFocus } from './utils';
import ModalComponent from '../utils/modal';
import Tooltip from '../utils/tooltip';
import { ReactComponent as SectionIcon } from '../../assets/icons/sectionIcon.svg';
import { ReactComponent as SectionClose } from '../../assets/icons/sectionClose.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/sectiondeleteIcon.svg';
import { ReactComponent as MoveDown } from '../../assets/icons/moveDownIcon.svg';
import { ReactComponent as MoveUp } from '../../assets/icons/moveUpIcon.svg';
import { ReactComponent as AddSection } from '../../assets/icons/addSectionIcon.svg';
import { getModalDetails } from '../../middleware/utils';
import { addFlipCardPanel } from '../../redux/flipCard/form.action';
import { addPanel, addSection, removeClick, removeElement } from '../../redux/form/form.actions';
import { addMcqPanel, handleChange, removeMcqSection } from '../../redux/multipleChoice/mcq.action';
import { LoreeInteractiveEditorDashboardContentType } from '../editor/editorUtilityFunctions/lintEditorType';

interface SectionProps {
  errors: any;
  number: number;
  type: string;
  handleChange: Function;
  handleTitle: Function;
  editorConfig: LoreeInteractiveEditorDashboardContentType;
}

const mapState = ({ form, flipform, mcqform }: any) => ({
  componentParameter: form.parameters,
  flipCardComponentParameter: flipform.parameters,
  flipCardComponentContentArray: flipform.contentArr,
  flipCardArrayIndex: flipform.num,
  componentContentArray: form.contentArr,
  arrayIndex: form.num,
  mcqPrameter: mcqform.parameters,
  mcqContentArray: mcqform.contentArr,
  optionId: mcqform.option_id,
  dndParameters: form.dndParameters,
  dndArray: form.dndArray,
  dndLayout: form.dndLayout,
});

const mapDispatch = (dispatch: Function) => ({
  InsertSection: (data: any) => dispatch(addPanel(data)),
  InsertFlipCardSection: (data: {}) => dispatch(addFlipCardPanel(data)),
  DeleteSection: (data: any) => dispatch(removeClick(data)),
  InsertMcqSection: (data: {}) => dispatch(addMcqPanel(data)),
  DeleteMcqSection: (data: {}) => dispatch(removeMcqSection(data)),
  HandleMcqEditor: (data: {}) => dispatch(handleChange(data)),
  InsertDndSection: (data: {}) => dispatch(addSection(data)),
  DeleteDndSection: (data: {}) => dispatch(removeElement(data)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & SectionProps;

const SectionComponent = (props: Props) => {
  const {
    errors,
    number,
    type,
    handleChange,
    handleTitle,
    componentParameter,
    componentContentArray,
    flipCardComponentParameter,
    flipCardComponentContentArray,
    mcqPrameter,
    optionId,
    mcqContentArray,
    dndLayout,
    dndParameters,
    dndArray,
    InsertSection,
    InsertFlipCardSection,
    DeleteSection,
    InsertMcqSection,
    DeleteMcqSection,
    HandleMcqEditor,
    InsertDndSection,
    DeleteDndSection,
    editorConfig,
  } = props;

  const getComponentContentArray = useCallback(() => {
    switch (type) {
      case 'flipcard':
        return flipCardComponentContentArray;
      case 'multiplechoice':
        return mcqContentArray;
      case 'DragandDrop':
        return dndLayout === 'CustomOverlay' ? dndArray : componentContentArray;
      case 'Hotspot':
        return dndArray;
      default:
        return componentContentArray;
    }
  }, [
    componentContentArray,
    flipCardComponentContentArray,
    mcqContentArray,
    type,
    dndArray,
    dndLayout,
  ]);

  const getParameters = useCallback(() => {
    switch (type) {
      case 'flipcard':
        return flipCardComponentParameter;
      case 'multiplechoice':
        return mcqPrameter;
      case 'DragandDrop':
        return dndLayout === 'CustomOverlay' ? dndParameters : componentParameter;
      case 'Hotspot':
        return dndParameters;
      default:
        return componentParameter;
    }
  }, [componentParameter, flipCardComponentParameter, mcqPrameter, type, dndParameters, dndLayout]);

  const [contentParameter, setContentParameter] = useState(getParameters());
  const [contentArray, setContentArray] = useState(getComponentContentArray());
  const [showModal, setShowModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState<{ index?: number; panel?: string[] }>({});

  useEffect(() => {
    setContentArray(getComponentContentArray());
    setContentParameter(getParameters());
  }, [getComponentContentArray, getParameters]);

  const handleSectionToggle = (index: any) => {
    const array = [...contentArray];
    const isOpened = array[index].indexOf('isOpened');
    if (isOpened > -1) {
      array[index].splice(isOpened, 1);
    } else {
      array[index].push('isOpened');
    }
    setContentArray(array);
  };

  const insertSection = (index: number, focusIndex: number) => {
    const array = contentParameter;
    const content = contentArray;
    array.splice(index + 1, 0, getParamsValue(type, dndLayout));
    content.splice(index + 1, 0, getContentArrayValue(type, dndLayout, number, optionId));
    const insertSectionFunction = getInsertSectionFunction();
    insertSectionFunction({
      parameters: [...array],
      contentArr: [...content],
      num: number + 1,
      option_id: optionId + 2,
    });
    setFocus(`collapse${focusIndex + 1}`);
  };

  const getDeleteFunction = () => {
    switch (type) {
      case 'multipleChoice':
        return DeleteMcqSection;
      case 'DragandDrop':
        return dndLayout === 'CustomOverlay' ? DeleteDndSection : DeleteSection;
      case 'Hotspot':
        return DeleteDndSection;
      default:
        return DeleteSection;
    }
  };

  const handleDeleteSection = (index: number, element: string[]) => {
    const array = getParameters();
    const deleteFunction = getDeleteFunction();
    const content = contentArray;
    array.splice(index, 1);
    content.splice(index, 1);
    let errorObject = errors;
    delete errorObject[element[0]];
    delete errorObject[element[1]];
    deleteFunction({
      parameters: [...array],
      contentArr: [...content],
      errors: errorObject,
    });
    setShowModal(false);
  };

  const handleMoveSection = (position: string, selectedIndex: number, focusIndex: number) => {
    const array = [...contentParameter];
    const content = contentArray;
    const targetIndex = position === 'up' ? selectedIndex - 1 : selectedIndex + 1;
    const focusElementIndex = position === 'up' ? focusIndex - 1 : focusIndex + 1;
    [array[selectedIndex], array[targetIndex]] = [array[targetIndex], array[selectedIndex]];
    [content[selectedIndex], content[targetIndex]] = [content[targetIndex], content[selectedIndex]];
    content[targetIndex].splice(2, 1);
    const insertSectionFunction = getInsertSectionFunction();
    insertSectionFunction({
      parameters: [...array],
      contentArr: [...content],
      num: number,
      option_id: optionId,
    });
    setContentParameter([...array]);
    setFocus(`collapse${focusElementIndex}`);
  };

  const getInsertSectionFunction = () => {
    switch (type) {
      case 'flipcard':
        return InsertFlipCardSection;
      case 'multiplechoice':
        return InsertMcqSection;
      case 'DragandDrop':
        return dndLayout === 'CustomOverlay' ? InsertDndSection : InsertSection;
      case 'Hotspot':
        return InsertDndSection;
      default:
        return InsertSection;
    }
  };

  const bodyContent = (panel: string[], index: number) => {
    switch (type) {
      case 'Accordion':
      case 'Tabs':
      case 'ClickAndRevealInteractions':
      case 'flipcard':
        return (
          <SectionBodyComponent
            panel={panel}
            index={index}
            errors={errors}
            parameter={getParameters()}
            type={type}
            handleChange={handleChange}
            handleTitleChange={handleTitle}
            editorConfig={editorConfig}
            indexValue={contentArray[index]}
          />
        );
      case 'multiplechoice':
        return (
          <MCQFormBodyComponent
            panel={panel}
            index={index}
            errors={errors}
            parameter={mcqPrameter}
            handleChange={HandleMcqEditor}
            editorConfig={editorConfig}
            indexValue={contentArray[index]}
          />
        );
      case 'imageslider':
        return (
          <ImageSliderFormBodyComponent
            panel={panel}
            index={index}
            errors={errors}
            parameter={contentParameter}
            type={type}
            handleChange={handleChange}
            handleTitleChange={handleTitle}
          />
        );
      case 'DragandDrop':
        return dndLayout === 'CustomOverlay' ? (
          <DndHotspotFormComponent
            type={type}
            index={index}
            panel={panel}
            editorConfig={editorConfig}
          />
        ) : (
          <ImageSliderFormBodyComponent
            panel={panel}
            index={index}
            errors={errors}
            parameter={contentParameter}
            type={type}
            handleChange={handleChange}
            handleTitleChange={handleTitle}
          />
        );
      case 'Hotspot':
        return (
          <DndHotspotFormComponent
            type={type}
            index={index}
            panel={panel}
            editorConfig={editorConfig}
          />
        );
    }
  };

  return (
    <>
      {contentArray?.map((panel: string[], index: number) => {
        return (
          <React.Fragment key={index}>
            <div className='section-accordion'>
              <div
                className='inner-content text-capitalize'
                key={index}
                id={`collapse${index + 1}`}
              >
                {contentArray.length > 1 && (
                  <>
                    <Button
                      size='sm'
                      onClick={() => {
                        setShowModal(true);
                        setSelectedSection({
                          panel: panel,
                          index: index,
                        });
                      }}
                      className='delete-icon float-end close-btn section-button'
                      id={`delete${index + 1}`}
                    >
                      <Tooltip info='Delete'>
                        <DeleteIcon />
                      </Tooltip>
                    </Button>
                    {index !== contentArray.length - 1 && (
                      <Button
                        size='sm'
                        className='float-end section-button'
                        onClick={() => handleMoveSection('down', index, index + 1)}
                      >
                        <Tooltip info='Move down'>
                          <MoveDown />
                        </Tooltip>
                      </Button>
                    )}
                    {index !== 0 && (
                      <Button
                        size='sm'
                        className='float-end section-button'
                        onClick={() => handleMoveSection('up', index, index + 1)}
                      >
                        <Tooltip info='Move up'>
                          <MoveUp />
                        </Tooltip>
                      </Button>
                    )}
                    {index !== contentArray.length - 1 && (
                      <Button
                        size='sm'
                        className='float-end section-button'
                        onClick={() => insertSection(index, index + 1)}
                      >
                        <Tooltip info='Add section below'>
                          <AddSection />
                        </Tooltip>
                      </Button>
                    )}
                  </>
                )}
                <div
                  className='d-flex fw-bolder section-style position-relative'
                  role='button'
                  tabIndex={0}
                  onKeyDown={() => handleSectionToggle(index)}
                  onClick={() => handleSectionToggle(index)}
                >
                  <div className='position-absolute'>
                    {contentArray[index].indexOf('isOpened') === -1 ? (
                      <SectionIcon />
                    ) : (
                      <SectionClose />
                    )}
                    <> &nbsp; &nbsp; Section {`${index + 1}`}</>
                  </div>
                </div>
              </div>
              {contentArray[index].indexOf('isOpened') === -1 && bodyContent(panel, index)}
            </div>
          </React.Fragment>
        );
      })}
      <ModalComponent
        show={showModal}
        handleCancel={() => setShowModal(false)}
        modalDetails={getModalDetails('Remove', `Section ${(selectedSection.index as number) + 1}`)}
        confirmButton={{
          label: 'Remove',
          disabled: false,
          handleConfirm: () =>
            handleDeleteSection(selectedSection.index as number, selectedSection.panel as string[]),
        }}
        cancelButton={{
          label: 'Cancel',
        }}
      />
    </>
  );
};

export default connector(SectionComponent);
