import React from "react";

class NotFoundPage extends React.Component{
  render(){
    return <div data-testid='401' style={{textAlign:"center"}}>
      <h1>401 - Access Denied</h1>
    </div>;
  }
}
export default NotFoundPage;
