import React, { useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import Search from './typeSearch';
import ListElementComponent from '../editor/mediaManagement/listMediaCollection';
import './tabSection.scss';
import DropDownComponent from './dropDown';
import { IconLibrary } from '../editor/mediaManagement/icons';
import { VideoModalContent } from '../editor/mediaManagement/videos';

interface TabSectionComponentInterface {
  sectionTypes: Array<{
    title: string;
    disabled: boolean;
    elementCollection?: Array<{ id: number; name: string; url: string }>;
    hide: boolean;
  }>;
  isSearch: boolean;
  isSort: boolean;
  isErrorInFetch: boolean;
  isFetching: boolean;
  handleSelectedIcon: Function;
  handleTabSelection: Function;
  handleSelectedElement: Function;
  handleConfirmState: Function;
  handleElement?: Function;
  enableOrDisableContainers: Function;
}

const sortingOptions = {
  Recent: 'Recent',
  Ascending: 'Ascending',
  Descending: 'Descending',
};

const TabSectionComponent = (props: TabSectionComponentInterface) => {
  const {
    handleElement,
    sectionTypes,
    isSearch,
    isSort,
    isErrorInFetch,
    isFetching,
    handleSelectedIcon,
    handleTabSelection,
    handleSelectedElement,
    handleConfirmState,
    enableOrDisableContainers,
  } = props;
  const [selectedSorting, setSelectedSorting] = useState('Recent');
  const [searchText, setSearchText] = useState('');
  const [selectedTab, setSelectedTab] = useState(sectionTypes[0].title);
  const [isSearchText, setIsSearchText] = useState(false);

  const handleNavSelection = (title: string) => {
    if (selectedTab === title) return;
    handleTabSelection(title);
    setSelectedTab(title);
    setSelectedSorting('Recent');
    setSearchText('');
    setIsSearchText(false);
  };

  return (
    <div className='lint-custom-tab-section' id='lint-custom-tab-section'>
      <Row bsPrefix='p-0 d-flex flex-column flex-md-row lint-image-row'>
        <Col sm={6} className='p-0'>
          <Nav
            variant='tabs'
            defaultActiveKey={sectionTypes[0].title}
            onSelect={(e) => handleNavSelection(e ?? '')}
          >
            {sectionTypes?.map((type) => {
              return (
                <Nav.Item key={type.title} id={type.title} className={type.hide ? 'd-none' : ''}>
                  <Nav.Link eventKey={type.title}>{type.title}</Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
        </Col>
        {(isSearch || isSort) && (
          <Col sm={6} className='d-inline-flex justify-content-end align-items-center p-0'>
            {isSearch && (
              <Search
                initialvalue={searchText}
                onChange={setSearchText}
                isSearchText={isSearchText}
                setIsSearchText={setIsSearchText}
              />
            )}
            {isSort && (
              <div className='sorting-dropdown'>
                <DropDownComponent
                  optionCollection={sortingOptions}
                  setSelectedOption={setSelectedSorting}
                  selectedOption={selectedSorting}
                  className='mx-2 lint-custom-dropdown'
                  type='sorting'
                />
              </div>
            )}
          </Col>
        )}
      </Row>
      <Tab.Content className='p-0'>
        <Tab.Pane eventKey={selectedTab} key={selectedTab} active>
          {selectedTab === 'Images' && sectionTypes[0]?.elementCollection && (
            <ListElementComponent
              elementCollection={sectionTypes[0]?.elementCollection}
              handleElementSelection={handleElement as Function}
              isErrorInFetch={isErrorInFetch}
              isFetching={isFetching}
              searchText={searchText}
              sortingValue={selectedSorting}
            />
          )}
          {selectedTab === 'Icons' && (
            <IconLibrary
              searchText={searchText}
              handleSelectedIcon={handleSelectedIcon}
              enableOrDisableContainers={enableOrDisableContainers}
              handleConfirmState={handleConfirmState}
            />
          )}
          {selectedTab === 'Video' && (
            <VideoModalContent
              handleConfirmState={handleConfirmState}
              handleSelectedElement={handleSelectedElement}
            />
          )}
        </Tab.Pane>
      </Tab.Content>
    </div>
  );
};

export default TabSectionComponent;
