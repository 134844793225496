export const customStyles = {
  // Font-headings for customization
  fontHeadings: { H1: 'h1', H2: 'h2', H3: 'h3', H4: 'h4', H5: 'h5', H6: 'h6', Normal: '' },

  // Font-family for customization
  fontFamily: {
    Arial: 'Arial',
    'Arial Black': 'Arial Black',
    'Brush Script MT': 'Brush Script MT',
    'Comic New Courier': 'Comic New Courier',
    Georgia: 'Georgia',
    Helvetica: 'Helvetica',
    'Lato Bold': 'Lato Bold',
    'Lato Italic': 'Lato Italic',
    'Lato Regular': 'Lato Regular',
    Lucida: 'Lucida',
    'Open Sans': 'open Sans, sans-serif',
    Poppins: 'Poppins',
    'Poppins Bold': 'Poppins-Bold',
    'Poppins SemiBold': 'Poppins SemiBold',
    'Lucida Sans Unicode': 'Lucida Sans Unicode',
    Tahoma: 'Tahoma',
    'Times New Roman': 'Times New Roman',
    'Trebuchet MS': 'Trebuchet MS',
    Verdana: 'Verdana',
  },

  editorNativeFonts: {
    none: 'None',
    arial: 'Arial',
    arialBlack: 'Arial Black',
    brushScriptMT: 'Brush Script MT',
    comicNewCourier: 'Comic New Courier',
    georgia: 'Georgia',
    helvetica: 'Helvetica',
    latoBold: 'Lato Bold',
    latoItalic: 'Lato Italic',
    latoRegular: 'Lato Regular',
    lucida: 'Lucida',
    openSans: 'Open Sans',
    poppins: 'Poppins',
    poppinsBold: 'Poppins Bold',
    poppinsSemiBold: 'Poppins SemiBold',
    lucidaSansUnicode: 'Lucida Sans Unicode',
    tahoma: 'Tahoma',
    timesNewRoman: 'Times New Roman',
    trebuchetMS: 'Trebuchet MS',
    verdana: 'Verdana',
  },

  // Font-size for customization
  fontSize: {
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
    11: '11',
    12: '12',
    13: '13',
    14: '14',
    15: '15',
    16: '16',
    17: '17',
    18: '18',
    19: '19',
    20: '20',
    24: '24',
    26: '26',
    28: '28',
    32: '32',
    36: '36',
    40: '40',
    42: '42',
    48: '48',
    52: '52',
    56: '56',
    68: '68',
    72: '72',
  },
  separatorSize: {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
  },
  cardsInRows: {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
  },
  cardsInLayout: {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
  },
  // Font-weight for customisation
  fontWeight: { Bold: 'Bold', Normal: 'Normal', Lighter: 'Lighter' },

  // Accordion and Tabs Border-type for customization

  borderType: {
    'border-left': 'border-start border-dark',
    'border-right': 'border-end border-dark',
    'border-top': 'border-top border-dark',
    'border-bottom': 'border-bottom border-dark',
    border: 'border border-dark',
  },

  cardSide: {
    front: 'Front',
    back: 'Back',
  },

  cardType: {
    square: 'square',
    portrait: 'portrait',
  },

  // Accordion and Tabs bordersize for customization
  borderSize: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'],

  // Accordion and Tabs borderstyle for customization
  borderStyle: {
    Solid: 'solid',
    Dotted: 'dotted',
    Dashed: 'dashed',
    Double: 'double',
    Groove: 'groove',
    Ridge: 'ridge',
    None: 'none',
  },

  // Button-type for customization
  buttonType: {
    Fill: 'btn btn-square btn-dark',
    Outline: 'btn btn-outline-dark',
    Pill: 'btn btn-pill btn-dark',
  },

  // Button-size for customization
  btnSize: {
    Small: 'sm',
    Default: 'default',
    Large: 'lg',
  },

  // Icon-size for customization
  iconSize: {
    Default: '10px',
    Medium: '16px',
    Large: '24px',
  },

  icons: {
    Plus: 'M3898.577,496.06h-4.792V491.27a1.354,1.354,0,0,0-2.709,0v4.791h-4.79a1.355,1.355,0,1,0,0,2.709h4.79v4.791a1.354,1.354,0,0,0,2.709,0V498.77h4.792a1.355,1.355,0,0,0,0-2.709Z',
    Minus: 'M3898.577,496.06h-12.291a1.355,1.355,0,1,0,0,2.709h12.291a1.355,1.355,0,0,0,0-2.709Z',
    Information:
      'M.25,15.75V5.25a1.25,1.25,0,0,1,2.5,0v10.5a1.25,1.25,0,1,1-2.5,0ZM0,1.5A1.5,1.5,0,1,1,1.5,3,1.5,1.5,0,0,1,0,1.5Z',
    Exclamation:
      'M14.775,23.19a1.125,1.125,0,0,1-.205-.063,1,1,0,0,1-.192-.1.925.925,0,0,1-.169-.139.975.975,0,0,1-.139-.167,1.137,1.137,0,0,1-.1-.192,1.086,1.086,0,0,1-.065-.644,1.046,1.046,0,0,1,.065-.207.86.86,0,0,1,.1-.191.842.842,0,0,1,.139-.17,1.05,1.05,0,0,1,.169-.139,1.08,1.08,0,0,1,.192-.1,1.1,1.1,0,0,1,1.208.242.89.89,0,0,1,.139.17.86.86,0,0,1,.1.191,1.262,1.262,0,0,1,.065.207,1.111,1.111,0,0,1,0,.436,1.365,1.365,0,0,1-.065.208.971.971,0,0,1-.1.192,1.1,1.1,0,0,1-.5.409,1.12,1.12,0,0,1-.208.063,1.172,1.172,0,0,1-.216.022,1.215,1.215,0,0,1-.22-.021ZM13.9,17.733V7.9a1.111,1.111,0,1,1,2.222,0v9.836a1.111,1.111,0,1,1-2.222,0Z',
    Question:
      'M3.744,23.868A1.471,1.471,0,0,1,3.336,22.8a1.535,1.535,0,0,1,.408-1.116,1.35,1.35,0,0,1,1.008-.419,1.388,1.388,0,0,1,1.02.419,1.51,1.51,0,0,1,.42,1.116,1.448,1.448,0,0,1-.42,1.068,1.389,1.389,0,0,1-1.02.42A1.35,1.35,0,0,1,3.744,23.868Zm.364-4.076a1.169,1.169,0,0,1-.279-.58h.006A4.052,4.052,0,0,1,3.9,17.556a5.953,5.953,0,0,1,.564-1.392,7.7,7.7,0,0,1,.828-1.188q.468-.552.876-1.092a6.95,6.95,0,0,0,.684-1.093,2.638,2.638,0,0,0,.276-1.2A2.441,2.441,0,0,0,6.54,9.948,2.172,2.172,0,0,0,4.8,9.264a3.174,3.174,0,0,0-1.5.373,4.226,4.226,0,0,0-1.25,1.008l0,0a.783.783,0,0,1-.456.193c-.244,0-.309.053-.525-.1a.65.65,0,0,1-.337-.512,1.17,1.17,0,0,1,.181-.619l.005,0A6.719,6.719,0,0,1,2.7,8.2a4.754,4.754,0,0,1,2.34-.564,4.1,4.1,0,0,1,2.916,1.02,3.671,3.671,0,0,1,1.092,2.821,3.5,3.5,0,0,1-.288,1.44,6.665,6.665,0,0,1-.707,1.224q-.42.575-.888,1.139A13.109,13.109,0,0,0,6.3,16.44a5.128,5.128,0,0,0-.612,1.283,3.63,3.63,0,0,0-.123,1.488h.007l-.005.023s0,.009,0,.014h0a1.219,1.219,0,0,1-.257.544.652.652,0,0,1-.55.208A.9.9,0,0,1,4.108,19.792Z',
  },

  frontFlipDecoration: {
    bgcolor: 'Background Fill',
    frontTextColor: 'Text Color',
  },

  backFlipDecoration: {
    backColor: 'Background Fill',
    backTextColor: 'Text Color',
  },

  frontFlipPadding: {
    frontTop: 'Top',
    frontRight: 'Right',
    frontBottom: 'Bottom',
    frontLeft: 'Left',
  },

  backFlipPadding: {
    backTop: 'Top',
    backRight: 'Right',
    backBottom: 'Bottom',
    backLeft: 'Left',
  },
};
