import { EditorState, Transaction } from 'prosemirror-state';

export function removeLinkFromNode() {
  return (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    const { from, to } = state.selection;
    const nodeTypes = ['image', 'iconTag', 'icon'];
    const tr = state.tr;
    state.doc.nodesBetween(from, to, (node, pos) => {
      if (nodeTypes.includes(node.type.name)) {
        tr.removeNodeMark(pos, state.schema.marks['link']);
      }

      return true;
    });

    if (dispatch) {
      dispatch(tr);
    }
    return true;
  };
}
