import { Extension } from '@tiptap/core';
import { Attrs, NodeType } from 'prosemirror-model';
import {
  sinkListItem,
  toggleList,
  updateNodeStyles,
  removeAllEmptySpan,
} from '../prosemirrorCustomCommands/listCommands';
import { updateNodeStylesInRange } from '../prosemirrorCustomCommands/nodeAttributesCommands';
import {
  updateNodeAttributes,
  updateNodeAttributesAtPos,
} from '../prosemirrorCustomCommands/nodeAttributes';
import { applyLinkToNode } from '../prosemirrorCustomCommands/applyLinkToNode';
import { removeLinkFromNode } from '../prosemirrorCustomCommands/removeLinkFromNode';
import { applyLinkToText } from '../prosemirrorCustomCommands/applyLinkToText';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    commands: {
      sinkListItems: (itemType: NodeType | string) => ReturnType;
      toggleLists: (listType: string, itemType: string, numberingType: string) => ReturnType;
      updateNodeStyles: (typeName: string, attrs: Attrs) => ReturnType;
      updateNodeAttributes: (attrs: Attrs) => ReturnType;
      updateNodeAttributesAtPos: (attrs: Attrs, pos: number, typeName: string) => ReturnType;
      removeAllEmptySpan: () => ReturnType;
      updateNodeStylesInRange: (typeNames: string[], attrs: Attrs, attribute: string) => ReturnType;
      applyLinkToNode: (url: string, newWindow: Boolean | null) => ReturnType;
      removeLinkFromNode: () => ReturnType;
      applyLinkToText: (attrs: Attrs) => ReturnType;
    };
  }
}

export const CommandExtension = Extension.create({
  addCommands() {
    return {
      sinkListItems:
        (itemType: NodeType | string) =>
        ({ state, dispatch }) => {
          return sinkListItem(itemType)(state, dispatch);
        },
      toggleLists:
        (listType: string, itemType: string, numberingType: string) =>
        ({ state, dispatch, editor, tr, chain, commands, can }) => {
          return toggleList(listType, itemType, numberingType)(
            editor,
            tr,
            state,
            chain,
            commands,
            can,
            dispatch,
          );
        },
      updateNodeStyles:
        (typeName: string, attrs: Attrs) =>
        ({ state, dispatch }) => {
          return updateNodeStyles(typeName, attrs)(state, dispatch);
        },
      updateNodeAttributes:
        (attrs: Attrs) =>
        ({ state, dispatch }) => {
          return updateNodeAttributes(attrs)(state, dispatch);
        },
      updateNodeAttributesAtPos:
        (attrs: Attrs, pos: number, typeName: string) =>
        ({ state, dispatch }) => {
          return updateNodeAttributesAtPos(attrs, pos, typeName)(state, dispatch);
        },
      removeAllEmptySpan:
        () =>
        ({ state, dispatch }) => {
          return removeAllEmptySpan()(state, dispatch);
        },
      updateNodeStylesInRange:
        (typeNames: string[], attrs: Attrs, attribute: string) =>
        ({ state, dispatch }) => {
          return updateNodeStylesInRange(typeNames, attrs, attribute)(state, dispatch);
        },
      applyLinkToNode:
        (url: string, newWindow: Boolean | null) =>
        ({ state, dispatch }) => {
          return applyLinkToNode(url, newWindow)(state, dispatch);
        },
      removeLinkFromNode:
        () =>
        ({ state, dispatch }) => {
          return removeLinkFromNode()(state, dispatch);
        },
      applyLinkToText:
        (attrs: Attrs) =>
        ({ state, dispatch }) => {
          return applyLinkToText(attrs)(state, dispatch);
        },
    };
  },
});
