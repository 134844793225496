/* eslint-disable @typescript-eslint/no-explicit-any */
import { mergeAttributes, Node } from '@tiptap/core';
import { optimizeStyle } from './utilityFunctions';
import { ResolvedPos } from 'prosemirror-model';

export interface HardBreakOptions {
  keepMarks: boolean;
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    hardBreaks: {
      /**
       * Add a hard break
       */
      setHardBreaks: (k: string) => ReturnType;
    };
  }
}

export const HardBreak = Node.create<HardBreakOptions>({
  name: 'hardBreak',

  addOptions() {
    return {
      keepMarks: true,
      HTMLAttributes: {},
    };
  },

  inline: true,

  group: 'inline',

  selectable: false,

  parseHTML() {
    return [{ tag: 'br' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['br', optimizeStyle(mergeAttributes(this.options.HTMLAttributes, HTMLAttributes))];
  },

  renderText() {
    return '\n';
  },

  addCommands() {
    return {
      setHardBreaks:
        (key) =>
        ({ commands, chain, state, editor }) => {
          return commands.first([
            () => commands.exitCode(),
            () =>
              commands.command(() => {
                const { selection, storedMarks } = state;
                // for list items
                const selectionStart: ResolvedPos = selection.$from;
                const parentNode = selectionStart.node(selectionStart.depth - 1);
                if (parentNode.type.name === 'listItem' && key !== 'shift') {
                  let styles;
                  const { from, to } = this.editor.state.selection;
                  this.editor.state.doc.nodesBetween(from, to, (node, pos) => {
                    if (node.type.name === 'listItem') {
                      styles = node.attrs['style'];
                    }
                  });
                  return chain()
                    .splitListItem('listItem')
                    .updateAttributes('paragraph', {
                      style: styles,
                    })
                    .updateAttributes('listItem', {
                      style: styles,
                    })
                    .run();
                }
                const { keepMarks } = this.options;
                const { splittableMarks } = editor.extensionManager;
                const marks =
                  storedMarks || (selection.$to.parentOffset && selection.$from.marks());
                return chain()
                  .insertContent({ type: this.name })
                  .command(({ tr, dispatch }) => {
                    if (dispatch && marks && keepMarks) {
                      const filteredMarks = marks.filter((mark) =>
                        splittableMarks.includes(mark.type.name),
                      );
                      tr.ensureMarks(filteredMarks);
                    }
                    return true;
                  })
                  .run();
              }),
          ]);
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Mod-Enter': () => this.editor.commands.setHardBreaks('enter'),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Shift-Enter': () => this.editor.commands.setHardBreaks('shift'),
    };
  },
});
