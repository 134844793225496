import { Level } from '@tiptap/extension-heading';
import { Editor } from '@tiptap/react';
import { EditorState } from 'prosemirror-state';
import { Fragment } from 'prosemirror-model';
import { LoreeInteractiveEditorDashboardContentType, exceptionalKeys } from './lintEditorType';
import { isCellSelection } from '../editorExtensions/tableElementContent/tableUtils';
import { parseFontSize, setDefaultFontStyle } from '../editorExtensions/utilityFunctions';

export const numberValidation = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const key = e.key;
  if (!(!Number.isNaN(parseInt(key)) || exceptionalKeys.includes(key))) {
    e.preventDefault();
    e.stopPropagation();
  }
};

export const isInTable = (state: EditorState) => {
  const $head = state.selection.$head;
  for (let d = $head.depth; d > 0; d--)
    if ($head.node(d).type.spec['tableRole'] == 'row') return true;
  return false;
};

export const canCellPropertySelection = (state: EditorState): boolean => {
  const selection = state.selection;
  return !isCellSelection(selection) && isInTable(state);
};

export const checkDeleteColumnDisabled = (state: EditorState): boolean => {
  const selection = state.selection;
  let parent;
  if (isCellSelection(selection)) {
    parent = selection.$from.node(selection.$from.depth - 1);
  } else {
    parent = selection.$from.node(selection.$from.depth - 2);
  }
  if (!parent) return false;
  return isInTable(state) && parent.childCount > 1 && parent.type.name === 'tableRow';
};

export const checkDeleteRowDisabled = (state: EditorState): boolean => {
  const selection = state.selection;
  let parent;
  if (isCellSelection(selection)) {
    parent = selection.$from.node(selection.$from.depth - 2);
  } else {
    parent = selection.$from.node(selection.$from.depth - 3);
  }
  if (!parent) return false;
  return isInTable(state) && parent.childCount > 2 && parent.type.name === 'table';
};

export const getCustomFontFamilyStyles = (
  editorConfig: LoreeInteractiveEditorDashboardContentType,
  level: Level | 0,
) => {
  const adminDashboardStyles = setDefaultFontStyle(editorConfig?.customHeaderStyleList, level);
  const customFontSize = parseFontSize(adminDashboardStyles.size);
  return `font-family: ${adminDashboardStyles.font}; font-size: ${customFontSize?.size ?? '16'}${
    customFontSize?.unit ?? 'px'
  };`;
};

export const createPlaceholderText = (
  editor: Editor,
  editorConfig?: LoreeInteractiveEditorDashboardContentType,
): Fragment[] => {
  let paragraphFontStyles = '';
  let headingFontStyles = '';

  if (editorConfig?.customHeaderStyleList.length) {
    paragraphFontStyles = getCustomFontFamilyStyles(editorConfig, 0);
    headingFontStyles = getCustomFontFamilyStyles(editorConfig, 3);
  }

  const paragraphContent = Fragment.from(
    editor.schema.node(
      'paragraph',
      {
        style:
          'padding: 5px;margin: 0;border-width: 0; border-style: solid; border-color: #000000;color: #000000;' +
          paragraphFontStyles,
      },
      editor.schema.text('Insert text here'),
    ),
  );

  const headerContent = Fragment.from(
    editor.schema.node(
      'heading',
      {
        style:
          'padding: 5px;margin: 0px;border-width: 0; border-style: solid; border-color: #000000;color: #000000;' +
          headingFontStyles,
        level: 3,
      },
      editor.schema.text('Header'),
    ),
  );

  return [paragraphContent, headerContent];
};

export const getCapitalizeWord = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getTableElementStylesFromWindow = (
  tableElement: HTMLElement,
  cssStyleName: string,
) => {
  const windowBackgroundColorStyle = window
    .getComputedStyle(tableElement)
    .getPropertyValue(cssStyleName);

  if (windowBackgroundColorStyle) {
    return windowBackgroundColorStyle;
  }

  switch (cssStyleName) {
    case 'background-color':
      return '#ffffff';
    case 'border-color':
      return '#000000';
    case 'border-collapse':
      return 'collapse';
    case 'border-style':
      return 'solid';
    case 'padding':
    case 'padding-top':
    case 'padding-bottom':
    case 'padding-left':
    case 'padding-right':
      return '0px';
  }
  return '';
};

export const isCellSelected = (editor: Editor): boolean => {
  const { selection } = editor.state;
  return (isCellSelection(selection) || isInTable(editor.state)) && !editor.can().mergeCells();
};
