import React, { useState } from 'react';
import '../../../assets/scss/multipleChoice.scss';
import ModalComponent from '../../utils/modal';

const McqTipComponent = (props: { tip: string }) => {
  const [showModal, setShowModal] = useState(false);
  const handleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <>
      <i
        className='fa fa-info-circle fa-lg hover'
        tabIndex={0}
        role='button'
        aria-label='Show tip button'
        onClick={handleModal}
        onKeyDown={(event) => {
          if (event.key === ' ' || event.key === 'Enter') {
            event.preventDefault();
            handleModal();
          }
        }}
        style={{
          float: 'right',
        }}
      />
      <ModalComponent
        show={showModal}
        handleCancel={handleModal}
        modalDetails={{
          title: 'Tips',
          body: <div>{props.tip}</div>,
        }}
        isCentered={false}
      />
    </>
  );
};

export default McqTipComponent;
