export const allMathOperatorsByChar: Record<string, string> = {
  _: '\\underline',
  '&#x23E1;': '\\underbrace',
  '&#x23E0;': '\\overbrace',
  '&#x23DF;': '\\underbrace',
  '&#x23DE;': '\\overbrace',
  '&#x23DD;': '\\underbrace',
  '&#x23DC;': '\\overbrace',
  '&#x23B5;': '\\underbrace',
  '&#x23B4;': '\\overbrace',
  '&#x20DC;': '\\square',
  '&#x20DB;': '\\square',
  '&#x2064;': '',
  '&#x2057;': "''''",
  '&#x203E;': '\\bar',
  '&#x2037;': '```',
  '&#x2036;': '``',
  '&#x2035;': '`',
  '&#x2034;': "'''",
  '&#x2033;': "''",
  '&#x201F;': '``',
  '&#x201E;': ',,',
  '&#x201B;': '`',
  '&#x201A;': ',',
  '&#x302;': '\\hat',
  '&#x2F7;': '\\sim',
  '&#x2DD;': '\\sim',
  '&#x2DC;': '\\sim',
  '&#x2DA;': '\\circ',
  '&#x2D9;': '\\cdot',
  '&#x2D8;': '',
  '&#x2CD;': '\\_',
  '&#x2CB;': 'ˋ',
  '&#x2CA;': 'ˊ',
  '&#x2C9;': 'ˉ',
  '&#x2C7;': '',
  '&#x2C6;': '\\hat',
  '&#xBA;': 'o',
  '&#xB9;': '1',
  '&#xB8;': '¸',
  '&#xB4;': '´',
  '&#xB3;': '3',
  '&#xB2;': '2',
  '&#xB0;': '\\circ',
  '&#xAF;': '\\bar',
  '&#xAA;': 'a',
  '&#xA8;': '\\cdot\\cdot',
  '~': '\\sim',
  '`': '`',
  '^': '\\hat',
  '--': '--',
  '++': '++',
  '&amp;': '\\&',
  '&#x2061;': '',
  '&#x221C;': '\\sqrt[4]{}',
  '&#x221B;': '\\sqrt[3]{}',
  '&#x221A;': '\\sqrt{}',
  '&#x2146;': 'd',
  '&#x2145;': '\\mathbb{D}',
  '?': '?',
  '@': '@',
  '//': '//',
  '!!': '!!',
  '!': '!',
  '&#x266F;': '\\#',
  '&#x266E;': '',
  '&#x266D;': '',
  '&#x2032;': "'",
  '&lt;>': '<>',
  '**': '\\star\\star',
  '&#x2207;': '\\nabla',
  '&#x2202;': '\\partial',
  '&#x2299;': '\\bigodot',
  '&#xAC;': '\\neg',
  '&#x2222;': '\\measuredangle',
  '&#x2221;': '\\measuredangle',
  '&#x2220;': '\\angle',
  '&#xF7;': '\\div',
  '/': '/',
  '&#x2216;': '\\backslash',
  '\\': '\\backslash',
  '%': '\\%',
  '&#x2297;': '\\bigotimes',
  '&#xB7;': '\\cdot',
  '&#x2A3F;': '\\coprod',
  '&#x2A2F;': '\\times',
  '&#x22C5;': '\\cdot',
  '&#x22A1;': '\\boxdot',
  '&#x22A0;': '\\boxtimes',
  '&#x2062;': '',
  '&#x2043;': '-',
  '&#x2022;': '\\cdot',
  '&#xD7;': '\\times',
  '.': '.',
  '*': '\\star',
  '&#x222A;': '\\cup',
  '&#x2229;': '\\cap',
  '&#x2210;': '\\coprod',
  '&#x220F;': '\\prod',
  '&#x2240;': '',
  '&#x2AFF;': '',
  '&#x2AFC;': '\\mid\\mid\\mid',
  '&#x2A09;': '\\times',
  '&#x2A08;': '',
  '&#x2A07;': '',
  '&#x2A06;': '\\sqcup',
  '&#x2A05;': '\\sqcap',
  '&#x2A02;': '\\otimes',
  '&#x2A00;': '\\odot',
  '&#x22C2;': '\\cap',
  '&#x22C1;': '\\vee',
  '&#x22C0;': '\\wedge',
  '&#x2A04;': '\\uplus',
  '&#x2A03;': '\\cup',
  '&#x22C3;': '\\cup',
  '&#x2A1C;': '\\underline{\\int}',
  '&#x2A1B;': '\\overline{\\int}',
  '&#x2A1A;': '\\int',
  '&#x2A19;': '\\int',
  '&#x2A18;': '\\int',
  '&#x2A17;': '\\int',
  '&#x2A16;': '\\oint',
  '&#x2A15;': '\\oint',
  '&#x2A14;': '\\int',
  '&#x2A13;': '\\int',
  '&#x2A12;': '\\int',
  '&#x2A11;': '\\int',
  '&#x2A10;': '\\int',
  '&#x2A0F;': '\\bcancel{\\int}',
  '&#x2A0E;': '',
  '&#x2A0D;': '\\hcancel{\\int}',
  '&#x2A0C;': '\\iiiint',
  '&#x2233;': '\\oint',
  '&#x2232;': '\\oint',
  '&#x2231;': '\\int',
  '&#x2230;': '\\oiint',
  '&#x222F;': '\\oiint',
  '&#x222E;': '\\oint',
  '&#x222B;': '\\int',
  '&#x2A01;': '\\oplus',
  '&#x2298;': '\\oslash',
  '&#x2296;': '\\ominus',
  '&#x2295;': '\\oplus',
  '&#x222D;': '\\iiint',
  '&#x222C;': '\\iint',
  '&#x2A0B;': '',
  '&#x2A0A;': '',
  '&#x2211;': '\\sum',
  '&#x229F;': '\\boxminus',
  '&#x229E;': '\\boxplus',
  '&#x2214;': '\\dot{+}',
  '&#x2213;': '+-',
  '&#x2212;': '-',
  '&#xB1;': '\\pm',
  '-': '-',
  '+': '+',
  '&#x2B46;': '\\Rrightarrow',
  '&#x2B45;': '\\Lleftarrow',
  '&#x29F4;': ':\\rightarrow',
  '&#x29EF;': '',
  '&#x29DF;': '\\bullet-\\bullet',
  '&#x299F;': '\\angle',
  '&#x299E;': '\\measuredangle',
  '&#x299D;': '\\measuredangle',
  '&#x299C;': '\\perp',
  '&#x299B;': '\\measuredangle',
  '&#x299A;': '',
  '&#x2999;': '\\vdots',
  '&#x297F;': '',
  '&#x297E;': '',
  '&#x297D;': '\\prec',
  '&#x297C;': '\\succ',
  '&#x297B;': '\\underset{\\rightarrow}{\\supset}',
  '&#x297A;': '',
  '&#x2979;': '\\underset{\\rightarrow}{\\subset}',
  '&#x2978;': '\\underset{\\rightarrow}{>}',
  '&#x2977;': '',
  '&#x2976;': '\\underset{\\leftarrow}{<}',
  '&#x2975;': '\\underset{\\approx}{\\rightarrow}',
  '&#x2974;': '\\underset{\\sim}{\\rightarrow}',
  '&#x2973;': '\\underset{\\sim}{\\leftarrow}',
  '&#x2972;': '\\overset{\\sim}{\\rightarrow}',
  '&#x2971;': '\\overset{=}{\\rightarrow}',
  '&#x2970;': '',
  '&#x296F;': '',
  '&#x296E;': '',
  '&#x296D;': '\\overline{\\rightharpoondown}',
  '&#x296C;': '\\underline{\\rightharpoonup}',
  '&#x296B;': '\\overline{\\leftharpoondown}',
  '&#x296A;': '\\underline{\\leftharpoonup}',
  '&#x2969;': '\\rightleftharpoons',
  '&#x2968;': '\\rightleftharpoons',
  '&#x2967;': '\\rightleftharpoons',
  '&#x2966;': '\\rightleftharpoons',
  '&#x2965;': '\\Downarrow',
  '&#x2964;': '\\Rightarrow',
  '&#x2963;': '\\Uparrow',
  '&#x2962;': '\\Leftarrow',
  '&#x2961;': '\\downarrow',
  '&#x2960;': '\\uparrow',
  '&#x295F;': '\\rightarrow',
  '&#x295E;': '\\leftarrow',
  '&#x295D;': '\\downarrow',
  '&#x295C;': '\\uparrow',
  '&#x295B;': '\\rightarrow',
  '&#x295A;': '\\leftarrow',
  '&#x2959;': '\\downarrow',
  '&#x2958;': '\\uparrow',
  '&#x2957;': '\\rightarrow',
  '&#x2956;': '\\leftarrow',
  '&#x2955;': '\\downarrow',
  '&#x2954;': '\\uparrow',
  '&#x2953;': '\\rightarrow',
  '&#x2952;': '\\leftarrow',
  '&#x2951;': '\\updownarrow',
  '&#x2950;': '\\leftrightarrow',
  '&#x294F;': '\\updownarrow',
  '&#x294E;': '\\leftrightarrow',
  '&#x294D;': '\\updownarrow',
  '&#x294C;': '\\updownarrow',
  '&#x294B;': '\\leftrightarrow',
  '&#x294A;': '\\leftrightarrow',
  '&#x2949;': '',
  '&#x2948;': '\\leftrightarrow',
  '&#x2947;': '\\nrightarrow',
  '&#x2946;': '',
  '&#x2945;': '',
  '&#x2944;': '\\rightleftarrows',
  '&#x2943;': '\\leftrightarrows',
  '&#x2942;': '\\rightleftarrows',
  '&#x2941;': '\\circlearrowright',
  '&#x2940;': '\\circlearrowleft',
  '&#x293F;': '\\rightarrow',
  '&#x293E;': '\\leftarrow',
  '&#x293D;': '',
  '&#x293C;': '',
  '&#x293B;': '',
  '&#x293A;': '',
  '&#x2939;': '',
  '&#x2938;': '',
  '&#x2937;': '\\Rsh',
  '&#x2936;': '\\Lsh',
  '&#x2935;': '\\downarrow',
  '&#x2934;': '\\uparrow',
  '&#x2933;': '\\leadsto',
  '&#x2932;': '',
  '&#x2931;': '',
  '&#x2930;': '',
  '&#x292F;': '',
  '&#x292E;': '',
  '&#x292D;': '',
  '&#x292C;': '\\times',
  '&#x292B;': '\\times',
  '&#x292A;': '',
  '&#x2929;': '',
  '&#x2928;': '',
  '&#x2927;': '',
  '&#x2926;': '',
  '&#x2925;': '',
  '&#x2924;': '',
  '&#x2923;': '',
  '&#x2922;': '',
  '&#x2921;': '',
  '&#x2920;': '\\mapsto\\cdot',
  '&#x291F;': '\\cdot\\leftarrow',
  '&#x291E;': '\\rightarrow\\cdot',
  '&#x291D;': '\\leftarrow',
  '&#x291C;': '\\rightarrow',
  '&#x291B;': '\\leftarrow',
  '&#x291A;': '\\rightarrow',
  '&#x2919;': '\\leftarrow',
  '&#x2918;': '\\rightarrow',
  '&#x2917;': '\\rightarrow',
  '&#x2916;': '\\rightarrow',
  '&#x2915;': '\\rightarrow',
  '&#x2914;': '\\rightarrow',
  '&#x2913;': '\\downarrow',
  '&#x2912;': '\\uparrow',
  '&#x2911;': '\\rightarrow',
  '&#x2910;': '\\rightarrow',
  '&#x290F;': '\\rightarrow',
  '&#x290E;': '\\leftarrow',
  '&#x290D;': '\\rightarrow',
  '&#x290C;': '\\leftarrow',
  '&#x290B;': '\\Downarrow',
  '&#x290A;': '\\Uparrow',
  '&#x2909;': '\\uparrow',
  '&#x2908;': '\\downarrow',
  '&#x2907;': '\\Rightarrow',
  '&#x2906;': '\\Leftarrow',
  '&#x2905;': '\\mapsto',
  '&#x2904;': '\\nLeftrightarrow',
  '&#x2903;': '\\nRightarrow',
  '&#x2902;': '\\nLeftarrow',
  '&#x2901;': '\\rightsquigarrow',
  '&#x2900;': '\\rightsquigarrow',
  '&#x27FF;': '\\rightsquigarrow',
  '&#x27FE;': '\\Rightarrow',
  '&#x27FD;': '\\Leftarrow',
  '&#x27FC;': '\\mapsto',
  '&#x27FB;': '\\leftarrow',
  '&#x27FA;': '\\Longleftrightarrow',
  '&#x27F9;': '\\Longrightarrow',
  '&#x27F8;': '\\Longleftarrow',
  '&#x27F7;': '\\leftrightarrow',
  '&#x27F6;': '\\rightarrow',
  '&#x27F5;': '\\leftarrow',
  '&#x27F1;': '\\Downarrow',
  '&#x27F0;': '\\Uparrow',
  '&#x22B8;': '\\rightarrow',
  '&#x21FF;': '\\leftrightarrow',
  '&#x21FE;': '\\rightarrow',
  '&#x21FD;': '\\leftarrow',
  '&#x21FC;': '\\nleftrightarrow',
  '&#x21FB;': '\\nrightarrow',
  '&#x21FA;': '\\nleftarrow',
  '&#x21F9;': '\\nleftrightarrow',
  '&#x21F8;': '\\nrightarrow',
  '&#x21F7;': '\\nleftarrow',
  '&#x21F6;': '\\Rrightarrow',
  '&#x21F5;': '',
  '&#x21F4;': '\\rightarrow',
  '&#x21F3;': '\\Updownarrow',
  '&#x21F2;': '\\searrow',
  '&#x21F1;': '\\nwarrow',
  '&#x21F0;': '\\Leftarrow',
  '&#x21EF;': '\\Uparrow',
  '&#x21EE;': '\\Uparrow',
  '&#x21ED;': '\\Uparrow',
  '&#x21EC;': '\\Uparrow',
  '&#x21EB;': '\\Uparrow',
  '&#x21EA;': '\\Uparrow',
  '&#x21E9;': '\\Downarrow',
  '&#x21E8;': '\\Rightarrow',
  '&#x21E7;': '\\Uparrow',
  '&#x21E6;': '\\Leftarrow',
  '&#x21E5;': '\\rightarrow',
  '&#x21E4;': '\\leftarrow',
  '&#x21E3;': '\\downarrow',
  '&#x21E2;': '\\rightarrow',
  '&#x21E1;': '\\uparrow',
  '&#x21E0;': '\\leftarrow',
  '&#x21DF;': '\\downarrow',
  '&#x21DE;': '\\uparrow',
  '&#x21DD;': '\\rightsquigarrow',
  '&#x21DC;': '\\leftarrow',
  '&#x21DB;': '\\Rrightarrow',
  '&#x21DA;': '\\Lleftarrow',
  '&#x21D9;': '\\swarrow',
  '&#x21D8;': '\\searrow',
  '&#x21D7;': '\\nearrow',
  '&#x21D6;': '\\nwarrow',
  '&#x21D5;': '\\Updownarrow',
  '&#x21D4;': '\\Leftrightarrow',
  '&#x21D3;': '\\Downarrow',
  '&#x21D2;': '\\Rightarrow',
  '&#x21D1;': '\\Uparrow',
  '&#x21D0;': '\\Leftarrow',
  '&#x21CF;': '\\nRightarrow',
  '&#x21CE;': '\\nLeftrightarrow',
  '&#x21CD;': '\\nLeftarrow',
  '&#x21CC;': '\\rightleftharpoons',
  '&#x21CB;': '\\leftrightharpoons',
  '&#x21CA;': '\\downdownarrows',
  '&#x21C9;': '\\rightrightarrows',
  '&#x21C8;': '\\upuparrows',
  '&#x21C7;': '\\leftleftarrows',
  '&#x21C6;': '\\leftrightarrows',
  '&#x21C5;': '',
  '&#x21C4;': '\\rightleftarrows',
  '&#x21C3;': '\\downharpoonleft',
  '&#x21C2;': '\\downharpoonright',
  '&#x21C1;': '\\rightharpoondown',
  '&#x21C0;': '\\rightharpoonup',
  '&#x21BF;': '\\upharpoonleft',
  '&#x21BE;': '\\upharpoonright',
  '&#x21BD;': '\\leftharpoondown',
  '&#x21BC;': '\\leftharpoonup',
  '&#x21BB;': '\\circlearrowright',
  '&#x21BA;': '\\circlearrowleft',
  '&#x21B9;': '\\leftrightarrows',
  '&#x21B8;': '\\overline{\\nwarrow}',
  '&#x21B7;': '\\curvearrowright',
  '&#x21B6;': '\\curvearrowleft',
  '&#x21B5;': '\\swarrow',
  '&#x21B4;': '\\searrow',
  '&#x21B3;': '\\Rsh',
  '&#x21B2;': '\\Lsh',
  '&#x21B1;': '\\Rsh',
  '&#x21B0;': '\\Lsh',
  '&#x21AF;': '\\swarrow',
  '&#x21AE;': '',
  '&#x21AD;': '\\leftrightsquigarrow',
  '&#x21AC;': '\\looparrowright',
  '&#x21AB;': '\\looparrowleft',
  '&#x21AA;': '\\hookrightarrow',
  '&#x21A9;': '\\hookleftarrow',
  '&#x21A8;': '\\underline{\\updownarrow}',
  '&#x21A7;': '\\downarrow',
  '&#x21A6;': '\\rightarrowtail',
  '&#x21A5;': '\\uparrow',
  '&#x21A4;': '\\leftarrowtail',
  '&#x21A3;': '\\rightarrowtail',
  '&#x21A2;': '\\leftarrowtail',
  '&#x21A1;': '\\downarrow',
  '&#x21A0;': '\\twoheadrightarrow',
  '&#x219F;': '\\uparrow',
  '&#x219E;': '\\twoheadleftarrow',
  '&#x219D;': '\\nearrow',
  '&#x219C;': '\\nwarrow',
  '&#x219B;': '',
  '&#x219A;': '',
  '&#x2199;': '\\swarrow',
  '&#x2198;': '\\searrow',
  '&#x2197;': '\\nearrow',
  '&#x2196;': '\\nwarrow',
  '&#x2195;': '\\updownarrow',
  '&#x2194;': '\\leftrightarrow',
  '&#x2193;': '\\downarrow',
  '&#x2192;': '\\rightarrow',
  '&#x2191;': '\\uparrow',
  '&#x2190;': '\\leftarrow',
  '|||': '\\left|||\\right.',
  '||': '\\left||\\right.',
  '|': '\\left|\\right.',
  '&#x2AFE;': '',
  '&#x2AFD;': '//',
  '&#x2AFB;': '///',
  '&#x2AFA;': '',
  '&#x2AF9;': '',
  '&#x2AF8;': '',
  '&#x2AF7;': '',
  '&#x2AF6;': '\\vdots',
  '&#x2AF5;': '',
  '&#x2AF4;': '',
  '&#x2AF3;': '',
  '&#x2AF2;': '\\nparallel',
  '&#x2AF1;': '',
  '&#x2AF0;': '',
  '&#x2AEF;': '',
  '&#x2AEE;': '\\bcancel{\\mid}',
  '&#x2AED;': '',
  '&#x2AEC;': '',
  '&#x2AEB;': '',
  '&#x2AEA;': '',
  '&#x2AE9;': '',
  '&#x2AE8;': '\\underline{\\perp}',
  '&#x2AE7;': '\\overline{\\top}',
  '&#x2AE6;': '',
  '&#x2AE5;': '',
  '&#x2AE4;': '',
  '&#x2AE3;': '',
  '&#x2AE2;': '',
  '&#x2AE1;': '',
  '&#x2AE0;': '\\perp',
  '&#x2ADF;': '\\top',
  '&#x2ADE;': '\\dashv',
  '&#x2ADD;&#x338;': '',
  '&#x2ADD;': '',
  '&#x2ADB;': '\\pitchfork',
  '&#x2ADA;': '',
  '&#x2AD9;': '',
  '&#x2AD8;': '',
  '&#x2AD7;': '',
  '&#x2AD6;': '',
  '&#x2AD5;': '',
  '&#x2AD4;': '',
  '&#x2AD3;': '',
  '&#x2AD2;': '',
  '&#x2AD1;': '',
  '&#x2AD0;': '',
  '&#x2ACF;': '',
  '&#x2ACE;': '',
  '&#x2ACD;': '',
  '&#x2ACC;': '\\underset{\\neq}{\\supset}',
  '&#x2ACB;': '\\underset{\\neq}{\\subset}',
  '&#x2ACA;': '\\underset{\\approx}{\\supset}',
  '&#x2AC9;': '\\underset{\\approx}{\\subset}',
  '&#x2AC8;': '\\underset{\\sim}{\\supset}',
  '&#x2AC7;': '\\underset{\\sim}{\\subset}',
  '&#x2AC6;': '\\supseteqq',
  '&#x2AC5;': '\\subseteqq',
  '&#x2AC4;': '\\dot{\\supseteq}',
  '&#x2AC3;': '\\dot{\\subseteq}',
  '&#x2AC2;': '\\underset{\\times}{\\supset}',
  '&#x2AC1;': '\\underset{\\times}{\\subset}',
  '&#x2AC0;': '\\underset{+}{\\supset}',
  '&#x2ABF;': '\\underset{+}{\\subset}',
  '&#x2ABE;': '',
  '&#x2ABD;': '',
  '&#x2ABC;': '\\gg ',
  '&#x2ABB;': '\\ll',
  '&#x2ABA;': '\\underset{\\cancel{\\approx}}{\\succ}',
  '&#x2AB9;': '\\underset{\\cancel{\\approx}}{\\prec}',
  '&#x2AB8;': '\\underset{\\approx}{\\succ}',
  '&#x2AB7;': '\\underset{\\approx}{\\prec}',
  '&#x2AB6;': '\\underset{\\cancel{=}}{\\succ}',
  '&#x2AB5;': '\\underset{\\cancel{=}}{\\prec}',
  '&#x2AB4;': '\\underset{=}{\\succ}',
  '&#x2AB3;': '\\underset{=}{\\prec}',
  '&#x2AB2;': '',
  '&#x2AB1;': '',
  '&#x2AAE;': '',
  '&#x2AAD;': '\\underline{\\hcancel{>}}',
  '&#x2AAC;': '\\underline{\\hcancel{>}}',
  '&#x2AAB;': '\\hcancel{>}',
  '&#x2AAA;': '\\hcancel{<}',
  '&#x2AA9;': '',
  '&#x2AA8;': '',
  '&#x2AA7;': '\\vartriangleright',
  '&#x2AA6;': '\\vartriangleleft',
  '&#x2AA5;': '><',
  '&#x2AA4;': '><',
  '&#x2AA3;': '\\underline{\\ll}',
  '&#x2AA2;&#x338;': '\\cancel{\\gg}',
  '&#x2AA2;': '\\gg',
  '&#x2AA1;&#x338;': '\\cancel{\\ll}',
  '&#x2AA1;': '\\ll',
  '&#x2AA0;': '\\overset{\\sim}{\\geqq}',
  '&#x2A9F;': '\\overset{\\sim}{\\leqq}',
  '&#x2A9E;': '\\overset{\\sim}{>}',
  '&#x2A9D;': '\\overset{\\sim}{<}',
  '&#x2A9C;': '',
  '&#x2A9B;': '',
  '&#x2A9A;': '\\overset{=}{>}',
  '&#x2A99;': '\\overset{=}{<}',
  '&#x2A98;': '',
  '&#x2A97;': '',
  '&#x2A96;': '',
  '&#x2A95;': '',
  '&#x2A94;': '',
  '&#x2A93;': '',
  '&#x2A92;': '\\underset{=}{\\gtrless}',
  '&#x2A91;': '\\underset{=}{\\lessgtr}',
  '&#x2A90;': '\\underset{<}{\\gtrsim}',
  '&#x2A8F;': '\\underset{>}{\\lesssim}',
  '&#x2A8E;': '\\underset{\\simeq}{>}',
  '&#x2A8D;': '\\underset{\\simeq}{<}',
  '&#x2A8C;': '\\gtreqqless',
  '&#x2A8B;': '\\lesseqqgtr',
  '&#x2A8A;': '\\underset{\\cancel{\\approx}}{>}',
  '&#x2A89;': '\\underset{\\approx}{<}',
  '&#x2A86;': '\\underset{\\approx}{>}',
  '&#x2A85;': '\\underset{\\approx}{<}',
  '&#x2A84;': '',
  '&#x2A83;': '',
  '&#x2A82;': '',
  '&#x2A81;': '',
  '&#x2A80;': '',
  '&#x2A7F;': '',
  '&#x2A7E;&#x338;': '\\bcancel{\\geq}',
  '&#x2A7E;': '\\geq',
  '&#x2A7D;&#x338;': '\\bcancel{\\leq}',
  '&#x2A7D;': '\\leq',
  '&#x2A7C;': '',
  '&#x2A7B;': '',
  '&#x2A7A;': '',
  '&#x2A79;': '',
  '&#x2A78;': '\\overset{\\dots}{\\equiv}',
  '&#x2A77;': '',
  '&#x2A76;': '===',
  '&#x2A75;': '==',
  '&#x2A74;': '::=',
  '&#x2A73;': '',
  '&#x2A72;': '\\underset{=}{+}',
  '&#x2A71;': '\\overset{=}{+}',
  '&#x2A70;': '\\overset{\\approx}{=}',
  '&#x2A6F;': '\\overset{\\wedge}{=}',
  '&#x2A6E;': '\\overset{*}{=}',
  '&#x2A6D;': '\\dot{\\approx}',
  '&#x2A6C;': '',
  '&#x2A6B;': '',
  '&#x2A6A;': '\\dot{\\sim}',
  '&#x2A69;': '',
  '&#x2A68;': '',
  '&#x2A67;': '\\dot{\\equiv}',
  '&#x2A66;': '\\underset{\\cdot}{=}',
  '&#x2A65;': '',
  '&#x2A64;': '',
  '&#x2A63;': '\\underset{=}{\\vee}',
  '&#x2A62;': '\\overset{=}{\\vee}',
  '&#x2A61;': 'ul(vv)',
  '&#x2A60;': '\\underset{=}{\\wedge}',
  '&#x2A5F;': '\\underline{\\wedge}',
  '&#x2A5E;': '\\overset{=}{\\wedge}',
  '&#x2A5D;': '\\hcancel{\\vee}',
  '&#x2A5C;': '\\hcancel{\\wedge}',
  '&#x2A5B;': '',
  '&#x2A5A;': '',
  '&#x2A59;': '',
  '&#x2A58;': '\\vee',
  '&#x2A57;': '\\wedge',
  '&#x2A56;': '',
  '&#x2A55;': '',
  '&#x2A54;': '',
  '&#x2A53;': '',
  '&#x2A52;': '\\dot{\\vee}',
  '&#x2A51;': '\\dot{\\wedge}',
  '&#x2A50;': '',
  '&#x2A4F;': '',
  '&#x2A4E;': '',
  '&#x2A4D;': '\\overline{\\cap}',
  '&#x2A4C;': '\\overline{\\cup}',
  '&#x2A4B;': '',
  '&#x2A4A;': '',
  '&#x2A49;': '',
  '&#x2A48;': '',
  '&#x2A47;': '',
  '&#x2A46;': '',
  '&#x2A45;': '',
  '&#x2A44;': '',
  '&#x2A43;': '\\overline{\\cap}',
  '&#x2A42;': '\\overline{\\cup}',
  '&#x2A41;': '',
  '&#x2A40;': '',
  '&#x2A3E;': '',
  '&#x2A3D;': '\\llcorner',
  '&#x2A3C;': '\\lrcorner',
  '&#x2A3B;': '',
  '&#x2A3A;': '',
  '&#x2A39;': '',
  '&#x2A38;': '',
  '&#x2A37;': '',
  '&#x2A36;': '\\hat{\\otimes}',
  '&#x2A35;': '',
  '&#x2A34;': '',
  '&#x2A33;': '',
  '&#x2A32;': '\\underline{\\times}',
  '&#x2A31;': '\\underline{\\times}',
  '&#x2A30;': '\\dot{\\times}',
  '&#x2A2E;': '',
  '&#x2A2D;': '',
  '&#x2A2C;': '',
  '&#x2A2B;': '',
  '&#x2A2A;': '',
  '&#x2A29;': '',
  '&#x2A28;': '',
  '&#x2A27;': '',
  '&#x2A26;': '\\underset{\\sim}{+}',
  '&#x2A25;': '\\underset{\\circ}{+}',
  '&#x2A24;': '\\overset{\\sim}{+}',
  '&#x2A23;': '\\hat{+}',
  '&#x2A22;': '\\dot{+}',
  '&#x2A21;': '\\upharpoonright',
  '&#x2A20;': '>>',
  '&#x2A1F;': '',
  '&#x2A1E;': '\\triangleleft',
  '&#x2A1D;': '\\bowtie',
  '&#x29FF;': '',
  '&#x29FE;': '+',
  '&#x29FB;': '\\hcancel{|||}',
  '&#x29FA;': '\\hcancel{||}',
  '&#x29F9;': '\\backslash',
  '&#x29F8;': '/',
  '&#x29F7;': 'hcancel{\backslash}',
  '&#x29F6;': '',
  '&#x29F5;': '\\backslash',
  '&#x29F2;': '\\Phi',
  '&#x29F1;': '',
  '&#x29F0;': '',
  '&#x29EE;': '',
  '&#x29ED;': '',
  '&#x29EC;': '',
  '&#x29EB;': '\\lozenge',
  '&#x29EA;': '',
  '&#x29E9;': '',
  '&#x29E8;': '',
  '&#x29E7;': '\\ddagger',
  '&#x29E2;': '\\sqcup\\sqcup',
  '&#x29E1;': '',
  '&#x29E0;': '\\square',
  '&#x29DE;': '',
  '&#x29DD;': '',
  '&#x29DC;': '',
  '&#x29DB;': '\\{\\{',
  '&#x29D9;': '\\{',
  '&#x29D8;': '\\}',
  '&#x29D7;': '',
  '&#x29D6;': '',
  '&#x29D5;': '\\bowtie',
  '&#x29D4;': '\\bowtie',
  '&#x29D3;': '\\bowtie',
  '&#x29D2;': '\\bowtie',
  '&#x29D1;': '\\bowtie',
  '&#x29D0;&#x338;': '| \\not\\triangleright',
  '&#x29D0;': '| \\triangleright',
  '&#x29CF;&#x338;': '\\not\\triangleleft |',
  '&#x29CF;': '\\triangleleft |',
  '&#x29CE;': '',
  '&#x29CD;': '\\triangle',
  '&#x29CC;': '',
  '&#x29CB;': '\\underline{\\triangle}',
  '&#x29CA;': '\\dot{\\triangle}',
  '&#x29C9;': '',
  '&#x29C8;': '\\boxed{\\circ}',
  '&#x29C7;': '\\boxed{\\circ}',
  '&#x29C6;': '\\boxed{\\rightarrow}',
  '&#x29C5;': '\\bcancel{\\square}',
  '&#x29C4;': '\\cancel{\\square}',
  '&#x29C3;': '\\odot',
  '&#x29C2;': '\\odot',
  '&#x29BF;': '\\odot',
  '&#x29BE;': '\\odot',
  '&#x29BD;': '\\varnothing',
  '&#x29BC;': '\\oplus',
  '&#x29BB;': '\\otimes',
  '&#x29BA;': '',
  '&#x29B9;': '\\varnothing',
  '&#x29B8;': '\\varnothing',
  '&#x29B7;': '\\ominus',
  '&#x29B6;': '\\ominus',
  '&#x29B5;': '\\ominus',
  '&#x29B4;': '\\vec{\\varnothing}',
  '&#x29B3;': '\\vec{\\varnothing}',
  '&#x29B2;': '\\dot{\\varnothing}',
  '&#x29B1;': '\\overline{\\varnothing}',
  '&#x29B0;': '\\varnothing',
  '&#x29AF;': '',
  '&#x29AE;': '',
  '&#x29AD;': '',
  '&#x29AC;': '',
  '&#x29AB;': '',
  '&#x29AA;': '',
  '&#x29A9;': '',
  '&#x29A8;': '',
  '&#x29A7;': '',
  '&#x29A6;': '',
  '&#x29A5;': '',
  '&#x29A4;': '',
  '&#x29A3;': '',
  '&#x29A2;': '',
  '&#x29A1;': '\\not\\lor',
  '&#x29A0;': '\\bcancel{>}',
  '&#x2982;': ':',
  '&#x2981;': '\\circ',
  '&#x2758;': '|',
  '&#x25B2;': '\\bigtriangleup',
  '&#x22FF;': '\\Epsilon',
  '&#x22FE;': '\\overline{\\ni}',
  '&#x22FD;': '\\overline{\\ni}',
  '&#x22FC;': '\\in',
  '&#x22FB;': '\\in',
  '&#x22FA;': '\\in',
  '&#x22F9;': '\\underline{\\in}',
  '&#x22F8;': '\\underline{\\in}',
  '&#x22F7;': '\\overline{\\in}',
  '&#x22F6;': '\\overline{\\in}',
  '&#x22F5;': '\\dot{\\in}',
  '&#x22F4;': '\\in',
  '&#x22F3;': '\\in',
  '&#x22F2;': '\\in',
  '&#x22F0;': '\\ddots',
  '&#x22E9;': '\\underset{\\sim}{\\succ}',
  '&#x22E8;': '\\underset{\\sim}{\\prec}',
  '&#x22E7;': '\\underset{\\not\\sim}{>}',
  '&#x22E6;': '\\underset{\\not\\sim}{<}',
  '&#x22E5;': '\\not\\sqsupseteq',
  '&#x22E4;': '\\not\\sqsubseteq',
  '&#x22E3;': '\\not\\sqsupseteq',
  '&#x22E2;': '\\not\\sqsubseteq',
  '&#x22E1;': '\\nsucc',
  '&#x22E0;': '\\nprec',
  '&#x22DF;': '\\succ',
  '&#x22DE;': '\\prec',
  '&#x22DD;': '\\overline{>}',
  '&#x22DC;': '\\overline{<}',
  '&#x22DB;': '\\underset{>}{\\leq}',
  '&#x22DA;': '\\underset{<}{\\geq}',
  '&#x22D5;': '\\#',
  '&#x22D3;': '\\cup',
  '&#x22D2;': '\\cap',
  '&#x22D1;': '\\supset',
  '&#x22D0;': '\\subset',
  '&#x22CF;': '\\wedge',
  '&#x22CE;': '\\vee',
  '&#x22CD;': '\\simeq',
  '&#x22C8;': '\\bowtie',
  '&#x22C7;': '\\ast',
  '&#x22C6;': '\\star',
  '&#x22C4;': '\\diamond',
  '&#x22BF;': '\\triangle',
  '&#x22BE;': '\\measuredangle',
  '&#x22BD;': '\\overline{\\lor}',
  '&#x22BC;': '\\overline{\\land}',
  '&#x22BB;': '\\underline{\\lor}',
  '&#x22BA;': '\\top',
  '&#x22B9;': '',
  '&#x22B7;': '\\circ\\multimap',
  '&#x22B6;': '\\circ\\multimap',
  '&#x22B3;': '\\triangleright',
  '&#x22B2;': '\\triangleleft',
  '&#x22B1;': '\\succ',
  '&#x22B0;': '\\prec',
  '&#x22AB;': '|\\models',
  '&#x22AA;': '|\\models',
  '&#x22A7;': '\\models',
  '&#x22A6;': '\\vdash',
  '&#x229D;': '\\ominus',
  '&#x229C;': '\\ominus',
  '&#x229B;': '\\odot',
  '&#x229A;': '\\odot',
  '&#x2294;': '\\sqcup',
  '&#x2293;': '\\sqcap',
  '&#x2292;': '\\sqsupseteq',
  '&#x2291;': '\\sqsubseteq',
  '&#x2290;&#x338;': '\\not\\sqsupset',
  '&#x2290;': '\\sqsupset',
  '&#x228F;&#x338;': '\\not\\sqsubset',
  '&#x228F;': '\\sqsubset',
  '&#x228E;': '\\cup',
  '&#x228D;': '\\cup',
  '&#x228C;': '\\cup',
  '&#x227F;&#x338;': '\\not\\succsim',
  '&#x227F;': '\\succsim',
  '&#x227E;': '\\precsim',
  '&#x2279;': '\\not\\overset{>}{<}',
  '&#x2278;': '\\not\\overset{>}{<}',
  '&#x2277;': '\\overset{>}{<}',
  '&#x2276;': '\\overset{<}{>}',
  '&#x2275;': '\\not\\geg',
  '&#x2274;': '\\not\\leq',
  '&#x2273;': '\\geg',
  '&#x2272;': '\\leq',
  '&#x226C;': '',
  '&#x2267;': '\\geg',
  '&#x2266;&#x338;': '\\not\\leq',
  '&#x2266;': '\\leq',
  '&#x2263;': '\\overset{=}{=} ',
  '&#x225E;': '\\overset{m}{=} ',
  '&#x225D;': '\\overset{def}{=}',
  '&#x2258;': '=',
  '&#x2256;': '=',
  '&#x2255;': '=:',
  '&#x2253;': '\\doteq',
  '&#x2252;': '\\doteq',
  '&#x2251;': '\\doteq',
  '&#x2250;': '\\doteq',
  '&#x224F;&#x338;': '',
  '&#x224F;': '',
  '&#x224E;&#x338;': '',
  '&#x224E;': '',
  '&#x224C;': '\\approx',
  '&#x224B;': '\\approx',
  '&#x224A;': '\\approx',
  '&#x2242;&#x338;': '\\neq',
  '&#x2242;': '=',
  '&#x223F;': '\\sim',
  '&#x223E;': '\\infty',
  '&#x223D;&#x331;': '\\sim',
  '&#x223D;': '\\sim',
  '&#x223B;': '\\sim',
  '&#x223A;': ':-:',
  '&#x2239;': '-:',
  '&#x2238;': '\\bot',
  '&#x2237;': '::',
  '&#x2236;': ':',
  '&#x2223;': '|',
  '&#x221F;': '\\llcorner',
  '&#x2219;': '\\cdot',
  '&#x2218;': '\\circ',
  '&#x2217;': '*',
  '&#x2215;': '/',
  '&#x220E;': '\\square',
  '&#x220D;': '\\ni',
  '&#x220A;': '\\in',
  '&#x2206;': '\\Delta',
  '&#x2044;': '/',
  '&#x2AB0;&#x338;': '\\nsucceq',
  '&#x2AB0;': '\\succeq',
  '&#x2AAF;&#x338;': '\\npreceq',
  '&#x2AAF;': '\\preceq',
  '&#x2A88;': '\\ngeqslant',
  '&#x2A87;': '\\nleqslant',
  '&#x29F3;': '\\Phi',
  '&#x29E6;': '\\models',
  '&#x29E5;': '\\not\\equiv',
  '&#x29E4;': '\\approx\\neq',
  '&#x29E3;': '\\neq',
  '&#x29C1;': '\\circle',
  '&#x29C0;': '\\circle',
  '&#x25E6;': '\\circle',
  '&#x25D7;': '\\circle',
  '&#x25D6;': '\\circle',
  '&#x25CF;': '\\circle',
  '&#x25CE;': '\\circledcirc',
  '&#x25CD;': '\\circledcirc',
  '&#x25CC;': '\\circledcirc',
  '&#x25C9;': '\\circledcirc',
  '&#x25C8;': '\\diamond',
  '&#x25C7;': '\\diamond',
  '&#x25C6;': '\\diamond',
  '&#x25C5;': '\\triangleleft',
  '&#x25C4;': '\\triangleleft',
  '&#x25C3;': '\\triangleleft',
  '&#x25C2;': '\\triangleleft',
  '&#x25C1;': '\\triangleleft',
  '&#x25C0;': '\\triangleleft',
  '&#x25BF;': '\\triangledown',
  '&#x25BE;': '\\triangledown',
  '&#x25BD;': '\\triangledown',
  '&#x25BC;': '\\triangledown',
  '&#x25B9;': '\\triangleright',
  '&#x25B8;': '\\triangleright',
  '&#x25B7;': '\\triangleright',
  '&#x25B6;': '\\triangleright',
  '&#x25B5;': '\\triangle',
  '&#x25B4;': '\\triangle',
  '&#x25B3;': '\\triangle',
  '&#x25B1;': '\\square',
  '&#x25B0;': '\\square',
  '&#x25AF;': '\\square',
  '&#x25AE;': '\\square',
  '&#x25AD;': '\\square',
  '&#x25AB;': '\\square',
  '&#x25AA;': '\\square',
  '&#x25A1;': '\\square',
  '&#x25A0;': '\\square',
  '&#x22ED;': '\\not\\triangleright',
  '&#x22EC;': '\\not\\triangleleft',
  '&#x22EB;': '\\not\\triangleright',
  '&#x22EA;': '\\not\\triangleleft',
  '&#x22D9;': '\\ggg',
  '&#x22D8;': '\\lll',
  '&#x22D7;': '*>',
  '&#x22D6;': '<*',
  '&#x22D4;': '\\pitchfork',
  '&#x22CC;': '',
  '&#x22CB;': '',
  '&#x22CA;': '\\rtimes',
  '&#x22C9;': '\\ltimes',
  '&#x22B5;': '\\triangleright',
  '&#x22B4;': '',
  '&#x22A5;': '\\bot',
  '&#x2281;': '\\nsucc',
  '&#x2280;': '\\preceq',
  '&#x227D;': '\\succeq',
  '&#x227C;': '\\preceq',
  '&#x227B;': '\\succ',
  '&#x227A;': '\\prec',
  '&#x2271;': '\\geq/',
  '&#x2270;': '\\leq/',
  '&#x226D;': '\\neq',
  '&#x226B;&#x338;': '\\not\\gg',
  '&#x226B;': '\\gg',
  '&#x226A;&#x338;': '\\not\\ll',
  '&#x226A;': '\\ll',
  '&#x2269;': '\\ngeqslant',
  '&#x2268;': '\\nleqslant',
  '&#x2261;': '\\equiv',
  '&#x225F;': '\\doteq',
  '&#x225C;': '\\triangleq',
  '&#x225B;': '\\doteq',
  '&#x225A;': '\\triangleq',
  '&#x2259;': '\\triangleq',
  '&#x2257;': '\\doteq',
  '&#x2254;': ':=',
  '&#x224D;': '\\asymp',
  '&#x2247;': '\\ncong',
  '&#x2246;': '\\ncong',
  '&#x2245;': '\\cong',
  '&#x2244;': '\\not\\simeq',
  '&#x2243;': '\\simeq',
  '&#x2241;': '\\not\\sim',
  '&#x2226;': '\\not\\parallel',
  '&#x2225;': '\\parallel',
  '&#x2224;': '\\not|',
  '&#x221D;': '\\propto',
  '==': '==',
  '=': '=',
  ':=': ':=',
  '/=': '=',
  '-=': '-=',
  '+=': '+=',
  '*=': '*=',
  '!=': '!=',
  '&#x2260;': '\\neq',
  '&#x2262;': '\\equiv /',
  '&#x2249;': '\\approx /',
  '&#x223C;': 'sim',
  '&#x2248;': '\\approx',
  '&#x226E;': '</',
  '&lt;': '<',
  '&#x226F;': '>/',
  '>=': '>=',
  '>': '>',
  '&#x2265;': '\\geq',
  '&#x2264;': '\\leq',
  '&lt;=': '<=',
  '&#x228B;': '\\supsetneq',
  '&#x228A;': '\\subsetneq',
  '&#x2289;': '\\nsupseteq',
  '&#x2288;': '\\nsubseteq',
  '&#x2287;': '\\supseteq',
  '&#x2286;': '\\subseteq',
  '&#x2285;': '\\not\\supset',
  '&#x2284;': '\\not\\subset',
  '&#x2283;&#x20D2;': '\\supset |',
  '&#x2283;': '\\supset',
  '&#x2282;&#x20D2;': '\\subset |',
  '&#x2282;': '\\subset',
  '&#x220C;': '\\not\\in',
  '&#x2209;': '\\notin',
  '&#x2208;': '\\in',
  '&#x2201;': 'C',
  '&#x2204;': '\\nexists',
  '&#x2203;': '\\exists',
  '&#x2200;': '\\forall',
  '&#x2227;': '\\land',
  '&amp;&amp;': '\\&\\&',
  '&#x2228;': '\\lor',
  '&#x22AF;': '\\cancel{\\vDash}',
  '&#x22AE;': '\\cancel{\\Vdash}',
  '&#x22AD;': '\\nvDash',
  '&#x22AC;': '\\nvDash',
  '&#x22A9;': '\\Vdash',
  '&#x22A8;': '\\vDash',
  '&#x22A4;': '\\top',
  '&#x22A3;': '\\dashv',
  '&#x22A2;': '\\vdash',
  '&#x220B;': '\\ni',
  '&#x22F1;': '\\ddots',
  '&#x22EF;': '\\hdots',
  '&#x22EE;': '\\vdots',
  '&#x2026;': '\\hdots',
  '&#x3F6;': '\\ni',
  ':': ':',
  '...': '\\cdots',
  '..': '..',
  '->': '->',
  '&#x2235;': '\\because',
  '&#x2234;': '\\therefore ',
  '&#x2063;': '',
  ',': ',',
  ';': ';',
  '&#x29FD;': '\\}',
  '&#x29FC;': '\\{',
  '&#x2998;': '\\]',
  '&#x2997;': '\\[',
  '&#x2996;': '\\ll',
  '&#x2995;': '\\gg',
  '&#x2994;': '\\gg',
  '&#x2993;': '\\ll',
  '&#x2992;': '\\gg',
  '&#x2991;': '\\ll',
  '&#x2990;': '\\]',
  '&#x298F;': '\\]',
  '&#x298E;': '\\]',
  '&#x298D;': '\\[',
  '&#x298C;': '\\[',
  '&#x298B;': '\\]',
  '&#x298A;': '\\triangleright',
  '&#x2989;': '\\triangleleft',
  '&#x2988;': '|\\)',
  '&#x2987;': '\\(|',
  '&#x2986;': '|\\)',
  '&#x2985;': '\\(\\(',
  '&#x2984;': '|\\}',
  '&#x2983;': '\\{|',
  '&#x2980;': '\\||',
  '&#x27EF;': '\\left. \\right]',
  '&#x27EE;': '\\left[ \\right.',
  '&#x27ED;': '\\left. \\right]]',
  '&#x27EC;': '\\left[[ \\right.',
  '&#x27EB;': '\\gg',
  '&#x27EA;': '\\ll',
  '&#x27E9;': '\\rangle',
  '&#x27E8;': '\\langle',
  '&#x27E7;': '\\left. \\right]]',
  '&#x27E6;': '\\left[[ \\right.',
  '&#x2773;': '\\left.\\right)',
  '&#x2772;': '\\left(\\right.',
  '&#x232A;': '\\rangle',
  '&#x2329;': '\\langle',
  '&#x230B;': '\\rfloor',
  '&#x230A;': '\\lfloor',
  '&#x2309;': '\\rceil',
  '&#x2308;': '\\lceil',
  '&#x2016;': '\\parallel',
  '}': '\\left.\\right}',
  '{': '\\left{\\right.',
  ']': '\\left]\\right.',
  '[': '\\left[\\right.',
  ')': '\\left.\\right)',
  '(': '\\left(\\right.',
  '&#x201D;': '"',
  '&#x201C;': '``',
  '&#x2019;': "'",
  '&#x2018;': '`',
  '%CE%B1': '\\alpha',
  '%CE%B2': '\\beta',
  '%CE%B3': '\\gamma',
  '%CE%93': '\\Gamma',
  '%CE%B4': '\\delta',
  '%CE%94': '\\Delta',
  '%CF%B5': '\\epsilon',
  '%CE%B6': '\\zeta',
  '%CE%B7': '\\eta',
  '%CE%B8': '\\theta',
  '%CE%98': '\\Theta',
  '%CE%B9': '\\iota',
  '%CE%BA': '\\kappa',
  '%CE%BB': '\\lambda',
  '%CE%BC': '\\mu',
  '%CE%BD': '\\nu',
  '%CE%BF': '\\omicron',
  '%CF%80': '\\pi',
  '%CE%A0': '\\Pi',
  '%CF%81': '\\pho',
  '%CF%83': '\\sigma',
  '%CE%A3': '\\Sigma',
  '%CF%84': '\\tau',
  '%CF%85': '\\upsilon',
  '%CE%A5': '\\Upsilon',
  '%CF%95': '\\phi',
  '%CE%A6': '\\Phi',
  '%CF%87': '\\chi',
  '%CF%88': '\\psi',
  '%CE%A8': '\\Psi',
  '%CF%89': '\\omega',
  '%CE%A9': '\\Omega',
};
