// Various helper function for working with tables
import { NodeType, Schema, ResolvedPos, Attrs, Node as ProseMirrorNode } from 'prosemirror-model';
import { EditorState, NodeSelection, PluginKey, Selection } from 'prosemirror-state';
import { CellSelection } from './cellSelection';

import { TableMap } from './tableMap';

declare module 'prosemirror-model' {
  interface TableNodeSpec {
    tableRole: string;
  }
}
export const key = new PluginKey('selectingCells');

export function cellAround($pos: ResolvedPos) {
  for (let d = $pos.depth - 1; d > 0; d--)
    if ($pos.node(d).type.spec['tableRole'] == 'row')
      return $pos.node(0).resolve($pos.before(d + 1));
  return null;
}

export function cellWrapping($pos: ResolvedPos) {
  for (let d = $pos.depth; d > 0; d--) {
    // Sometimes the cell can be in the same depth.
    const role = $pos.node(d).type.spec['tableRole'];
    if (role === 'cell' || role === 'header_cell') {
      return $pos.node(d);
    }
  }
  return null;
}

export function isInTable(state: EditorState) {
  const $head = state.selection.$head;
  for (let d = $head.depth; d > 0; d--)
    if ($head.node(d).type.spec['tableRole'] == 'row') return true;
  return false;
}

export function selectionCell(state: EditorState) {
  const sel: Selection | CellSelection | NodeSelection = state.selection;
  if (sel instanceof CellSelection) {
    return sel.$anchorCell.pos > sel.$headCell.pos ? sel.$anchorCell : sel.$headCell;
  } else if (
    sel instanceof NodeSelection &&
    sel.node &&
    sel.node.type.spec['tableRole'] == 'cell'
  ) {
    return sel.$anchor;
  }
  return cellAround(sel.$head) || cellNear(sel.$head);
}

function cellNear($pos: ResolvedPos) {
  for (let after = $pos.nodeAfter, pos = $pos.pos; after; after = after.firstChild, pos++) {
    const role = after.type.spec['tableRole'];
    if (role == 'cell' || role == 'header_cell') return $pos.doc.resolve(pos);
  }
  for (let before = $pos.nodeBefore, pos = $pos.pos; before; before = before.lastChild, pos--) {
    const role = before.type.spec['tableRole'];
    if (role == 'cell' || role == 'header_cell') return $pos.doc.resolve(pos - before.nodeSize);
  }
  return null;
}

export function pointsAtCell($pos: ResolvedPos) {
  return $pos.parent.type.spec['tableRole'] == 'row' && $pos.nodeAfter;
}

export function moveCellForward($pos: ResolvedPos) {
  return $pos.node(0).resolve($pos.pos + ($pos.nodeAfter?.nodeSize ?? 0));
}

export function inSameTable($a: ResolvedPos, $b: ResolvedPos) {
  return $a.depth == $b.depth && $a.pos >= $b.start(-1) && $a.pos <= $b.end(-1);
}

export function findCell($pos: ResolvedPos) {
  const [table, start] = getTableFromCellPosition($pos);
  return TableMap.get(table).findCell($pos.pos - start);
}

export function colCount($pos: ResolvedPos) {
  const [table, start] = getTableFromCellPosition($pos);
  return TableMap.get(table).getColumnIndexFromPos($pos.pos - start);
}

export function nextCell($pos: ResolvedPos, axis: 'horiz' | 'vert', dir: number) {
  const [table, start] = getTableFromCellPosition($pos);
  const map = TableMap.get(table);
  const moved = map.nextCell($pos.pos - start, axis, dir);
  return moved == null ? null : $pos.node(0).resolve(start + moved);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setAttr(attrs: Attrs, name: string, value: any) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: Record<string, any> = {};
  for (const prop in attrs) result[prop] = attrs[prop];
  result[name] = value;
  return result;
}

export function removeColSpan(attrs: Attrs, pos: number, n = 1) {
  const result = setAttr(attrs, 'colspan', attrs['colspan'] - n);
  if (result['colwidth']) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    result['colwidth'] = result['colwidth'].slice();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    result['colwidth'].splice(pos, n);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (!result['colwidth'].some((w: number) => w > 0)) result['colwidth'] = null;
  }
  return result;
}

export function addColSpan(attrs: Attrs, pos: number, n = 1) {
  const result = setAttr(attrs, 'colspan', attrs['colspan'] + n);
  if (result['colwidth']) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    result['colwidth'] = result['colwidth'].slice();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    for (let i = 0; i < n; i++) result['colwidth'].splice(pos, 0, 0);
  }
  return result;
}

export function columnIsHeader(map: TableMap, table: ProseMirrorNode, col: number) {
  const headerCell = tableNodeTypes(table.type.schema)['header_cell'];
  for (let row = 0; row < map.height; row++)
    if (table.nodeAt(map.map[col + row * map.width])?.type != headerCell) return false;
  return true;
}

export function tableNodeTypes(schema: Schema): Record<string, NodeType> {
  let result: Record<string, NodeType> = schema.cached['tableNodeTypes'] as Record<
    string,
    NodeType
  >;
  if (!result) {
    result = schema.cached['tableNodeTypes'] = {};
    for (const name in schema.nodes) {
      const type = schema.nodes[name];
      const role = type.spec['tableRole'];
      if (role) result[role] = type;
    }
  }
  return result;
}

export function getTableFromCellPosition($cell: ResolvedPos): [ProseMirrorNode, number] {
  for (let x = $cell.depth; x > 0; x--) {
    const n = $cell.node(x);
    if (n.type.spec['tableRole'] === 'table') {
      return [n, $cell.start(x)];
    }
  }
  throw Error('Cell is not inside a table');
}

export function getRowsFromTable(tableNode: ProseMirrorNode): [ProseMirrorNode, number][] {
  const res: [ProseMirrorNode, number][] = [];

  tableNode.forEach((n, o) => {
    if (n.type.spec['tableRole'] === 'row') {
      res.push([n, o]);
    } else {
      n.forEach((nn, oo) => {
        if (nn.type.spec['tableRole'] === 'row') {
          res.push([nn, o + oo + 1]);
        }
      });
    }
  });

  return res;
}
