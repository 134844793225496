/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { CONSTANTS } from '../editor/editorUtilityFunctions/constants';
import { LoreeInteractiveEditor } from '../editor/loreeInteractiveEditor';
import { LoreeInteractiveEditorDashboardContentType } from '../editor/editorUtilityFunctions/lintEditorType';

interface SectionBodyComponentProps {
  panel: Array<string>;
  index: number;
  errors: any;
  parameter: any;
  type: string;
  handleChange: Function;
  handleTitleChange: Function;
  editorConfig: LoreeInteractiveEditorDashboardContentType;
  indexValue: Array<string>;
}

const SectionBodyComponent = (props: SectionBodyComponentProps) => {
  const { panel, index, errors, parameter, type, handleChange, handleTitleChange, editorConfig } =
    props;

  const handleEditor = (index: number, value: string, fieldType: string) => {
    let fail = errors;
    const content = parameter;
    if (type === 'flipcard') {
      if (fieldType === 'fronttext') {
        content[index] = { ...content[index], fronttext: value };
      } else {
        content[index] = { ...content[index], backtext: value };
      }
    } else if (
      (fieldType === 'title' && type !== 'ClickAndRevealInteractions') ||
      (fieldType === 'text' && type === 'ClickAndRevealInteractions')
    ) {
      content[index] = { ...content[index], title: value };
    } else {
      content[index] = { ...content[index], text: value };
    }
    handleChange({
      parameters: content,
      errors: fail,
    });
  };

  const getSectionHeaderTitle = () => {
    switch (type) {
      case 'ClickAndRevealInteractions':
        return 'Click Content *';
      case 'flipcard':
        return 'Front View *';
      default:
        return 'Section Header *';
    }
  };

  const getSectionBodyTitle = () => {
    switch (type) {
      case 'ClickAndRevealInteractions':
        return 'Reveal Content *';
      case 'flipcard':
        return 'Back View *';
      default:
        return 'Section Body *';
    }
  };

  const getEditorContent = () => {
    return type === 'flipcard' ? parameter[index]['fronttext'] : parameter[index]['text'];
  };

  const getSecondEditorContent = () => {
    return type === 'flipcard'
      ? parameter[index]['backtext']
      : parameter[index][`${type !== 'ClickAndRevealInteractions' ? 'text' : 'title'}`];
  };
  return (
    <div role='button' tabIndex={0} onKeyDown={() => {}}>
      <Form.Group className='section-content'>
        <Form.Label className='title' htmlFor={panel[0]}>
          {getSectionHeaderTitle()}
        </Form.Label>
        {type === 'ClickAndRevealInteractions' || type === 'flipcard' ? (
          <>
            <LoreeInteractiveEditor
              initialValue={getEditorContent() ?? CONSTANTS.LOREE_EDITOR_EMPTY_CONTENT}
              editorConfig={editorConfig}
              updateEditorContent={(value: string) =>
                handleEditor(index, value, type === 'flipcard' ? 'fronttext' : 'title')
              }
              indexValue={props.indexValue[0]}
            />
          </>
        ) : (
          <FormControl
            className='lint-custom-input '
            id={panel[0]}
            name={panel[0]}
            type='text'
            onChange={(event) => {
              handleTitleChange(index, event);
            }}
            value={parameter[index]['title']}
            autoComplete='off'
          />
        )}
        {errors ? (
          <Form.Text className='text-danger'>
            {type !== 'ClickAndRevealInteractions' ? errors[`${panel[0]}`] : errors[`${panel[1]}`]}
          </Form.Text>
        ) : null}
      </Form.Group>
      <Form.Group className='mb-0 pt-2'>
        <Form.Label className='title' htmlFor={panel[1]}>
          {getSectionBodyTitle()}
        </Form.Label>
        <LoreeInteractiveEditor
          initialValue={getSecondEditorContent() ?? CONSTANTS.LOREE_EDITOR_EMPTY_CONTENT}
          editorConfig={editorConfig}
          updateEditorContent={(value: string) =>
            handleEditor(index, value, type === 'flipcard' ? 'backtext' : 'text')
          }
          indexValue={props.indexValue[1]}
        />
        {errors ? (
          <Form.Text className='text-danger'>
            {type !== 'ClickAndRevealInteractions' ? errors[`${panel[1]}`] : errors[`${panel[0]}`]}
          </Form.Text>
        ) : null}
      </Form.Group>
    </div>
  );
};

export default SectionBodyComponent;
