import React from 'react';
import { Form, ButtonToolbar, ButtonGroup, Button } from 'react-bootstrap';
import { ReactComponent as DefaultAlignment } from '../../assets/icons/Vertical_Centre_alignment.svg';
import { ReactComponent as TopAlignment } from '../../assets/icons/Top_alignment.svg';
import { ReactComponent as BottomAlignment } from '../../assets/icons/Bottom_alignment.svg';

interface IconAlignmentComponentProps {
  alignmentValue: string;
  SetAlignmentValue: Function;
}

const IconAlignmentComponent = (props: IconAlignmentComponentProps) => {
  const { alignmentValue, SetAlignmentValue } = props;
  const setAlignmentHandler = (value: string) => {
    SetAlignmentValue({
      iconAlignment: value,
    });
  };
  return (
    <Form.Group className='mb-3' data-testid='alignment'>
      <Form.Label className='design-section-text mb-2'>Alignment</Form.Label>
      <ButtonToolbar>
        <ButtonGroup className='alignment-button-group' size='sm'>
          <Button
            className={`alignment-buttons p-0 mr-2 ${alignmentValue === 'Default' ? 'active' : ''}`}
            value='Default'
            title='Default'
            data-testid='Default'
            onClick={() => setAlignmentHandler('Default')}
          >
            <DefaultAlignment />
          </Button>
          <Button
            className={`alignment-buttons p-0 mr-2 ${alignmentValue === 'Top' ? 'active' : ''}`}
            value='Top'
            title='Top'
            data-testid='Top'
            onClick={() => setAlignmentHandler('Top')}
          >
            <TopAlignment />
          </Button>
          <Button
            className={`alignment-buttons p-0 ${alignmentValue === 'Bottom' ? 'active' : ''}`}
            value='Bottom'
            title='Bottom'
            data-testid='Bottom'
            onClick={() => setAlignmentHandler('Bottom')}
          >
            <BottomAlignment />
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    </Form.Group>
  );
};

export default IconAlignmentComponent;
