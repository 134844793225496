/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { ReactComponent as AccordionExpand } from '../../../../assets/icons/accordionExpand.svg';
import { ReactComponent as AccordionHide } from '../../../../assets/icons/accordionHide.svg';
import QuestionTypeCustomize from './questionType';
import BehaviourSettingCustomize from './behaviourSetting';
import SectionCustomize from './sectionCustomize';
import BorderCustomize from './borderCustomize';
import ProgressBarCustomize from './progressBar';
import ButtonCustomize from './buttonCustomize';

const MultipleChoiceCustomizeSection = (props: {
  fontFamilyPropsList: any;
  customHeaderStylesPropsList: { [key: string]: { size: string; font: string } }[];
  id?: string;
}) => {
  const [collapseID, setCollapseId] = useState('Progress Bar');
  const sectionCollection = [
    'Progress Bar',
    'Section',
    'Button',
    'Question Type',
    'Body Border',
    'Behavioural Setting',
  ];

  const toggleCollapse = (id: string) => {
    setCollapseId(id !== collapseID ? id : '');
  };
  return (
    <Accordion className='mx-2' defaultActiveKey='Progress Bar'>
      {sectionCollection.map((sectionTitle: string) => {
        return (
          <Accordion.Item key={sectionTitle} eventKey={sectionTitle} className='border-0 my-3'>
            <Accordion.Header
              as='div'
              className={`custom-header-title m-0 ${
                collapseID === sectionTitle && 'selected-section'
              }`}
              onClick={() => toggleCollapse(sectionTitle)}
            >
              {collapseID === sectionTitle ? <AccordionExpand /> : <AccordionHide />}
              <div className={`px-2 ${collapseID === sectionTitle && 'selected-section'}`}>
                {sectionTitle}
              </div>
            </Accordion.Header>
            <Accordion.Body className='pb-0 px-2 pt-3'>
              <div className='icon-items container-inner'>
                {sectionTitle === 'Progress Bar' && (
                  <ProgressBarCustomize
                    id={props.id}
                    fontFamilyPropsList={props.fontFamilyPropsList}
                    customHeaderStylesPropsList={props.customHeaderStylesPropsList}
                  />
                )}
                {sectionTitle === 'Section' && <SectionCustomize />}
                {sectionTitle === 'Button' && (
                  <ButtonCustomize fontFamilyPropsList={props.fontFamilyPropsList} />
                )}
                {sectionTitle === 'Question Type' && <QuestionTypeCustomize />}
                {sectionTitle === 'Body Border' && <BorderCustomize />}
                {sectionTitle === 'Behavioural Setting' && <BehaviourSettingCustomize />}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};

export default MultipleChoiceCustomizeSection;
