import React, { useState } from 'react';
import { Form, Pagination, Dropdown } from 'react-bootstrap';

const PaginationTanStack = (props: any) => {
  const [pageNumber, setPageNumber] = useState(0);
  const paginationArray = [10, 20, 30, 40, 50, 75, 100, 200];

  const handleNextPage = () => {
    props.handleNextButtonClick();
  };

  return (
    <>
      <Pagination>
        {!props.hasEllipsis && (
          <Pagination.First
            onClick={() => {
              props.table.setPageIndex(0);
            }}
            disabled={!props.table.getCanPreviousPage()}
          />
        )}
        <Pagination.Prev
          onClick={() => {
            props.table.previousPage();
          }}
          disabled={!props.table.getCanPreviousPage()}
        />
        <Pagination.Next
          onClick={() => {
            props.table.nextPage();
          }}
          disabled={!props.table.getCanNextPage()}
        />
        {props.hasEllipsis && (
          <Pagination.Ellipsis disabled={!props.isNextPage} onClick={handleNextPage} />
        )}
        {!props.hasEllipsis && (
          <Pagination.Last
            onClick={() => {
              props.table.setPageIndex(props.table.getPageCount() - 1);
            }}
            disabled={!props.table.getCanNextPage()}
          />
        )}
      </Pagination>
      <div className='current-page-info'>
        <div className='option'>
          <Form.Text className='text-style' style={{ marginTop: 0 }}>
            {props.table.getState().pagination.pageIndex < props.table.getPageCount()
              ? `Page ${
                  props.table.getState().pagination.pageIndex + 1
                } of ${props.table.getPageCount()}`
              : `Page ${props.table.getPageCount()} of ${props.table.getPageCount()}`}
          </Form.Text>
        </div>
        <div className='option'>
          <Form.Control
            data-testid='pagination-search'
            type='text'
            size='sm'
            style={{ width: '46px' }}
            className={pageNumber > props.table.getPageCount() ? 'shadow-none border-danger' : ''}
            defaultValue={props.table.getState().pagination.pageIndex + 1}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (!/\d/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              setPageNumber(Number(e.target.value));
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              if (props.table.getPageCount() < Number(e.target.value)) {
                props.table.setPageIndex(props.table.getPageCount() - 1);
              } else {
                props.table.setPageIndex(page);
              }
            }}
          />
        </div>
      </div>
      <div className='pagination-options'>
        <div className='option'>
          <Dropdown onSelect={(pageSize) => props.table.setPageSize(Number(pageSize))}>
            <Dropdown.Toggle
              variant='secondary'
              id='dropdown-basic'
              className='dropdown-toggle-style'
            >
              {props.table.getState().pagination.pageSize}
              <i className='fa fa-chevron-down dropdown-icon' />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {paginationArray.map((pageSize) => (
                <Dropdown.Item key={pageSize} eventKey={pageSize}>
                  {pageSize}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className='option rowValues'>rows per page</div>
      </div>
    </>
  );
};

export default PaginationTanStack;
