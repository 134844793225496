/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import FontHandlerComponent from '../../../customizeComponentLayout/fontHandler';
import { customStyles } from '../../../customizeComponentLayout/styles';
import ColorPickerComponent from '../../../customizeComponentLayout/colorPicker';
import AlignmentComponent from '../../../customizeComponentLayout/alignment';
import SpaceComponent from '../../../customizeComponentLayout/spaceComponent';
import { getButtonDisplayType } from '../../utils';
import {
  customBgColor,
  customBtnType,
  customFontColor,
  customFontFamily,
  customFontSize,
  customFontWeight,
  customPaddingbottom,
  customPaddingleft,
  customPaddingright,
  customPaddingtop,
  fontColor,
  fontSize,
  fontType,
  fontWeight,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  setAlignment,
  setBgColor,
  setDragAreaType,
} from '../../../../redux/form/form.actions';

interface ButtonCustomizeInterface {
  componentType: string;
  actionType?: string;
}

const mapState = ({ form }: any) => ({
  fontColor: form.fontColor,
  fontFamily: form.fontFamily,
  fontSize: form.fontSize,
  fontWeight: form.fontWeight,
  bgcolor: form.bgcolor,
  dragAreaType: form.dragAreaType,
  paddingTop: form.paddingTop,
  paddingLeft: form.paddingLeft,
  paddingBottom: form.paddingBottom,
  paddingRight: form.paddingRight,
  btnPaddingTop: form.btnPaddingTop,
  btnPaddingLeft: form.btnPaddingLeft,
  btnPaddingBottom: form.btnPaddingBottom,
  btnPaddingRight: form.btnPaddingRight,
  btnFontColor: form.btnFontColor,
  btnFontWeight: form.btnFontWeight,
  btnFontSize: form.btnFontSize,
  btnFontFamily: form.btnFontFamily,
  btnBgColor: form.btnBgColor,
  btnType: form.btnType,
  alignment: form.alignment,
});

const mapDispatch = (dispatch: Function) => ({
  SetFontFamily: (form: {}) => dispatch(fontType(form)),
  SetFontSize: (form: {}) => dispatch(fontSize(form)),
  SetFontWeight: (form: {}) => dispatch(fontWeight(form)),
  SetBgColor: (form: {}) => dispatch(setBgColor(form)),
  SetFontColor: (form: {}) => dispatch(fontColor(form)),
  DragAreaType: (form: {}) => dispatch(setDragAreaType(form)),
  SetPaddingtop: (form: {}) => dispatch(paddingTop(form)),
  SetPaddingleft: (form: {}) => dispatch(paddingLeft(form)),
  SetPaddingbottom: (form: {}) => dispatch(paddingBottom(form)),
  SetPaddingright: (form: {}) => dispatch(paddingRight(form)),
  SetBtnPaddingTop: (form: {}) => dispatch(customPaddingtop(form)),
  SetBtnPaddingBottom: (form: {}) => dispatch(customPaddingbottom(form)),
  SetBtnPaddingRight: (form: {}) => dispatch(customPaddingright(form)),
  SetBtnPaddingLeft: (form: {}) => dispatch(customPaddingleft(form)),
  SetBtnFontFamily: (form: {}) => dispatch(customFontFamily(form)),
  SetBtnFontWeight: (form: {}) => dispatch(customFontWeight(form)),
  SetBtnFontSize: (form: {}) => dispatch(customFontSize(form)),
  SetBtnFontColor: (form: {}) => dispatch(customFontColor(form)),
  SetBtnBgColor: (form: {}) => dispatch(customBgColor(form)),
  CustomBtnType: (form: {}) => dispatch(customBtnType(form)),
  Setalignment: (form: {}) => dispatch(setAlignment(form)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = { fontFamilyPropsList: any } & ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ButtonCustomizeInterface;

const ButtonCustomize = (props: Props) => {
  const {
    componentType,
    paddingLeft,
    paddingBottom,
    paddingTop,
    paddingRight,
    btnPaddingBottom,
    btnPaddingLeft,
    btnPaddingRight,
    btnPaddingTop,
    btnFontColor,
    fontColor,
    btnBgColor,
    bgcolor,
    btnType,
    dragAreaType,
    fontFamily,
    btnFontFamily,
    fontSize,
    btnFontSize,
    actionType,
    CustomBtnType,
    DragAreaType,
    SetBtnPaddingTop,
    SetBtnPaddingBottom,
    SetBtnPaddingRight,
    SetBtnPaddingLeft,
    SetPaddingtop,
    SetPaddingbottom,
    SetPaddingleft,
    SetPaddingright,
    SetBtnBgColor,
    SetBtnFontColor,
    SetFontColor,
    SetBgColor,
    SetFontSize,
    SetFontFamily,
    SetBtnFontSize,
    SetBtnFontFamily,
  } = props;
  const isButtonCustomise = componentType === 'Button';

  useEffect(() => {
    if (actionType !== 'edit' && componentType !== 'Button') {
      SetBgColor({
        bgcolor: '#112299',
      });
      SetFontColor({
        fontColor: '#ffffff',
      });
    }
  }, [actionType, componentType, SetBgColor, SetFontColor]);

  const setButtonType = (value: { btnType: string }) => {
    const { btnType } = value;
    if (isButtonCustomise) {
      CustomBtnType({
        btnType: btnType,
      });
    } else {
      DragAreaType({
        dragAreaType: btnType,
      });
    }
  };

  const setPaddingTop = (value: { customPaddingtop: string }) => {
    const { customPaddingtop } = value;
    if (isButtonCustomise) {
      SetBtnPaddingTop({
        btnPaddingTop: customPaddingtop,
      });
    } else {
      SetPaddingtop({
        paddingTop: customPaddingtop,
      });
    }
  };

  const setPaddingRight = (value: { customPaddingright: string }) => {
    const { customPaddingright } = value;
    if (isButtonCustomise) {
      SetBtnPaddingRight({
        btnPaddingRight: customPaddingright,
      });
    } else {
      SetPaddingright({
        paddingRight: customPaddingright,
      });
    }
  };

  const setPaddingBottom = (value: { customPaddingbottom: string }) => {
    const { customPaddingbottom } = value;
    if (isButtonCustomise) {
      SetBtnPaddingBottom({
        btnPaddingBottom: customPaddingbottom,
      });
    } else {
      SetPaddingbottom({
        paddingBottom: customPaddingbottom,
      });
    }
  };

  const setPaddingLeft = (value: { customPaddingleft: string }) => {
    const { customPaddingleft } = value;
    if (isButtonCustomise) {
      SetBtnPaddingLeft({
        btnPaddingLeft: customPaddingleft,
      });
    } else {
      SetPaddingleft({
        paddingLeft: customPaddingleft,
      });
    }
  };

  const setFontColor = (value: { fontColor: string }) => {
    if (isButtonCustomise) {
      SetBtnFontColor({
        btnFontColor: value.fontColor,
      });
    } else {
      SetFontColor(value);
    }
  };

  const setBgColor = (value: { bgcolor: string }) => {
    if (isButtonCustomise) {
      SetBtnBgColor({
        btnBgColor: value.bgcolor,
      });
    } else {
      SetBgColor(value);
    }
  };

  const setFontSize = (value: { fontSize: string }) => {
    if (isButtonCustomise) {
      SetBtnFontSize({
        btnFontSize: value.fontSize,
      });
    } else {
      SetFontSize(value);
    }
  };

  const setFontFamily = (value: { fontFamily: string }) => {
    if (isButtonCustomise) {
      SetBtnFontFamily({
        btnFontFamily: value.fontFamily,
      });
    } else {
      SetFontFamily(value);
    }
  };
  return (
    <>
      <FontHandlerComponent
        componentType='Type'
        setFontProperty={setButtonType}
        fontPropertyValue={getButtonDisplayType(isButtonCustomise ? btnType : dragAreaType)}
        options={customStyles.buttonType}
      />
      {isButtonCustomise && (
        <AlignmentComponent
          alignmentValue={props.alignment}
          SetAlignmentValue={props.Setalignment}
        />
      )}
      <SpaceComponent
        componentType='Padding'
        left={isButtonCustomise ? btnPaddingLeft : paddingLeft}
        top={isButtonCustomise ? btnPaddingTop : paddingTop}
        right={isButtonCustomise ? btnPaddingRight : paddingRight}
        bottom={isButtonCustomise ? btnPaddingBottom : paddingBottom}
        SetLeft={setPaddingLeft}
        SetTop={setPaddingTop}
        SetRight={setPaddingRight}
        SetBottom={setPaddingBottom}
      />
      <FontHandlerComponent
        componentType='Font Family'
        setFontProperty={setFontFamily}
        fontPropertyValue={isButtonCustomise ? btnFontFamily : fontFamily}
        options={customStyles.fontFamily}
        customFonts={props.fontFamilyPropsList}
      />
      <FontHandlerComponent
        componentType='Font Size'
        setFontProperty={setFontSize}
        fontPropertyValue={isButtonCustomise ? btnFontSize : fontSize}
        options={customStyles.fontSize}
      />
      <ColorPickerComponent
        type={`${componentType}_fontColor`}
        title='Font Color'
        SetColor={setFontColor}
        color={isButtonCustomise ? btnFontColor : fontColor}
      />
      <ColorPickerComponent
        type={`${componentType}_bgColor`}
        title='Background Color'
        SetColor={setBgColor}
        color={isButtonCustomise ? btnBgColor : bgcolor}
      />
    </>
  );
};

export default connector(ButtonCustomize);
