import { Extension } from '@tiptap/core';
import { styleAttribute } from './utilityFunctions';
import { ExtensionOptions } from '../editorUtilityFunctions/lintEditorType';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    textIndentSpacing: {
      setTextIndent: (indentValue: string) => ReturnType;
    };
  }
}

export const TextIndent = Extension.create<ExtensionOptions>({
  name: 'TextIndent',

  addOptions() {
    return {
      types: ['paragraph', 'heading', 'CustomizedHeading'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          textIndent: styleAttribute('textIndent', 'text-indent'),
        },
      },
    ];
  },

  addCommands() {
    return {
      setTextIndent:
        (indentValue: string) =>
        ({ commands }) => {
          return commands.updateNodeAttributes({
            textIndent: indentValue,
          });
        },
    };
  },
});
