import { callOrReturn, getExtensionField, Node, ParentConfig } from '@tiptap/core';
import { TextSelection, Selection, Plugin } from 'prosemirror-state';
import { LoreeInteractiveEditorDashboardContentType } from '../editorUtilityFunctions/lintEditorType';
import {
  addColumnAfter,
  addColumnBefore,
  addRowAfter,
  addRowBefore,
  deleteColumn,
  deleteRow,
  deleteTable,
  goToNextCell,
  mergeCells,
  setCellAttr,
  splitCell,
  toggleHeader,
  toggleHeaderCell,
} from './tableElementContent/tableSectionCommands';
import { deleteTableWhenAllCellsSelected } from './tableElementContent/tableCommands';
import { Fragment } from 'prosemirror-model';
import { simpleElementNodeView } from './utilityFunctions';
import { createTable } from './tableElementContent/tableUtils';
import { CellSelection } from './tableElementContent/cellSelection';
import { TableView, TableViewConstructor } from './tableElementContent/tableView';
import { columnResizing } from './tableElementContent/columnResizing';
import { arrow, shiftArrow } from './tableElementContent/tableInput';
import { tableEditing } from './tableElementContent/tableEditing';

export interface TableOptions {
  HTMLAttributes: Record<string, never>;
  resizable: boolean;
  handleWidth: number;
  cellMinWidth: number;
  View: TableViewConstructor;
  lastColumnResizable: boolean;
  allowTableNodeSelection: boolean;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    tables: {
      insertTable: (options?: {
        rows?: number;
        cols?: number;
        withHeaderRow?: boolean;
        withHeaderColumn?: boolean;
        captionValue?: string;
        editorConfig?: LoreeInteractiveEditorDashboardContentType;
      }) => ReturnType;
      addColumnBefore: (placeholder?: Fragment[]) => ReturnType;
      addColumnAfter: (placeholder?: Fragment[]) => ReturnType;
      deleteColumn: () => ReturnType;
      addRowBefore: (placeholder?: Fragment[]) => ReturnType;
      addRowAfter: (placeholder?: Fragment[]) => ReturnType;
      deleteRow: () => ReturnType;
      deleteTable: () => ReturnType;
      mergeCells: () => ReturnType;
      splitCell: (placeholder?: Fragment[]) => ReturnType;
      toggleHeaderColumn: () => ReturnType;
      toggleHeaderRow: () => ReturnType;
      toggleHeaderCell: () => ReturnType;
      mergeOrSplit: () => ReturnType;
      setCellAttribute: (name: string, value: unknown) => ReturnType;
      goToNextCell: () => ReturnType;
      goToPreviousCell: () => ReturnType;
      fixTables: () => ReturnType;
      setCellSelection: (position: { anchorCell: number; headCell?: number }) => ReturnType;
      selectTableRowColumn: (axis: 'horiz' | 'vert', dir: number) => ReturnType;
      setSelectionToStart: (axis: 'horiz' | 'vert', dir: number) => ReturnType;
    };
  }

  interface NodeConfig<Options, Storage> {
    /**
     * Table Role
     */
    tableRole?:
      | string
      | ((this: {
          name: string;
          options: Options;
          storage: Storage;
          parent: ParentConfig<NodeConfig<Options>>['tableRole'];
        }) => string);
  }
}

export const Table = Node.create<TableOptions>({
  name: 'table',

  addOptions() {
    return {
      HTMLAttributes: {},
      resizable: true,
      handleWidth: 5,
      cellMinWidth: 25,
      View: TableView, //: TableView as unknown as NodeView,
      lastColumnResizable: true,
      allowTableNodeSelection: true,
    };
  },

  content: '(caption|tableColGroup|tableHead|tableBody|tableRow)*',

  tableRole: 'table',
  isolating: true,
  group: 'block',
  priority: 1002,
  resizable: true,

  parseHTML() {
    return [{ tag: 'table' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['table', HTMLAttributes, 0];
  },

  addAttributes() {
    return {
      'data-colwidths': {
        default: null,
        rendered: false,
        renderHTML: (attr) => {
          if (!attr['data-colwidths']) {
            return {};
          }
          const widths = attr['data-colwidths'] as number[];
          const sum = widths.reduce((t, v) => t + v, 0);
          if (widths.filter((x) => x === 0).length > 0 && sum > 0) {
            return { style: `minWidth: ${sum}px` };
          }
          if (widths.filter((x) => x === 0).length == 0 && sum > 0) {
            return { style: `width: ${sum}px` };
          }
          return {};
        },
        style: { default: null },
      },
    };
  },

  addNodeView() {
    return simpleElementNodeView('table');
  },

  addCommands() {
    return {
      insertTable:
        ({
          rows = 3,
          cols = 3,
          withHeaderRow = true,
          withHeaderColumn = false,
          captionValue = 'Loree Interactive tables',
          editorConfig,
        } = {}) =>
        ({ tr, dispatch, editor }) => {
          const node = createTable(
            editor.schema,
            rows,
            cols,
            withHeaderRow,
            withHeaderColumn,
            captionValue,
            editor,
            editorConfig,
          );
          if (dispatch) {
            const offset = tr.selection.anchor + 1;
            tr.replaceSelectionWith(node)
              .scrollIntoView()
              .setSelection(TextSelection.near(tr.doc.resolve(offset)));
          }
          return true;
        },
      addColumnBefore:
        (placeholder?: Fragment[]) =>
        ({ state, dispatch }) => {
          return addColumnBefore(state, placeholder, dispatch);
        },
      addColumnAfter:
        (placeholder?: Fragment[]) =>
        ({ state, dispatch }) => {
          return addColumnAfter(state, placeholder, dispatch);
        },
      deleteColumn:
        () =>
        ({ state, dispatch }) => {
          return deleteColumn(state, dispatch);
        },
      addRowBefore:
        (placeholder?: Fragment[]) =>
        ({ state, dispatch }) => {
          return addRowBefore(state, placeholder, dispatch);
        },
      addRowAfter:
        (placeholder?: Fragment[]) =>
        ({ state, dispatch }) => {
          return addRowAfter(state, placeholder, dispatch);
        },
      deleteRow:
        () =>
        ({ state, dispatch }) => {
          return deleteRow(state, dispatch);
        },
      deleteTable:
        () =>
        ({ state, dispatch }) => {
          return deleteTable(state, dispatch);
        },
      mergeCells:
        () =>
        ({ state, dispatch }) => {
          return mergeCells(state, dispatch);
        },
      splitCell:
        (placeholder?: Fragment[]) =>
        ({ state, dispatch }) => {
          return splitCell(state, placeholder, dispatch);
        },
      toggleHeaderColumn:
        () =>
        ({ state, dispatch }) => {
          return toggleHeader('column')(state, dispatch);
        },
      toggleHeaderRow:
        () =>
        ({ state, dispatch }) => {
          return toggleHeader('row')(state, dispatch);
        },
      toggleHeaderCell:
        () =>
        ({ state, dispatch }) => {
          return toggleHeaderCell(state, dispatch);
        },
      mergeOrSplit:
        (placeholder?: Fragment[]) =>
        ({ state, dispatch }) => {
          if (mergeCells(state, dispatch)) {
            return true;
          }
          return splitCell(state, placeholder, dispatch);
        },
      setCellAttribute:
        (name, value) =>
        ({ state, dispatch }) => {
          return setCellAttr(name, value)(state, dispatch);
        },
      goToNextCell:
        () =>
        ({ state, dispatch }) => {
          return goToNextCell(1)(state, dispatch);
        },
      goToPreviousCell:
        () =>
        ({ state, dispatch }) => {
          return goToNextCell(-1)(state, dispatch);
        },
      setCellSelection:
        (position) =>
        ({ tr, dispatch }) => {
          if (dispatch) {
            const selection = CellSelection.create(tr.doc, position.anchorCell, position.headCell);
            tr.setSelection(selection as unknown as Selection);
          }
          return true;
        },
      selectTableRowColumn:
        (axis: 'horiz' | 'vert', dir: number) =>
        ({ state, dispatch }) => {
          return shiftArrow(axis, dir)(state, dispatch);
        },
      setSelectionToStart:
        (axis: 'horiz' | 'vert', dir: number) =>
        ({ state, dispatch }) => {
          return arrow(axis, dir)(state, dispatch);
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      Tab: () => {
        if (this.editor.state.selection instanceof CellSelection) {
          if (this.editor.commands.goToNextCell()) {
            return true;
          }
          if (!this.editor.can().addRowAfter()) {
            return false;
          }
          return this.editor.chain().addRowAfter().goToNextCell().run();
        }
        return false;
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Shift-Tab': () => {
        if (this.editor.state.selection instanceof CellSelection) {
          this.editor.commands.goToPreviousCell();
          return true;
        }
        return false;
      },
      Backspace: deleteTableWhenAllCellsSelected,
      'Mod-Backspace': deleteTableWhenAllCellsSelected,
      Delete: deleteTableWhenAllCellsSelected,
      'Mod-Delete': deleteTableWhenAllCellsSelected,
    };
  },

  addProseMirrorPlugins() {
    const isResizable = this.options.resizable && this.editor.isEditable;

    return [
      ...(isResizable
        ? [
            columnResizing({
              handleWidth: this.options.handleWidth,
              cellMinWidth: this.options.cellMinWidth,
              nodeView: this.options.View,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore (incorrect type)
              lastColumnResizable: this.options.lastColumnResizable,
            }),
          ]
        : []),
      tableEditing({
        allowTableNodeSelection: this.options.allowTableNodeSelection,
      }),
    ] as unknown as Plugin[];
  },

  extendNodeSchema(extension) {
    const context = {
      name: extension.name,
      options: extension.options,
      storage: extension.storage,
    };

    return {
      tableRole: callOrReturn(getExtensionField(extension, 'tableRole', context)),
    };
  },
});
