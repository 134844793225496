/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { setDropzoneBgColor, setDropzoneOpacity } from '../../../../redux/form/form.actions';
import ColorPickerComponent from '../../../customizeComponentLayout/colorPicker';

const mapState = ({ form }: any) => ({
  dropzoneBgColor: form.dropzoneBgColor,
  dropzoneOpacity: form.dropzoneOpacity,
});

const mapDispatch = (dispatch: Function) => ({
  SetBgColor: (form: {}) => dispatch(setDropzoneBgColor(form)),
  SetOpacity: (form: {}) => dispatch(setDropzoneOpacity(form)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const DropZoneCustomize = (props: Props) => {
  const { dropzoneBgColor, SetBgColor, dropzoneOpacity, SetOpacity } = props;

  const handleOpacity = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    const finalValue = Number(value);
    if (finalValue < 0 || finalValue > 1) return;
    SetOpacity({
      dropzoneOpacity: value,
    });
  };

  return (
    <>
      <ColorPickerComponent
        type='dropzoneBgColor'
        title='Background Color'
        SetColor={SetBgColor}
        color={dropzoneBgColor}
      />
      <Form.Group className='d-flex position-relative justify-content-between'>
        <Form.Label className='design-section-text'>Opacity</Form.Label>
        <Form.Control
          className='w-50'
          type='number'
          min='0'
          max='1'
          name='opacity'
          step='0.1'
          size='sm'
          value={dropzoneOpacity}
          onChange={handleOpacity}
        />
      </Form.Group>
    </>
  );
};

export default connector(DropZoneCustomize);
